import * as qs from 'query-string';

import { apiUrl, getDefaultGetInit, parseStandardResponse } from '../utils/api';

const SEARCH_PATH = 'search';

export async function search(params) {
	const url = `${apiUrl}/${SEARCH_PATH}?${qs.stringify(params)}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function searchAllSongs(params) {
	const url = `${apiUrl}/${SEARCH_PATH}/songs?${qs.stringify({
		...params,
		page: params.page || 1,
		sorter: params.sorter || '',
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getSongsByCollection({ collection, term, identifier, limit, page = 1, sorter = '' }) {
	const url = `${apiUrl}/${SEARCH_PATH}/${collection}/${term}/${identifier}/songs?${qs.stringify({
		limit,
		page,
		sorter,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function searchCollection({ collection, term, limit, page = 1 }) {
	const url = `${apiUrl}/${SEARCH_PATH}/${collection}?${qs.stringify({
		term,
		limit,
		page,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getSuggestions({ term, limit }) {
	const url = `${apiUrl}/${SEARCH_PATH}/suggestions/?${qs.stringify({
		term,
		limit,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getSong({ songId }) {
	const url = `${apiUrl}/${SEARCH_PATH}/songs/${songId}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}
