import React, { useContext, useEffect, useState } from 'react';

import { PLAYLIST_STATUS, PLAYLIST_STATUS_MAP } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';
import * as qs from 'query-string';

import { getExternalShowPlaylists, exportShowPlaylistsReportCSV } from '../api/playlists';
import Loader from '../components/Loader';
import { PaginationButtons } from '../components/PaginationButtons';
import StatusBadge from '../components/StatusBadge';
import STATUS_BADGE_COLOUR from '../constants/status-badge-colours';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import { ReactComponent as DownloadImg } from '../images/download.svg';
import { ReactComponent as SearchIcon } from '../images/search-black.svg';
import { getISODateTimeString } from '../utils/date-time';

const DEFAULT_PAGE_SIZE = 200;
const QUERY_KEY = 'assetId';

const parseSearch = search => {
	let query = qs.parse(search)[QUERY_KEY];

	if (query) {
		[QUERY_KEY].forEach(k => {
			query = query.replace(new RegExp(k, 'gi'), ` ${k}`); // prepend whitespace
		});
		return query.replace(/\s\s+/g, ' ').trim();
	}
	return null;
};

const ShowPlaylists = ({ history, location }) => {
	const parsedQueryTerm = parseSearch(decodeURIComponent(location.search)) || '';

	const [externalPlaylists, setExternalPlaylists] = useState([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
	const [total, setTotal] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const [termQuery, setTermQuery] = useState(parsedQueryTerm);

	const { createToast } = useContext(GlobalContext);

	useEffect(() => {
		fetchExternalShowPlaylists(DEFAULT_PAGE_SIZE, 1, termQuery);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		history.push(`/show-playlists?${QUERY_KEY}=${encodeURIComponent(termQuery.replace('&', '%26'))}`);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [termQuery]);

	const handleExternalPlaylistClick = globalTrackerShowId => {
		history.push(`/show-playlist/${globalTrackerShowId}`);
	};

	const downloadReport = async () => {
		createToast('Generating Show Playlists Report...', TOAST_LEVEL.INFO, true);
		const data = await exportShowPlaylistsReportCSV();

		const csvURL = window.URL.createObjectURL(data);
		const tempLink = document.createElement('a');
		tempLink.href = csvURL;
		tempLink.setAttribute('download', `variis_show_playlists_${getISODateTimeString()}.csv`);
		tempLink.click();
		tempLink.remove();
		window.URL.revokeObjectURL(data);
	};

	const fetchExternalShowPlaylists = (pageSize, page, globalTrackerShowIds) => {
		setLoaded(false);

		getExternalShowPlaylists({
			limit: pageSize,
			page,
			globalTrackerShowIds: globalTrackerShowIds
				? globalTrackerShowIds
						.split(',')
						.filter(id => id.trim() !== '')
						.map(id => `%${id.trim().toLowerCase()}%`)
						.join(',')
				: null,
		})
			.then(response => {
				setPage(page);
				setPageSize(pageSize);
				setTotal(response.count);
				setExternalPlaylists(
					(response.data || []).map(playlist => ({
						...playlist,
						badge_status: playlist.valid
							? PLAYLIST_STATUS_MAP[PLAYLIST_STATUS.VALID]
							: PLAYLIST_STATUS_MAP[PLAYLIST_STATUS.INVALID],
						badge_colour: playlist.valid
							? STATUS_BADGE_COLOUR[PLAYLIST_STATUS.VALID]
							: STATUS_BADGE_COLOUR[PLAYLIST_STATUS.INVALID],
					}))
				);
			})
			.catch(() => {
				createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
				setPage(page);
				setPageSize(pageSize);
				setTotal(0);
				setExternalPlaylists([]);
			})
			.finally(() => {
				setLoaded(true);
			});
	};

	const handleSearchQuery = e => {
		setTermQuery(e.target.value);
	};

	const numPages = Math.ceil(total / pageSize);
	return (
		<div>
			<div className='show-playlists__header'>
				<h3 className='h3 mb-5'>Show Playlists</h3>
				<button
					name='ShowPlayLists - Download CSV'
					className='secondary-btn'
					disabled={!total}
					onClick={() => downloadReport()}
					type='button'
				>
					<DownloadImg /> Download CSV
				</button>
			</div>
			<div className='song-table__controls__row'>
				<div className='search-bar__container'>
					<div className='search-bar__input'>
						<SearchIcon className='search-bar__input__image' />
						<input
							type='search'
							placeholder='Search by comma-separated partial Names (Asset IDs)'
							value={termQuery}
							onChange={e => handleSearchQuery(e)}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									fetchExternalShowPlaylists(DEFAULT_PAGE_SIZE, 1, termQuery);
								}
							}}
							aria-label='Search'
						/>
					</div>
				</div>
			</div>
			<table className='show-playlists__table mb-1'>
				<thead>
					<tr className='show-playlists__table__header__row'>
						<th className='show-playlists__table__header__cell'>Name (Asset ID)</th>
						<th className='show-playlists__table__header__cell'>Number of Songs in Playlist</th>
						<th className='show-playlists__table__header__cell'>Number of Cleared Songs in Playlist</th>
						<th className='show-playlists__table__header__cell'>Status</th>
						<th className='show-playlists__table__header__cell'>Territory</th>
						<th className='show-playlists__table__header__cell'>Clearance</th>
					</tr>
				</thead>

				<tbody>
					{!loaded ? (
						<tr>
							<td colSpan={5}>
								<Loader loaded={loaded} />
							</td>
						</tr>
					) : (
						externalPlaylists.map(p => (
							<tr
								key={p.global_tracker_show_id}
								className={`show-playlists__table__row show-playlists__table__row--link
						${p.valid ? 'show-playlists__table__row--valid' : 'show-playlists__table__row--invalid'}`}
								onClick={() => handleExternalPlaylistClick(p.global_tracker_show_id)}
							>
								<td className='show-playlists__table__row__cell'>
									<b>{p.global_tracker_show_id}</b>
								</td>
								<td className='show-playlists__table__row__cell'>{p.num_songs}</td>
								<td className='show-playlists__table__row__cell'>{p.num_valid_songs}</td>
								<td className='show-playlists__table__row__cell'>{p.status || ''}</td>
								<td className='show-playlists__table__row__cell'>{p.territory || ''}</td>
								<td className='show-playlists__table__row__cell'>
									<div className={`playlist__header__badge ${p.valid ? 'ml-2 w-50' : 'w-75'}`}>
										<StatusBadge status={p.badge_status} flagged={!p.valid} className={p.badge_colour} />
									</div>
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>
			{numPages > 1 && loaded && (
				<div className='d-flex align-items-center mt-4'>
					<PaginationButtons
						handlePageChange={nextPage => {
							fetchExternalShowPlaylists(pageSize, nextPage);
						}}
						numPages={numPages}
						pageNum={page || 1}
					/>
				</div>
			)}
		</div>
	);
};

ShowPlaylists.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ShowPlaylists;
