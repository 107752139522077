import React from 'react';

import { SCHEDULED_JOBS_STATUSES, SCHEDULED_JOBS } from '@content-playlist-creation/common/constants/scheduled-jobs';
import cronstrue from 'cronstrue';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as healthAPI from '../api/health';
import * as utilsAPI from '../api/utils';
import AlertModal from '../components/AlertModal';
import DataTable from '../components/layout/DataTable';
import Loader from '../components/Loader';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import { ReactComponent as ScheduledJobCompletedImg } from '../images/scheduled-job-completed.svg';
import { ReactComponent as ScheduledJobFailedImg } from '../images/scheduled-job-failed.svg';
import { ReactComponent as ScheduledJobRunningImg } from '../images/scheduled-job-running.svg';
import { isAdministrator } from '../utils/auth';

class HealthDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loadedSanityChecks: false,
			loadedRunningJob: true,
			loadedSanityJobStatuses: false,
			sanityChecksData: {},
			jobStatuses: [],
			showConfirmationModal: false,
			confirmationModal: '',
			confirmationCallback: null,
		};
	}

	componentDidMount() {
		const { history } = this.props;

		if (!isAdministrator()) {
			history.push('/');
		} else {
			this.fetchScheduledJobsStatuses();
			this.fetchSanityChecks();
			// Fetch new statuses every 30 seconds
			this.fetcher = setInterval(() => {
				this.fetchScheduledJobsStatuses();
				this.fetchSanityChecks();
			}, 10 * 1000);
		}
	}

	componentWillUnmount() {
		if (this.fetcher) {
			clearTimeout(this.fetcher);
			this.fetcher = 0;
		}
	}

	fetchSanityChecks = async () => {
		await healthAPI.getSanityChecksStatuses().then(data => {
			this.setState({ sanityChecksData: data, loadedSanityChecks: true });
		});
	};

	fetchScheduledJobsStatuses = async () => {
		await healthAPI.getScheduledJobsHealth().then(data => {
			this.setState({ jobStatuses: data, loadedSanityJobStatuses: true });
		});
	};

	triggerReindex = async (index = null) => {
		await utilsAPI.triggerReindex({ index, destructiveReindex: true }).then(async () => {
			await this.fetchScheduledJobsStatuses();
		});
	};

	onResetJobStatus = async job => {
		await healthAPI.resetJobStatus(job.key).then(data => {
			this.setState({ jobStatuses: data, loadedSanityJobStatuses: true });
		});
	};

	onRunJob = async job => {
		const { createToast } = this.context;
		this.setState({ loadedRunningJob: false });
		await healthAPI
			.runJob(job.key)
			.then(async data => {
				this.setState({ jobStatuses: data, loadedSanityJobStatuses: true, loadedRunningJob: true });
			})
			.catch(err => {
				createToast('There was a problem triggering this job.', TOAST_LEVEL.ERROR, true);
			});
	};

	renderScheduleJobRow = job => {
		const { loadedRunningJob } = this.state;
		let executedDate = new Date().toLocaleDateString();

		let icon = null;

		if (job.status === SCHEDULED_JOBS_STATUSES.FAILED) {
			executedDate = new Date(job.last_error_timestamp).toLocaleString();
			icon = (
				<div className='scheduled-job scheduled-job--failed'>
					<ScheduledJobFailedImg />
				</div>
			);
		} else if (job.status === SCHEDULED_JOBS_STATUSES.COMPLETED) {
			executedDate = new Date(job.last_execution_time).toLocaleString();
			icon = (
				<div className='scheduled-job scheduled-job--completed'>
					<ScheduledJobCompletedImg />
				</div>
			);
		} else if (job.status === SCHEDULED_JOBS_STATUSES.RUNNING) {
			icon = (
				<div className='scheduled-job scheduled-job--running'>
					<ScheduledJobRunningImg />
				</div>
			);
		}

		return [
			icon,
			job.name,
			job.description,
			job.cron_expression ? `${cronstrue.toString(job.cron_expression, { verbose: true })} (UTC) ` : '',
			<div key={`${job.key}_STATUS`} className='scheduled-job'>
				<span className={`scheduled-job--${job.status.toLowerCase()} scheduled-job__status`}>{job.status}</span>
			</div>,
			executedDate,
			(job.status === SCHEDULED_JOBS_STATUSES.FAILED ? job.last_error_message : job.log_message) || '',
			<div key={`${job.key}_ACTIONS`}>
				{job.can_reset && job.status === SCHEDULED_JOBS_STATUSES.FAILED && (
					<button
						name='HealthDashboard - Reset status'
						className='scheduled-job__action-btn secondary-btn'
						type='button'
						onClick={() => this.onResetJobStatus(job)}
					>
						Reset status
					</button>
				)}
				{job.can_run &&
					job.status !== SCHEDULED_JOBS_STATUSES.RUNNING &&
					job.status !== SCHEDULED_JOBS_STATUSES.STARTING && (
						<button
							name='HealthDashboard - Run Job'
							className='scheduled-job__action-btn secondary-btn'
							type='button'
							disabled={!loadedRunningJob}
							onClick={() => this.onRunJob(job)}
						>
							Run Job
						</button>
					)}
			</div>,
		];
	};

	renderSanityChecks = sanityChecks => {
		return Object.keys(sanityChecks || {}).map(sanityCheckKey => {
			return (
				<div className='sanity-check' key={`${sanityCheckKey.trim().toLowerCase().replace(/\s+/g, '-')}`}>
					<div className='sanity-check__text'>{sanityCheckKey}</div>
					<div className={`sanity-check__number ${sanityChecks[sanityCheckKey] > 0 ? 'error' : ''}`}>
						{sanityChecks[sanityCheckKey]}
					</div>
				</div>
			);
		});
	};

	displayConfirmationModal = (message, callback) => {
		this.setState({
			confirmationModal: `Are you sure you want to perform ${message}?`,
			showConfirmationModal: true,
			confirmationCallback: callback,
		});
	};

	render() {
		const {
			loadedSanityChecks,
			sanityChecksData,
			loadedSanityJobStatuses,
			jobStatuses,
			showConfirmationModal,
			confirmationModal,
			confirmationCallback,
			loadedRunningJob,
		} = this.state;
		// eslint-disable-next-line camelcase
		const { updated_timestamp, ...sanityChecks } = sanityChecksData;
		const updatedSanityCheck = new Date(updated_timestamp).toLocaleString();
		const canPerformReindex =
			loadedSanityJobStatuses &&
			!(jobStatuses || []).some(
				job =>
					[SCHEDULED_JOBS.REINDEX_PLAYLISTS_INDEX, SCHEDULED_JOBS.REINDEX_SONGS_INDEX].includes(job.key) &&
					job.status === SCHEDULED_JOBS_STATUSES.RUNNING
			);

		return (
			<>
				<AlertModal
					title='Confirm Operation'
					description={confirmationModal}
					showModal={showConfirmationModal}
					onConfirmClick={() => {
						confirmationCallback().then(this.setState({ showConfirmationModal: false }));
					}}
					onCancelClick={() => this.setState({ showConfirmationModal: false, confirmationModal: '' })}
				/>
				<div className='health-dashboard'>
					<h3 className='h3 mb-3'>Health Dashboard</h3>
					<div className='health-dashboard__sanity-checks'>
						<h4 className='h4 mt-4'>Sanity Checks</h4>
						<hr />
						<div>
							Music Catalog sanity checks to ensure data quality, every check must be <b>0</b> in order to be consider
							valid. These checks are executed by <code>Catalog sanity checks</code> job.
						</div>
						<Loader loaded={loadedSanityChecks}>
							<div className='health-dashboard__sanity-checks__container'>
								<div className='health-dashboard__sanity-checks__row'>{this.renderSanityChecks(sanityChecks)}</div>
							</div>
							<div className='text-right font-italic' style={{ fontSize: '0.75rem' }}>
								Last time updated: {updatedSanityCheck}
							</div>
						</Loader>
					</div>
					<div className='health-dashboard__scheduled-jobs mb-4'>
						<h4 className='h4 mt-4'>Scheduled Job Statuses</h4>
						<hr />
						<Loader loaded={loadedSanityJobStatuses || loadedRunningJob}>
							<DataTable
								headerRow={[
									'',
									'Name',
									'Description',
									'Execution (UTC)',
									'Current Status',
									'Last Executed',
									'Output',
									'Actions',
								]}
								widths={['3%', '10%', '30%', '12%', '10%', '5%', '20%', '10%']}
								data={jobStatuses.map(job => this.renderScheduleJobRow(job))}
								style={{ fontSize: '0.75rem' }}
								fixedLayout={false}
								className='health-dashboard__scheduled-jobs__table'
							/>
						</Loader>
					</div>
					<div className='health-dashboard__search-engine mb-4'>
						<h4 className='h4 mt-4'>Search Engine Actions</h4>
						<hr />
						<p>
							Reindexing indexes will drop existing index and recreate it with up to date data, its required only when
							one of above jobs failed and search result being displayed are not correct
						</p>
						<div className='health-dashboard__alert'>
							Warning! Do not run during peak hours. This action puts a lot of strain on the server. Reindexing Search
							Engine indexes can take several hours to fully reindex music catalog.
						</div>
						<div className='health-dashboard__action-card__container'>
							<div className='health-dashboard__action-card'>
								<div className='health-dashboard__action-card__title'>Reindex Songs index</div>
								<div className='health-dashboard__action-card__description'>
									Updates songs within Search Engine, affects Search Results and Browse Tabs pages.
								</div>
								<div className='health-dashboard__action-card__disclaimer'>* This will take several hours</div>
								<button
									name='HealthDashboard - Reindex'
									type='button'
									className='px-3 primary-btn'
									disabled={!canPerformReindex}
									onClick={() =>
										this.displayConfirmationModal('SONGS index reindex', () => this.triggerReindex('songs'))
									}
								>
									Reindex
								</button>
							</div>

							<div className='health-dashboard__action-card'>
								<div className='health-dashboard__action-card__title'>Reindex Playlists index</div>
								<div className='health-dashboard__action-card__description'>
									Updates playlists data within Search Engine, affects Search Results pages
								</div>
								<div className='health-dashboard__action-card__disclaimer'>* This will take up to 10 minutes</div>
								<button
									name='HealthDashboard - Reindex'
									type='button'
									className='px-3 primary-btn'
									disabled={!canPerformReindex}
									onClick={() =>
										this.displayConfirmationModal('PLAYLISTS index reindex', () => this.triggerReindex('playlists'))
									}
								>
									Reindex
								</button>
							</div>

							<div className='health-dashboard__action-card'>
								<div className='health-dashboard__action-card__title'>Reindex All</div>
								<div className='health-dashboard__action-card__description'>
									Updates songs and playlists within Search Engine, affects Search Results and Browse Tabs pages.
								</div>
								<div className='health-dashboard__action-card__disclaimer'>* This will take several hours</div>
								<button
									name='HealthDashboard - Reindex'
									type='button'
									className='px-3 primary-btn'
									disabled={!canPerformReindex}
									onClick={() => this.displayConfirmationModal('FULL reindex', () => this.triggerReindex())}
								>
									Reindex
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

HealthDashboard.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
};

HealthDashboard.contextType = GlobalContext;

export default withRouter(HealthDashboard);
