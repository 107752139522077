import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Carousel from 'react-slick';

import { ReactComponent as PrevArrow } from '../../images/chevron-left.svg';
import { ReactComponent as NextArrow } from '../../images/chevron-right.svg';

const MAX_ITEMS_DEFAULT = 9;

const getChildren = (children, itemsPerRow) => {
	const isCarouselChildren =
		children && Array.isArray(children) && children.filter(c => c.props.isCarouselItem).length > 0;

	// children with isCarouselItem = true should only display itemsPerRow, children with isCarouselItem = false should display MAX_ITEMS_DEFAULT
	return (
		children &&
		Array.isArray(children) &&
		children.slice(0, isCarouselChildren ? itemsPerRow : MAX_ITEMS_DEFAULT).map((child, i) => (
			<div style={{ width: `${100 / itemsPerRow}%` }} className='mb-3' key={i}>
				{child}
			</div>
		))
	);
};

function CollectionArrow(props) {
	const { children, type, largeCollection, onArrowClick, style, hidden } = props;

	if (hidden) {
		return null;
	}

	return (
		<div
			className={classNames(`collection__children__arrow collection__children__arrow--${type}`, {
				'collection__children__arrow--large': largeCollection,
			})}
			style={{
				...style,
				display: 'flex',
			}}
			onClick={onArrowClick}
		>
			{children}
		</div>
	);
}

class Collection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pageData: [],
			pagesLoaded: [],
			pageNum: 0,
		};

		this.carousel = React.createRef();
		this.swiping = false;
	}

	componentDidMount() {
		this.preloadData();
	}

	componentDidUpdate(prevProps) {
		const { children } = this.props;

		if (prevProps.children.length !== children.length) {
			this.preloadData();
		}
	}

	preloadData = () => {
		const { children, itemsPerRow } = this.props;

		this.setState({
			pageData: (children || []).slice(0, itemsPerRow * 3), // preload 2 pages of data
			pagesLoaded: [0, 1], // preload 2 pages of data
			pageNum: 0,
		});
	};

	onChange = firstElementInSlideIndex => {
		const { itemsPerRow, children } = this.props;
		const { pageData, pagesLoaded } = this.state;
		const nextPageNumOffset = Math.ceil(firstElementInSlideIndex / itemsPerRow) + 1;
		const totalPages = Math.ceil(children.length / itemsPerRow);
		const newState = { pageNum: Math.ceil((firstElementInSlideIndex - 1) / itemsPerRow) };
		if (totalPages > nextPageNumOffset && !pagesLoaded.includes(nextPageNumOffset)) {
			const nextArrowOffset = itemsPerRow;
			// preload new page
			const nextPage = children.slice(
				nextPageNumOffset * itemsPerRow,
				nextPageNumOffset * itemsPerRow + nextArrowOffset
			);

			newState.pagesLoaded = pagesLoaded.concat([nextPageNumOffset]);
			newState.pageData = pageData.concat(nextPage);
		}
		this.setState({ ...newState });
	};

	nextSlide = () => {
		this.carousel.current.slickNext();
	};

	prevSlide = () => {
		this.carousel.current.slickPrev();
	};

	handleMouseDown = event => {
		event.preventDefault();
	};

	handleMouseUp = () => {
		this.swiping = this.carousel.current.innerSlider.state.swiping;
	};

	handleClick = event => {
		if (this.swiping) {
			event.stopPropagation();
			event.preventDefault();
		}
	};

	render() {
		const {
			className,
			showHeaderUnderline,
			header,
			seeAllLink,
			history,
			enableCarousel,
			largeCollection,
			itemsPerRow,
			children,
		} = this.props;
		const { pageData, pageNum } = this.state;
		const totalPages = Math.ceil(children.length / itemsPerRow);
		const settings = {
			speed: 300,
			dots: false,
			infinite: false,
			slidesToShow: itemsPerRow,
			slidesToScroll: itemsPerRow,
			arrows: false,
			adaptativeHeight: false,
			afterChange: firstElementInSlideIndex => {
				this.onChange(firstElementInSlideIndex + 1);
			},
		};
		return (
			<div className={`collection ${className}`}>
				<div className={`collection__header ${showHeaderUnderline ? 'collection__header--carousel' : ''}`}>
					<h3 className='collection__header__text'>{header}</h3>
					{seeAllLink && (
						<span
							className={`collection__header__see-all ${enableCarousel ? 'collection__header__see-all--carousel' : ''}`}
							onClick={() => history.push(seeAllLink)}
						>
							See All
						</span>
					)}
				</div>
				<div
					className={`collection__children ${enableCarousel ? 'collection__children--carousel' : ''} ${
						largeCollection ? 'collection__children--large' : ''
					}`}
				>
					{enableCarousel ? (
						<div className='collection__carousel'>
							<CollectionArrow
								type='left'
								largeCollection={largeCollection}
								onArrowClick={() => {
									this.prevSlide();
								}}
								hidden={!pageNum}
							>
								<PrevArrow alt='previous page' />
							</CollectionArrow>
							<Carousel
								ref={this.carousel}
								{...settings}
								onMouseDown={e => this.handleOnMouseDown(e)}
								onClick={e => this.handleOnClick(e)}
							>
								{React.Children.map(pageData, child => (
									<div
										onClickCapture={this.handleClick}
										onMouseUpCapture={this.handleMouseUp}
										onMouseDownCapture={this.handleMouseDown}
									>
										{child}
									</div>
								))}
							</Carousel>
							<CollectionArrow
								type='right'
								largeCollection={largeCollection}
								onArrowClick={() => {
									this.nextSlide();
								}}
								hidden={pageNum + 1 >= totalPages}
							>
								<NextArrow alt='next page' />
							</CollectionArrow>
						</div>
					) : (
						getChildren(children, itemsPerRow)
					)}
				</div>
			</div>
		);
	}
}

Collection.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string,
	enableCarousel: PropTypes.bool,
	header: PropTypes.string.isRequired,
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	itemsPerRow: PropTypes.number,
	largeCollection: PropTypes.bool,
	seeAllLink: PropTypes.string,
	showHeaderUnderline: PropTypes.bool,
};

Collection.defaultProps = {
	className: '',
	children: [],
	enableCarousel: false,
	itemsPerRow: 3,
	seeAllLink: '',
	showHeaderUnderline: false,
	largeCollection: false,
};

export default withRouter(Collection);
