import * as qs from 'query-string';

import { apiUrl, getDefaultGetInit, parseStandardResponse } from '../utils/api';

import { formatPlaylistFiltersPayload } from './utils';

const LICENSING_PATH = 'licensing';

export async function getLicensingDashboardSummary() {
	const url = `${apiUrl}/${LICENSING_PATH}/summary`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

// classes
export async function getPublishedClassesImpactedReport({ pageSize: limit, page, sorters = null }) {
	const url = `${apiUrl}/${LICENSING_PATH}/classes/report?${qs.stringify({
		limit,
		page,
		...(sorters && { sorters: JSON.stringify(sorters) }),
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function exportPublishedClassesImpactedReportCSV() {
	const url = `${apiUrl}/${LICENSING_PATH}/classes/export`;
	const init = getDefaultGetInit();
	const res = await fetch(url, init);

	return res.blob();
}

export async function exportPublishedClassesHistoryReportCSV() {
	const url = `${apiUrl}/${LICENSING_PATH}/classes/exportHistory`;
	const init = getDefaultGetInit();
	const res = await fetch(url, init);

	return res.blob();
}

// playlists
export async function getPlaylistsImpactedReport({
	pageSize: limit,
	page,
	sorters = null,
	playlistSourceFilter = null,
	playlistBrandsFilter = null,
	playlistStatusesFilter = null,
}) {
	const url = `${apiUrl}/${LICENSING_PATH}/playlists/report?${qs.stringify({
		limit,
		page,
		...(sorters && { sorters: JSON.stringify(sorters) }),
		...formatPlaylistFiltersPayload({
			playlistSourceFilter,
			playlistBrandsFilter,
			playlistStatusesFilter,
		}),
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function exportPlaylistsImpactedReportCSV({
	playlistSourceFilter = null,
	playlistBrandsFilter = null,
	playlistStatusesFilter = null,
}) {
	const url = `${apiUrl}/${LICENSING_PATH}/playlists/export?${qs.stringify(
		formatPlaylistFiltersPayload({
			playlistSourceFilter,
			playlistBrandsFilter,
			playlistStatusesFilter,
		})
	)}`;
	const init = getDefaultGetInit();
	const res = await fetch(url, init);

	return res.blob();
}

// songs
export function exportSongsDroppedReportCSVURL() {
	return `${apiUrl}/${LICENSING_PATH}/songs-dropped/export`;
}

export function exportSongsAddedReportCSVURL() {
	return `${apiUrl}/${LICENSING_PATH}/songs-added/export`;
}
