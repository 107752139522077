import React from 'react';

import { PLAYLIST_STATUS_MAP } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';

import { PLAYLIST_TYPE_MAP } from '../../constants/playlist-types';
import { minutesToHHMMSSFormatter } from '../../utils/date-time';

const PlaylistGrid = ({ playlists, handleRowClick, showPreviousStatus, showNotes }) => {
	return (
		<table className='playlist-grid'>
			<thead>
				<tr className='playlist-grid__header-row'>
					<th style={{ width: '22%' }} className='playlist-grid__header-cell'>
						Title of Playlist
					</th>
					<th style={{ width: '22%' }} className='playlist-grid__header-cell'>
						Show ID
					</th>
					<th style={{ width: '10%' }} className='playlist-grid__header-cell'>
						Created by
					</th>
					<th style={{ width: '15%' }} className='playlist-grid__header-cell'>
						{showNotes ? 'Notes' : 'Created Date'}
					</th>
					<th style={{ width: '10%' }} className='playlist-grid__header-cell'>
						Submitted Date
					</th>
					<th style={{ width: '10%' }} className='playlist-grid__header-cell'>
						Playlist Type
					</th>
					<th style={{ width: '15%' }} className='playlist-grid__header-cell'>
						Target Duration
					</th>
					{showPreviousStatus ? (
						<th style={{ width: '20%' }} className='playlist-grid__header-cell'>
							Previous Status
						</th>
					) : null}
				</tr>
			</thead>
			<tbody>
				{playlists.map(p => (
					<tr key={p.playlist_id} className='playlist-grid__row'>
						<td onClick={() => handleRowClick(p.playlist_id)}>{p.name}</td>
						<td onClick={() => handleRowClick(p.playlist_id)}>{p.show_id}</td>
						<td onClick={() => handleRowClick(p.playlist_id)}>{`${p.first_name} ${p.last_name}`}</td>
						<td onClick={() => handleRowClick(p.playlist_id)}>{showNotes ? p.notes : p.created_date}</td>
						<td onClick={() => handleRowClick(p.playlist_id)}>{p.submitted_date}</td>
						<td onClick={() => handleRowClick(p.playlist_id)}>{PLAYLIST_TYPE_MAP[p.playlist_type_id]}</td>
						<td onClick={() => handleRowClick(p.playlist_id)}>{minutesToHHMMSSFormatter(p.target_duration)}</td>
						{showPreviousStatus ? (
							<td onClick={() => handleRowClick(p.playlist_id)}>
								{PLAYLIST_STATUS_MAP[p.invalid_previous_playlist_status_id]}
							</td>
						) : null}
					</tr>
				))}
			</tbody>
		</table>
	);
};

PlaylistGrid.propTypes = {
	handleRowClick: PropTypes.func,
	playlists: PropTypes.arrayOf(PropTypes.object).isRequired,
	showNotes: PropTypes.bool,
	showPreviousStatus: PropTypes.bool,
};

PlaylistGrid.defaultProps = {
	showPreviousStatus: false,
	handleRowClick: null,
	showNotes: false,
};

export default PlaylistGrid;
