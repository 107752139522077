module.exports = {
	EMAIL_REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
	EMAIL_SENDERS: {
		ADMIN_SUPPORT_EMAIL: 'support@music.equinoxplus.com',
		PLAYLIST_SUPPORT_EMAIL: 'playlist-license-alert@music.equinoxplus.com',
		SYSTEM_ADMIN_SUPPORT_EMAIL: 'no-reply@music.equinoxplus.com',
		NO_REPLY: 'no-reply@music.equinoxplus.com',
		DEFAULT_CC: 'playlist-license-alert@equinox.com',
	},
	EMAIL_PARAMETERS_NAMES: {
		PLAYLIST_NOTIFICATION_RECEIVERS: '/content-music/playlist-notifications-receivers',
		ADMIN_MESSAGE_RECEIVER: '/content-music/admin-message-receivers',
	},
	EMAIL_SENDER_TYPE: {
		PLAYLIST_SUPPORT: 'PLAYLIST_SUPPORT',
		ADMIN_SUPPORT: 'ADMIN_SUPPORT',
		SYSTEM_ADMIN_SUPPORT: 'SYSTEM_ADMIN_SUPPORT',
	},
	EMAIL_RECEIVERS_TYPES: {
		PLAYLIST_NOTIFICATION_RECEIVERS: 'playlist',
		ADMIN_NOTIFICATION_RECEIVERS: 'admin',
	},
};
