import * as qs from 'query-string';

import { apiUrl, getDefaultGetInit, parseStandardResponse } from '../utils/api';

const PERMISSIONS_PATH = 'permissions';

export async function getPermissions(brandIds) {
	const url = `${apiUrl}/${PERMISSIONS_PATH}?${qs.stringify({ brandIds })}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}
