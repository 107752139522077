import React from 'react';

import {
	PLAYLIST_STATUS,
	PLAYLIST_STATUS_MAP,
	PLAYLIST_TYPE,
	PLAYLIST_TYPE_MAP,
} from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as playlistsApi from '../api/playlists';
import * as sharedFoldersApi from '../api/shared-folders';
import AlertModal from '../components/AlertModal';
import ContentCopy from '../components/ContentCopy';
import DropdownButton from '../components/DropdownButton';
import EditPropertyModal from '../components/EditPropertyModal';
import Loader from '../components/Loader';
import MoreButton from '../components/MoreButton';
import { NotesPanel } from '../components/NotesPanel';
import SongsList from '../components/song/SongsList';
import StatusBadge from '../components/StatusBadge';
import { BRANDS } from '../constants/brands';
import * as PERMISSIONS from '../constants/permissions';
import PLAYER_MODES from '../constants/player-modes';
import PLAYER_STATUS from '../constants/player-status';
import { PLAYLIST_DURATION } from '../constants/playlist-duration';
import STATUS_BADGE_COLOURS from '../constants/status-badge-colours';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import arrowLeftImg from '../images/arrow-left.svg';
import pauseImg from '../images/pause.svg';
import playImg from '../images/play.svg';
import { ReactComponent as SearchImg } from '../images/search.svg';
import { ReactComponent as SearchOffImg } from '../images/search_off.svg';
import {
	getEmail,
	isAdministrator,
	isContentCreator,
	isInstructor,
	isSharedFolderUser,
	hasPermission,
	isViewOnly,
} from '../utils/auth';
import { minutesToHHMMSSFormatter, secondsToHHMMSSFormatter } from '../utils/date-time';
import {
	getAdjustedDuration,
	getTotalDuration,
	getAdjustedTargetDurationDifference,
	getCardioTargetDifference,
	getCardioDuration,
} from '../utils/playlist';
import {
	isPlaylistDurationValid,
	getPlaylistSubmissionRules,
	canBypassSubmissionRules,
} from '../utils/playlist-submission-rules';

class Playlist extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			playlist: {},
			playlists: [],
			folders: [],
			showErrorModal: false,
			errors: [],
			showArchivePlaylistModal: false,
			showRestorePlaylistModal: false,
			showEditShowIdModal: false,
			loaded: false,
		};
		this.fetchPlaylist = this.fetchPlaylist.bind(this);
		this.fetchPlaylists = this.fetchPlaylists.bind(this);
		this.fetchFolders = this.fetchFolders.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleArchivePlaylist = this.handleArchivePlaylist.bind(this);
		this.handleRestorePlaylist = this.handleRestorePlaylist.bind(this);
		this.handleApprove = this.handleApprove.bind(this);
		this.handleReject = this.handleReject.bind(this);
		this.handleRevise = this.handleRevise.bind(this);
		this.handleBackToPlaylists = this.handleBackToPlaylists.bind(this);
		this.getPlaylistSubText = this.getPlaylistSubText.bind(this);
		this.loadPlaylistToPlayer = this.loadPlaylistToPlayer.bind(this);
		this.loadSongInPlaylist = this.loadSongInPlaylist.bind(this);
		this.getNumUnplayableSongs = this.getNumUnplayableSongs.bind(this);
		this.handleEditShowIdSubmit = this.handleEditShowIdSubmit.bind(this);
		this.validPlaylistDurationSubmitOrApproval = this.validPlaylistDurationSubmitOrApproval.bind(this);
	}

	componentDidMount() {
		this.fetchPlaylist();
		this.fetchFolders();
		if (!isContentCreator()) {
			this.fetchPlaylists();
		}
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props;
		if (prevProps.location.pathname !== location.pathname) {
			this.fetchPlaylist();
		}
	}

	handleBackToPlaylists() {
		const { history } = this.props;
		const { playlist } = this.state;

		if (isInstructor()) {
			history.push('/my-playlists/');
		}
		if (isContentCreator()) {
			if (playlist.playlist_status_id === PLAYLIST_STATUS.SUBMITTED) {
				history.push('/playlists/pending');
			} else if (playlist.playlist_status_id === PLAYLIST_STATUS.APPROVED) {
				history.push('/playlists/approved');
			} else if (playlist.playlist_status_id === PLAYLIST_STATUS.INVALID) {
				history.push('/playlists/invalid');
			} else if (playlist.playlist_status_id === PLAYLIST_STATUS.REJECTED) {
				history.push('/playlists/rejected');
			}
		}
	}

	async handlePlaylistAction(playlistActionFn) {
		const { history } = this.props;
		const { createToast } = this.context;

		try {
			await playlistActionFn();
		} catch (err) {
			this.setState({
				loaded: false,
			});
			createToast(`${err.message || ''}`, TOAST_LEVEL.ERROR, true);
			if (isInstructor()) {
				history.push('/my-playlists');
			} else {
				history.push('/');
			}
		}
	}

	async handleSubmit(e) {
		const { playlist } = this.state;

		e.preventDefault();

		if (!this.validPlaylistDurationSubmitOrApproval()) {
			return;
		}

		const response = await playlistsApi.submitPlaylistForApproval(playlist.playlist_id);

		if (response.playlist_status_id === PLAYLIST_STATUS.SUBMITTED) {
			this.setState({ playlist: response });
		} else {
			this.setState({
				showErrorModal: true,
				errors: ['One or more songs have become unavailable for use.'],
			});
		}
	}

	async handleArchivePlaylist() {
		const { getSearchPlaylists } = this.context;
		const { playlist } = this.state;
		const { history } = this.props;

		await this.handlePlaylistAction(async () => {
			this.setState({ showArchivePlaylistModal: false });
			await playlistsApi.archivePlaylist(playlist.playlist_id);
			await getSearchPlaylists(true);
			history.goBack();
		});
	}

	async handleRestorePlaylist() {
		const { playlist } = this.state;

		await this.handlePlaylistAction(async () => {
			this.setState({ showRestorePlaylistModal: false });
			await playlistsApi.restorePlaylist(playlist.playlist_id);
			window.location.reload();
		});
	}

	async handleApprove(e) {
		e.preventDefault();

		const { playlist } = this.state;

		if (!this.validPlaylistDurationSubmitOrApproval()) {
			return;
		}

		await this.handlePlaylistAction(async () => {
			const response = await playlistsApi.approvePlaylist(playlist.playlist_id);

			if (response.playlist_status_id === PLAYLIST_STATUS.APPROVED) {
				this.setState({
					playlist: response,
				});
			} else {
				this.setState({
					showErrorModal: true,
					errors: ['Failed to approve playlist.'],
				});
			}
		});
	}

	async handleReject(e) {
		e.preventDefault();

		const { playlist } = this.state;

		await this.handlePlaylistAction(async () => {
			const response = await playlistsApi.rejectPlaylist(playlist.playlist_id);

			if (response.playlist_status_id === PLAYLIST_STATUS.REJECTED) {
				this.setState({
					playlist: response,
				});
			} else {
				this.setState({
					showErrorModal: true,
					errors: ['Failed to reject playlist.'],
				});
			}
		});
	}

	async handleRevise(e) {
		e.preventDefault();

		const { playlist } = this.state;

		await this.handlePlaylistAction(async () => {
			const response = await playlistsApi.revisePlaylist(playlist.playlist_id);

			if (response.playlist_status_id === PLAYLIST_STATUS.DRAFT) {
				this.setState({
					playlist: response,
				});
			} else {
				this.setState({
					showErrorModal: true,
					errors: ['Failed to restore playlist back to Draft.'],
				});
			}
		});
	}

	async handleTargetDurationChange(targetDuration) {
		const { playlist } = this.state;

		await this.handlePlaylistAction(async () => {
			await playlistsApi.editPlaylist(playlist.playlist_id, null, targetDuration);
			this.fetchPlaylist();
		});
	}

	async handlePlaylistTypeChange(playlistType) {
		const { playlist } = this.state;

		await this.handlePlaylistAction(async () => {
			if (playlistType !== playlist.playlist_type_id) {
				await playlistsApi.editPlaylist(playlist.playlist_id, null, null, undefined, playlistType);
				this.fetchPlaylist();
			}
		});
	}

	async handleEditNotesSubmit(notes) {
		const { playlist } = this.state;

		await this.handlePlaylistAction(async () => {
			if (notes !== playlist.note) {
				await playlistsApi.editPlaylist(playlist.playlist_id, undefined, undefined, notes || null);
				this.fetchPlaylist();
			}
		});
	}

	async handleEditShowIdSubmit(showId) {
		const { playlist } = this.state;

		await this.handlePlaylistAction(async () => {
			this.setState({ showEditShowIdModal: false });
			if (showId && showId !== playlist.show_id) {
				await playlistsApi.editPlaylist(playlist.playlist_id, showId);
				this.fetchPlaylist();
			}
		});
	}

	getNumUnplayableSongs() {
		const { currentPlaylist } = this.context;

		// a song is unplayable if the song is not valid or is blacklisted
		return currentPlaylist.filter(s => !s.valid || s.is_blacklisted).length;
	}

	getPlaylistSubText() {
		const { currentPlaylist } = this.context;
		let subtext = '';
		// number of valid songs
		const validSongsNumber = (currentPlaylist || []).filter(s => !!s.valid).length;

		if (validSongsNumber > 0) {
			subtext = subtext.concat(`${validSongsNumber} Song${validSongsNumber > 1 ? 's ' : ' '}`);
		} else {
			subtext = subtext.concat('0 Songs ');
		}

		// number of unplayable songs
		subtext = subtext.concat(` — ${this.getNumUnplayableSongs()} Unplayable`);

		return subtext;
	}

	getPlaylistInvalidatedInfo() {
		const { playlist } = this.state;

		if (playlist.playlist_status_id === PLAYLIST_STATUS.INVALID) {
			return (
				<div className='playlist__sub-text'>
					Previous Status: {PLAYLIST_STATUS_MAP[playlist.invalid_previous_playlist_status_id]} — Invalidated Date:&nbsp;
					{playlist.invalidated_timestamp ? new Date(playlist.invalidated_timestamp).toLocaleString() : 'n/a'}
				</div>
			);
		}
		return null;
	}

	getPlaylistTotalDurationInfo() {
		const { playlist } = this.state;
		const songs = playlist.songs ? playlist.songs.filter(s => s.valid) : [];

		return `Total Duration: ${getTotalDuration(songs) || '0:00:00'} — `;
	}

	getPlaylistDurationInfo() {
		const { playlist } = this.state;
		const { zoneKeys } = this.context;
		const playlistBrandId = playlist.brand_id;
		const songs = playlist.songs ? playlist.songs.filter(s => s.valid) : [];
		const playlistZoneKeys = zoneKeys.filter(zone => !playlistBrandId || zone.brand_id === playlistBrandId);
		const adjustedTargetDurationDifference = getAdjustedTargetDurationDifference(playlist, playlistZoneKeys);
		const cardioDurationDifference = getCardioTargetDifference(playlist, playlistZoneKeys);

		// show Difference only if a target duration has been selected and the duration is invalid
		const showAdjustedTargetDurationDifference =
			playlist.target_duration &&
			!isPlaylistDurationValid(playlist, playlistZoneKeys) &&
			adjustedTargetDurationDifference !== 0;

		// show Cardio Duration if it is a SoulCycle VoD/Live playlist with a target duration of >= 20
		const showCardioDuration =
			[PLAYLIST_TYPE.VOD, PLAYLIST_TYPE.LIVE].includes(playlist.playlist_type_id) && playlist.target_duration >= 20;

		// show Cardio Duration Difference (minimum time remaining required for Cardio Duration to be valid) if showCardioDuration and invalid
		const showCardioDurationDifference =
			showCardioDuration &&
			getPlaylistSubmissionRules(playlist, playlistZoneKeys).filter(r => !r.valid && r.isCardioDurationRule).length > 0;

		return (
			<span>
				Adjusted Duration: {getAdjustedDuration(songs, playlistZoneKeys) || '0:00:00'}
				{showAdjustedTargetDurationDifference && (
					<span className='playlist__target-duration--invalid'>
						{`(${adjustedTargetDurationDifference > 0 ? '+' : '-'}${secondsToHHMMSSFormatter(
							Math.abs(adjustedTargetDurationDifference)
						)})`}
					</span>
				)}
				{showCardioDuration && (
					<span className='playlist__target-duration'>
						{' '}
						— Cardio Duration:
						{getCardioDuration(playlist, playlistZoneKeys) || '0:00:00'}
					</span>
				)}
				{showCardioDurationDifference && (
					<span className='playlist__target-duration--invalid'>
						{`(${cardioDurationDifference > 0 ? '+' : '-'}${secondsToHHMMSSFormatter(
							Math.abs(cardioDurationDifference)
						)})`}
					</span>
				)}
			</span>
		);
	}

	getTargetDurationDropdownOptions = () => {
		const { playlist } = this.state;

		let minutes = PLAYLIST_DURATION[playlist.brand_id];

		if (playlist.brand_id === BRANDS.SOULCYCLE) {
			// if SoulCycle VoD, do not show 3 and 75 minute options
			if (playlist.playlist_type_id === PLAYLIST_TYPE.VOD) {
				minutes = minutes.filter(m => m !== 3 && m !== 75);
			}

			// if SoulCycle Live, do not show less than 20 minutes and 75 minute options
			if (playlist.playlist_type_id === PLAYLIST_TYPE.LIVE) {
				minutes = minutes.filter(m => m >= 20 && m !== 75);
			}
		} else if (playlist.brand_id === BRANDS.EQUINOX && playlist.playlist_type_id === PLAYLIST_TYPE.VOD) {
			minutes = minutes.filter(m => m >= 20 && m <= 60);
		}

		return (minutes || []).map(minute => ({
			value: minute,
			label: minutesToHHMMSSFormatter(minute),
		}));
	};

	getPlaylistTypeDropdownOptions = () => {
		const { playlistTypes } = this.context;

		return playlistTypes.map(type => ({
			value: type.playlist_type_id,
			label: type.name,
		}));
	};

	getPlaylistTargetDuration() {
		const { playlist } = this.state;

		if (
			(isInstructor() && playlist.playlist_status_id === PLAYLIST_STATUS.DRAFT) ||
			(isContentCreator() &&
				![PLAYLIST_STATUS.ARCHIVED, PLAYLIST_STATUS.INVALID, PLAYLIST_STATUS.APPROVED].includes(
					playlist.playlist_status_id
				))
		) {
			return (
				<span className='d-flex'>
					<span className='playlist__target-duration__text'>Target Duration:</span>
					<DropdownButton
						options={this.getTargetDurationDropdownOptions()}
						placeholder='00:00:00'
						placeholderClassName='playlist__target-duration__dropdown-text'
						controlClassName='playlist__target-duration__dropdown-control'
						arrowClassName='playlist__target-duration__arrow'
						showArrowIcon
						value={playlist.target_duration ? secondsToHHMMSSFormatter(playlist.target_duration * 60) : 'None'}
						onChange={e => this.handleTargetDurationChange(e.value)}
						disabled={isViewOnly()}
					/>
				</span>
			);
		}
		return (
			<span className='playlist__target-duration__text'>
				Target Duration: {playlist.target_duration ? secondsToHHMMSSFormatter(playlist.target_duration * 60) : 'n/a'}
			</span>
		);
	}

	getShowIdInfo() {
		const { playlist } = this.state;

		if (
			playlist.playlist_status_id === PLAYLIST_STATUS.APPROVED ||
			playlist.playlist_status_id === PLAYLIST_STATUS.INVALID ||
			playlist.playlist_status_id === PLAYLIST_STATUS.SUBMITTED
		) {
			return (
				<div className='playlist__show-id'>
					{isContentCreator() || isAdministrator() ? (
						<>
							Show ID:
							<span
								className='playlist__show-id__text playlist__show-id__text--editable'
								onClick={() => this.setState({ showEditShowIdModal: true })}
							>
								{playlist.show_id && playlist.show_id.trim() ? playlist.show_id : 'Attach Show ID'}
							</span>
						</>
					) : (
						<>
							Show ID: <span className='playlist__show-id__text'>{playlist.show_id ? playlist.show_id : '—'}</span>
						</>
					)}
				</div>
			);
		}
		return null;
	}

	getPlaylistType() {
		const { playlist } = this.state;

		return (
			<span className='d-flex'>
				<span className='mr-2'>Playlist Type:</span>
				<DropdownButton
					options={this.getPlaylistTypeDropdownOptions()}
					placeholder='00:00:00'
					placeholderClassName='playlist__target-duration__dropdown-text'
					controlClassName='playlist__target-duration__dropdown-control'
					arrowClassName='playlist__target-duration__arrow'
					showArrowIcon
					value={playlist.playlist_type_id ? PLAYLIST_TYPE_MAP[playlist.playlist_type_id] : 'No Category'}
					onChange={e => this.handlePlaylistTypeChange(e.value)}
					disabled={isViewOnly() || playlist.playlist_status_id !== PLAYLIST_STATUS.DRAFT || !playlist.playlist_type_id}
				/>
			</span>
		);
	}

	getPlaylistNotes() {
		const { playlist } = this.state;

		const isLocked =
			playlist.playlist_status_id === PLAYLIST_STATUS.ARCHIVED ||
			playlist.playlist_status_id === PLAYLIST_STATUS.APPROVED ||
			playlist.playlist_status_id === PLAYLIST_STATUS.REJECTED ||
			(isInstructor() && playlist.playlist_status_id === PLAYLIST_STATUS.SUBMITTED);

		return (
			<div className='playlist__notes'>
				Notes:
				<div className='playlist__notes__textarea'>
					<NotesPanel
						note={playlist.notes}
						onNoteUpdate={newNote => this.handleEditNotesSubmit(newNote)}
						tooltipHeader='Playlist Notes'
						tooltipDetail={playlist.name}
						unopenedHeight={50}
						isLocked={isLocked}
					/>
				</div>
			</div>
		);
	}

	getInstructorPlaylistMetadata(hasSongs, numberUnplayable) {
		const { playlist } = this.state;

		return (
			<>
				{hasSongs && playlist.playlist_status_id === PLAYLIST_STATUS.DRAFT && (
					<button
						name='Playlist - Submit for Approval'
						type='submit'
						className='primary-btn primary-btn--w-guards'
						onClick={this.handleSubmit}
						disabled={!!numberUnplayable || !this.canSubmitPlaylist()}
					>
						Submit for Approval
					</button>
				)}
				{playlist.playlist_status_id === PLAYLIST_STATUS.REJECTED && (
					<button
						name='Playlist - Revise'
						type='submit'
						className='primary-btn primary-btn--w-guards'
						onClick={this.handleRevise}
					>
						Revise
					</button>
				)}
			</>
		);
	}

	getContentCreatorPlaylistMetadata(hasSongs, numberUnplayable) {
		const { playlist } = this.state;

		return (
			<>
				{[PLAYLIST_STATUS.SUBMITTED, PLAYLIST_STATUS.REJECTED].includes(playlist.playlist_status_id) &&
					hasSongs &&
					!isViewOnly() && (
						<button
							name='Playlist - Approve'
							type='submit'
							className='primary-btn primary-btn--w-guards mr-2'
							onClick={this.handleApprove}
							disabled={!!numberUnplayable || playlist.pending_airtable_update}
						>
							Approve
						</button>
					)}
				{[PLAYLIST_STATUS.SUBMITTED, PLAYLIST_STATUS.APPROVED, PLAYLIST_STATUS.INVALID].includes(
					playlist.playlist_status_id
				) &&
					!isViewOnly() && (
						<button
							name='Playlist - Reject'
							type='submit'
							className='primary-btn primary-btn--w-guards'
							onClick={this.handleReject}
							disabled={playlist.pending_airtable_update}
						>
							Reject
						</button>
					)}
				{playlist.pending_airtable_update && (
					<p className='mt-3'>
						* Current playlist version is being synced to Music Drop base, please wait until it get sync before updating
						the playlist status
					</p>
				)}
			</>
		);
	}

	validPlaylistDurationSubmitOrApproval() {
		if (canBypassSubmissionRules()) {
			return true;
		}

		const { playlist } = this.state;
		const { zoneKeys } = this.context;
		const playlistBrandId = playlist.brand_id;
		const playlistZoneKeys = zoneKeys.filter(zone => !playlistBrandId || zone.brand_id === playlistBrandId);

		// ensure playlist's duration is valid
		if (!isPlaylistDurationValid(playlist, playlistZoneKeys)) {
			this.setState({
				showErrorModal: true,
				errors: [
					`Your playlist needs to be between 2 minutes shorter, or 1 minute longer,
               than the duration of your class. Your playlist isn’t long or short enough, please edit your playlist or the In/Out times and re-submit.`,
				],
			});
			return false;
		}
		return true;
	}

	loadSongInPlaylist() {
		const { setPlayerMode, setShowPlayer } = this.context;

		setPlayerMode(PLAYER_MODES.PLAYLIST);
		setShowPlayer(true);
	}

	loadPlaylistToPlayer(songId = null) {
		const { currentPlaylist, setPlayerPlaylist, loadPlaylistIntoPlayer } = this.context;
		const { playlist } = this.state;
		const currentWorkingPlaylist = currentPlaylist.filter(s => !!s.valid && !s.is_blacklisted);
		const song = songId ? currentWorkingPlaylist.find(s => s.song_id === songId) : null;

		setPlayerPlaylist(playlist);
		loadPlaylistIntoPlayer(currentWorkingPlaylist, song, this.loadSongInPlaylist);
	}

	async fetchPlaylist() {
		const { match, history } = this.props;
		const { setCurrentPlaylist, setCurrentPlaylistID } = this.context;

		await this.handlePlaylistAction(async () => {
			const playlist = await playlistsApi.getPlaylist(match.params.pid);

			// redirect to My Playlists if user is just an instructor and the playlist does not belong to the instructor
			if (isInstructor() && !isContentCreator() && getEmail() !== playlist.email && history) {
				history.push('/my-playlists');
			}

			setCurrentPlaylist(playlist.songs || []);
			setCurrentPlaylistID(playlist.playlist_id);

			this.setState({ playlist, loaded: true });
		});
	}

	async fetchPlaylists() {
		const playlists = await playlistsApi.getUserPlaylists({
			playlistStatusIds: [PLAYLIST_STATUS.DRAFT],
			includeSongDetails: true,
		});

		this.setState({ playlists: playlists.data });
	}

	async fetchFolders() {
		let folders = [];

		if (isSharedFolderUser() && hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR])) {
			const result = await sharedFoldersApi.getFolders(null, null, false);

			folders = result.data || [];
		}

		this.setState({ folders });
	}

	// can't submit playlist if there are any rules that are not met
	canSubmitPlaylist() {
		if (canBypassSubmissionRules()) {
			return true;
		}

		const { playlist } = this.state;
		const { zoneKeys } = this.context;
		const playlistBrandId = playlist.brand_id;
		const playlistZoneKeys = zoneKeys.filter(zone => !playlistBrandId || zone.brand_id === playlistBrandId);

		return getPlaylistSubmissionRules(playlist, playlistZoneKeys).filter(r => !r.valid).length === 0;
	}

	renderPlaylistSubmissionRules() {
		if (canBypassSubmissionRules()) {
			return <></>;
		}

		const { playlist } = this.state;
		const { zoneKeys } = this.context;
		const playlistBrandId = playlist.brand_id;
		const playlistZoneKeys = zoneKeys.filter(zone => !playlistBrandId || zone.brand_id === playlistBrandId);

		return (
			<div>
				<div className='playlist__submission__header'>Submission Rules</div>
				<ul>
					{getPlaylistSubmissionRules(playlist, playlistZoneKeys).map((rule, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<li className='playlist__submission__item' key={i}>
							<span>{rule.text}</span>
							<span
								className={`${
									!rule.valid ? 'playlist__submission__item--invalid' : 'playlist__submission__item--valid'
								} ml-4`}
							>
								{rule.valid ? <>&#10003;</> : <>&#10007;</>}
							</span>
						</li>
					))}
				</ul>
			</div>
		);
	}

	renderPlaylistData(numberUnplayable) {
		const { currentPlaylist } = this.context;
		const { playlist, folders, playlists } = this.state;
		const hasSongs = currentPlaylist.length > 0;

		if (!hasSongs) {
			return (
				<div style={{ marginTop: '-2rem' }}>
					{isInstructor() && this.getInstructorPlaylistMetadata(hasSongs, numberUnplayable)}
					{isContentCreator() && this.getContentCreatorPlaylistMetadata(hasSongs, numberUnplayable)}
					<div className='playlist__empty w-100 h-100 d-flex justify-content-center'>
						<div className='playlist__empty__container'>
							<div className='playlist__empty__header'>This playlist is currently empty</div>
							<div className='playlist__empty__sub'>
								Begin adding songs to your playlists from your song library or search for something new!
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div>
				{isInstructor() && this.getInstructorPlaylistMetadata(hasSongs, numberUnplayable)}
				{isContentCreator() && this.getContentCreatorPlaylistMetadata(hasSongs, numberUnplayable)}
				<SongsList
					songs={currentPlaylist}
					totalSongs={currentPlaylist ? currentPlaylist.length : 0}
					fetchSongsCollectionCallback={this.fetchPlaylist}
					playlists={playlists}
					folders={folders}
					showRemoveSongButton
					playlistId={playlist.playlist_id}
					playlistStatus={playlist.playlist_status_id}
					disableDropdown
					sortBy='created_timestamp'
					loadPlaylistToPlayer={this.loadPlaylistToPlayer}
					playlistBrandId={playlist.brand_id}
				/>
			</div>
		);
	}

	render() {
		const {
			errors,
			loaded,
			playlist,
			showArchivePlaylistModal,
			showEditShowIdModal,
			showErrorModal,
			showRestorePlaylistModal,
		} = this.state;
		const { currentPlaylist, playerStatus, playerPlaylist, playlistInSearchContext, setPlaylistToSearchContext } =
			this.context;
		const numberUnplayable = this.getNumUnplayableSongs();
		const unplayable = numberUnplayable === currentPlaylist.length;
		const isPlaylistPlaying =
			playerStatus === PLAYER_STATUS.PLAYING && playerPlaylist && playerPlaylist.playlist_id === playlist.playlist_id;
		const showPlaylistType = playlist.brand_id === BRANDS.SOULCYCLE || playlist.brand_id === BRANDS.EQUINOX;

		return (
			<Loader loaded={loaded}>
				<div className='playlist__container'>
					<AlertModal
						title='Unable to Submit'
						description='Unable to submit your playlist due to the following reasons:'
						reasons={errors}
						showModal={showErrorModal && playlist.playlist_status_id === PLAYLIST_STATUS.DRAFT}
						onConfirmClick={() => this.setState({ showErrorModal: false })}
					/>
					<AlertModal
						title='Unable to Approve'
						description='Unable to approve your playlist due to the following reasons:'
						reasons={errors}
						showModal={
							showErrorModal &&
							[PLAYLIST_STATUS.REJECTED, PLAYLIST_STATUS.SUBMITTED].includes(playlist.playlist_status_id)
						}
						onConfirmClick={() => this.setState({ showErrorModal: false })}
					/>
					<AlertModal
						title='Archive Playlist?'
						description='Are you sure you want to archive this playlist?'
						showModal={showArchivePlaylistModal}
						onConfirmClick={() => this.handleArchivePlaylist()}
						onCancelClick={() => this.setState({ showArchivePlaylistModal: false })}
					/>
					<AlertModal
						title='Restore Playlist?'
						description='Are you sure you want to restore this archived playlist back to Drafts?'
						showModal={showRestorePlaylistModal}
						onConfirmClick={() => this.handleRestorePlaylist()}
						onCancelClick={() => this.setState({ showRestorePlaylistModal: false })}
					/>
					{showEditShowIdModal ? (
						<EditPropertyModal
							propertyName='Show ID'
							description='Show ID'
							propertyValue={playlist.show_id}
							onCancelClick={() => this.setState({ showEditShowIdModal: false })}
							onSubmitClick={name => this.handleEditShowIdSubmit(name)}
						/>
					) : null}
					<span className='playlist__back' onClick={this.handleBackToPlaylists}>
						<img src={arrowLeftImg} alt='back' className='playlist__back__btn' />
						Back to Playlists
					</span>

					<div className='playlist__header  justify-content-between'>
						<div className='d-flex align-items-center'>
							<ContentCopy text={playlist.name} iconAltText='Copy playlist name icon' tooltipText='Copy playlist name'>
								<h3
									className='h3'
									onCopy={e => {
										// when copy make sure to just copy text plain strings
										e.preventDefault();
										e.clipboardData.setData('text/plain', window.getSelection().toString());
									}}
								>
									{playlist.name}
								</h3>
							</ContentCopy>

							<div className='d-flex align-items-end mb-2'>
								<div className='playlist__header__badge mr-1'>
									<StatusBadge
										status={playlist.playlist_status_id ? PLAYLIST_STATUS_MAP[playlist.playlist_status_id] : 'n/a'}
										flagged={playlist.playlist_status_id === PLAYLIST_STATUS.INVALID}
										className={STATUS_BADGE_COLOURS[playlist.playlist_status_id]}
										inAirtable={
											isContentCreator() &&
											PLAYLIST_STATUS.APPROVED === playlist.playlist_status_id &&
											!!playlist.airtable_version &&
											playlist.airtable_version === playlist.current_version
										}
									/>
								</div>
								<div className='playlist__actions'>
									{isInstructor() &&
										playlist &&
										![PLAYLIST_STATUS.APPROVED, PLAYLIST_STATUS.INVALID, PLAYLIST_STATUS.SUBMITTED].includes(
											playlist.playlist_status_id
										) && (
											<MoreButton
												options={[
													playlist.playlist_status_id !== PLAYLIST_STATUS.ARCHIVED
														? {
																label: 'Archive',
																callback: () =>
																	this.setState({
																		showArchivePlaylistModal: true,
																	}),
														  }
														: {
																label: 'Restore to Draft',
																callback: () =>
																	this.setState({
																		showRestorePlaylistModal: true,
																	}),
														  },
												]}
											/>
										)}
									<img
										onClick={() => {
											if (!unplayable) {
												this.loadPlaylistToPlayer();
											}
										}}
										src={isPlaylistPlaying ? pauseImg : playImg}
										alt={isPlaylistPlaying ? 'Pause playlist' : 'Play playlist'}
										className={`playlist__play-btn${unplayable ? ' unplayable' : ''}`}
									/>
								</div>
							</div>
						</div>
						<div className='playlist__metadata d-flex align-items-center'>
							<span className='playlist__metadata__title'>Version:&nbsp;</span>
							<span className='playlist__metadata__value'>{(playlist.current_version || 1).toFixed(1)}</span>
							{isContentCreator() &&
								PLAYLIST_STATUS.APPROVED === playlist.playlist_status_id &&
								playlist.airtable_version && (
									<>
										&nbsp;-&nbsp;
										<span className='playlist__metadata__title'>Pushed Version:</span>
										<span className='playlist__metadata__value'>
											&nbsp;
											{playlist.airtable_version ? playlist.airtable_version.toFixed(1) : '-'}
										</span>
									</>
								)}
						</div>
					</div>
					<div className='row d-flex align-items-start'>
						<div className={isContentCreator() ? 'col-md-6' : 'col-md-7'}>
							{this.getShowIdInfo()}
							{showPlaylistType && <div className='playlist__sub-text'>{this.getPlaylistType()}</div>}
							<div className='playlist__sub-text'>{this.getPlaylistSubText()}</div>
							{this.getPlaylistInvalidatedInfo()}
							<div className='playlist__sub-text d-flex'>
								{this.getPlaylistTotalDurationInfo()}
								{this.getPlaylistTargetDuration()}
							</div>
							<div className='playlist__sub-text'>{this.getPlaylistDurationInfo()}</div>
							<div className='playlist__sub-text'>{this.getPlaylistNotes()}</div>
						</div>
						<div className={`${isContentCreator() ? 'col-md-6' : 'col-md-5'} playlist__submission`}>
							{this.renderPlaylistSubmissionRules()}
						</div>
						{isContentCreator() &&
							!isViewOnly() &&
							([PLAYLIST_STATUS.SUBMITTED, PLAYLIST_STATUS.REJECTED].includes(playlist.playlist_status_id) ||
								playlistInSearchContext) && (
								<div className='col-md-2'>
									{playlistInSearchContext ? (
										<button
											name='Playlist - Remove Playlist from Catalog Context'
											type='submit'
											className='playlist__action_button primary-btn mt-0'
											onClick={() => setPlaylistToSearchContext(null)}
										>
											<SearchOffImg /> Remove Playlist from Catalog Context
										</button>
									) : (
										<button
											name='Playlist - Add Playlist to Catalog Context'
											type='submit'
											className='playlist__action_button secondary-btn mt-0'
											onClick={() =>
												setPlaylistToSearchContext({
													playlist_id: playlist.playlist_id,
													name: playlist.name,
												})
											}
										>
											<SearchImg /> Add Playlist to Catalog Context
										</button>
									)}
								</div>
							)}
					</div>
					<div className='playlist__sub-text playlist__sub-text--no-margin' />
					{this.renderPlaylistData(numberUnplayable)}
				</div>
			</Loader>
		);
	}
}

Playlist.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
	match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Playlist.contextType = GlobalContext;

export default withRouter(Playlist);
