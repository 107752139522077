import * as qs from 'query-string';

import {
	apiUrl,
	getDefaultGetInit,
	getDefaultDeleteInit,
	getDefaultPostInit,
	parseStandardResponse,
} from '../utils/api';

const SHARED_FOLDER_PATH = 'shared-folders';

export async function createSharedFolder(name, description, initSong) {
	const url = `${apiUrl}/${SHARED_FOLDER_PATH}`;
	const init = getDefaultPostInit({ name, description, initSong });

	return parseStandardResponse(fetch(url, init));
}

export async function editSharedFolder(folderID, name, description) {
	const url = `${apiUrl}/${SHARED_FOLDER_PATH}/${folderID}/edit`;
	const init = getDefaultPostInit({ name, description });

	return parseStandardResponse(fetch(url, init));
}

export async function archiveSharedFolder(folderID) {
	const url = `${apiUrl}/${SHARED_FOLDER_PATH}/${folderID}`;
	const init = getDefaultDeleteInit();

	return parseStandardResponse(fetch(url, init));
}

export async function restoreSharedFolder(folderID) {
	const url = `${apiUrl}/${SHARED_FOLDER_PATH}/${folderID}/restore`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getFolders(limit, page, includeArchived, includeSongsData) {
	const url = `${apiUrl}/${SHARED_FOLDER_PATH}?${qs.stringify({
		limit,
		page,
		includeArchived,
		includeSongsData,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getFolder(
	folderID,
	page = null,
	limit = null,
	sortBy = null,
	bpms = null,
	artistInitialCharFilter = null
) {
	const url = `${apiUrl}/${SHARED_FOLDER_PATH}/${folderID}?${qs.stringify({
		limit,
		page,
		sortBy,
		bpms: bpms ? bpms.join(',') : undefined,
		artistInitialCharFilter,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function addSongToFolder(folderID, songId) {
	const url = `${apiUrl}/${SHARED_FOLDER_PATH}/${folderID}/add-song/${songId}`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init));
}

export async function removeSongFromFolder(folderID, sharedFolderSongId) {
	const url = `${apiUrl}/${SHARED_FOLDER_PATH}/${folderID}/remove-song/${sharedFolderSongId}`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getFolderBrandUsers(folderID, allExceptSelf = false) {
	const url = `${apiUrl}/${SHARED_FOLDER_PATH}/${folderID}/brand-users?${qs.stringify({
		allExceptSelf,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function shareFolder(
	folderID,
	sharedWithAllViewersBrandIds = [],
	viewerIds = [],
	sharedWithAllCreatorsBrandIds = [],
	creatorIds = []
) {
	const url = `${apiUrl}/${SHARED_FOLDER_PATH}/${folderID}/share`;
	const init = getDefaultPostInit({
		share_w_all_viewer_brand_ids: sharedWithAllViewersBrandIds,
		viewer_ids: viewerIds,
		share_w_all_creator_brand_ids: sharedWithAllCreatorsBrandIds,
		creator_ids: creatorIds,
	});

	return parseStandardResponse(fetch(url, init));
}
