import React, { useState } from 'react';

import range from 'lodash/range';
import PropTypes from 'prop-types';

const INITIALS = [...range('A'.charCodeAt(0), 'Z'.charCodeAt(0) + 1).map(i => String.fromCharCode(i)), '#'];

const ArtistNameFilter = ({ onCharSelect, value = '' }) => {
	const [character, setCharacter] = useState((value || '').toUpperCase());

	return (
		<>
			<label className='mr-3 playlist__sub-text'>Filter By Artist Name: </label>
			{INITIALS.map(char => (
				<span
					className={`artist-filter__character ${char === character && 'artist-filter__character--selected'}`}
					key={char}
					onClick={() => {
						setCharacter(char !== character ? char : '');
						onCharSelect(char !== character ? char : '');
					}}
				>
					{char}
				</span>
			))}
		</>
	);
};

ArtistNameFilter.propTypes = {
	onCharSelect: PropTypes.func.isRequired,
	value: PropTypes.string,
};

ArtistNameFilter.defaultProps = {
	value: null,
};

export default ArtistNameFilter;
