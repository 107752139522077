import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import * as licensingApi from '../../api/licensing';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';

import ClassesImpacted from './ClassesImpacted';
import PlaylistsImpacted from './PlaylistsImpacted';

const LicensingDashboard = React.memo(({ match }) => {
	const [loaded, setLoaded] = useState(false);
	const [summary, setSummary] = useState({});

	// handle playlist filters at this level to modify summary counts and to retrieve data
	const [playlistFilters, setPlaylistFilters] = useState([]);
	const [playlistSourceFilter, setPlaylistSourceFilter] = useState('ALL');
	const { createToast } = useContext(GlobalContext);

	useEffect(() => fetchSummary(), []); // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		fetchSummary();
	}, [match.params.collection]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchSummary = () => {
		setLoaded(false);
		licensingApi
			.getLicensingDashboardSummary()
			.then(summary => setSummary(summary))
			.catch(() => {
				createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
			})
			.finally(() => {
				setLoaded(true);
			});
	};

	const getSubPages = () => {
		return {
			classes: {
				name: `${summary.classes || 0} Published Classes Impacted`,
				element: <ClassesImpacted countSingleOcurrences={summary.classes || 0} parentLoaded={loaded} />,
			},
			playlists: {
				name: `${summary.playlists || 0} Playlists Impacted`,
				element: (
					<PlaylistsImpacted
						countSingleOcurrences={summary.playlists || 0}
						parentLoaded={loaded}
						filters={playlistFilters}
						setFilters={filters => setPlaylistFilters(filters)}
						playlistSourceFilter={playlistSourceFilter}
						handlePlaylistFilterChange={playlistSourceFilter => setPlaylistSourceFilter(playlistSourceFilter)}
					/>
				),
			},
		};
	};

	const subPageName = match.params.collection || 'classes';
	const subPages = getSubPages();
	const subPagesTabs = Object.keys(subPages).map(sp => (
		<NavLink
			key={sp}
			className={`licesing-dashboard__tab ${subPageName === sp ? 'active' : ''}`}
			activeClassName='active'
			to={`/licensing-dashboard/${sp}`}
			exact
		>
			{subPages[sp].name}
		</NavLink>
	));
	const subPage = subPages[subPageName] && subPages[subPageName].element;

	return (
		<div className='licesing-dashboard'>
			<div className='licesing-dashboard__header'>
				<h3 className='h3'>Licensing Dashboard</h3>
			</div>
			<nav className='navigation-tabs mt-4'>{subPagesTabs}</nav>
			<div className='px-3'>{subPage}</div>
		</div>
	);
});

LicensingDashboard.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			collection: PropTypes.string.isRequired,
		}),
	}).isRequired,
};

export default withRouter(LicensingDashboard);
