import { secondsToMSSFormatter } from './date-time';

export const getArtistInitials = artistName => {
	let formattedName = artistName;

	formattedName = formattedName.replace(/[^0-9a-zA-Z\s]/gi, '');
	if (formattedName.split(' ').length >= 2) {
		// return initials
		return `${formattedName.split(' ')[0].charAt(0).toUpperCase()}${formattedName
			.split(' ')[1]
			.charAt(0)
			.toUpperCase()}`;
	}
	// if name was only 1 word, use first 2 letters of name
	return formattedName.slice(0, 2).toUpperCase();
};

export const formatBPM = (bpm = null) => {
	return bpm ? `${Math.round(bpm)} BPM` : '';
};

export const formatSongDuration = (duration = null) => {
	return duration ? secondsToMSSFormatter(duration) : '';
};

export const getSongsCountLabel = songsCount =>
	songsCount ? `${songsCount} ${songsCount > 1 ? 'Songs' : 'Song'}` : '0 Songs';

//  Add white spaces after semicolons and clean multiple continuos spaces to
// allow name break on word gracefully, if "Artist" name is a long string with multiple artists without white spaces
export const formatArtistName = (name = '') => {
	return name.replace(/;/g, '; ').replace(/\s\s+/g, ' ').trim();
};

export const getSongsSortOptions = (includeZoneKey = false) => {
	const defaultSortOptions = [
		{ label: 'Recently Added', value: 'created_timestamp', sortOrder: 'DESC' },
		{ label: 'Title', value: 'name' },
		{ label: 'Artist', value: 'artist' },
	];

	if (includeZoneKey) {
		defaultSortOptions.splice(1, 0, { label: 'Zone Key', value: 'zone_key_id' });
	}

	return defaultSortOptions;
};

export const getSongsSortFunction = (a, b, filterProperty) => {
	let songAPropertyValue = a[filterProperty];

	let songBPropertyValue = b[filterProperty];

	if (songAPropertyValue === songBPropertyValue) {
		return 0;
	}

	// normalize strings
	songAPropertyValue =
		typeof songAPropertyValue === 'string' ? songAPropertyValue.toString().toLowerCase() : songAPropertyValue;
	songBPropertyValue =
		typeof songBPropertyValue === 'string' ? songBPropertyValue.toString().toLowerCase() : songBPropertyValue;

	if (filterProperty === 'zone_key_id' || filterProperty === 'favourite') {
		// sort zone-key or favourite properties differently to check if the property for a song has not been set yet
		if (songAPropertyValue && !songBPropertyValue) {
			return -1;
		}
		if (!songAPropertyValue && songBPropertyValue) {
			return 1;
		}
	}

	if (songAPropertyValue < songBPropertyValue) {
		return -1;
	}
	if (songAPropertyValue > songBPropertyValue) {
		return 1;
	}
	return 0;
};
