import React, { useContext, useEffect, useRef, useState } from 'react';

import { PLAYLIST_STATUS } from '@content-playlist-creation/common/constants';
import '../../images/plus.svg';
import '../../images/star-fav.svg';
import '../../images/star-fav-disabled.svg';
import '../../images/star-empty.svg';
import '../../images/star-empty-disabled.svg';
import '../../images/notes.svg';
import '../../images/notes-empty.svg';
import '../../images/play.svg';
import '../../images/play-white.svg';
import '../../images/dots.svg';
import '../../images/download.svg';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import * as playlistSongsApi from '../../api/playlist-songs';
import * as playlistsApi from '../../api/playlists';
import * as sharedFoldersApi from '../../api/shared-folders';
import * as songsApi from '../../api/songs';
import * as userSongsApi from '../../api/user-songs';
import { DROP_ZONES } from '../../constants/drop-zones';
import * as PERMISSIONS from '../../constants/permissions';
import PLAYER_MODE from '../../constants/player-modes';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { isContentCreator, isInstructor, hasPermission, isViewOnly } from '../../utils/auth';
import { getSongsSortFunction, getSongsSortOptions } from '../../utils/songs';
import DropdownButton from '../DropdownButton';
import { PaginationButtons } from '../PaginationButtons';
import { Unavailable } from '../Unavailable';

import SongsRow from './SongsRow';

const SongsList = ({
	disableDropdown,
	fetchSongsCollectionCallback,
	folderId,
	folders,
	handleSongListPageChange,
	hideUserSongInfo,
	loadPlaylistToPlayer,
	pageNum,
	pageSize,
	playlistBrandId,
	playlistId,
	playlistStatus,
	playlists,
	showClearedDate,
	songs,
	// Rule for parent components that use this component:
	// If showing user info OR we're in a folder then ORDER BY 'created_timestamp' else 'favourite'
	sortBy,
	handleSortByChange,
	totalSongs,
}) => {
	const [listState, setListState] = useState({
		songs: songs || [],
		playlists: playlists || [],
		folders: folders || [],
	});

	const { createToast, setPlayerMode, setCurrentSong, setShowPlayer, setShowNewFolderModal, zoneKeys, transitions } =
		useContext(GlobalContext);
	const previousValueRef = useRef();
	const previousValue = previousValueRef.current || {};

	if (folders !== previousValue.folders && folders !== listState.folders) {
		setListState({ ...listState, folders });
	}

	if (playlists !== previousValue.playlists && playlists !== listState.playlists) {
		setListState({ ...listState, playlists });
	}

	if (songs !== previousValue.songs && songs !== listState.songs) {
		setListState({ ...listState, songs });
	}

	useEffect(() => {
		previousValueRef.current = listState;
	});

	const handleFavouriteSong = async song => {
		const { songs: workingSongs } = listState;

		if (song.is_favorite) {
			await songsApi.removeSongFromFavorites(song.song_id);
		} else {
			await songsApi.addSongToFavorites(song.song_id);
		}

		setListState({
			...listState,
			songs: workingSongs.map(s => ({
				...s,
				...(s.song_id === song.song_id && {
					...s,
					is_favorite: !song.is_favorite,
				}),
			})),
		});
	};

	const handlePlaySong = song => {
		if (!!playlistId && loadPlaylistToPlayer && song.valid) {
			loadPlaylistToPlayer(song.song_id);
		} else if (song.valid) {
			setPlayerMode(PLAYER_MODE.SONG);
			setCurrentSong(song);
			setShowPlayer(true);
		}
	};

	const handleAddSongToPlaylist = async (song, playlistId) => {
		const { playlists: workingPlaylists } = listState;
		let addToPlaylistId;
		if (playlistId === -1) {
			try {
				const playlistsResults = await playlistsApi.createPlaylist();
				addToPlaylistId = playlistsResults[0].playlist_id;
				workingPlaylists.push(playlistsResults[0]);
			} catch {
				createToast(
					'There was an error adding song to a playlist, please refresh and try later',
					TOAST_LEVEL.ERROR,
					true
				);
				return;
			}
		} else {
			addToPlaylistId = playlistId;
		}

		const playlist = await playlistsApi.addSongToPlaylist(addToPlaylistId, song.song_id);

		const userPlaylists = (workingPlaylists || []).map(p => ({
			...p,
			...(p.playlist_id === addToPlaylistId && {
				playlist_duration: playlist.duration,
				num_songs: (playlist.songs || []).filter(s => !!s.valid).length,
			}),
		}));

		setListState({
			...listState,
			playlists: userPlaylists,
		});

		if (fetchSongsCollectionCallback && playlistId === playlist.playlist_id) {
			fetchSongsCollectionCallback();
		}

		createToast('Successfully added song to playlist!', TOAST_LEVEL.SUCCESS, true);
	};

	const handleBlacklistSong = async () => {
		if (fetchSongsCollectionCallback) {
			fetchSongsCollectionCallback();
		}
	};

	const handleRemoveSongFromPlaylist = async song => {
		await playlistsApi.removeSongFromPlaylist(playlistId, song.playlist_song_id);

		// if SongsList is in Playlist, then need to callback to Playlist to fetch new playlist data
		if (fetchSongsCollectionCallback) {
			fetchSongsCollectionCallback();
		}
	};

	const handleAddSongToFolder = async (song, folderId) => {
		const { folders: workingFolders } = listState;

		// create folder with init song if 'Create New Folder' clicked
		if (folderId === -1) {
			setShowNewFolderModal(true, song.song_id);
			return;
		}

		// adding song to existing folder
		const folder = await sharedFoldersApi.addSongToFolder(folderId, song.song_id);

		setListState({
			...listState,
			folders: (workingFolders || []).map(f => ({
				...f,
				...(f.shared_folder_id === folderId && { num_songs: folder.num_songs }),
			})),
		});

		createToast('Successfully added song to folder!', TOAST_LEVEL.SUCCESS, true);
	};

	const handleFilterChange = sortOption => {
		if (sortOption.value !== sortBy) {
			const { songs: workingSongs } = listState;
			let sorted = workingSongs.sort((a, b) => getSongsSortFunction(a, b, sortOption.value));
			if (!!folderId && sortOption.value === 'created_timestamp') {
				sorted = sorted.reverse();
			}

			handleSortByChange(sortOption.value);
			setListState({ ...listState, songs: sorted });
		}
	};

	const handleRemoveSongFromFolder = async song => {
		await sharedFoldersApi.removeSongFromFolder(folderId, song.shared_folder_song_id);

		// if SongsList is in folder, then need to callback to Playlist to fetch new playlist data
		if (fetchSongsCollectionCallback) {
			fetchSongsCollectionCallback();
		}
	};

	const handleRemoveSong = async song => {
		// Folder Creator removing song from folder
		if (!!folderId && hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR])) {
			await handleRemoveSongFromFolder(song);
		} else {
			await handleRemoveSongFromPlaylist(song);
		}
	};

	const handleZoneKeyChange = async (zoneKey, id) => {
		const { songs: workingSongs } = listState;

		if (playlistId) {
			// Playlist -- id is playlist_song_id
			await playlistSongsApi.updatePlaylistSongZoneKey(zoneKey, id, playlistId);
		}
		if (workingSongs && !hideUserSongInfo && !playlistId) {
			// MySongs
			await userSongsApi.updateUserSongs(id, zoneKey);
		}

		if (fetchSongsCollectionCallback) {
			fetchSongsCollectionCallback();
		}
	};

	const handleTransitionChange = async (transitionId, playlistSongId) => {
		await playlistSongsApi.updatePlaylistSongTransition(transitionId, playlistSongId, playlistId);
		if (fetchSongsCollectionCallback) {
			fetchSongsCollectionCallback();
		}
	};

	const handleInValChange = async (inVal, playlistSongId) => {
		await playlistSongsApi.updatePlaylistSongInVal(inVal, playlistSongId, playlistId);

		if (fetchSongsCollectionCallback) {
			fetchSongsCollectionCallback();
		}
	};

	const handleOutValChange = async (outVal, playlistSongId) => {
		await playlistSongsApi.updatePlaylistSongOutVal(outVal, playlistSongId, playlistId);

		if (fetchSongsCollectionCallback) {
			fetchSongsCollectionCallback();
		}
	};

	const handleUpdatePlaylistNote = async (note, playlistSongId) => {
		await playlistSongsApi.updatePlaylistSongNotes(note, playlistSongId, playlistId);

		if (fetchSongsCollectionCallback) {
			fetchSongsCollectionCallback();
		}
	};

	const handleUpdateMySongsNote = async (note, userSongId) => {
		await userSongsApi.updateUserSongs(userSongId, undefined, note);

		if (fetchSongsCollectionCallback) {
			fetchSongsCollectionCallback();
		}
	};

	// SongRow in Playlist view is draggable if the playlist is NOT ARCHIVED and:
	//      Content Creators can drag REJECTED or SUBMITTED playlists only
	//      Instructors can drag DRAFT playlists only
	//      View Only Content Creators cannot drag
	const isPlaylistSongRowDraggable = () => {
		return (
			playlistId &&
			playlistStatus !== PLAYLIST_STATUS.ARCHIVED &&
			((isContentCreator() &&
				(playlistStatus === PLAYLIST_STATUS.REJECTED || playlistStatus === PLAYLIST_STATUS.SUBMITTED) &&
				!isViewOnly()) ||
				(isInstructor() && playlistStatus === PLAYLIST_STATUS.DRAFT))
		);
	};

	const renderFolderContents = () => {
		const { songs: workingSongs, playlists: workingPlaylists, folders: workingFolders } = listState;

		// Folder
		return (
			<div className='pt-4 w-100 row no-gutters'>
				{workingSongs.map(song => (
					<SongsRow
						song={song}
						inPlaylist={!!playlistId}
						inFolder={!!folderId}
						playlistStatus={playlistStatus}
						playlists={workingPlaylists}
						folders={workingFolders}
						handleRemoveSong={handleRemoveSong}
						handlePlaySong={() => handlePlaySong(song)}
						handleFavouriteSong={handleFavouriteSong}
						handleAddSongToPlaylist={handleAddSongToPlaylist}
						handleAddSongToFolder={handleAddSongToFolder}
						handleBlacklistSongISRC={handleBlacklistSong}
						handleBlacklistSongVariisID={handleBlacklistSong}
						key={`${song.song_id}-p${playlistId || ''}-f${folderId || ''}`}
						showClearedDate={showClearedDate || (!!folderId && sortBy === 'created_timestamp')}
					/>
				))}
			</div>
		);
	};

	const renderNonDraggableSongList = () => {
		const { songs: workingSongs, playlists: workingPlaylists, folders: workingFolders } = listState;

		// MySongs or Non Draggable CC Playlist
		return (
			<div className='pt-5 w-100'>
				{workingSongs.map(song => (
					<SongsRow
						song={song}
						inPlaylist={!!playlistId}
						playlistStatus={playlistStatus}
						inFolder={!!folderId}
						playlists={workingPlaylists}
						folders={workingFolders}
						handleRemoveSong={handleRemoveSong}
						handlePlaySong={handlePlaySong}
						handleFavouriteSong={handleFavouriteSong}
						handleAddSongToPlaylist={handleAddSongToPlaylist}
						handleAddSongToFolder={handleAddSongToFolder}
						handleTransitionChange={handleTransitionChange}
						handleInValChange={handleInValChange}
						handleOutValChange={handleOutValChange}
						handleZoneKeyChange={handleZoneKeyChange}
						handleBlacklistSongISRC={handleBlacklistSong}
						handleBlacklistSongVariisID={handleBlacklistSong}
						handleUpdateNote={playlistId ? handleUpdatePlaylistNote : handleUpdateMySongsNote}
						key={`${song.song_id}-p${playlistId || ''}-f${folderId || ''}`}
						zoneKeys={zoneKeys.filter(zone => !playlistBrandId || zone.brand_id === playlistBrandId)}
						transitions={transitions}
						showClearedDate={showClearedDate}
						isBlacklisted={song.is_blacklisted}
					/>
				))}
			</div>
		);
	};

	const renderDraggableSongList = () => {
		const { songs: workingSongs, playlists: workingPlaylists, folders: workingFolders } = listState;

		// draggable in Playlist for Instructors and Content Creators
		return (
			<div className='pt-5 w-100'>
				{workingSongs.map((song, index) => (
					<Draggable
						key={`${song.song_id}_${index}`} // eslint-disable-line react/no-array-index-key
						draggableId={`${song.song_id}_${index}`}
						index={index}
						isDragDisabled={!song.valid}
					>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								style={{
									background: 'white',
									marginBottom: '1rem',
									...provided.draggableProps.style,
									opacity: !!snapshot && (snapshot.draggingOver || '').includes('--opacity') ? '0.65' : '1',
								}}
							>
								<SongsRow
									song={song}
									inPlaylist={!!playlistId}
									playlistStatus={playlistStatus}
									playlists={workingPlaylists}
									folders={workingFolders}
									handleRemoveSong={handleRemoveSong}
									handlePlaySong={handlePlaySong}
									handleFavouriteSong={handleFavouriteSong}
									handleAddSongToPlaylist={handleAddSongToPlaylist}
									handleAddSongToFolder={handleAddSongToFolder}
									handleZoneKeyChange={handleZoneKeyChange}
									handleUpdateNote={handleUpdatePlaylistNote}
									handleTransitionChange={handleTransitionChange}
									handleInValChange={handleInValChange}
									handleOutValChange={handleOutValChange}
									handleBlacklistSongISRC={handleBlacklistSong}
									handleBlacklistSongVariisID={handleBlacklistSong}
									key={`${song.song_id}-p${playlistId || ''}-f${folderId || ''}`}
									zoneKeys={zoneKeys.filter(zone => !playlistBrandId || zone.brand_id === playlistBrandId)}
									transitions={transitions}
									showClearedDate={showClearedDate}
									isBlacklisted={song.is_blacklisted}
								/>
							</div>
						)}
					</Draggable>
				))}
			</div>
		);
	};

	return (
		<div>
			<Unavailable songs={songs || []} />
			<div className='mt-4'>
				<div className='songslist__filter__container'>
					{!disableDropdown && (
						<DropdownButton
							options={getSongsSortOptions(!folderId)}
							onChange={handleFilterChange}
							placeholder='Sort By'
							showArrowIcon
							menuClassName='dropdown__sort'
							value={sortBy}
						/>
					)}
				</div>
				{songs && (
					<Droppable droppableId={DROP_ZONES.INSTRUCTOR_PLAYLIST}>
						{provided => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{hideUserSongInfo && renderFolderContents()}
								{!hideUserSongInfo &&
									(playlistId && isPlaylistSongRowDraggable()
										? renderDraggableSongList()
										: renderNonDraggableSongList())}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				)}
			</div>
			{!playlistId && totalSongs > pageSize ? (
				<div className='d-flex align-items-center mt-4'>
					<PaginationButtons
						handlePageChange={nextPage => {
							handleSongListPageChange(pageSize, nextPage);
						}}
						numPages={songs ? Math.ceil(totalSongs / pageSize) : 0}
						pageNum={pageNum || 0}
					/>
				</div>
			) : null}
		</div>
	);
};

SongsList.propTypes = {
	disableDropdown: PropTypes.bool,
	fetchSongsCollectionCallback: PropTypes.func.isRequired,
	folderId: PropTypes.number,
	folders: PropTypes.arrayOf(PropTypes.object),
	handleSongListPageChange: PropTypes.func,
	hideUserSongInfo: PropTypes.bool,
	loadPlaylistToPlayer: PropTypes.func,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	playlistBrandId: PropTypes.number,
	playlistId: PropTypes.number,
	playlistStatus: PropTypes.number,
	playlists: PropTypes.arrayOf(PropTypes.object),
	showClearedDate: PropTypes.bool,
	songs: PropTypes.arrayOf(PropTypes.object),
	sortBy: PropTypes.string,
	handleSortByChange: PropTypes.func,
	totalSongs: PropTypes.number,
};

SongsList.defaultProps = {
	disableDropdown: false,
	folderId: null,
	handleSongListPageChange: null,
	hideUserSongInfo: false,
	loadPlaylistToPlayer: null,
	pageNum: 0,
	pageSize: 10,
	playlistId: null,
	playlistStatus: null,
	playlists: [],
	folders: [],
	showClearedDate: false,
	songs: [],
	sortBy: null,
	handleSortByChange: null,
	totalSongs: 0,
	playlistBrandId: null,
};

export default SongsList;
