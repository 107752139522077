import { apiUrl, getDefaultGetInit, parseStandardResponse } from '../utils/api';

const ZONE_KEYS_PATH = 'zone-keys';

export async function getZoneKeys() {
	const url = `${apiUrl}/${ZONE_KEYS_PATH}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}
