import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import * as qs from 'query-string';
import { withRouter } from 'react-router';

import * as searchApi from '../../api/search';
import Loader from '../../components/Loader';
import { PaginationButtons } from '../../components/PaginationButtons';
import ArtistCard from '../../components/search/ArtistCard';
import CollectionCard from '../../components/search/CollectionCard';
import { SEARCH_COLLECTIONS } from '../../constants/search';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as ArrowBack } from '../../images/arrow-back.svg';
import { getSongsCountLabel } from '../../utils/songs';

const PAGE_SIZE = 42;

const SearchResultsCollection = ({ location, history }) => {
	const collectionKey = (location.pathname || '')
		.split('/')
		.filter(l => !!l && Object.values(SEARCH_COLLECTIONS).includes(l))[0];
	const searchTerm = decodeURIComponent(qs.parse(location.search).q || '');
	const urlEncodeTerm = encodeURIComponent(searchTerm.replace('&', '%26'));
	const page = Number(qs.parse(location.search).p || '1');
	const [paginationEnabled, setPaginationEnabled] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [cards, setCards] = useState([]);
	const [total, setTotal] = useState(1000);
	const { createToast } = useContext(GlobalContext);

	const handleBack = () => {
		if (searchTerm) {
			history.push(`/search?q=${urlEncodeTerm}`);
		} else {
			history.goBack();
		}
	};

	const getCollectionResultHeader = () => {
		switch (collectionKey) {
			case SEARCH_COLLECTIONS.ARTIST:
				return (
					<span>
						Artists Results for &ldquo;
						<b>{searchTerm}</b>
						&rdquo;
					</span>
				);
			case SEARCH_COLLECTIONS.ALBUM:
				return (
					<span>
						Album Results for &ldquo;
						<b>{searchTerm}</b>
						&rdquo;
					</span>
				);
			case SEARCH_COLLECTIONS.PLAYLIST:
				return (
					<span>
						Playlists Results for &ldquo;
						<b>{searchTerm}</b>
						&rdquo;
					</span>
				);
			default:
				return (
					<span>
						Search Results for &ldquo;
						<b>{searchTerm}</b>
						&rdquo;
					</span>
				);
		}
	};

	useEffect(() => {
		const loadContent = async () => {
			await searchApi
				.searchCollection({
					collection: collectionKey,
					term: searchTerm,
					limit: PAGE_SIZE,
					page,
				})
				.then(res => {
					setCards(res.data);
					setTotal(res.total);
					setPaginationEnabled(res.total > PAGE_SIZE);
					setLoaded(true);
				})
				.catch(() => {
					createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
				});
		};

		loadContent();
	}, [page]);

	const renderCards = () => {
		return cards.map((card, i) => {
			switch (collectionKey) {
				case SEARCH_COLLECTIONS.ARTIST:
					return (
						<ArtistCard
							name={card.name}
							description={getSongsCountLabel(card.songsCount)}
							showInitials
							key={i}
							className='search__card'
							redirectTo={`/search/${SEARCH_COLLECTIONS.ARTIST}/${encodeURIComponent(
								card.name.replace('&', '%26')
							)}/songs?q=${urlEncodeTerm}`}
							handleBlacklistArtist={() =>
								total === 1
									? history.push(`/search?q=${encodeURIComponent(card.name.replace('&', '%26'))}`)
									: window.location.reload()
							}
						/>
					);
				case SEARCH_COLLECTIONS.ALBUM:
					return (
						<CollectionCard
							className='search__card'
							image={card.thumbnail_image || card.image}
							title={card.name}
							subtitle={card.artist}
							key={i}
							redirectTo={`/search/${SEARCH_COLLECTIONS.ALBUM}/${encodeURIComponent(card.name.replace('&', '%26'))}/${
								card.release_icpn
							}/songs?q=${urlEncodeTerm}`}
						/>
					);
				case SEARCH_COLLECTIONS.PLAYLIST:
					return (
						<CollectionCard
							className='search__card'
							image={card.cover_thumb_images || card.cover_images}
							title={card.name}
							subtitle={getSongsCountLabel((card.song || []).length)}
							key={card.playlist_id}
							status={card.playlist_status_name}
							redirectTo={`/playlists/${card.playlist_id}`}
						/>
					);
				default:
					return null;
			}
		});
	};

	const handlePageChange = nextPage => {
		history.push(`/search/${collectionKey}?q=${urlEncodeTerm}&p=${nextPage}`);
	};

	return (
		<div className='search'>
			<div className='search__back mb-3' onClick={handleBack}>
				<ArrowBack />
				Back
			</div>
			<h3 className='search__header mb-5'>
				{getCollectionResultHeader()} {paginationEnabled && <span className='search__header__subtitle'>({total})</span>}
			</h3>
			<Loader loaded={loaded} className='search__loader'>
				<div className='search__cards'>{renderCards()}</div>
				{paginationEnabled && (
					<div className='search__pagination mt-4'>
						<PaginationButtons
							handlePageChange={handlePageChange}
							numPages={Math.ceil(total / PAGE_SIZE)}
							pageNum={page || 0}
						/>
					</div>
				)}
			</Loader>
		</div>
	);
};

SearchResultsCollection.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(SearchResultsCollection);
