import React, { useState, useEffect, useContext } from 'react';

import { PLAYLIST_STATUS } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';

import * as blacklistApi from '../../api/blacklist';
import { BLACKLIST_TYPE } from '../../constants/blacklist';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import AlertModal from '../AlertModal';

const getBlacklistSongPlaylistUsages = isrcPlaylistUsages =>
	isrcPlaylistUsages.map((playlist, index) => {
		const url = `${playlist.is_internal ? '/playlist/' : '/show-playlist/'}${playlist.playlist_id}`;
		const canClick = playlist.playlist_status_id !== PLAYLIST_STATUS.DRAFT; // CCs and Admins cannot visit DRAFT playlists, server does not return ARCHIVED
		const navigateToPlaylist = () => {
			if (canClick) {
				window.location = url;
			}
		};

		return (
			<li key={index}>
				<span
					onClick={navigateToPlaylist}
					className={`${canClick ? 'alert-modal__html-reasons--link' : ''} float-left`}
				>
					{playlist['Playlist Name']}
				</span>
				<span className='float-right'>
					{playlist.is_internal ? '[Playlist] ' : '[Show Playlist] '}({playlist.status})
				</span>
			</li>
		);
	});

const SongsBlacklistModal = ({ onConfirmClick, onCancelClick, showModal, blacklistType }) => {
	const [playlistUsage, setPlaylistUsages] = useState([]);
	const { createToast, isrcToBlacklist: isrc, variisIDToBlacklist: variisID } = useContext(GlobalContext);

	const fetchPlaylistUsageBySong = async () => {
		if (isrc) {
			blacklistApi
				.getPlaylistUsage(BLACKLIST_TYPE.ISRC, isrc)
				.then(res => {
					setPlaylistUsages(res ? res.data || [] : []);
				})
				.catch(() => {
					createToast(
						'There was an error retrieving playlist usage. Please refresh the page and try again later',
						TOAST_LEVEL.ERROR,
						true
					);
				});
		}
		if (variisID) {
			blacklistApi
				.getPlaylistUsage(BLACKLIST_TYPE.VARIIS_ID, variisID)
				.then(res => {
					setPlaylistUsages(res ? res.data || [] : []);
				})
				.catch(() => {
					createToast(
						'There was an error retrieving playlist usage. Please refresh the page and try again later',
						TOAST_LEVEL.ERROR,
						true
					);
				});
		}
	};

	useEffect(() => {
		fetchPlaylistUsageBySong();
	}, [isrc, variisID]);

	if (!showModal) {
		return null;
	}

	return (
		<AlertModal
			title={`Blacklist ${blacklistType === BLACKLIST_TYPE.ISRC ? 'ISRC' : 'Variis ID'}`}
			description={`Are you sure you want to blacklist the ${
				blacklistType === BLACKLIST_TYPE.ISRC ? 'ISRC' : 'Variis ID'
			} ${blacklistType === BLACKLIST_TYPE.ISRC ? isrc : variisID}?\n
        It will be hidden from the search for all instructors, and will be flagged as "removed" in existing playlists. `}
			showModal={showModal}
			onConfirmClick={onConfirmClick}
			onCancelClick={onCancelClick}
			htmlReasons={getBlacklistSongPlaylistUsages(playlistUsage)}
		/>
	);
};

SongsBlacklistModal.propTypes = {
	onCancelClick: PropTypes.func,
	onConfirmClick: PropTypes.func.isRequired,
	showModal: PropTypes.bool,
	blacklistType: PropTypes.number,
};

SongsBlacklistModal.defaultProps = {
	onCancelClick: null,
	showModal: true,
	blacklistType: BLACKLIST_TYPE.ISRC,
};
export default SongsBlacklistModal;
