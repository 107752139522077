import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

class PaginationButtons extends React.Component {
	getPaginationButton(page) {
		const { numPages, pageNum, handlePageChange } = this.props;
		let transitionPage = page;

		transitionPage = page === 'Previous' ? pageNum - 1 : transitionPage;
		transitionPage = page === 'Next' ? pageNum + 1 : transitionPage;

		return (
			<li
				className={classNames('page-item', {
					'pagination__selected-page': pageNum === page,
					disabled:
						(page === 'Previous' && (pageNum === 1 || numPages === 0)) ||
						(page === 'Next' && (pageNum === numPages || numPages === 0)),
				})}
				key={page}
			>
				<div className='pagination page-link' onClick={() => handlePageChange(transitionPage)}>
					{page}
				</div>
			</li>
		);
	}

	getPaginationEllipsis = key => (
		<li className='page-item disabled' key={key}>
			<div className='page-link'>...</div>
		</li>
	);

	render() {
		const { numPages, pageNum: currentPageNum } = this.props;
		return (
			<nav aria-label='pagination' className='mx-auto'>
				<ul className='pagination'>
					{this.getPaginationButton('Previous')}
					{!Number.isNaN(numPages)
						? Array.from(Array(numPages)).map((e, i) => {
								const pageNum = i + 1;

								if (pageNum === 1 || pageNum === numPages || Math.abs(currentPageNum - pageNum) < 4) {
									return this.getPaginationButton(pageNum);
								}
								if (pageNum - 1 === 1 || pageNum + 1 === numPages) {
									return this.getPaginationEllipsis(pageNum);
								}

								return null;
						  })
						: null}
					{this.getPaginationButton('Next')}
				</ul>
			</nav>
		);
	}
}

PaginationButtons.propTypes = {
	handlePageChange: PropTypes.func.isRequired,
	numPages: PropTypes.number.isRequired,
	pageNum: PropTypes.number.isRequired,
};

export { PaginationButtons };
