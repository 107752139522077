import React from 'react';

import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

import { formatNumberWithCommas } from '../../utils/numbers';

const SongNumbers = ({ clearedSongs, invalidSongs, purgedSongs, totalSongs }) => {
	return (
		<>
			<h5>Song Numbers</h5>
			<div className='catalog__container row'>
				<div className='catalog__stats__column col-4'>
					<div className='catalog__stat'>
						<div className='catalog__stat__text'>Cleared Songs</div>
						<div className='catalog__stat__number'>{formatNumberWithCommas(clearedSongs)}</div>
					</div>
					<div className='catalog__stat'>
						<div className='catalog__stat__text'>Not Cleared Songs</div>
						<div className='catalog__stat__number'>{formatNumberWithCommas(invalidSongs)}</div>
					</div>
					<div className='catalog__stat'>
						<div className='catalog__stat__text'>Purged Songs</div>
						<div className='catalog__stat__number'>{formatNumberWithCommas(purgedSongs)}</div>
					</div>
					<div className='catalog__stat'>
						<div className='catalog__stat__text'>Total Songs</div>
						<div className='catalog__stat__number'>{formatNumberWithCommas(totalSongs)}</div>
					</div>
				</div>
				<div className='catalog__content catalog__chart col-8'>
					<div className='catalog__chart'>
						<Pie
							data={{
								labels: ['Cleared', 'Not Cleared ', 'Purged'],
								datasets: [
									{
										data: [clearedSongs, invalidSongs, purgedSongs],
										backgroundColor: ['#191919', '#4c4c4c', '#7f7f7f'],
									},
								],
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

SongNumbers.propTypes = {
	clearedSongs: PropTypes.number,
	invalidSongs: PropTypes.number,
	purgedSongs: PropTypes.number,
	totalSongs: PropTypes.number,
};

SongNumbers.defaultProps = {
	clearedSongs: 0,
	invalidSongs: 0,
	purgedSongs: 0,
	totalSongs: 0,
};

export default React.memo(SongNumbers);
