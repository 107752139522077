import React from 'react';

import * as songsAPI from '../api/songs';
import ImportFileInput from '../components/forms/FileInput';
import DataTable from '../components/layout/DataTable';
import { PaginationButtons } from '../components/PaginationButtons';
import ISRC from '../constants/isrc';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import { secondsToMSSFormatter } from '../utils/date-time';

const SPLIT_BY_COMMA_IGNORE_COMMAS_WITHIN_QUOTES = /(".*?"|[^",\s]+)(?=\s*,|\s*$)/g;
const MAX_ISRCS_PER_PAGE = 50;
const MAX_ISRCS_INPUT = 5000;

class SongsValidation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			textInput: '',
			inputs: [],
			data: [],
			notFoundISRCs: [],
			loading: false,
			formatError: false,
			currentPageSize: MAX_ISRCS_PER_PAGE,
			currentPageNum: 1,
			totalISRCS: 0,
			submitted: false,
			fileName: '',
		};
	}

	handleSongsValidation = async () => {
		this.setState({ loading: true, submitted: false });
		const isrcsToValidate = this.getISRCSToSubmit() || [];

		if (isrcsToValidate.length) {
			const res = await songsAPI.getClearanceStatusByISRC(isrcsToValidate);

			this.setState(
				{
					data: (res.data || [])
						.filter(r => !!r)
						.map(r => ({
							...r,
							Valid: r.Valid ? 'Valid' : 'Invalid',
							'Percent Clearance': `${r['Percent Clearance']}%`,
							'Song Identifiers': (
								<div>
									<p>
										<strong>ISRC:</strong> {r.ISRC}
									</p>
									<p>
										<strong>
											<span>Variis ID:&nbsp;</span>
										</strong>
										{r['Variis Track ID']}
									</p>
								</div>
							),
							Metadata: (
								<div>
									<p>
										<strong>Name:</strong> {r.Title}
									</p>
									<p>
										<strong>Artist:</strong> {r.Artist}
									</p>
									<p>
										<strong>Album:</strong> {r.Album}
									</p>
									<p>
										<strong>Genre:</strong> {r.Genre}
									</p>
									<p>
										<strong>Label:</strong> {r.Label}
									</p>
									<p>
										<strong>Duration:</strong> {secondsToMSSFormatter(r.Duration)}
									</p>
									<p>
										<strong>BPM:</strong> {r.BPM ? Math.round(r.BPM) : 'n/a'}
									</p>
								</div>
							),
						})),
					totalISRCS: (res.data || []).length,
					submitted: true,
				},
				() => this.setNotFoundISRCs()
			);
		}
		this.setState({ loading: false });
	};

	handleSongsReport = async () => {
		const isrcsToValidate = this.getISRCSToSubmit() || [];

		if (isrcsToValidate.length) {
			const data = await songsAPI.getSongValidationReport(isrcsToValidate);

			// download CSV file
			const csvURL = window.URL.createObjectURL(data);

			const tempLink = document.createElement('a');

			tempLink.href = csvURL;
			tempLink.setAttribute(
				'download',
				`variis_isrc_report_${new Date().toISOString().replace(/-|T|:/g, '').split('.')[0]}.csv`
			);
			tempLink.click();
			tempLink.remove();
			window.URL.revokeObjectURL(data);
		}
	};

	getISRCSToSubmit() {
		let { inputs, maxReached } = this.state;
		const { textInput } = this.state;

		if ((!inputs || !inputs.length) && !!textInput) {
			const rows = textInput.split(/\r\n|\n/).filter(r => !!r && !!r.trim().length);

			maxReached = rows.length > MAX_ISRCS_INPUT;
			inputs = this.formatNColumnsTextInput(rows.splice(0, MAX_ISRCS_INPUT));

			if (!inputs.length) {
				// No valid inputs with manually input text
				this.setState({
					formatError: true,
					loading: false,
					maxReached,
				});
			}
		}

		return inputs || [];
	}

	setNotFoundISRCs() {
		const { data } = this.state;
		const inputISRCs = this.getISRCSToSubmit();

		this.setState({
			notFoundISRCs: inputISRCs.filter(i => data.filter(d => d.ISRC === i).length === 0),
		});
	}

	formatNColumnsTextInput = contentRows => {
		const inputs = [];
		const regex = new RegExp(ISRC.REGEX_PATTERN, 'g');
		const isrcColumnIndex =
			!!contentRows && !!contentRows.length
				? contentRows[contentRows.length - 1]
						.match(SPLIT_BY_COMMA_IGNORE_COMMAS_WITHIN_QUOTES)
						.findIndex(c => c.match(regex)) || 0
				: 0;

		contentRows.forEach(r => {
			// Grab first column for each row
			const rowContent = r.match(SPLIT_BY_COMMA_IGNORE_COMMAS_WITHIN_QUOTES);

			if (!!rowContent && !!rowContent[isrcColumnIndex] && rowContent[isrcColumnIndex].toString().trim().match(regex)) {
				inputs.push(rowContent[isrcColumnIndex].toString().trim());
			}
		});
		return inputs;
	};

	handleFileChosen = file => {
		this.setState({ fileName: !!file && !!file.name ? file.name : '' });
	};

	handleFileContent = async content => {
		const { createToast } = this.context;
		try {
			this.setState({ loading: true, formatError: true });

			if (!content) {
				throw new Error('No content within csv file');
			}
			let inputs = [];
			const contentRows = content.split(/\r\n|\n/).filter(r => !!r && !!r.trim().length);
			const regex = new RegExp(ISRC.REGEX_PATTERN, 'g');

			let maxReached = false;

			if (!contentRows.length) {
				throw new Error('No content within csv file');
			}

			if (contentRows.length === 1) {
				// Single row with comma separated numbers
				const rows = contentRows.splice(',');

				maxReached = rows.length > MAX_ISRCS_INPUT;
				inputs = rows.filter(c => c.match(regex));
			} else {
				maxReached = contentRows.length > MAX_ISRCS_INPUT;
				inputs = this.formatNColumnsTextInput(contentRows.splice(0, MAX_ISRCS_INPUT));
			}

			if (inputs.length === 0) {
				throw new Error('Something failing with expected format');
			}

			this.setState({
				inputs,
				textInput: this.formatFileInputsForTextInput(inputs),
				loading: false,
				formatError: false,
				maxReached,
			});
		} catch (e) {
			this.setState({ loading: false, formatError: true, inputs: [] });
			createToast('Wrong formatted file, please review and upload it again', TOAST_LEVEL.ERROR, false);
		}
	};

	formatFileInputsForTextInput = inputs => inputs.join(',\n');

	render() {
		const {
			textInput,
			loading,
			formatError,
			currentPageNum,
			currentPageSize,
			totalISRCS,
			data,
			maxReached,
			submitted,
			notFoundISRCs,
			fileName,
		} = this.state;
		const tableHeaders = [
			'Song Identifiers',
			'Metadata',
			'Track Source',
			'Valid',
			'Percent Clearance',
			'Not Valid Reason',
			'Number of Playlists Appeared',
			'US Deal End Date',
		];

		const currentPageData = data
			.slice((currentPageNum - 1) * currentPageSize, (currentPageNum - 1) * currentPageSize + currentPageSize)
			.map(r => {
				const row = [];

				tableHeaders.forEach(headerName => {
					row.push(r[headerName]);
				});
				return row;
			});

		return (
			<div className='songs-validation'>
				<h3 className='h3'>Songs Validation</h3>
				<br />
				<h4 className='mb-4'>ISRC Numbers Verification</h4>
				<div className='songs-validation__inputs'>
					<p>
						Use the below text box to input ISRCs to validate their rights or use the upload button to import a{' '}
						<code>.CSV</code> file.
						<br />
						<span>
							*ISRC numbers should be included as a comma-separated list.{' '}
							<b>Max ISRCs numbers allowed is {MAX_ISRCS_INPUT}.</b>
						</span>
					</p>
					<div className='justify-content-between d-flex'>
						<textarea
							onChange={e =>
								this.setState({
									textInput: e.target.value,
									formatError: false,
									inputs: [],
									fileName: '',
								})
							}
							value={textInput}
							className='col-10'
							style={{
								minHeight: '150px',
								maxHeight: '400px',
								resize: 'vertical',
							}}
							placeholder={'Enter ISRCs numbers:\nCCXXXYYNNNNN,\nCCXXXYYNNNNN,...'}
						/>
						<ImportFileInput
							className='col-2 file-input--block'
							acceptFiles='.csv'
							label='Upload file'
							handleFileRead={this.handleFileContent}
							handleFileChosen={this.handleFileChosen}
							fileName={fileName}
							disabled={loading}
						/>
					</div>
					{formatError && <div className='error'>* Input has wrong format</div>}
					{maxReached && (
						<div>
							* Max number of ISRCs reached, list will scan only
							{MAX_ISRCS_INPUT}
						</div>
					)}
					<button
						name='SongValidation - Validate'
						disabled={loading || formatError}
						className='btn primary-btn mt-3'
						onClick={this.handleSongsValidation}
						type='submit'
					>
						Validate
					</button>
					{!!data && !!data.length && !!totalISRCS ? (
						<button
							name='SongValidation - Export Results to CSV'
							className='btn primary-btn mt-3 ml-3 px-4'
							onClick={this.handleSongsReport}
							type='button'
						>
							Export Results to CSV
						</button>
					) : null}
				</div>
				{!!data && !!data.length && !!totalISRCS && (
					<div className='songs-validation__results'>
						<DataTable
							headerRow={tableHeaders}
							className='songs-validation__results__table'
							data={currentPageData}
							fixedLayout
							widths={['15%', '20%', '10%', '5%', '5%', '15%', '5%', '10%']}
							style={{ fontSize: '0.75rem' }}
						/>
						<div className='d-flex align-items-center mt-2'>
							<PaginationButtons
								handlePageChange={nextPage => {
									this.setState({ currentPageNum: nextPage });
								}}
								numPages={Math.ceil(totalISRCS / currentPageSize)}
								pageNum={currentPageNum}
							/>
						</div>
					</div>
				)}
				{submitted && (!data || !data.length || notFoundISRCs.length) ? (
					<div>
						<p className='mt-4 font-weight-bold'>ISRCs Not Found:</p>
						{notFoundISRCs.map(isrc => (
							<div key={isrc}>{isrc}</div>
						))}
					</div>
				) : null}
			</div>
		);
	}
}
SongsValidation.contextType = GlobalContext;

export default SongsValidation;
