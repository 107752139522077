import React from 'react';

import PropTypes from 'prop-types';

import * as playlistsApi from '../api/playlists';
import * as sharedFoldersApi from '../api/shared-folders';
import * as transitionsApi from '../api/transitions';
import * as zoneKeysApi from '../api/zone-keys';
import * as PERMISSIONS from '../constants/permissions';
import PLAYER_MODES from '../constants/player-modes';
import PLAYER_STATUS from '../constants/player-status';
import { isInstructor, isSharedFolderUser, hasPermission, getUser, canViewLicensing } from '../utils/auth';

const { PLAYLIST_STATUS } = require('@content-playlist-creation/common/constants');

const GlobalContext = React.createContext();

const fetchPlaylists = async playlistInSearchContext => {
	// Bring extended data for playlists for including thumbnails and songs counts to be displayed on side playlist rail and on "Add to Playlist `+`" buttons when adding songs a
	return isInstructor()
		? (
				await playlistsApi.getUserPlaylists({
					playlistStatusIds: [PLAYLIST_STATUS.DRAFT],
					includeThumbnails: true,
					includeSongDetails: true,
				})
		  ).data
		: (
				await playlistsApi.getPlaylists({
					status: [PLAYLIST_STATUS.SUBMITTED, PLAYLIST_STATUS.REJECTED],
					limit: 25, // display latest 25 on search playlist rail
					page: 1,
					sortBy: 'updated-date',
					sortOrder: 'DESC',
					playlistIds: playlistInSearchContext ? [playlistInSearchContext.playlist_id] : [],
					includeThumbnails: true,
					playlistTypeFilter: -1, // all types
				})
		  ).data;
};

const fetchFolders = async () => {
	if (isSharedFolderUser() && hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR])) {
		const result = await sharedFoldersApi.getFolders(null, null, false);

		return result.data || [];
	}

	return [];
};

const fetchExternalPlaylistStatuses = async () => {
	if (canViewLicensing()) {
		const result = await playlistsApi.getExternalPlaylistStatuses();

		if (result) {
			return result.map(s => s.status).filter(s => typeof s === 'string');
		}
		return [];
	}
	return [];
};

/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
export class GlobalProvider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isrcToBlacklist: null,
			showISRCBlacklistModal: false,
			isrcBlacklistCallback: null,
			openISRCBlacklistModal: (isrcToBlacklist, isrcBlacklistCallback) => {
				this.setState({ isrcToBlacklist, showISRCBlacklistModal: true, isrcBlacklistCallback });
			},
			closeISRCBlacklistModal: () => {
				this.setState({ isrcToBlacklist: null, showISRCBlacklistModal: false, isrcBlacklistCallback: null });
			},

			artistToBlacklist: null,
			showArtistBlacklistModal: false,
			artistBlacklistCallback: null,
			openArtistBlacklistModal: (artistToBlacklist, artistBlacklistCallback) => {
				this.setState({ artistToBlacklist, showArtistBlacklistModal: true, artistBlacklistCallback });
			},
			closeArtistBlacklistModal: () => {
				this.setState({
					artistToBlacklist: null,
					showArtistBlacklistModal: false,
					artistBlacklistCallback: null,
				});
			},
			variisIDToBlacklist: null,
			showVariisIDBlacklistModal: false,
			variisBlacklistCallback: null,
			openVariisIDBlacklistModal: (variisIDToBlacklist, variisBlacklistCallback) => {
				this.setState({ variisIDToBlacklist, showVariisIDBlacklistModal: true, variisBlacklistCallback });
			},
			closeVariisIDBlacklistModal: () => {
				this.setState({ variisIDToBlacklist: null, showVariisIDBlacklistModal: false, variisBlacklistCallback: null });
			},

			newSearchDraggedFavoriteSong: null,
			setNewSearchDraggedFavoriteSong: songId => {
				this.setState({ newSearchDraggedFavoriteSong: songId });
			},

			showNewPlaylistModal: false,
			setShowNewPlaylistModal: (showNewPlaylistModal, initSongId, redirect) => {
				this.setState({
					showNewPlaylistModal,
					newPlaylistModalInitSongId: initSongId,
					redirectToPlaylist: redirect,
				});
			},
			newPlaylistModalInitSongId: null,
			redirectToPlaylist: true,
			clearNewPlaylistModalInitSongId: () => {
				this.setState({ newPlaylistModalInitSongId: null });
			},
			showNewFolderModal: false,
			songToInitializeFolderWith: null,
			setShowNewFolderModal: (showNewFolderModal, songToInitializeFolder = null) => {
				this.setState({ showNewFolderModal, songToInitializeFolder });
			},

			isrcToPlayCount: null,
			showISRCPlayCountModal: false,
			openISRCPlayCountModal: isrcToPlayCount => {
				this.setState({ isrcToPlayCount, showISRCPlayCountModal: true });
			},
			closeISRCPlayCountModal: () => {
				this.setState({ isrcToPlayCount: null, showISRCPlayCountModal: false });
			},

			showPlayer: false,
			setShowPlayer: showPlayer => {
				this.setState({ showPlayer });
			},

			toasts: [],
			createToast: (message, level, autoHide, callback = null, hideTimeout = null) => {
				this.setState({
					toasts: [
						...this.state.toasts,
						{
							message,
							level,
							autoHide,
							callback,
							hideTimeout,
						},
					],
				});
			},
			removeToast: message => {
				this.setState({
					toasts: this.state.toasts.filter(t => t.message !== message),
				});
			},

			playerStatus: PLAYER_STATUS.STOPPED,
			setPlayerStatus: playerStatus => {
				this.setState({ playerStatus });
			},

			currentSong: null,
			setCurrentSong: currentSong => {
				let { playerStatus } = this.state;

				if (playerStatus === PLAYER_STATUS.PLAYING) {
					playerStatus = PLAYER_STATUS.PAUSE;
				} else if (playerStatus === PLAYER_STATUS.PAUSE) {
					playerStatus = PLAYER_STATUS.PLAYING;
				}

				this.setState({ currentSong, playerStatus });
			},

			currentPlaylist: [],
			setCurrentPlaylist: currentPlaylist => {
				this.setState({ currentPlaylist });
			},
			currentPlaylistID: null,
			setCurrentPlaylistID: currentPlaylistID => {
				this.setState({ currentPlaylistID });
			},

			playerSongsQueue: [],
			loadPlaylistIntoPlayer: (playerSongsQueue, song, callback, refreshQueue = false) => {
				let currentSong;

				let playlistSongId;

				let { playerStatus } = this.state;

				if (!song && !!refreshQueue && !!playerSongsQueue && !!playerSongsQueue.length) {
					currentSong = this.state.currentSong;
					playlistSongId = this.state.playlistSongId;
				} else {
					if (playerStatus === PLAYER_STATUS.PLAYING) {
						playerStatus = PLAYER_STATUS.PAUSE;
					} else if (playerStatus === PLAYER_STATUS.PAUSE) {
						playerStatus = PLAYER_STATUS.PLAYING;
					}
					currentSong = song || (playerSongsQueue.length ? playerSongsQueue[0] : null);
					playlistSongId = currentSong.playlist_song_id;
				}

				this.setState(
					{
						playerSongsQueue,
						currentSong,
						playlistSongId,
						playerStatus,
					},
					callback
				);
			},

			playlistSongId: null,
			setPlaylistSongId: playlistSongId => {
				this.setState({ playlistSongId });
			},

			playerMode: PLAYER_MODES.SONG,
			setPlayerMode: playerMode => {
				this.setState({ playerMode });
			},
			playerPlaylist: null,
			setPlayerPlaylist: playerPlaylist => {
				this.setState({ playerPlaylist });
			},

			zoneKeys: [],
			transitions: [],
			playlistTypes: [],
			setZoneKeysTransitions: (zoneKeys, transitions) => {
				this.setState({ zoneKeys, transitions });
			},
			isDragging: false,
			setIsDragging: isDragging => {
				this.setState({ isDragging });
			},
			searchPlaylists: null,
			setSearchPlaylists: playlists => {
				this.setState({ searchPlaylists: playlists });
			},
			getSearchPlaylists: async (reset = false) => {
				let { searchPlaylists } = this.state;

				if (searchPlaylists === null || reset) {
					searchPlaylists = await fetchPlaylists(this.state.playlistInSearchContext);
					this.setState({ searchPlaylists });
				}

				return searchPlaylists;
			},

			searchFolders: null,
			setSearchFolders: folders => {
				this.setState({ searchFolders: folders });
			},
			getSearchFolders: async (reset = false) => {
				let { searchFolders } = this.state;

				if (searchFolders === null || reset) {
					searchFolders = await fetchFolders();
					this.setState({ searchFolders });
				}
				return searchFolders;
			},

			playlistInSearchContext: sessionStorage.getItem('PLAYLISTINSEARCHCONTEXT')
				? JSON.parse(sessionStorage.getItem('PLAYLISTINSEARCHCONTEXT'))
				: null,
			setPlaylistToSearchContext: async playlistInSearchContext => {
				if (playlistInSearchContext) {
					sessionStorage.setItem('PLAYLISTINSEARCHCONTEXT', JSON.stringify(playlistInSearchContext));
				} else {
					sessionStorage.removeItem('PLAYLISTINSEARCHCONTEXT');
				}

				// reset search playlist to be pull when loading search results
				const searchPlaylists = await fetchPlaylists(playlistInSearchContext);

				this.setState({ playlistInSearchContext, searchPlaylists });
			},

			externalPlaylistStatuses: [],

			scrollLock: false,
			toggleScrollLock: (newValue = null) => {
				this.setState({ scrollLock: newValue !== null ? newValue : this.state.scrollLock });
			},
		};
	}

	async componentDidMount() {
		if (getUser()) {
			// logged in
			await this.initUserSessionContext();
		}
	}

	initUserSessionContext = async () => {
		const playlistInSearchContext = sessionStorage.getItem('PLAYLISTINSEARCHCONTEXT')
			? JSON.parse(sessionStorage.getItem('PLAYLISTINSEARCHCONTEXT'))
			: null;
		const [zoneKeys, transitions, playlistTypes, searchPlaylists, searchFolders, externalPlaylistStatuses] =
			await Promise.all([
				zoneKeysApi.getZoneKeys(),
				transitionsApi.getTransitions(),
				playlistsApi.getPlaylistTypes(),
				fetchPlaylists(playlistInSearchContext),
				fetchFolders(),
				fetchExternalPlaylistStatuses(),
			]);

		this.setState({
			zoneKeys,
			transitions,
			playlistTypes,
			searchPlaylists,
			searchFolders,
			playlistInSearchContext,
			externalPlaylistStatuses,
		});
	};

	render() {
		const { children } = this.props;

		return <GlobalContext.Provider value={this.state}>{children}</GlobalContext.Provider>;
	}
}

GlobalProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const GlobalConsumer = GlobalContext.Consumer;

export default GlobalContext;
