import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as settingsAPI from '../api/settings';
import Loader from '../components/Loader';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import closeImg from '../images/close.svg';
import { isAdministrator } from '../utils/auth';

const Settings = ({ history }) => {
	const [adminMessageReceiversLoaded, setAdminMessageReceiversLoaded] = useState(false);
	const [adminMessageReceivers, setAdminMessageReceivers] = useState([]);
	const [newAdminMessageEmail, setNewAdminMessageEmail] = useState(null);
	const [playlistNotificationReceiversLoaded, setPlaylistNotificationReceiversLoaded] = useState(false);
	const [playlistNotificationReceivers, setPlaylistNotificationReceivers] = useState([]);
	const [newPlaylistNotificationEmail, setNewPlaylistNotificationEmail] = useState(null);
	const { createToast } = useContext(GlobalContext);

	useEffect(() => {
		if (!isAdministrator()) {
			history.push('/');
		} else {
			fetchNotificationReceivers();
			fetchAdminMessageReceivers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchNotificationReceivers = async () => {
		await settingsAPI.getPlaylistNotificationReceivers().then(data => {
			setPlaylistNotificationReceivers(data);
			setPlaylistNotificationReceiversLoaded(true);
		});
	};

	const fetchAdminMessageReceivers = async () => {
		await settingsAPI.getAdminMessageReceivers().then(data => {
			setAdminMessageReceivers(data);
			setAdminMessageReceiversLoaded(true);
		});
	};

	const onAddNewPlaylistNotificationReceiver = async event => {
		event.preventDefault();
		await settingsAPI
			.addPlaylistNotificationReceiver(newPlaylistNotificationEmail)
			.then(data => {
				setPlaylistNotificationReceiversLoaded(false);
				setNewPlaylistNotificationEmail('');
				fetchNotificationReceivers();
			})
			.catch(err => {
				createToast(err.message, TOAST_LEVEL.ERROR, true);
			});
	};

	const onAddNewAdminMessageReceiver = async event => {
		event.preventDefault();
		await settingsAPI
			.addAdminMessageReceiver(newAdminMessageEmail)
			.then(data => {
				setAdminMessageReceiversLoaded(false);
				setNewAdminMessageEmail('');
				fetchAdminMessageReceivers();
			})
			.catch(err => {
				createToast(err.message, TOAST_LEVEL.ERROR, true);
			});
	};

	const onDeletePlaylistNotificationReceiver = async deleteEmail => {
		await settingsAPI
			.deletePlaylistNotificationReceiver(deleteEmail)
			.then(() => {
				setPlaylistNotificationReceiversLoaded(false);
				fetchNotificationReceivers();
			})
			.catch(() => {
				createToast('Email could not be deleted', TOAST_LEVEL.ERROR, true);
			});
	};

	const onDeleteAdminMessageReceiver = async deleteEmail => {
		await settingsAPI
			.deleteAdminMessageReceiver(deleteEmail)
			.then(() => {
				setAdminMessageReceiversLoaded(false);
				fetchAdminMessageReceivers();
			})
			.catch(() => {
				createToast('Email could not be deleted', TOAST_LEVEL.ERROR, true);
			});
	};

	return (
		<>
			<div className='settings'>
				<h3 className='h3 mb-3'>Settings</h3>
				<div>
					<h4 className='h4 mt-4'>Admin message receivers</h4>
					<hr />
					<div>Emails which will be notified about all the scheduled jobs statuses, updates and alerts</div>
					<form className='d-flex justify-content-between align-items-center' onSubmit={onAddNewAdminMessageReceiver}>
						<div className='settings__add-new-email d-flex align-items-center justify-content-start'>
							<label className='settings__add-email-label'>New admin message receiver:</label>
							<input
								type='email'
								onChange={e => setNewAdminMessageEmail(e.target.value)}
								required
								className='settings__add-email-input'
								value={newAdminMessageEmail}
								disabled={!adminMessageReceiversLoaded}
							/>
						</div>
						<button
							name='Settings - Add new admin message receiver'
							type='submit'
							className='settings__add-new-btn primary-btn'
							disabled={!adminMessageReceiversLoaded || !newAdminMessageEmail}
							onClick={onAddNewAdminMessageReceiver}
						>
							Add new admin message receiver
						</button>
					</form>
					<Loader loaded={adminMessageReceiversLoaded}>
						<table className='settings__table'>
							<thead>
								<tr className='settings__table-header-row'>
									<th className='settings__table-header-cell'>Email</th>
								</tr>
							</thead>
							<tbody>
								{adminMessageReceivers.map(email => (
									<tr key={`notification-receiver-${email}`} className='settings__table-row'>
										<td>
											<div className='d-flex justify-content-between'>
												{email}
												<button
													name='Settings - Remove'
													type='button'
													className='settings__delete-btn'
													onClick={() => onDeleteAdminMessageReceiver(email)}
												>
													<img src={closeImg} alt='remove' />
												</button>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</Loader>
				</div>
				<div className='mt-5'>
					<h4 className='h4 mt-4'>Playlist notification receivers</h4>
					<hr />
					<div>
						Emails which will be notified about the playlists status on label takedowns, LSR revokes, LSR revalidations
						and songs expirations
					</div>
					<form
						className='d-flex justify-content-between align-items-center'
						onSubmit={onAddNewPlaylistNotificationReceiver}
					>
						<div className='settings__add-new-email d-flex align-items-center justify-content-start'>
							<label className='settings__add-email-label'>New playlist notification receiver:</label>
							<input
								type='email'
								onChange={e => setNewPlaylistNotificationEmail(e.target.value)}
								required
								className='settings__add-email-input'
								value={newPlaylistNotificationEmail}
								disabled={!playlistNotificationReceiversLoaded}
							/>
						</div>
						<button
							name='Settings - Add new playlist notification receiver'
							type='submit'
							className='settings__add-new-btn primary-btn'
							disabled={!playlistNotificationReceiversLoaded || !newPlaylistNotificationEmail}
							onClick={onAddNewPlaylistNotificationReceiver}
						>
							Add new playlist notification receiver
						</button>
					</form>
					<Loader loaded={playlistNotificationReceiversLoaded}>
						<table className='settings__table'>
							<thead>
								<tr className='settings__table-header-row'>
									<th className='settings__table-header-cell'>Email</th>
								</tr>
							</thead>
							<tbody>
								{playlistNotificationReceivers.map(email => (
									<tr key={`notification-receiver-${email}`} className='settings__table-row'>
										<td>
											<div className='d-flex justify-content-between'>
												{email}
												<button
													name='Settings - Remove'
													type='button'
													className='settings__delete-btn'
													onClick={() => onDeletePlaylistNotificationReceiver(email)}
												>
													<img src={closeImg} alt='remove' />
												</button>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</Loader>
				</div>
			</div>
		</>
	);
};

Settings.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(Settings);
