import * as qs from 'query-string';

import {
	apiUrl,
	getDefaultDeleteInit,
	getDefaultGetInit,
	getDefaultPostInit,
	parseStandardResponse,
} from '../utils/api';

const GENRES_PATH = 'genres';

export async function getGenresMappings(includeUnmappedOnly, includeBlacklisted) {
	const url = `${apiUrl}/${GENRES_PATH}/genres-mapping?${qs.stringify({
		includeUnmappedOnly,
		includeBlacklisted,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getClearedGenres() {
	const url = `${apiUrl}/${GENRES_PATH}/cleared-genre`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function updateGenreMapping(genre, clearedGenre) {
	const url = `${apiUrl}/${GENRES_PATH}/map-genres`;
	const init = getDefaultPostInit({ genre, clearedGenre });

	return parseStandardResponse(fetch(url, init));
}

export async function addClearedGenre(genre) {
	const url = `${apiUrl}/${GENRES_PATH}/cleared-genre`;
	const init = getDefaultPostInit({ genre });

	return parseStandardResponse(fetch(url, init));
}

export async function removeClearedGenre(genre) {
	const url = `${apiUrl}/${GENRES_PATH}/cleared-genre/${genre}`;
	const init = getDefaultDeleteInit();

	return parseStandardResponse(fetch(url, init));
}
