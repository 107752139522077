export default {
	EXTERNAL_SHOW_PLAYLISTS: {
		key: 'SHOW_PLAYLISTS',
		label: 'Show Playlist',
	},
	INTERNAL_PLAYLISTS: {
		key: 'PLAYLISTS',
		label: 'Playlists',
	},
};
