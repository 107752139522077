import React, { useState, useEffect, useContext } from 'react';

import * as blacklistApi from '../../api/blacklist';
import AlertModal from '../../components/AlertModal';
import TextInputForm from '../../components/forms/TextInputForm';
import DataTable from '../../components/layout/DataTable';
import Loader from '../../components/Loader';
import { NotesPanel } from '../../components/NotesPanel';
import { PaginationButtons } from '../../components/PaginationButtons';
import { BLACKLIST_TYPE } from '../../constants/blacklist';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import VARIIS_ID_REGEX_PATTERN from '../../constants/variis-id';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as RemoveImg } from '../../images/close.svg';
import { dateToMMMDDYYYYFormatter } from '../../utils/date-time';

const PAGE_SIZE = 50;

const SongByVariisIDBlacklist = () => {
	const [blacklistedSongs, setBlacklistedSongs] = useState([]);
	const [selectedVariisID, setSelectedVariisID] = useState(null);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const { createToast, openVariisIDBlacklistModal } = useContext(GlobalContext);
	const [total, setTotal] = useState(0);
	const [pageNum, setPageNum] = useState(1);

	const showRemoveVariisIdModal = variisID => {
		setSelectedVariisID(variisID);
		setShowRemoveModal(true);
	};

	const renderPlaylistCell = (playlistNames = []) => {
		return (
			<div className='blacklist__playlist-metadata  my-3'>
				{playlistNames.map((playlist, index) => (
					<div className='pr-3' key={index}>
						{playlist}
					</div>
				))}
			</div>
		);
	};

	const renderSongCell = songs => {
		return songs.map(
			(
				song,
				index // Song Metadata
			) => (
				<div className='blacklist__song-metadata my-3' key={index}>
					<div>
						<b>Song Title</b>:{song.name}
					</div>
					<div>
						<b>Artist</b>:{song.artist}
					</div>
					<div>
						<b>Album</b>:{song.album}
					</div>
				</div>
			)
		);
	};

	const renderRemoveButton = blacklistedVariisID => (
		<div className='blacklist__btn-remove' alt='remove' onClick={() => showRemoveVariisIdModal(blacklistedVariisID)}>
			<RemoveImg />
		</div>
	);

	const handleNoteUpdate = async (variisID, newNote) => {
		await blacklistApi.updateBlacklistRecord(BLACKLIST_TYPE.VARIIS_ID, variisID, newNote);
		// eslint-disable-next-line no-use-before-define
		await fetchBlacklistedSongs();
	};

	const formatRows = data => {
		return data.map(blacklistedVariisID => [
			blacklistedVariisID.value, // Variis ID
			renderSongCell(blacklistedVariisID.songs || []), // Song Metadata
			renderPlaylistCell([
				...(blacklistedVariisID.affected_internal_playlists
					? blacklistedVariisID.affected_internal_playlists.split(',')
					: []),
				...(blacklistedVariisID.affected_external_playlists
					? blacklistedVariisID.affected_external_playlists.split(',')
					: []),
			]), // Affected Playlists
			dateToMMMDDYYYYFormatter(blacklistedVariisID.created_timestamp), // Blacklisted Date
			blacklistedVariisID.blacklisted_by, // Blacklisted By
			<NotesPanel // Notes
				note={blacklistedVariisID.notes}
				onNoteUpdate={newNote => handleNoteUpdate(blacklistedVariisID.value, newNote)}
				tooltipHeader='Songs Notes'
				tooltipDetail={blacklistedVariisID.value}
			/>, // Notes
			renderRemoveButton(blacklistedVariisID.value),
		]);
	};

	const fetchBlacklistedSongs = async (page = null) => {
		await blacklistApi
			.getBlacklistedSongsByVariisID(PAGE_SIZE, page || pageNum)
			.then(res => {
				setBlacklistedSongs(formatRows(res.data || []));
				setTotal(res.count || 0);
				setLoaded(true);
				if (!!page && page !== pageNum) {
					setPageNum(page || pageNum);
				}
			})
			.catch(() => {
				createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
			});
	};

	const handleRemoveVariisID = async () => {
		const numDeleted = await blacklistApi.removeBlacklistedVariisID(selectedVariisID);

		setShowRemoveModal(false);
		await fetchBlacklistedSongs(1);

		// show toast
		if (numDeleted > 0) {
			createToast(`Successfully removed ${selectedVariisID} from Variis ID filter!`, TOAST_LEVEL.SUCCESS, true);
		} else {
			createToast(
				'There was an error while updating the Variis ID filter. Please try again later',
				TOAST_LEVEL.ERROR,
				true
			);
		}
	};

	const handleAddSongToBlacklist = async () => {
		await fetchBlacklistedSongs(1);
		setSelectedVariisID(null);
	};

	const handleAddVariisIDOnSubmit = async variisId => {
		setSelectedVariisID(variisId);
		openVariisIDBlacklistModal(variisId, handleAddSongToBlacklist);
	};

	// componentDidMount
	useEffect(() => {
		fetchBlacklistedSongs();
	}, [pageNum]);

	// table information
	const tableHeaders = [
		'Variis ID',
		'Song Metadata',
		'Affected Playlists',
		'Blacklisted Date',
		'Blacklisted By',
		'Notes',
		'',
	];

	const tableWidths = ['10%', '30%', '15%', '10%', '15%', '18%', '2%'];

	return (
		<Loader loaded={loaded} className='licesing-dashboard__loader'>
			<AlertModal
				title='Remove Variis ID From Blacklist'
				showModal={showRemoveModal}
				description={`Are you sure you want to remove the Variis ID ${selectedVariisID} from the blacklist?`}
				onConfirmClick={handleRemoveVariisID}
				onCancelClick={() => setShowRemoveModal(false)}
			/>
			<div className='mb-3 d-flex flex-column'>
				<div className='d-flex justify-content-between mb-3'>
					<h4 className='h4 mb-1'>Variis IDs</h4>
					<TextInputForm
						label='Add Variis ID To Blacklist'
						onSubmit={variisID => handleAddVariisIDOnSubmit(variisID)}
						submitText='Submit'
						inputValidationRegexp={VARIIS_ID_REGEX_PATTERN.REGEX_PATTERN}
						inputValidationFailMessage='The provided Variis ID is not formatted correctly. Please enter a valid Variis ID.'
						useValidationErrorPopup={false}
					/>
				</div>
				<div>
					<DataTable
						className='blacklist__table'
						fixedLayout
						data={blacklistedSongs}
						headerRow={tableHeaders}
						widths={tableWidths}
					/>
				</div>
				{loaded && total > PAGE_SIZE && (
					<div className='d-flex mt-5'>
						<PaginationButtons
							handlePageChange={p => setPageNum(p)}
							numPages={Math.ceil(total / PAGE_SIZE)}
							pageNum={pageNum}
						/>
					</div>
				)}
			</div>
		</Loader>
	);
};

export default SongByVariisIDBlacklist;
