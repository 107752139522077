import * as qs from 'query-string';

import {
	apiUrl,
	getDefaultDeleteInit,
	getDefaultGetInit,
	getDefaultPostInit,
	parseStandardResponse,
} from '../utils/api';

const PLAYLIST_PATH = 'playlists';

export async function getUserPlaylists({
	playlistStatusIds = [],
	playlistTypeIds,
	page = null,
	limit = null,
	includeArchived = false,
	includeThumbnails = false,
	includeSongDetails = false,
	username = null,
}) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/user?${qs.stringify({
		playlistStatusIds: playlistStatusIds ? playlistStatusIds.join(',') : null,
		playlistTypeIds,
		page,
		limit,
		includeArchived,
		includeThumbnails,
		includeSongDetails,
		username,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getPlaylists({
	status = [],
	limit,
	page,
	sortOrder,
	sortBy,
	playlistIds = [],
	playlistTypeFilter,
	brandFilter,
	includeThumbnails = false,
	includeSongDetails = false,
}) {
	const url = `${apiUrl}/${PLAYLIST_PATH}?${qs.stringify({
		status: status.join(','),
		limit,
		page,
		sortOrder,
		sortBy,
		playlistIds: playlistIds.join(','),
		playlistTypeFilter,
		brandFilter,
		includeThumbnails,
		includeSongDetails,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getPlaylist(playlistId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/${playlistId}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function addSongToPlaylist(playlistId, songId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/${playlistId}/add-song/${songId}`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function removeSongFromPlaylist(playlistId, playlistsSongId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/${playlistId}/remove-song/${playlistsSongId}`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function createPlaylist(playlistTypeId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}`;
	const init = getDefaultPostInit({ playlistTypeId });

	return parseStandardResponse(fetch(url, init));
}

export async function submitPlaylistForApproval(playlistId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/${playlistId}/submit`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function approvePlaylist(playlistId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/${playlistId}/approve`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function rejectPlaylist(playlistId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/${playlistId}/reject`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function revisePlaylist(playlistId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/${playlistId}/revise`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function editPlaylist(playlistId, showID, targetDuration, notes, playlistTypeId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/${playlistId}/edit`;
	const init = getDefaultPostInit({
		show_id: showID,
		target_duration: targetDuration,
		notes,
		playlist_type_id: playlistTypeId,
	});

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function archivePlaylist(playlistId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/${playlistId}`;
	const init = getDefaultDeleteInit();

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function restorePlaylist(playlistId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/${playlistId}/restore`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function arrangeSongs(songs) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/arrange`;
	const init = getDefaultPostInit({ songs });

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function getExternalShowPlaylists({ limit, page, globalTrackerShowIds }) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/show-playlists?${qs.stringify({
		limit,
		page,
		globalTrackerShowIds,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function exportShowPlaylistsReportCSV() {
	const url = `${apiUrl}/${PLAYLIST_PATH}/show-playlists/export`;
	const init = getDefaultGetInit();
	const res = await fetch(url, init);

	return res.blob();
}

export async function getExternalShowPlaylist(globalTrackerShowId) {
	const url = `${apiUrl}/${PLAYLIST_PATH}/show-playlists/${globalTrackerShowId}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getPlaylistReport() {
	const url = `${apiUrl}/${PLAYLIST_PATH}/report/download`;
	const init = getDefaultGetInit();
	const res = await fetch(url, init);

	return res.blob();
}

export async function getPlaylistTypes() {
	const url = `${apiUrl}/${PLAYLIST_PATH}/playlist-types`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getExternalPlaylistStatuses() {
	const url = `${apiUrl}/${PLAYLIST_PATH}/external-playlist-statuses`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}
