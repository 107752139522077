import React, { useState } from 'react';

import PropTypes from 'prop-types';

const NotesPanel = ({
	note,
	onNoteUpdate,
	tooltipDetail,
	tooltipHeader,
	isLocked,
	unopenedHeight,
	expandableHeight,
}) => {
	const [openPanel, setOpenPanel] = useState(false);
	const [newNote, setNewNote] = useState(note || '');
	const [noteState, setNoteState] = useState(note || '');

	const handleNoteUpdate = n => {
		setNoteState(n);
		onNoteUpdate(n);
		setOpenPanel(false);
	};

	return (
		<div className='notes__tooltip' style={{ ...(expandableHeight && { display: 'flex', padding: '1rem 0' }) }}>
			{!noteState || !expandableHeight ? (
				<textarea
					value={noteState || ''}
					onClick={() => setOpenPanel(!openPanel)}
					className='notes__textarea'
					readOnly
					placeholder='Enter note...'
					style={{
						...(noteState && !expandableHeight && { height: unopenedHeight }),
						...(expandableHeight && { height: 'unset' }),
					}}
					{...(expandableHeight && !noteState && { rows: 1 })}
				/>
			) : null}
			{noteState && expandableHeight ? (
				<p className='notes__paragraph' onClick={() => setOpenPanel(!openPanel)}>
					{noteState || ''}
				</p>
			) : null}
			<div className={`notes__tooltip__panel shadow ${openPanel ? 'notes__tooltip__panel--visible' : ''}`}>
				<div className='h6 mb-1'>{tooltipHeader}</div>
				<div className='notes__tooltip__panel__item__date mb-3'>{tooltipDetail}</div>
				<div className='h-100'>
					<textarea
						onChange={e => setNewNote(e.target.value)}
						value={newNote}
						className='notes__tooltip__panel__textarea'
						placeholder='Enter note here'
						readOnly={isLocked}
						style={{ ...(isLocked && { resize: 'none' }) }}
					/>
					<div className='d-flex justify-content-end'>
						<div>
							<span onClick={() => setOpenPanel(false)} className='notes__tooltip__panel__close'>
								Close
							</span>
							{!isLocked && (
								<button
									name='NotesPanel - Update'
									className='notes__tooltip__panel__add ml-3 mt-1 primary-btn'
									style={{ width: '80px' }}
									onClick={() => {
										handleNoteUpdate(newNote);
										setOpenPanel(false);
									}}
									disabled={isLocked}
									type='button'
								>
									Update
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

NotesPanel.propTypes = {
	expandableHeight: PropTypes.bool,
	isLocked: PropTypes.bool,
	note: PropTypes.string,
	onNoteUpdate: PropTypes.func.isRequired,
	tooltipDetail: PropTypes.string,
	tooltipHeader: PropTypes.string.isRequired,
	unopenedHeight: PropTypes.number,
};

NotesPanel.defaultProps = {
	isLocked: false,
	note: '',
	tooltipDetail: '',
	unopenedHeight: 32,
	expandableHeight: false,
};

export { NotesPanel };
