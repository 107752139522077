import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { BPM_RANGES } from '../constants/bpm-ranges';

class BPMFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedBPMs: [],
		};

		this.toggleBPMSelection = this.toggleBPMSelection.bind(this);
	}

	toggleBPMSelection(selection) {
		const { selectedBPMs } = this.state;
		const { onChangeBPMs } = this.props;
		const idx = selectedBPMs.indexOf(selection);

		if (idx > -1) {
			selectedBPMs.splice(idx, 1);
		} else {
			selectedBPMs.push(selection);
		}
		onChangeBPMs(selectedBPMs);
		this.forceUpdate();
	}

	render() {
		const { selectedBPMs } = this.state;

		return (
			<div>
				<label className='mr-3 playlist__sub-text'>Filter By BPM: </label>
				{BPM_RANGES.map(bpm => (
					<span
						className={classNames('new-playlist__modal__item', {
							'new-playlist__modal__item--selected': selectedBPMs.includes(bpm),
						})}
						onClick={() => this.toggleBPMSelection(bpm)}
						key={bpm}
					>
						{bpm}
					</span>
				))}
			</div>
		);
	}
}

BPMFilter.propTypes = {
	onChangeBPMs: PropTypes.func.isRequired,
};

export default BPMFilter;
