module.exports = {
	5: {
		// VOD only
		ADJUSTED_DURATION_MIN: 5,
		ADJUSTED_DURATION_MAX: 6,
	},
	10: {
		// VOD only
		ADJUSTED_DURATION_MIN: 10,
		ADJUSTED_DURATION_MAX: 11,
	},
	15: {
		// VOD only
		ADJUSTED_DURATION_MIN: 15,
		ADJUSTED_DURATION_MAX: 16,
	},
	20: {
		// VOD/Live
		ADJUSTED_DURATION_MIN: 20,
		ADJUSTED_DURATION_MAX: 22,
		CARDIO_DURATION_STRETCH_MIN: 19,
		CARDIO_DURATION_NO_STRETCH_MIN: 20,
		CARDIO_DURATION_NO_STRETCH_MAX: 22,
	},
	30: {
		// VOD/Live
		ADJUSTED_DURATION_MIN: 30,
		ADJUSTED_DURATION_MAX: 33,
		CARDIO_DURATION_MIN: 27,
	},
	45: {
		// VOD/Live
		ADJUSTED_DURATION_MIN: 45,
		ADJUSTED_DURATION_MAX: 48,
		CARDIO_DURATION_MIN: 41,
	},
	60: {
		// VOD/Live
		ADJUSTED_DURATION_MIN: 60,
		ADJUSTED_DURATION_MAX: 64,
		CARDIO_DURATION_MIN: 55,
	},
	90: {
		// VOD/Live
		ADJUSTED_DURATION_MIN: 90,
		ADJUSTED_DURATION_MAX: 95,
		CARDIO_DURATION_MIN: 84,
	},
};
