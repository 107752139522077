import React, { useState, useEffect, useContext } from 'react';

import * as blacklistApi from '../../api/blacklist';
import AlertModal from '../../components/AlertModal';
import TextInputForm from '../../components/forms/TextInputForm';
import DataTable from '../../components/layout/DataTable';
import Loader from '../../components/Loader';
import { NotesPanel } from '../../components/NotesPanel';
import { PaginationButtons } from '../../components/PaginationButtons';
import { BLACKLIST_TYPE } from '../../constants/blacklist';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as RemoveImg } from '../../images/close.svg';
import { dateToMMMDDYYYYFormatter } from '../../utils/date-time';

const PAGE_SIZE = 100;

const ArtistBlacklist = () => {
	const [blacklistArtists, setBlacklistArtists] = useState([]);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [selectedArtist, setSelectedArtist] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [total, setTotal] = useState(0);
	const [pageNum, setPageNum] = useState(1);
	const { createToast, openArtistBlacklistModal } = useContext(GlobalContext);

	const showRemoveArtistCModal = artist => {
		setSelectedArtist(artist);
		setShowRemoveModal(true);
	};

	const renderPlaylistCell = (playlistName = '') => {
		return (
			<div className='blacklist__playlist-metadata  my-3'>
				{playlistName.split(',').map((name, index) => (
					<div className='pr-3' key={index}>
						<span>{name}</span>
					</div>
				))}
			</div>
		);
	};

	const renderRemoveButton = blacklistArtist => (
		<div className='blacklist__btn-remove' alt='remove' onClick={() => showRemoveArtistCModal(blacklistArtist)}>
			<RemoveImg />
		</div>
	);

	const handleNoteUpdate = async (blacklistArtist, newNote) => {
		await blacklistApi.updateBlacklistRecord(BLACKLIST_TYPE.ARTIST, blacklistArtist, newNote);
		// eslint-disable-next-line no-use-before-define
		await fetchBlacklistedArtist();
	};

	const formatRows = data => {
		return data.map(blacklistArtist => [
			blacklistArtist.value, // Artist
			(blacklistArtist.track_source || 'All').split(',').map((source, index) => (
				<div key={index}>
					<span>{source}</span>
				</div>
			)), // Track Source
			blacklistArtist.affected_songs, // Total Songs
			renderPlaylistCell(blacklistArtist.affected_playlists || ''), // Affected Playlists
			dateToMMMDDYYYYFormatter(blacklistArtist.created_timestamp), // Blacklisted Date
			blacklistArtist.blacklisted_by, // Blacklisted By
			<NotesPanel
				note={blacklistArtist.notes}
				onNoteUpdate={newNote => handleNoteUpdate(blacklistArtist.value, newNote)}
				tooltipHeader='Artist Notes'
				tooltipDetail={blacklistArtist.value}
			/>, // Notes
			renderRemoveButton(blacklistArtist.value),
		]);
	};

	const fetchBlacklistedArtist = async (page = null, preventLoading = false) => {
		if (!preventLoading) {
			setLoaded(false);
		}

		await blacklistApi
			.getBlacklistedArtists(PAGE_SIZE, page || pageNum)
			.then(res => {
				setBlacklistArtists(formatRows(res.data || []));
				setTotal(res.count || 0);
				setLoaded(true);
				if (!!page && page !== pageNum) {
					setPageNum(page || pageNum);
				}
			})
			.catch(() => {
				createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
			});
	};

	useEffect(() => {
		fetchBlacklistedArtist();
	}, [pageNum]);

	const handleRemoveArtist = async () => {
		await blacklistApi
			.removeBlacklistedArtist(selectedArtist)
			.then(async numDeleted => {
				if (numDeleted <= 0) {
					throw new Error("Couldn't remove artist from blacklist");
				}
				createToast(`Successfully removed ${selectedArtist} from Artist filter!`, TOAST_LEVEL.SUCCESS, true);
				await fetchBlacklistedArtist(1);
			})
			.catch(() => {
				createToast(
					'There was an error while updating the Artist filter. Please try again later',
					TOAST_LEVEL.ERROR,
					true
				);
			})
			.finally(() => {
				setShowRemoveModal(false);
			});
	};

	const handleAddArtistToBlacklist = async res => {
		// add in memory to reduce delay when fetching new page
		if (res && res[0]) {
			const cacheBlacklistArtist = blacklistArtists.filter(a => a[0] !== res[0].value);

			cacheBlacklistArtist.unshift(formatRows(res)[0]);
			setBlacklistArtists(cacheBlacklistArtist.slice(0, PAGE_SIZE));
		}

		fetchBlacklistedArtist(1, true);
		setSelectedArtist(null);
	};

	const handleArtistSubmit = async artist => {
		setSelectedArtist(artist);
		openArtistBlacklistModal(artist, handleAddArtistToBlacklist);
	};

	// table information
	const tableHeaders = [
		'Artist',
		'Track Source',
		'Total Songs',
		'Affected Playlists',
		'Blacklisted Date',
		'Blacklisted By',
		'Notes',
		'',
	];

	const tableWidths = ['25%', '12%', '8%', '15%', '10%', '13%', '15%', '2%'];

	return (
		<Loader loaded={loaded} className='licesing-dashboard__loader'>
			<AlertModal
				title='Remove Artist From Blacklist'
				showModal={showRemoveModal}
				description={`Are you sure you want to remove the artist ${selectedArtist} from the blacklist?`}
				onConfirmClick={handleRemoveArtist}
				onCancelClick={() => setShowRemoveModal(false)}
			/>
			<div className='mb-3 d-flex flex-column'>
				<div className='d-flex justify-content-between mb-3'>
					<h4 className='h4 mb-1'>Artists</h4>
					<TextInputForm
						label='Add Artist To Blacklist'
						onSubmit={artist => handleArtistSubmit(artist)}
						submitText='Submit'
						inputValidationRegexp={/(?!^$|\s+)/}
						inputValidationFailMessage='The provided artist is not formatted correctly. Please enter a valid artist.'
						useValidationErrorPopup={false}
					/>
				</div>
				<div>
					<DataTable
						data={blacklistArtists}
						headerRow={tableHeaders}
						widths={tableWidths}
						className='blacklist__table blacklist__table__artists'
						fixedLayout
					/>
				</div>
				{loaded && total > PAGE_SIZE && (
					<div className='d-flex mt-5'>
						<PaginationButtons
							handlePageChange={p => setPageNum(p)}
							numPages={Math.ceil(total / PAGE_SIZE)}
							pageNum={pageNum}
						/>
					</div>
				)}
			</div>
		</Loader>
	);
};

export default ArtistBlacklist;
