import * as qs from 'query-string';

import { apiUrl, getDefaultGetInit, parseStandardResponse } from '../utils/api';

const SUGGESTIONS_PATH = 'suggestions';

export async function getSuggestions({ term, limit }) {
	const url = `${apiUrl}/${SUGGESTIONS_PATH}?${qs.stringify({ term, limit })}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}
