import React, { useContext, useState, useEffect } from 'react';

import classNames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';

import * as playlistsApi from '../../api/playlists';
import { BRANDS } from '../../constants/brands';
import { DROP_ZONES } from '../../constants/drop-zones';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { isInstructor, getBrands } from '../../utils/auth';

import CollectionCard from './CollectionCard';

const PlaylistRail = () => {
	const {
		getSearchPlaylists,
		searchPlaylists,
		createToast,
		showPlayer,
		playlistInSearchContext,
		setShowNewPlaylistModal,
	} = useContext(GlobalContext);
	const [playlists, setPlaylists] = useState([]);

	const loadContextCollections = async (reset = false) => {
		try {
			setPlaylists(await getSearchPlaylists(reset));
		} catch (_) {
			createToast(
				'There was an error getting the information, please refresh the page and try again later',
				TOAST_LEVEL.ERROR,
				true
			);
		}
	};

	useEffect(() => {
		loadContextCollections(true);
	}, []);

	useEffect(() => {
		loadContextCollections();
	}, [searchPlaylists, playlistInSearchContext]);

	const handleNewPlaylist = async () => {
		// New SoulCycle or Equinox show modal to specify Playlist Type
		const userBrands = getBrands();
		if (userBrands.includes(BRANDS.SOULCYCLE) || userBrands.includes(BRANDS.EQUINOX)) {
			setShowNewPlaylistModal(true, undefined, false);
			return;
		}

		// all other brands create new playlist
		try {
			await playlistsApi.createPlaylist();
			getSearchPlaylists(true);
		} catch (err) {
			createToast(
				'There was an error creating new playlist, please refresh the page and try again later',
				TOAST_LEVEL.ERROR,
				true
			);
		}
	};

	return (
		<div
			className={classNames('playlist-rail', {
				'playlist-rail--playing': showPlayer,
			})}
		>
			<div className='playlist-rail__header'>Preview Playlist</div>
			<div>
				{playlists.length} {isInstructor() ? 'Created Playlists' : 'Submitted / Rejected Playlists'}
			</div>
			<div className='playlist-rail__playlists'>
				{playlists.map(playlist => (
					<Droppable
						droppableId={`${DROP_ZONES.PLAYLIST_RAIL_PLAYLIST}_${playlist.playlist_id}`}
						key={`drop-${playlist.playlist_id}`}
					>
						{(provided, snapshot) => (
							<div
								className={classNames('playlist__drop-zone', {
									'playlist__drop-zone--dragging-over': snapshot.isDraggingOver,
								})}
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								<CollectionCard
									title={playlist.name}
									subtitle={`${playlist.num_songs || 0} Songs`}
									status={playlist.playlist_status_name}
									inPlaylistRail
									image={playlist.cover_thumbnail_images || playlist.cover_images}
									redirectTo={`/playlist/${playlist.playlist_id}`}
									key={playlist.playlist_id}
								/>
								{snapshot.isDraggingOver && <div className='playlist__drop-zone__placeholder'>Add to playlists</div>}
							</div>
						)}
					</Droppable>
				))}
			</div>
			{isInstructor() && (
				<div className='playlist-rail__new-playlist' onClick={handleNewPlaylist}>
					+ Create New Playlist
				</div>
			)}
		</div>
	);
};

export default PlaylistRail;
