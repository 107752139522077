import { EMAIL_RECEIVERS_TYPES } from '@content-playlist-creation/common/constants/email';

import {
	apiUrl,
	getDefaultDeleteInit,
	getDefaultGetInit,
	getDefaultPostInit,
	parseStandardResponse,
} from '../utils/api';

const SETTINGS_PATH = 'settings';

export async function getPlaylistNotificationReceivers() {
	const url = `${apiUrl}/${SETTINGS_PATH}/emails/${EMAIL_RECEIVERS_TYPES.PLAYLIST_NOTIFICATION_RECEIVERS}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function addPlaylistNotificationReceiver(newEmail) {
	const url = `${apiUrl}/${SETTINGS_PATH}/emails/${EMAIL_RECEIVERS_TYPES.PLAYLIST_NOTIFICATION_RECEIVERS}`;
	const init = getDefaultPostInit({
		newEmail,
	});

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function deletePlaylistNotificationReceiver(deleteEmail) {
	const url = `${apiUrl}/${SETTINGS_PATH}/emails/${EMAIL_RECEIVERS_TYPES.PLAYLIST_NOTIFICATION_RECEIVERS}/${deleteEmail}`;
	const init = getDefaultDeleteInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getAdminMessageReceivers() {
	const url = `${apiUrl}/${SETTINGS_PATH}/emails/${EMAIL_RECEIVERS_TYPES.ADMIN_NOTIFICATION_RECEIVERS}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function addAdminMessageReceiver(newEmail) {
	const url = `${apiUrl}/${SETTINGS_PATH}/emails/${EMAIL_RECEIVERS_TYPES.ADMIN_NOTIFICATION_RECEIVERS}`;
	const init = getDefaultPostInit({
		newEmail,
	});

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function deleteAdminMessageReceiver(deleteEmail) {
	const url = `${apiUrl}/${SETTINGS_PATH}/emails/${EMAIL_RECEIVERS_TYPES.ADMIN_NOTIFICATION_RECEIVERS}/${deleteEmail}`;
	const init = getDefaultDeleteInit();

	return parseStandardResponse(fetch(url, init));
}
