import React, { useContext, useEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { withRouter } from 'react-router';

import * as playlistsApi from '../../api/playlists';
import * as sharedFoldersApi from '../../api/shared-folders';
import * as songsApi from '../../api/songs';
import { BRANDS } from '../../constants/brands';
import { DROP_ZONES } from '../../constants/drop-zones';
import PLAYER_MODE from '../../constants/player-modes';
import PLAYER_STATUS from '../../constants/player-status';
import { SEARCH_COLLECTIONS } from '../../constants/search';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as AlertButton } from '../../images/alert-black.svg';
import { ReactComponent as BlacklistImg } from '../../images/blacklist-add.svg';
import bookmarkImg from '../../images/bookmark.svg';
import bookmarkedImg from '../../images/bookmarked.svg';
import { ReactComponent as DragHandle } from '../../images/drag-dots.svg';
import { ReactComponent as PauseButton } from '../../images/pause-button-black.svg';
import { ReactComponent as PlayButton } from '../../images/play-button-black.svg';
import { ReactComponent as SearchIcon } from '../../images/search-black.svg';
import { isInstructor, isContentCreator, isAdministrator, getBrands, isViewOnly } from '../../utils/auth';
import { secondsToMSSFormatter } from '../../utils/date-time';
import Badge from '../Badge';
import DropdownButton from '../DropdownButton';
import Loader from '../Loader';
import { PaginationButtons } from '../PaginationButtons';
import ActionMenu from '../search/ActionMenu';
import { Unavailable } from '../Unavailable';

const PAGE_SIZE = 40;
const STANDARD_HEADERS = ['', 'SONG', 'GENRE', 'BPM', 'DURATION', ''];
const STANDARD_HEADER_WIDTHS = ['6%', '35%', '10%', '10%', '10%', '5%'];

const getTableHeader = ({ borderless, customColumns = [], widthOverwrites = [] }) => {
	// Position custom columns after standard columns
	const headers = [...STANDARD_HEADERS];
	headers.splice(5, 0, ...customColumns.map(c => c.columnHeader));

	let headerWidths = [];

	if (widthOverwrites.length === headers.length) {
		headerWidths = [...widthOverwrites];
	} else {
		headerWidths = [...STANDARD_HEADER_WIDTHS];
		headerWidths.splice(5, 0, ...customColumns.map(c => c.fixedWidth || '10%'));
	}

	return (
		headers && (
			<tr className='data-table__header-row'>
				{headers
					.map((text, index) => {
						const customColumn = customColumns.find(c => c.columnHeader === text) || {};
						const headerStyle = {};

						if (headerWidths && headerWidths[index]) {
							headerStyle.width = headerWidths[index];
						}

						return (
							<th
								className={classNames('song-table__table__header', {
									'song-table__table__header--borderless': borderless,
									[customColumn.headerClassName]: !!customColumn.headerClassName,
								})}
								key={index}
								style={headerStyle}
							>
								{text}
							</th>
						);
					})
					.filter(cell => !!cell)}
			</tr>
		)
	);
};

const getBookmarkButton = (song, handleBookmarkSong) => {
	return (
		<button
			className='song-result__actions__item song-result__actions__item--undisabled'
			name='SongTable - Bookmark song result'
			onClick={() => handleBookmarkSong(song)}
			type='button'
		>
			<img src={song.is_favorite ? bookmarkedImg : bookmarkImg} alt='bookmark song result' />
		</button>
	);
};

const getPlayButton = (song, handlePlaySong, currentSongPlaying, playerStatus) => {
	if (song.invalidated_song) {
		return (
			<div className='song-table__table__row__play'>
				<AlertButton
					alt='invalid song'
					className='song-table__table__row__play__icon--visible song-table__table__row__play__icon--red'
				/>
			</div>
		);
	}

	const isSongPlaying =
		currentSongPlaying &&
		currentSongPlaying.song_id.toString() === song.song_id.toString() &&
		playerStatus === PLAYER_STATUS.PLAYING;
	const PlayPauseButton = isSongPlaying ? PauseButton : PlayButton;

	return (
		<div className='song-table__table__row__play'>
			<PlayPauseButton
				alt='pause song'
				className='song-table__table__row__play__icon'
				onClick={() => handlePlaySong(song)}
			/>
		</div>
	);
};

const getTableBody = ({
	songs,
	playlists,
	folders,
	tableKey,
	handleBookmarkSong,
	handleAddSongToPlaylist,
	handleAddSongToFolder,
	handlePlaySong,
	handleBlacklistSongISRC,
	handleBlacklistSongVariisID,
	currentSong,
	playerStatus,
	onSearch,
	borderless,
	customColumns,
}) => {
	return songs.map((row, rowIndex) => {
		const renderSongColumns = provided => (
			<>
				<td>{getPlayButton(row, handlePlaySong, currentSong, playerStatus)}</td>
				<td>
					<div className='song-table__table__row__song-name'>
						{row.name}
						{row.explicit && <span className='song-table__table__row__explicit'>EXPLICIT</span>}
					</div>
					<div className='song-table__table__row__grey'>{row.display_artist}</div>
				</td>
				<td className='song-table__table__row__grey'>{row.genre}</td>
				<td className='song-table__table__row__grey'>{row.bpm ? `${Math.round(row.bpm)}` : '-'}</td>
				<td className='song-table__table__row__grey'>
					{row.duration && secondsToMSSFormatter(Math.round(row.duration))}
				</td>
				{customColumns.map((c, index) => (
					<td key={`custom-column-${index}`} className={`song-table__table__row__grey ${c.cellClassName || ''}`}>
						<div className={`w-100 ${c.cellContentClassName || ''}`}>{row[c.songProperty]}</div>
					</td>
				))}
				<td>
					<div className='d-flex flex-direction-column justify-content-between w-100 mr-2'>
						<div className='song-table__table__row__actions'>
							{isInstructor() && getBookmarkButton(row, handleBookmarkSong)}
							{!row.invalidated_song ? (
								<ActionMenu
									song={row}
									handleAddToPlaylist={(songId, playlistId) => handleAddSongToPlaylist(songId, playlistId)}
									handleAddToFolder={(songId, folderId) => handleAddSongToFolder(songId, folderId)}
									handleAddToFavourites={() => handleBookmarkSong(row)}
									handleAddISRCSongToBlacklist={isrc => handleBlacklistSongISRC && handleBlacklistSongISRC(isrc)}
									handleAddVariisIDSongToBlacklist={variisID =>
										handleBlacklistSongVariisID && handleBlacklistSongVariisID(variisID)
									}
									playlists={playlists}
									folders={folders}
									showSongInfo
								/>
							) : null}
						</div>
						{onSearch && (!isContentCreator() || !isViewOnly()) && (
							<div {...provided.dragHandleProps} className='song-table__table__row__drag-handle'>
								<DragHandle />
							</div>
						)}
					</div>
				</td>
			</>
		);

		return (
			<Draggable
				key={row.key || row.song_id}
				draggableId={`${row.key || row.song_id}_${tableKey}`}
				index={rowIndex}
				isDragDisabled={!onSearch || (isContentCreator() && isViewOnly())}
			>
				{(provided, snapshot) => (
					<>
						<tr
							ref={provided.innerRef}
							{...provided.draggableProps}
							className={classNames('song-table__table__row', {
								'song-table__table__row--dragging': snapshot.isDragging,
								'song-table__table__row--borderless': borderless,
								'song-table__table__row--disabled': row.invalidated_song,
							})}
							key={`tr-${row.key || row.song_id}-${rowIndex}`}
							style={{
								...provided.draggableProps.style,
								...(snapshot.isDropAnimating && {
									transitionDuration: `0.001s`,
								}),
								opacity: !!snapshot && (snapshot.draggingOver || '').includes('--opacity') ? '0.65' : '1',
							}}
						>
							{renderSongColumns(provided)}
						</tr>
						{!!snapshot.isDragging && (
							<tr
								className={classNames('song-table__table__row song-table__table__row--clone', {
									'song-table__table__row--borderless': borderless,
								})}
								style={{
									...(snapshot.isDropAnimating && {
										transitionDuration: `0.001s`,
									}),
								}}
							>
								{renderSongColumns(provided)}
							</tr>
						)}
					</>
				)}
			</Draggable>
		);
	});
};

const SongTable = ({
	location,
	history,
	title,
	pageSize,
	sorters = [],
	playlists,
	folders,
	borderless,
	hideIfNoData,
	fetchPlaylists,
	onBookmarkSong,
	navigateToNewPage,
	total,
	pageNum,
	sortFilter,
	onSorterChange,
	onPageChange,
	handleBlacklistSongISRC,
	handleBlacklistSongVariisID,
	loaded,
	songs,
	collectionKey,
	handleBlacklistArtist,
	tableKey,
	widthOverwrites,
	showUnavailable,
	customColumns = [],
	filterControls = [],
	filters = [],
	resetFilters,
	removeFilter,
	handleSearchTermOnCollection,
}) => {
	const {
		createToast,
		setPlayerMode,
		setCurrentSong,
		setShowPlayer,
		setShowNewFolderModal,
		currentSong,
		playerStatus,
		getSearchPlaylists,
		newSearchDraggedFavoriteSong,
		setShowNewPlaylistModal,
	} = useContext(GlobalContext);
	const onSearch = location && location.pathname.startsWith('/search');

	// track updates pof drag from search results to my songs
	useEffect(() => {
		if (onBookmarkSong) {
			onBookmarkSong(newSearchDraggedFavoriteSong);
		}
	}, [newSearchDraggedFavoriteSong]);

	const handlePlaySong = song => {
		setPlayerMode(PLAYER_MODE.SONG);
		setCurrentSong(song);
		setShowPlayer(true);
	};

	const handleBookmarkSong = async song => {
		if (song.is_favorite) {
			await songsApi.removeSongFromFavorites(song.song_id);
		} else {
			await songsApi.addSongToFavorites(song.song_id);
		}
		// callback to fetch songs to reflect change
		if (onBookmarkSong) {
			onBookmarkSong(song.song_id);
		}
	};

	const handleAddSongToFolder = async (songId, folderId) => {
		if (folderId === -1) {
			setShowNewFolderModal(true, songId);
		} else {
			await sharedFoldersApi
				.addSongToFolder(folderId, songId)
				.then(() => {
					createToast('Successfully added song to folder!', TOAST_LEVEL.SUCCESS, true);
				})
				.catch(() => {
					createToast(
						'There was an error adding song to a folder, please refresh and try later',
						TOAST_LEVEL.ERROR,
						true
					);
				});
		}
	};

	const handleAddSongToPlaylist = async (songId, playlistId) => {
		if (playlistId !== -1) {
			// adding to existing playlist
			try {
				await playlistsApi.addSongToPlaylist(playlistId, songId);
				await getSearchPlaylists(true);
				createToast('Successfully added song to playlist!', TOAST_LEVEL.SUCCESS, true);
			} catch (err) {
				createToast(
					'There was an error adding song to a playlist, please refresh and try later',
					TOAST_LEVEL.ERROR,
					true
				);
			}
			return;
		}

		// creating new SoulCycle playlist: show modal
		if (getBrands().includes(BRANDS.SOULCYCLE)) {
			setShowNewPlaylistModal(true, songId);
			return;
		}

		// creating new non-SoulCycle playlist: simply create
		const playlistsResults = await playlistsApi.createPlaylist().catch(() => {
			createToast(
				'There was an error adding song to a playlist, please refresh and try later',
				TOAST_LEVEL.ERROR,
				true
			);
		});

		if (playlistsResults && playlistsResults.length === 1) {
			await playlistsApi.addSongToPlaylist(playlistsResults[0].playlist_id, songId);
			await getSearchPlaylists(true);
			createToast('Successfully added song to playlist!', TOAST_LEVEL.SUCCESS, true);

			if (fetchPlaylists) {
				await fetchPlaylists();
			}

			if (history && navigateToNewPage) {
				history.push(`/playlist/${playlistsResults[0].playlist_id}`);
			}
		}
	};

	const getSorterInput = sorters => {
		return sorters && sorters.length ? (
			<div className='song-table__filter'>
				<span>Sort By: </span>
				<div className='song-table__filter__dropdown'>
					<DropdownButton
						options={sorters}
						onChange={s => onSorterChange(s.value)}
						placeholder='Sort By:'
						value={sortFilter}
						showArrowIcon
						menuClassName='song-table__filter__dropdown__menu'
						alignCenter
					/>
				</div>
			</div>
		) : null;
	};

	const renderControls = () => {
		return (
			<div className='song-table__controls'>
				<>
					<div
						className={classNames('song-table__controls__row d-flex', {
							'justify-content-end': !filterControls.length,
							'justify-content-between': filterControls.length,
						})}
					>
						{filterControls.length ? (
							<div className='song-table__filter'>
								<span>Filter By: </span>
								{filterControls.map((control, index) => (
									<div key={`${index}`}>{control}</div>
								))}
							</div>
						) : null}
						{filterControls.length ? getSorterInput(sorters) : null}
					</div>

					{filters.length ? (
						<div className='song-table__controls__row'>
							<div className='song-table__filter  song-table__filter--wrappable'>
								<span>Your Selection: </span>
								{filters.map((filter, index) => {
									const props = removeFilter
										? {
												onClick: () => removeFilter(index),
												closeCallback: () => removeFilter(index),
										  }
										: {};
									return (
										<Badge key={`${filter}_${index}`} className='song-table__filter__badge' text={filter} {...props} />
									);
								})}
								{filterControls.length ? (
									<span className='song-table__controls__action' onClick={() => resetFilters()}>
										Clear All
									</span>
								) : null}
							</div>
						</div>
					) : null}

					{handleSearchTermOnCollection ? (
						<div className='song-table__controls__row'>
							<div className='search-bar__container'>
								<div className='search-bar__input'>
									<SearchIcon className='search-bar__input__image' />
									<input
										type='search'
										placeholder='Search for a song'
										onChange={handleSearchTermOnCollection}
										onKeyDown={e => {
											if (e.key === 'Enter' || e.keyCode === 13) {
												handleSearchTermOnCollection(e);
											}
										}}
										aria-label='Search'
									/>
								</div>
							</div>
						</div>
					) : null}

					{showUnavailable ? (
						<div className='song-table__controls__row'>
							<Unavailable songs={songs || []} />
						</div>
					) : null}
				</>
			</div>
		);
	};

	if (hideIfNoData && (!songs || !songs.length)) {
		return null;
	}

	return (
		<div className='song-table'>
			<div className='song-table__header'>
				<div className='song-table__header__title'>
					<h3>
						{title}
						{!hideIfNoData && (
							<div className='song-table__header__title__subtitle'>
								({total}
								{total > 1 || total === 0 ? ' results' : ' result'})
							</div>
						)}
					</h3>
				</div>
				{!borderless && collectionKey === SEARCH_COLLECTIONS.ARTIST && (isContentCreator() || isAdministrator()) && (
					<button
						name='SongTable - Blacklist Artist'
						type='submit'
						className='song-table__header__action-btn primary-btn'
						onClick={handleBlacklistArtist}
					>
						<BlacklistImg />
						Blacklist Artist
					</button>
				)}
				{!showUnavailable && !filterControls.length ? getSorterInput(sorters) : null}
			</div>
			{showUnavailable || filters.length || filterControls.length ? renderControls() : null}
			<div>
				<table className='data-table data-table--fixed'>
					<thead>{getTableHeader({ borderless, customColumns, widthOverwrites })}</thead>
					<Droppable droppableId={DROP_ZONES.SONGS_TABLE} isDropDisabled>
						{provided => (
							<tbody ref={provided.innerRef} {...provided.droppableProps}>
								{!loaded ? (
									<tr>
										<td colSpan={7 + customColumns.length}>
											<Loader loaded={loaded} />
										</td>
									</tr>
								) : (
									getTableBody({
										songs,
										playlists,
										folders,
										tableKey,
										handleBookmarkSong,
										handleAddSongToPlaylist,
										handleAddSongToFolder,
										handlePlaySong,
										handleBlacklistSongISRC,
										handleBlacklistSongVariisID,
										currentSong,
										playerStatus,
										onSearch,
										borderless,
										customColumns,
									})
								)}
							</tbody>
						)}
					</Droppable>
				</table>
			</div>
			{loaded && total > pageSize && (
				<div className='d-flex mt-5'>
					<PaginationButtons
						handlePageChange={p => onPageChange(p)}
						numPages={Math.ceil(total / pageSize || PAGE_SIZE)}
						pageNum={pageNum}
					/>
				</div>
			)}
		</div>
	);
};

SongTable.propTypes = {
	borderless: PropTypes.bool,
	collectionKey: PropTypes.string.isRequired,
	customColumns: PropTypes.arrayOf(PropTypes.object),
	fetchPlaylists: PropTypes.func,
	filterControls: PropTypes.arrayOf(PropTypes.object),
	filters: PropTypes.arrayOf(PropTypes.string),
	folders: PropTypes.arrayOf(PropTypes.object),
	handleBlacklistArtist: PropTypes.func,
	handleBlacklistSongISRC: PropTypes.func,
	handleBlacklistSongVariisID: PropTypes.func,
	hideIfNoData: PropTypes.bool,
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	loaded: PropTypes.bool,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
	navigateToNewPage: PropTypes.bool,
	onBookmarkSong: PropTypes.func,
	onSorterChange: PropTypes.func,
	handleSearchTermOnCollection: PropTypes.func,
	onPageChange: PropTypes.func,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	playlists: PropTypes.arrayOf(PropTypes.object),
	removeFilter: PropTypes.func,
	resetFilters: PropTypes.func,
	showUnavailable: PropTypes.bool,
	songs: PropTypes.arrayOf(PropTypes.object),
	sortFilter: PropTypes.string,
	sorters: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	),
	tableKey: PropTypes.string.isRequired,
	title: PropTypes.node,
	total: PropTypes.number,
	widthOverwrites: PropTypes.arrayOf(PropTypes.string),
};

SongTable.defaultProps = {
	playlists: [],
	songs: [],
	folders: [],
	sorters: [],
	filterControls: [],
	filters: [],
	title: '',
	pageNum: 1,
	pageSize: PAGE_SIZE,
	total: 0,
	borderless: false,
	hideIfNoData: false,
	fetchPlaylists: null,
	onBookmarkSong: null,
	navigateToNewPage: false,
	sortFilter: null,
	loaded: false,
	onSorterChange: null,
	onPageChange: null,
	handleBlacklistArtist: null,
	handleBlacklistSongISRC: null,
	handleBlacklistSongVariisID: null,
	customColumns: [],
	widthOverwrites: [],
	showUnavailable: false,
	removeFilter: null,
	resetFilters: null,
	handleSearchTermOnCollection: null,
};

export default withRouter(SongTable);
