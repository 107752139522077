import React, { useState, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import * as qs from 'query-string';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { getAllGenres, getAllArtists, getAllDecades, getBPMRanges } from '../../api/browse';
import ArtistNameFilter from '../../components/ArtistNameFilter';
import DropdownButton from '../../components/DropdownButton';
import Loader from '../../components/Loader';
import { PaginationButtons } from '../../components/PaginationButtons';
import ArtistCard from '../../components/search/ArtistCard';
import { COLLECTIONS, COLLECTIONS_LABELS } from '../../constants/browse-catalog';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as ArrowBack } from '../../images/arrow-back.svg';

const DEFAULT_PAGE_SIZE = 40;
const PAGE_SIZES = [40, 100, 500, 1000];

const Browse = ({ history, match, location }) => {
	const { collection: collectionKey } = match.params;
	const page = Number(qs.parse(location.search).p || '1');
	const collectionFilter = qs.parse(location.search).f || '';
	const [paginationEnabled, setPaginationEnabled] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [cards, setCards] = useState([]);
	const [total, setTotal] = useState(1000);
	const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
	const [useCustomPageSize, setUseCustomPageSizes] = useState(false);
	const { createToast } = useContext(GlobalContext);

	const loadContent = async () => {
		setLoaded(false);

		let fetchCards = null;

		switch (collectionKey) {
			case COLLECTIONS.bpms:
				fetchCards = getBPMRanges;
				break;
			case COLLECTIONS.genres:
				fetchCards = getAllGenres;
				break;
			case COLLECTIONS.artists:
				fetchCards = getAllArtists;
				break;
			case COLLECTIONS.decades:
				fetchCards = getAllDecades;
				break;
			default:
				break;
		}

		if (fetchCards) {
			await fetchCards(pageSize, page, collectionFilter)
				.then(res => {
					setCards(res.data);
					setTotal(res.total);
					setPaginationEnabled(res.total > pageSize);
					setUseCustomPageSizes(res.total > DEFAULT_PAGE_SIZE);
					setLoaded(true);
				})
				.catch(() => {
					createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
				});
		} else {
			createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
		}
	};

	useEffect(() => {
		setLoaded(false);
		loadContent();
	}, [page, pageSize, collectionFilter]);

	const renderCards = () => {
		return cards.map(({ key: term, count = 0, display_artist: displayArtist }, i) => {
			const redirectLink = `${collectionKey}/${encodeURIComponent(term.replace('&', '%26'))}`;

			return collectionKey !== COLLECTIONS.artists ? (
				<Link className='browse__card browse__card--text-only' key={i} to={redirectLink}>
					<span>{term.replace(/\//g, ' / ')}</span>
				</Link>
			) : (
				<ArtistCard
					name={displayArtist}
					description={`${count} ${count > 1 || count === 0 ? 'Songs' : 'Song'}`}
					showInitials
					key={i}
					className='browse__card'
					redirectTo={redirectLink}
					handleBlacklistArtist={() => window.location.reload()}
				/>
			);
		});
	};

	const handleBack = () => {
		history.push('/dashboard/');
	};

	const handleFilterPageChange = (nextPage, filter) => {
		history.push(`/browse/${collectionKey}?p=${nextPage}${filter ? `&f=${filter === '#' ? '$' : filter}` : ''}`);
	};

	const handlePageSizeChange = newPageSize => {
		setPageSize(newPageSize);
		handleFilterPageChange(1, collectionFilter);
	};

	return (
		<div className='browse'>
			<div className='browse__back mb-3' onClick={handleBack}>
				<ArrowBack />
				Back
			</div>
			<h3 className='browse__header mb-4'>
				{COLLECTIONS_LABELS[collectionKey]}
				{loaded && <span className='browse__header__subtitle'>({total})</span>}
			</h3>
			{(!!useCustomPageSize || collectionKey === COLLECTIONS.artists) && (
				<div className='browse__actions'>
					<div className='browse__actions__collection-filter'>
						{collectionKey === COLLECTIONS.artists && (
							<ArtistNameFilter value={collectionFilter} onCharSelect={char => handleFilterPageChange(1, char)} />
						)}
					</div>
					{!!useCustomPageSize && (
						<div className='browse__actions__pagination'>
							<span>Page size: </span>
							<div className='browse__actions__pagination__dropdown'>
								<DropdownButton
									options={PAGE_SIZES.map(ps => ({ value: ps.toString(), label: ps }))}
									onChange={ps => handlePageSizeChange(Number(ps.value))}
									placeholder='Page Size'
									value={pageSize.toString()}
									showArrowIcon
									menuClassName='browse__actions__pagination__dropdown__menu'
									alignCenter
								/>
							</div>
						</div>
					)}
				</div>
			)}
			<Loader loaded={loaded} className='browse__loader'>
				<div className='browse__cards'>{renderCards()}</div>
				{paginationEnabled && (
					<div className='browse__pagination mt-4'>
						<PaginationButtons
							handlePageChange={nextPage => handleFilterPageChange(nextPage, collectionFilter)}
							numPages={Math.ceil(total / pageSize)}
							pageNum={page || 0}
						/>
					</div>
				)}
			</Loader>
		</div>
	);
};

Browse.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
	match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(Browse);
