import React, { useState } from 'react';

import PropTypes from 'prop-types';

import checkCircleImg from '../images/check-circle.svg';
import contentCopyImg from '../images/content-copy.svg';

import Tooltip from './Tooltip';

export default function ContentCopy({ text, tooltipText, iconAltText, children }) {
	const defaultProps = { tooltipText, iconAltText, iconSrc: contentCopyImg };
	const [newProps, setNewProps] = useState(defaultProps);

	return (
		<div className='d-flex align-items-center content-copy-container'>
			{children}

			<Tooltip text={newProps.tooltipText}>
				<button
					name='ContentCopy - Copied'
					type='button'
					className='btn btn-link content-copy-btn'
					onClick={() => {
						navigator.clipboard.writeText(text).then(() => {
							setNewProps({
								tooltipText: 'Copied!',
								iconAltText: 'Success icon',
								iconSrc: checkCircleImg,
							});
							setTimeout(() => {
								setNewProps(defaultProps);
							}, 1500);
						});
					}}
				>
					<img src={newProps.iconSrc} alt={newProps.iconAltText} />
				</button>
			</Tooltip>
		</div>
	);
}

ContentCopy.propTypes = {
	text: PropTypes.string,
	tooltipText: PropTypes.string.isRequired,
	iconAltText: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

ContentCopy.defaultProps = {
	text: '',
};
