import React, { useState } from 'react';

import PropTypes from 'prop-types';

const EditPropertyModal = ({ propertyName, propertyValue, description, onCancelClick, onSubmitClick }) => {
	const [newPropertyValue, setNewPropertyValue] = useState(propertyValue);

	return (
		<div className='edit-playlist-modal modal d-block' role='dialog'>
			<div className='edit-playlist-modal__dialog'>
				<div className='edit-playlist-modal__content modal-content'>
					<p className='edit-playlist-modal__title'>
						Edit
						{propertyName}
					</p>
					<label className='edit-playlist-modal__label'>{description}</label>
					<input
						type='text'
						required
						className='edit-playlist-modal__input px-2'
						value={newPropertyValue}
						onChange={e => setNewPropertyValue(e.target.value)}
					/>
					<div className='d-flex justify-content-end'>
						<button
							name='EditPropertyModal - Cancel'
							className='edit-playlist-modal__submit-btn secondary-btn'
							onClick={onCancelClick}
							type='button'
						>
							Cancel
						</button>
						<button
							name='EditPropertyModal - Submit'
							className='edit-playlist-modal__cancel-btn primary-btn'
							onClick={() => onSubmitClick(newPropertyValue)}
							disabled={(newPropertyValue || '').length === 0 || !newPropertyValue.trim()}
							type='submit'
						>
							Submit
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

EditPropertyModal.propTypes = {
	description: PropTypes.string.isRequired,
	onCancelClick: PropTypes.func.isRequired,
	onSubmitClick: PropTypes.func.isRequired,
	propertyName: PropTypes.string.isRequired,
	propertyValue: PropTypes.string.isRequired,
};

export default EditPropertyModal;
