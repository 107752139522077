import React from 'react';

import PropTypes from 'prop-types';

import airtableImg from '../images/airtable.svg';
import alertImg from '../images/alert.svg';

const StatusBadge = ({ status, flagged, className = '', inAirtable = false }) => (
	<div className={`status-badge ${className}`}>
		<div className='status-badge__text'>
			{status}
			{flagged && <img src={alertImg} alt='alert' className='status-badge__alert' />}
			{inAirtable && (
				<img
					src={airtableImg}
					alt='airtable'
					className='status-badge__alert status-badge__airtable'
					title='Synced with Music Drop Base'
				/>
			)}
		</div>
	</div>
);

StatusBadge.propTypes = {
	className: PropTypes.string,
	flagged: PropTypes.bool,
	inAirtable: PropTypes.bool,
	status: PropTypes.string.isRequired,
};

StatusBadge.defaultProps = {
	className: '',
	flagged: false,
	inAirtable: false,
};

export default StatusBadge;
