import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as authApi from '../../api/auth';
import * as brandsApi from '../../api/brands';
import * as teamApi from '../../api/team';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import { MODES } from '../../constants/user-modal-modes';
import GlobalContext from '../../contexts/GlobalContext';
import { getInitials, getUsername, setUser } from '../../utils/auth';
import UserModal from '../UserModal';

const UserActions = ({ history }) => {
	// State
	const [showUserActions, setShowUserActions] = useState(false);
	const [showUserModal, setShowUserModal] = useState(false);
	const [brands, setBrands] = useState([]);
	const [currentUser, setCurrentUser] = useState({});
	const [mode] = useState(MODES.EDIT);
	const { createToast, getSearchPlaylists, getSearchFolders } = useContext(GlobalContext);

	const userInitials = getInitials();

	const fetchUser = async (user = null) => {
		const userResponse = await teamApi.getUser(getUsername());

		if (user) {
			setUser({
				email: user.email,
				first_name: user.firstName,
				last_name: user.lastName,
				middle_name: user.middleName,
				roles: user.roles,
				permissions: user.permissions,
				username: user.username,
				brand_ids: user.brandIds,
				shared_folders_only: userResponse.shared_folders_only,
			});

			const currentRoles = currentUser.roles.map(r => r.value);
			const newRoles = userResponse.roles.map(r => r.value);

			if (newRoles.some(r => currentRoles.includes(r))) {
				await getSearchPlaylists(true);
				await getSearchFolders(true);
			}
		}

		setCurrentUser(userResponse);
	};

	const fetchBrands = async () => {
		const brandsResponse = await brandsApi.getBrands();

		setBrands(brandsResponse);
	};

	// componentDidMount
	useEffect(() => {
		fetchUser();
		fetchBrands();
	}, []);

	const handleLogout = () => {
		authApi.logout();
		history.push('/login');
	};

	const handleModalSubmit = async user => {
		setShowUserModal(false);
		setShowUserActions(false);

		authApi
			.editUser(user)
			.then(() => {
				createToast('Successfully edited user!', TOAST_LEVEL.SUCCESS, true);
				fetchUser(user);
			})
			.catch(err => {
				createToast(`There was an error while updating your profile. ${err.message || ''}`, TOAST_LEVEL.ERROR, true);
			});
	};

	return (
		<div>
			{showUserModal && (
				<UserModal
					mode={mode}
					user={currentUser}
					brands={brands}
					onCancel={() => setShowUserModal(false)}
					onSubmit={user => handleModalSubmit(user)}
				/>
			)}
			<div className='global-header__user-actions' onClick={() => setShowUserActions(!showUserActions)}>
				{userInitials}
			</div>
			<div className={`global-header__panel shadow ${showUserActions ? 'notifications__panel--visible' : ''}`}>
				<ul className='global-header__user-action-item-list'>
					<li
						className='global-header__user-action-item'
						onClick={() => {
							setShowUserModal(true);
							setShowUserActions(false);
						}}
					>
						Edit
					</li>
					<li className='global-header__user-action-item' onClick={() => handleLogout()}>
						Logout
					</li>
				</ul>
			</div>
			<div
				className={`global-header__overlay ${showUserActions ? 'd-block' : 'd-none'}`}
				onClick={() => setShowUserActions(!showUserActions)}
			/>
		</div>
	);
};

UserActions.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(UserActions);
