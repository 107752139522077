import React from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Switch from 'react-switch';

import * as PERMISSIONS from '../../constants/permissions';
import { hasPermission } from '../../utils/auth';

import FolderCard from './FolderCard';

const FolderCards = ({ folders, limit, header, history, filter, toggleArchivedFolder, includeArchived }) => {
	const canCreateFolder = hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR]);

	if (limit) {
		// eslint-disable-next-line no-param-reassign
		folders = folders.slice(0, limit);
	}
	return (
		<div className='w-100'>
			{header && header !== '' ? (
				<div className='folders__header'>
					<span>{header}</span>
					<span className='folders__header__see-all' onClick={() => history.push('/folders')}>
						See All
					</span>
				</div>
			) : null}
			{filter ? (
				<div className='mt-4 d-flex justify-content-end'>
					<label className='mr-4 d-flex'>
						<span className='playlist__switch__label'>Show Archived Folders</span>
						<Switch
							onChange={toggleArchivedFolder}
							checked={includeArchived}
							checkedIcon={false}
							uncheckedIcon={false}
							height={20}
							width={40}
							className='mt-1'
							onColor='#000'
							offColor='#d2d2d2'
						/>
					</label>
				</div>
			) : null}
			<div className='folders__list'>
				{!folders.length && !header ? (
					<div className='folder__empty w-100 h-100 d-flex justify-content-center'>
						<div className='folder__empty__container'>
							<div className='folder__empty__header'>There are no folders yet</div>
							<div className='folder__empty__sub'>
								{canCreateFolder
									? 'Begin by creating a new folder and adding songs to it!'
									: 'Catalogs are being prepared for you, please stand by!'}
							</div>
						</div>
					</div>
				) : null}

				{folders.map(f => (
					<FolderCard
						title={f.name}
						folderID={f.shared_folder_id}
						coverImages={f.cover_thumbnail_images || f.cover_images}
						key={f.shared_folder_id}
						numSongs={f.num_songs}
						isDeleted={!!f.deleted_timestamp}
						totalViewers={f.total_folder_viewers}
						totalCreators={f.total_folder_creator_shares}
					/>
				))}
			</div>
		</div>
	);
};

FolderCards.propTypes = {
	filter: PropTypes.bool,
	folders: PropTypes.arrayOf(PropTypes.object).isRequired,
	header: PropTypes.string,
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	includeArchived: PropTypes.bool,
	limit: PropTypes.number,
	toggleArchivedFolder: PropTypes.func,
};

FolderCards.defaultProps = {
	filter: false,
	header: '',
	limit: null,
	includeArchived: false,
	toggleArchivedFolder: null,
};

export default withRouter(FolderCards);
