import * as qs from 'query-string';

import { apiUrl, getDefaultDeleteInit, getDefaultGetInit, parseStandardResponse } from '../utils/api';

const TEAM_PATH = 'team';

export async function getTeam(limit, page, sortOrder) {
	const url = `${apiUrl}/${TEAM_PATH}?${qs.stringify({
		limit,
		page,
		sortOrder,
	})}`;

	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getUser(username) {
	const url = `${apiUrl}/${TEAM_PATH}/${username}`;

	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function deleteUser(username) {
	const url = `${apiUrl}/${TEAM_PATH}/delete/${username}`;

	const init = getDefaultDeleteInit();

	return parseStandardResponse(fetch(url, init));
}
