import React, { useState } from 'react';

import PropTypes from 'prop-types';

import moreInverted from '../images/more-black.svg';
import moreImg from '../images/more.svg';

const MoreButton = ({ options = [] }) => {
	const [openPanel, setOpenPanel] = useState(false);

	return (
		<div className='more songslist__song__btn songslist__tooltip'>
			<img src={openPanel ? moreInverted : moreImg} alt='more' onClick={() => setOpenPanel(!openPanel)} />
			<div className={`more__panel shadow ${openPanel ? 'more__panel--visible' : ''}`}>
				{options.map(
					(opt, i) =>
						opt && (
							<div
								key={i}
								className='songslist__tooltip__panel__item songslist__tooltip__panel__item__action'
								onClick={() => {
									setOpenPanel(false);
									opt.callback();
								}}
							>
								{opt.label}
							</div>
						)
				)}
			</div>
			<div
				className={`songslist__tooltip__overlay ${openPanel ? 'd-block' : 'd-none'}`}
				onClick={() => setOpenPanel(false)}
			/>
		</div>
	);
};

MoreButton.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MoreButton;
