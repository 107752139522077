import React from 'react';

import { PLAYLIST_STATUS } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as playlistsApi from '../api/playlists';
import * as sharedFoldersAPI from '../api/shared-folders';
import AlertModal from '../components/AlertModal';
import ArtistNameFilter from '../components/ArtistNameFilter';
import BPMFilter from '../components/BPMFilter';
import Loader from '../components/Loader';
import MoreButton from '../components/MoreButton';
import FolderModal from '../components/sharedFolder/FolderModal';
import ShareModal from '../components/sharedFolder/ShareModal';
import SongsList from '../components/song/SongsList';
import * as PERMISSIONS from '../constants/permissions';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import arrowLeftImg from '../images/arrow-left.svg';
import viewersImg from '../images/people.svg';
import addViewerImgBlack from '../images/person-add-black-small.svg';
import { ReactComponent as AddViewerImg } from '../images/person-add-white-small.svg';
import { hasPermission, isInstructor, isSharedFolderUser } from '../utils/auth';

const PAGE_SIZE = 100;

class Folder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			folder: {},
			playlists: [],
			folders: [],
			showErrorModal: false,
			errors: [],
			showArchiveFolderModal: false,
			showRestoreFolderModal: false,
			submitSuccess: false,
			showFolderEditModal: false,
			showFolderShareModal: false,
			pageSize: PAGE_SIZE,
			pageNum: 1,
			sortBy: 'created_timestamp',
			bpms: [],
			artistInitialChar: '',
			folderDataLoaded: false,
			folderLoaded: false,
		};
		this.fetchFolder = this.fetchFolder.bind(this);
		this.fetchPlaylists = this.fetchPlaylists.bind(this);

		this.handleArchiveFolder = this.handleArchiveFolder.bind(this);
		this.handleEditFolder = this.handleEditFolder.bind(this);
		this.handleRestoreFolder = this.handleRestoreFolder.bind(this);
		this.handleShareFolder = this.handleShareFolder.bind(this);
		this.handleArtistFilterChange = this.handleArtistFilterChange.bind(this);
		this.handleBPMFilterChange = this.handleBPMFilterChange.bind(this);
	}

	componentDidMount() {
		this.fetchFolder(this.state);
		this.fetchPlaylists();
		this.fetchFolders();
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props;

		if (prevProps.location.pathname !== location.pathname) {
			this.fetchFolder(this.state);
		}
	}

	async handleArchiveFolder() {
		const { folder } = this.state;
		const { history } = this.props;
		this.setState({ showArchiveFolderModal: false });
		await sharedFoldersAPI.archiveSharedFolder(folder.shared_folder_id);
		history.goBack();
	}

	async handleRestoreFolder() {
		const { folder } = this.state;
		this.setState({ showRestoreFolderModal: false });
		await sharedFoldersAPI.restoreSharedFolder(folder.shared_folder_id);
		window.location.reload();
	}

	async handleEditFolder({ folderID, name, description }) {
		const { createToast } = this.state;
		sharedFoldersAPI
			.editSharedFolder(folderID, name, description)
			.then(async folders => {
				if (folders && folders[0].shared_folder_id === folderID) {
					await this.fetchFolder({ ...this.state });
				}
			})
			.catch(() => {
				createToast('There was a problem editing a folder, please try later', TOAST_LEVEL.ERROR, true);
			})
			.finally(() => {
				this.setState({ showFolderEditModal: false });
			});
	}

	async handleBPMFilterChange(bpms) {
		await this.fetchFolder({ ...this.state, bpms, pageNum: 1 });
	}

	async handleArtistFilterChange(char) {
		await this.fetchFolder({ ...this.state, artistInitialChar: char, pageNum: 1 });
	}

	handleBackToFolders = () => {
		const { history } = this.props;

		history.push('/folders/');
	};

	getFolderOptions = () => {
		const { folder } = this.state;
		const options = [];

		if (hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR])) {
			options.push({
				label: 'Edit',
				callback: () => this.setState({ showFolderEditModal: true }),
			});
			options.push({
				label: 'Share',
				callback: () => this.setState({ showFolderShareModal: true }),
			});
			if (folder.deleted_timestamp) {
				options.push({
					label: 'Restore',
					callback: () => this.setState({ showRestoreFolderModal: true }),
				});
			} else {
				options.push({
					label: 'Archive',
					callback: () => this.setState({ showArchiveFolderModal: true }),
				});
			}
		}

		return options;
	};

	handlePageChange = async (limit, page) => {
		await this.fetchFolder({ ...this.state, pageSize: limit, pageNum: page });
	};

	handleSortByChange = async sortBy => {
		await this.fetchFolder({ ...this.state, sortBy, pageNum: 1 });
	};

	handleShareFolder = async (sharedWithAllViewersBrandIds, viewersIds, sharedWithAllCreatorsBrandIds, creatorIds) => {
		const { folder } = this.state;
		const { createToast } = this.state;

		sharedFoldersAPI
			.shareFolder(
				folder.shared_folder_id,
				sharedWithAllViewersBrandIds,
				viewersIds,
				sharedWithAllCreatorsBrandIds,
				creatorIds
			)
			.then(() => this.fetchFolder({ ...this.state }))
			.catch(() => {
				createToast(
					'There was a problem sharing the folder with more viewers, please try later',
					TOAST_LEVEL.ERROR,
					true
				);
			})
			.finally(() => {
				this.setState({ showFolderShareModal: false });
			});
	};

	getFolderSubText() {
		const { folder } = this.state;
		const songsNumber = folder ? folder.num_songs || 0 : 0;

		return `${songsNumber} Song${songsNumber > 1 || songsNumber === 0 ? 's ' : ' '} In Folder`;
	}

	async fetchFolder({ pageNum, pageSize, bpms, artistInitialChar, sortBy } = {}) {
		const { history, match } = this.props;

		this.setState({
			folderDataLoaded: false,
		});

		const folder = await sharedFoldersAPI.getFolder(
			match.params.fid,
			pageNum,
			pageSize,
			sortBy,
			bpms,
			artistInitialChar
		);

		// redirect to Folders if has no permission over the folder
		if (!folder && history) {
			history.push('/folders');
		} else {
			this.setState({
				folder,
				pageNum,
				pageSize,
				sortBy,
				bpms,
				artistInitialChar,
				folderDataLoaded: true,
				folderLoaded: true,
			});
		}
	}

	async fetchFolders() {
		let folders = [];

		if (isSharedFolderUser() && hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR])) {
			const result = await sharedFoldersAPI.getFolders(null, null, false);

			folders = result.data || [];
		}

		this.setState({ folders });
	}

	async fetchPlaylists() {
		const { playlistInSearchContext } = this.state;
		const playlists = isInstructor()
			? (await playlistsApi.getUserPlaylists({ playlistStatusIds: [PLAYLIST_STATUS.DRAFT], includeSongDetails: true }))
					.data
			: (
					await playlistsApi.getPlaylists({
						status: [PLAYLIST_STATUS.SUBMITTED, PLAYLIST_STATUS.REJECTED],
						limit: 25,
						page: 1,
						sortBy: 'updated-date',
						sortOrder: 'DESC',
						playlistIds: playlistInSearchContext ? [playlistInSearchContext.playlist_id] : [],
						includeSongDetails: true,
						playlistTypeFilter: -1, // all types
					})
			  ).data;

		this.setState({ playlists });
	}

	renderFolderData() {
		const { folder, playlists, folders, pageNum, sortBy } = this.state;
		const canEditFolder = hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR]);

		if (!folder || !folder.num_songs) {
			return (
				<div className='folder__empty w-100 h-100 d-flex justify-content-center'>
					<div className='folder__empty__container'>
						<div className='folder__empty__header'>This folder is currently empty</div>
						{canEditFolder && (
							<div className='folder__empty__sub'>
								Begin adding songs to your folders from your song library or search for something new!
							</div>
						)}
					</div>
				</div>
			);
		}

		const filteredSongsCount = folder?.songs?.length ? folder.songs[0].count || 0 : 0;

		if (!filteredSongsCount) {
			return (
				<div className='folder__empty w-100 h-100 d-flex justify-content-center'>
					<div className='folder__empty__container'>
						<div className='folder__empty__header'>No matches!</div>
						<div className='folder__empty__sub'>Filters did not return results, please adjust your query</div>
					</div>
				</div>
			);
		}

		return (
			<SongsList
				folderId={folder.shared_folder_id}
				songs={folder.songs}
				totalSongs={filteredSongsCount}
				playlists={playlists}
				pageSize={PAGE_SIZE}
				pageNum={pageNum}
				sortBy={sortBy}
				handleSortByChange={sortBy => this.handleSortByChange(sortBy)}
				handleSongListPageChange={(pageSize, pageNum) => this.handlePageChange(pageSize, pageNum)}
				hideUserSongInfo
				showRemoveSongButton
				fetchSongsCollectionCallback={() => this.fetchFolder({ ...this.state })}
				folders={folders}
			/>
		);
	}

	render() {
		const {
			folder,
			showArchiveFolderModal,
			showRestoreFolderModal,
			showFolderShareModal,
			showFolderEditModal,
			folderDataLoaded,
			folderLoaded,
		} = this.state;
		const canEditFolder = hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR]);
		const folderOptions = this.getFolderOptions();

		return (
			<Loader loaded={folderLoaded}>
				<div className='folder__container'>
					<AlertModal
						title='Archive Folder?'
						description='Are you sure you want to archive this folder?'
						showModal={showArchiveFolderModal}
						onConfirmClick={() => this.handleArchiveFolder()}
						onCancelClick={() => this.setState({ showArchiveFolderModal: false })}
					/>
					<AlertModal
						title='Restore Folder?'
						description='Are you sure you want to restore this archived folder back to Drafts?'
						showModal={showRestoreFolderModal}
						onConfirmClick={() => this.handleRestoreFolder()}
						onCancelClick={() => this.setState({ showRestoreFolderModal: false })}
					/>
					{canEditFolder && showFolderEditModal ? (
						<FolderModal
							folder={folder}
							onClose={() => this.setState({ showFolderEditModal: false })}
							onSubmit={this.handleEditFolder}
						/>
					) : null}
					{canEditFolder && showFolderShareModal ? (
						<ShareModal
							folder={folder}
							onClose={() => this.setState({ showFolderShareModal: false })}
							onSubmit={this.handleShareFolder}
						/>
					) : null}
					<span className='folder__back' onClick={this.handleBackToFolders}>
						<img src={arrowLeftImg} alt='back' className='folder__back__btn' />
						Back to Folders
					</span>
					<div className='folder__header justify-content-between'>
						<div className='d-flex align-items-center'>
							<h3 className='h3'>{folder.name}</h3>

							<div className='d-flex align-items-end mb-2'>
								{canEditFolder && folderOptions.length && (
									<div className='folder__actions'>
										<MoreButton options={folderOptions} />
									</div>
								)}
							</div>
						</div>
						<div className='folder__metadata d-flex align-items-center'>
							{canEditFolder && (
								<button
									name='Folder - Share'
									className='folder__share_btn primary-btn'
									onClick={() => this.setState({ showFolderShareModal: true })}
									type='button'
								>
									Share <AddViewerImg />
								</button>
							)}
						</div>
					</div>
					{folder.description && <p className='folder__sub-text'>{folder.description}</p>}
					<div className='folder__sub-text d-flex align-items-center'>
						{this.getFolderSubText()}
						{canEditFolder && (
							<>
								<div className='folder__viewers_count ml-2'>
									-
									<img src={viewersImg} alt='Viewers' />
									{folder.total_folder_viewers} Viewer{folder.total_folder_viewers !== 1 ? 's' : ''}
								</div>
								<div className='folder__viewers_count ml-2'>
									-
									<img src={addViewerImgBlack} alt='Creators' />
									{folder.total_folder_creator_shares} Shared With (Folder Creator)
								</div>
							</>
						)}
					</div>
					<div className='folder__sub-text folder__sub-text--no-margin' />
					<div className='mt-4'>
						<BPMFilter onChangeBPMs={bpms => this.handleBPMFilterChange(bpms)} />
					</div>
					<div className='mt-3'>
						<ArtistNameFilter onCharSelect={char => this.handleArtistFilterChange(char)} />
					</div>
					<Loader loaded={folderDataLoaded}>{this.renderFolderData()}</Loader>
				</div>
			</Loader>
		);
	}
}

Folder.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			fid: PropTypes.string.isRequired,
		}),
	}).isRequired,
};

Folder.contextType = GlobalContext;

export default withRouter(Folder);
