import React from 'react';

import PropTypes from 'prop-types';

const Badge = ({ text, className = '', closeCallback = null, onClick = null }) => (
	<div className={`badge ${className}`} onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default' }}>
		<div className='badge__text'>{text}</div>
		{closeCallback && <div className='badge__close' onClick={closeCallback} />}
	</div>
);

Badge.propTypes = {
	className: PropTypes.string,
	closeCallback: PropTypes.func,
	onClick: PropTypes.func,
	text: PropTypes.string.isRequired,
};

Badge.defaultProps = {
	className: '',
	closeCallback: null,
	onClick: null,
};

export default Badge;
