import React, { useContext, useEffect, useState } from 'react';

import { PLAYLIST_STATUS_MAP } from '@content-playlist-creation/common/constants';
import invert from 'lodash/invert';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as playlistsApi from '../api/playlists';
import DropdownButton from '../components/DropdownButton';
import { PaginationButtons } from '../components/PaginationButtons';
import PlaylistCards from '../components/playlist/PlaylistCards';
import { BRANDS } from '../constants/brands';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import { getBrands } from '../utils/auth';

const DEFAULT_LIMIT = 24;
const DEFAULT_PAGE = 1;

const MyPlaylists = ({ history }) => {
	const [filters, setFilters] = useState([]);
	const [playlists, setPlaylists] = useState([]);
	const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);
	const [pageNum, setPageNum] = useState(DEFAULT_PAGE);
	const [total, setTotal] = useState(0);
	const [showArchivedPlaylists, setShowArchivedPlaylists] = useState(false);

	const { createToast, setShowNewPlaylistModal, getSearchPlaylists, playlistTypes } = useContext(GlobalContext);
	const formattedPlaylistTypes = (playlistTypes || [])
		.map(t => ({
			[t.playlist_type_id]: t.name,
		}))
		.reduce((resObj, currObj) => {
			for (const key in currObj) {
				if (key in currObj) {
					resObj[key] = currObj[key]; // eslint-disable-line no-param-reassign
				}
			}
			return resObj;
		}, {});

	useEffect(() => {
		fetchPlaylists(DEFAULT_LIMIT, DEFAULT_PAGE);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// track filter, sorter, termQuery change
	useEffect(() => {
		if (pageNum === 1) {
			fetchPlaylists(DEFAULT_LIMIT, DEFAULT_PAGE);
		} else {
			setPageNum(1);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const handleNewPlaylist = async () => {
		const userBrands = getBrands();
		if (userBrands.includes(BRANDS.SOULCYCLE) || userBrands.includes(BRANDS.EQUINOX)) {
			setShowNewPlaylistModal(true);
			return;
		}

		try {
			const playlists = await playlistsApi.createPlaylist();
			await getSearchPlaylists(true);

			if (playlists && playlists.length === 1) {
				history.push(`/playlist/${playlists[0].playlist_id}`);
			}
		} catch (err) {
			createToast(
				'There was an error creating new playlist, please refresh the page and try again later.',
				TOAST_LEVEL.ERROR,
				true
			);
		}
	};

	const fetchPlaylists = async (pageSize, pageNum, showArchivedPlaylists = false) => {
		const playlistStatusesFilters = filters
			.filter(f => Object.values(PLAYLIST_STATUS_MAP).includes(f))
			.map(b => invert(PLAYLIST_STATUS_MAP)[b]);
		const playlistTypeFilters = filters
			.filter(f => Object.values(formattedPlaylistTypes).includes(f) || f === 'No Category')
			.map(b => (b !== 'No Category' ? invert(formattedPlaylistTypes)[b] : -1));
		const includeArchivedPlaylists = filters.includes('Archived') || showArchivedPlaylists;

		const playlists = await playlistsApi.getUserPlaylists({
			playlistStatusIds:
				Array.isArray(playlistStatusesFilters) && playlistStatusesFilters.length ? playlistStatusesFilters : null,
			playlistTypeIds:
				Array.isArray(playlistTypeFilters) && playlistTypeFilters.length ? playlistTypeFilters.join(',') : null,
			page: pageNum,
			limit: pageSize,
			includeArchived: includeArchivedPlaylists,
			includeSongDetails: true, // include playlist songs counts
			includeThumbnails: true,
		});

		setPlaylists(playlists.data);
		setPageSize(pageSize);
		setPageNum(pageNum);
		setTotal(playlists.count);
		setShowArchivedPlaylists(includeArchivedPlaylists);
	};

	const numPages = playlists && playlists.length ? Math.ceil(total / pageSize) : 0;

	const statusFilterOptions = Object.values(PLAYLIST_STATUS_MAP)
		.filter(f => !filters.includes(f) && f !== 'Valid')
		.map(f => ({ label: f, value: f }));
	const typeFilterOptions = [...Object.values(formattedPlaylistTypes), 'No Category']
		.filter(f => !filters.includes(f))
		.map(f => ({ label: f, value: f }));

	return (
		<div>
			<h3 className='h3'>My Playlists</h3>
			<button
				name='MyPlayLists - New Playlist'
				className='primary-btn mt-2'
				onClick={() => handleNewPlaylist()}
				type='button'
			>
				New Playlist
			</button>
			<PlaylistCards
				header=''
				playlists={playlists}
				includeArchived={showArchivedPlaylists}
				toggleArchivedFolder={() => {
					fetchPlaylists(pageSize, 1, !showArchivedPlaylists);
				}}
				filters={filters}
				filterControls={[
					<div key='statusFilter' className='song-table__filter__dropdown'>
						<DropdownButton
							options={statusFilterOptions}
							onChange={f => setFilters([...filters, f.value])}
							placeholder='Status'
							value='Status'
							showArrowIcon
							menuClassName='song-table__filter__dropdown__menu'
							alignCenter
							disabled={!statusFilterOptions.length}
						/>
					</div>,
					getBrands().includes(BRANDS.SOULCYCLE) && (
						<div key='typeFilter' className='song-table__filter__dropdown'>
							<DropdownButton
								options={typeFilterOptions}
								onChange={f => setFilters([...filters, f.value])}
								placeholder='Type'
								value='Type'
								showArrowIcon
								menuClassName='song-table__filter__dropdown__menu'
								alignCenter
								disabled={!typeFilterOptions.length}
							/>
						</div>
					),
				]}
				removeFilter={filterIndex => {
					const newFilters = [...filters];
					newFilters.splice(filterIndex, 1);
					setFilters(newFilters);
				}}
				resetFilters={() => {
					setFilters([]);
				}}
			/>
			{numPages > 1 ? (
				<div className='d-flex align-items-center mt-5'>
					<PaginationButtons
						handlePageChange={nextPage => {
							fetchPlaylists(pageSize, nextPage, showArchivedPlaylists);
						}}
						numPages={numPages}
						pageNum={pageNum || 0}
					/>
				</div>
			) : null}
		</div>
	);
};

MyPlaylists.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(MyPlaylists);
