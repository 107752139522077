import { clearUser } from './auth';

export const apiUrl = process.env.REACT_APP_MUSIC_API_URL || '/api';

function getGlobalHeaders() {
	return {
		Accept: 'application/json',
	};
}

function getDefaultInit() {
	const headers = new Headers(getGlobalHeaders());

	return {
		headers,
		credentials: 'include',
	};
}

export function getDefaultGetInit() {
	const init = getDefaultInit();

	init.method = 'GET';
	return init;
}

export function getDefaultDeleteInit() {
	const init = getDefaultInit();

	init.method = 'DELETE';
	return init;
}

export function getDefaultPostInit(payload) {
	const init = getDefaultInit();

	init.method = 'POST';
	if (payload) {
		init.headers.append('Content-Type', 'application/json; charset=utf-8');
		init.body = JSON.stringify(payload);
	}
	return init;
}

export async function parseStandardResponse(request, redirectLoginOnUnauth = true, customErrorHandler = false) {
	const response = await request;
	if (!response.ok) {
		const responseData = await response.json();
		if (response.status === 401 || (responseData.error || '').indexOf('maintenance') > -1) {
			if (redirectLoginOnUnauth) {
				clearUser();
				window.location = '/login';
			} else {
				return null;
			}
		}

		if (customErrorHandler) {
			throw new Error(responseData.message);
		}

		throw new Error(response.statusText);
	}
	return response.json();
}
