import React from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import ArtistBlacklist from './ArtistBlacklist';
import GenreBlacklist from './GenreBlacklist';
import SongByISRCBlacklist from './SongByISRCBlacklist';
import SongByVariisIDBlacklist from './SongByVariisIDBlacklist';

const Blacklist = ({ match }) => {
	const subPageName = match.params.collection || 'genre';
	const subPages = {
		genre: {
			name: 'Genre',
			element: <GenreBlacklist />,
		},
		isrc: {
			name: 'ISRC',
			element: <SongByISRCBlacklist />,
		},
		variisID: {
			name: 'Variis ID',
			element: <SongByVariisIDBlacklist />,
		},
		artist: {
			name: 'Artist',
			element: <ArtistBlacklist />,
		},
	};
	const subPagesTabs = Object.keys(subPages).map(sp => (
		<NavLink
			key={sp}
			className={`licesing-dashboard__tab ${subPageName === sp ? 'active' : ''}`}
			activeClassName='active'
			to={`/blacklist/${sp}`}
			exact
		>
			{subPages[sp].name}
		</NavLink>
	));
	const subPage = subPages[subPageName] && subPages[subPageName].element;

	return (
		<div className='blacklist'>
			<h3 className='h3'>Blacklist</h3>
			<br />
			<nav className='navigation-tabs mt-4'>{subPagesTabs}</nav>
			<div className='px-3'>{subPage}</div>
		</div>
	);
};

Blacklist.propTypes = {
	match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(Blacklist);
