import { apiUrl, getDefaultGetInit, getDefaultPostInit, parseStandardResponse } from '../utils/api';

const HEALTH_PATH = 'health';

export async function getScheduledJobsHealth() {
	const url = `${apiUrl}/${HEALTH_PATH}/schedule-jobs`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getSanityChecksStatuses() {
	const url = `${apiUrl}/${HEALTH_PATH}/sanity-checks`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function resetJobStatus(jobKey) {
	const url = `${apiUrl}/${HEALTH_PATH}/reset-status`;
	const init = getDefaultPostInit({
		jobKey,
	});

	return parseStandardResponse(fetch(url, init));
}

export async function runJob(jobKey) {
	const url = `${apiUrl}/${HEALTH_PATH}/run-job`;
	const init = getDefaultPostInit({
		jobKey,
	});

	return parseStandardResponse(fetch(url, init));
}
