import React, { useState } from 'react';

import stringsHelpers from '@content-playlist-creation/common/utils/strings-helper';
import PropTypes from 'prop-types';

const generaterHeaderColumns = (headerRow, widths, tableId) =>
	headerRow.map((text, index) => {
		const headerStyle = {};
		const key = `${tableId}-h-${index}`;
		if (widths && widths[index]) {
			headerStyle.width = widths[index];
		}
		return (
			<th className='data-table__header-cell' key={key} style={headerStyle}>
				{text}
			</th>
		);
	});

const generaterFooterColumns = (footerRow, widths, tableId) =>
	footerRow.map((text, index) => {
		const footerStyle = {};
		const key = `${tableId}-f-${index}`;

		if (widths && widths[index]) {
			footerStyle.width = widths[index];
		}
		if (!text) {
			return (
				<td className='data-table__footer-cell empty' key={key} style={footerStyle}>
					{text}
				</td>
			);
		}
		return (
			<td className='data-table__footer-cell' key={key} style={footerStyle}>
				{text}
			</td>
		);
	});

const generaterDataRows = (data, tableId) =>
	(data || []).map((row, rowIndex) => (
		<tr className='data-table__row' key={`${tableId}-d${rowIndex}`}>
			{row.map((value, index) => (
				<td className='data-table__cell' key={`${tableId}-d${rowIndex}-c${index}`}>
					{value}
				</td>
			))}
		</tr>
	));

/**
 * Represents a very minimal table designed to only display data
 * @returns {ReactElement} The rendered result
 */
const DataTable = ({
	data,
	headerRow = null,
	footerRow = null,
	widths = null,
	className = '',
	style = {},
	fixedLayout = true,
	tableId = '',
}) => {
	const tableUUID = useState([stringsHelpers.generateRandomKey(tableId)]);

	return (
		<table
			className={['data-table', `${fixedLayout ? 'data-table--fixed' : ''}`, className || '']
				.filter(c => !!c)
				.join(' ')}
			style={style}
		>
			{headerRow && headerRow.length ? (
				<thead>
					<tr className='data-table__header-row'>{generaterHeaderColumns(headerRow, widths, tableUUID)}</tr>
				</thead>
			) : null}
			<tbody>{generaterDataRows(data, tableUUID)}</tbody>
			{footerRow && footerRow.length ? (
				<tfoot>
					{className && className.indexOf('data-table--footer-summary') > -1 ? (
						<tr className='data-table__footer-summary-row'>
							<td colSpan={footerRow.length} />
						</tr>
					) : null}
					<tr className='data-table__footer-row'>{generaterFooterColumns(footerRow, widths, tableUUID)}</tr>
				</tfoot>
			) : null}
		</table>
	);
};

DataTable.propTypes = {
	className: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.any).isRequired,
	fixedLayout: PropTypes.bool,
	footerRow: PropTypes.arrayOf(PropTypes.object),
	headerRow: PropTypes.arrayOf(PropTypes.any),
	style: PropTypes.oneOfType([PropTypes.object]),
	tableId: PropTypes.string,
	widths: PropTypes.arrayOf(PropTypes.string),
};

DataTable.defaultProps = {
	headerRow: null,
	footerRow: null,
	widths: null,
	className: '',
	style: {},
	fixedLayout: true,
	tableId: null,
};
export default DataTable;
