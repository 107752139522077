import React, { useContext } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as BlacklistImg } from '../../images/blacklist-add.svg';
import { isAdministrator, isContentCreator } from '../../utils/auth';
import { getArtistInitials, formatArtistName } from '../../utils/songs';

const ArtistCard = ({ className, name = '', description, showInitials, redirectTo, handleBlacklistArtist }) => {
	const ConditionalLink = redirectTo ? Link : React.Fragment;
	const linkProps = redirectTo ? { to: redirectTo } : {};
	const { openArtistBlacklistModal } = useContext(GlobalContext);

	return (
		<div
			className={classNames('artist__card', {
				'artist__card--clickable': redirectTo,
				[className]: !!className,
			})}
		>
			<ConditionalLink {...linkProps}>
				<div className='artist__card__image'>
					{showInitials && <div className='artist__card__image__initials'>{getArtistInitials(name)}</div>}
				</div>
				<div className='artist__card__metadata'>
					<div>{formatArtistName(name)}</div>
					<div className='artist__card__metadata--description'>{description}</div>
				</div>
				{(isContentCreator() || isAdministrator()) && (
					<div
						className='artist__card__blacklist'
						onClick={event => {
							event.preventDefault();
							event.stopPropagation();
							openArtistBlacklistModal(name, handleBlacklistArtist);
						}}
					>
						<BlacklistImg />
					</div>
				)}
			</ConditionalLink>
		</div>
	);
};

ArtistCard.propTypes = {
	className: PropTypes.string,
	description: PropTypes.string,
	handleBlacklistArtist: PropTypes.func,
	name: PropTypes.string,
	redirectTo: PropTypes.string,
	showInitials: PropTypes.bool,
};

ArtistCard.defaultProps = {
	className: null,
	description: '',
	name: '',
	redirectTo: '',
	showInitials: false,
	handleBlacklistArtist: null,
};

export default ArtistCard;
