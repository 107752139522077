import React from 'react';

import PropTypes from 'prop-types';

const Loader = ({ loaded, title, children, className }) => {
	if (loaded) {
		return <>{children}</>;
	}

	return (
		<div className={`loader__wrapper ${className}`}>
			{title && <h3>{title}</h3>}
			<div className='loader'>
				<div className='loader__bar' />
				<div className='loader__bar' />
				<div className='loader__bar' />
				<div className='loader__bar' />
				<div className='loader__bar' />
			</div>
		</div>
	);
};

Loader.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string,
	loaded: PropTypes.bool.isRequired,
	title: PropTypes.string,
};

Loader.defaultProps = {
	children: null,
	className: '',
	title: '',
};

export default React.memo(Loader);
