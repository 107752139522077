import React, { useState, useEffect, useContext } from 'react';

import { PLAYLIST_STATUS } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';

import * as blacklistApi from '../../api/blacklist';
import { getMusicFeeds } from '../../api/metadata-feeds';
import { BLACKLIST_TYPE } from '../../constants/blacklist';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import AlertModal from '../AlertModal';

const getBlacklistSongPlaylistUsages = playlistUsage =>
	playlistUsage.map((playlist, index) => {
		const url = `${playlist.is_internal ? '/playlist/' : '/show-playlist/'}${playlist.playlist_id}`;
		const canClick = playlist.playlist_status_id !== PLAYLIST_STATUS.DRAFT; // CCs and Admins cannot visit DRAFT playlists, server does not return ARCHIVED
		const navigateToPlaylist = () => {
			if (canClick) {
				window.location = url;
			}
		};

		return (
			<li key={index}>
				<span
					onClick={navigateToPlaylist}
					className={`${canClick ? 'alert-modal__html-reasons--link' : ''} float-left`}
				>
					{playlist['Playlist Name']}
				</span>
				<span className='float-right'>
					{playlist.is_internal ? '[Playlist] ' : '[Show Playlist] '}({playlist.status})
				</span>
			</li>
		);
	});

const ArtistBlacklistModal = ({ onConfirmClick, onCancelClick, showModal }) => {
	const [playlistUsage, setPlaylistUsages] = useState([]);
	const [musicFeeds, setMusicFeeds] = useState([]);
	const [selectedMusicFeeds, setSelectedMusicFeeds] = useState([]);
	const { createToast, artistToBlacklist: artist } = useContext(GlobalContext);

	const fetchLabelMusicFeeds = async () => setMusicFeeds(await getMusicFeeds());
	const fetchPlaylistUsageArtist = async () => {
		if (artist) {
			blacklistApi
				.getPlaylistUsage(BLACKLIST_TYPE.ARTIST, artist)
				.then(res => {
					setPlaylistUsages(res ? res.data || [] : []);
				})
				.catch(() => {
					createToast(
						'There was an error retrieving playlist usage. Please refresh the page and try again later',
						TOAST_LEVEL.ERROR,
						true
					);
				});
		}
	};

	useEffect(() => {
		fetchLabelMusicFeeds();
	}, []);

	useEffect(() => {
		fetchPlaylistUsageArtist();
	}, [artist]);

	const handleFeedSelection = feed_id => {
		const feed_index = selectedMusicFeeds.indexOf(feed_id);

		if (feed_index > -1) {
			setSelectedMusicFeeds(selectedMusicFeeds.filter(mf => mf !== feed_id));

			selectedMusicFeeds.splice(feed_index, 1);
		} else {
			setSelectedMusicFeeds([...selectedMusicFeeds, feed_id]);
		}
	};

	if (!showModal) {
		return null;
	}

	return (
		<AlertModal
			title='Blacklist Artist'
			description={`Are you sure you want to blacklist the artist ${(artist || '').toUpperCase()}? \n
        Artist song will be hidden from the search for all instructors, and will be flagged as "removed" in existing playlists. `}
			showModal={showModal}
			onConfirmClick={() => onConfirmClick(selectedMusicFeeds)}
			onCancelClick={onCancelClick}
			htmlReasons={getBlacklistSongPlaylistUsages(playlistUsage)}
		>
			{(musicFeeds || []).length && (
				<div className='blacklist__modal__custom-content mb-2'>
					<b className='mb-2'>*If the artist has been blacklisted before, it will overwrite previous configuration</b>
					<div className='blacklist__modal__custom-content--highlighted'>
						<b className='mb-2'>Choose music feed:</b>
						<div className='d-flex align-items-center'>
							<label className='m-0'>
								<input
									key='all'
									className='mr-2'
									type='checkbox'
									checked={!selectedMusicFeeds.length}
									onChange={() => {
										setSelectedMusicFeeds([]);
									}}
								/>
								All music feeds
							</label>
						</div>
						{musicFeeds.map(feed => (
							<div key={feed.music_metadata_feed_id} className='d-flex align-items-center'>
								<label className='m-0'>
									<input
										className='mr-2'
										type='checkbox'
										checked={selectedMusicFeeds.indexOf(feed.music_metadata_feed_id) > -1}
										onChange={() => handleFeedSelection(feed.music_metadata_feed_id)}
									/>
									{feed.feed_name}
								</label>
							</div>
						))}
					</div>
				</div>
			)}
		</AlertModal>
	);
};

ArtistBlacklistModal.propTypes = {
	onCancelClick: PropTypes.func,
	onConfirmClick: PropTypes.func.isRequired,
	showModal: PropTypes.bool,
};

ArtistBlacklistModal.defaultProps = {
	onCancelClick: null,
	showModal: true,
};
export default ArtistBlacklistModal;
