import React from 'react';

import { init as initApm } from '@elastic/apm-rum';
import ReactDOM from 'react-dom';

import './styles/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

initApm({
	serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
	serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER_URL,
	environment: process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT,
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
