export const NOTIFICATION_EVENT = {
	SONG_INVALIDATED_SONG: 'SONG_INVALIDATED_SONG',
	PLAYLIST_INVALIDATED_PLAYLIST: 'PLAYLIST_INVALIDATED_PLAYLIST',
	PLAYLIST_SUBMITTED_FOR_APPROVAL: 'PLAYLIST_SUBMITTED_FOR_APPROVAL',
	PLAYLIST_CONTENT_CREATOR_INVALIDATED_PLAYLIST: 'PLAYLIST_CONTENT_CREATOR_INVALIDATED_PLAYLIST',
	PLAYLIST_APPROVED_PLAYLIST: 'PLAYLIST_APPROVED_PLAYLIST',
	PLAYLIST_REJECTED_PLAYLIST: 'PLAYLIST_REJECTED_PLAYLIST',
	PLAYLIST_VERSION_SYNCED_TO_AIRTABLE: 'PLAYLIST_VERSION_SYNCED_TO_AIRTABLE',
	PLAYLIST_CONTENT_CREATOR_INVALIDATED_EXTERNAL_PLAYLIST: 'PLAYLIST_CONTENT_CREATOR_INVALIDATED_EXTERNAL_PLAYLIST',
	SONG_BLACKLISTED_SONG_RESTORED: 'SONG_BLACKLISTED_SONG_RESTORED',
	SONG_BLACKLISTED_SONG: 'SONG_BLACKLISTED_SONG',
	ARTIST_BLACKLISTED_ARTIST_RESTORED: 'ARTIST_BLACKLISTED_ARTIST_RESTORED',
	ARTIST_BLACKLISTED_ARTIST: 'ARTIST_BLACKLISTED_ARTIST',
};
