import React from 'react';

import { PLAYLIST_STATUS, PLAYLIST_STATUS_MAP } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { getExternalShowPlaylist } from '../api/playlists';
import ContentCopy from '../components/ContentCopy';
import StatusBadge from '../components/StatusBadge';
import STATUS_BADGE_COLOURS from '../constants/status-badge-colours';
import arrowLeftImg from '../images/arrow-left.svg';

class ShowPlaylist extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			externalPlaylistSongs: [],
			isPlaylistValid: false,
			numValidSongs: 0,
		};
		this.handleBackToExternalShowPlaylists = this.handleBackToExternalShowPlaylists.bind(this);
	}

	componentDidMount() {
		this.fetchExternalShowPlaylist();
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props;

		if (prevProps.location.pathname !== location.pathname) {
			this.fetchExternalShowPlaylist();
		}
	}

	handleBackToExternalShowPlaylists() {
		const { history } = this.props;

		history.push('/show-playlists');
	}

	getPlaylistSubText() {
		const { numValidSongs, externalPlaylistSongs } = this.state;

		let subtext = `${numValidSongs} Cleared Song${numValidSongs > 1 || numValidSongs === 0 ? 's ' : ' '}`;

		subtext = subtext.concat(`– ${externalPlaylistSongs.length} Total Songs`);
		return subtext;
	}

	getPlaylistStatusText() {
		const { externalPlaylistSongs } = this.state;

		return `Status: ${
			externalPlaylistSongs.length && externalPlaylistSongs[0] && externalPlaylistSongs[0].status
				? externalPlaylistSongs[0].status
				: 'n/a'
		}`;
	}

	async fetchExternalShowPlaylist() {
		const { match } = this.props;
		const externalPlaylistSongs = await getExternalShowPlaylist(match.params.globalTrackerShowId);
		const numValidSongs = externalPlaylistSongs.filter(p => p.valid).length;

		this.setState({
			externalPlaylistSongs,
			numValidSongs,
			isPlaylistValid: numValidSongs === externalPlaylistSongs.length,
		});
	}

	render() {
		const { match } = this.props;
		const { isPlaylistValid, externalPlaylistSongs } = this.state;

		return (
			<div>
				<span className='playlist__back' onClick={this.handleBackToExternalShowPlaylists}>
					<img src={arrowLeftImg} alt='back' className='playlist__back__btn' />
					Back to Show Playlists
				</span>
				<div className='playlist__header align-items-center'>
					<ContentCopy
						text={match.params.globalTrackerShowId}
						tooltipText='Copy playlist name'
						iconAltText='Copy playlist icon'
					>
						<h3
							className='h3'
							onCopy={e => {
								// when copy make sure to just copy text plain strings
								e.preventDefault();
								e.clipboardData.setData('text/plain', window.getSelection().toString());
							}}
						>
							{match.params.globalTrackerShowId}
						</h3>
					</ContentCopy>
					<div className='playlist__header__badge'>
						<StatusBadge
							status={isPlaylistValid ? 'Valid' : PLAYLIST_STATUS_MAP[PLAYLIST_STATUS.INVALID]}
							flagged={!isPlaylistValid}
							className={STATUS_BADGE_COLOURS[isPlaylistValid ? PLAYLIST_STATUS.VALID : PLAYLIST_STATUS.INVALID]}
						/>
					</div>
				</div>
				<div className='playlist__sub-text mt-2'>{this.getPlaylistStatusText()}</div>
				<div className='playlist__sub-text'>{this.getPlaylistSubText()}</div>
				<table className='show-playlists__table mt-3'>
					<thead>
						<tr className='show-playlists__table__header__row'>
							<th className='show-playlists__table__header__cell'>Key Song</th>
							<th className='show-playlists__table__header__cell'>Name</th>
							<th className='show-playlists__table__header__cell'>Artist</th>
							<th className='show-playlists__table__header__cell'>ISRC</th>
							<th className='show-playlists__table__header__cell'>ISRC In System</th>
							<th className='show-playlists__table__header__cell'>Track Release ID</th>
							<th className='show-playlists__table__header__cell'>Variis Track ID</th>
							<th className='show-playlists__table__header__cell'>Clearance</th>
							<th className='show-playlists__table__header__cell'>Blacklisted</th>
						</tr>
					</thead>
					<tbody>
						{externalPlaylistSongs.map(ps => (
							<tr key={ps.key_song} className='show-playlists__table__row'>
								<td className='show-playlists__table__row__cell'>{ps.key_song}</td>
								<td className='show-playlists__table__row__cell'>{ps.song}</td>
								<td className='show-playlists__table__row__cell'>{ps.artist}</td>
								<td className='show-playlists__table__row__cell'>{ps.isrc}</td>
								<td className='show-playlists__table__row__cell'>{ps.isrc_found ? 'Found' : 'Not Found'}</td>
								<td className='show-playlists__table__row__cell'>{ps.track_release_id}</td>
								<td className='show-playlists__table__row__cell'>
									{ps.variis_track_id ? ps.variis_track_id : 'No Variis ID From Source'}
								</td>
								<td className='show-playlists__table__row__cell'>{ps.valid ? 'Valid ' : 'Invalid'}</td>
								<td className='show-playlists__table__row__cell'>{ps.is_blacklisted ? 'Yes ' : 'No'}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

ShowPlaylist.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
	match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(ShowPlaylist);
