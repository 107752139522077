import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const renderAlertReasons = (reasons, htmlReasons) => {
	// show unordered list of plaintext reasons
	if (reasons && reasons.length > 0) {
		return (
			<ul>
				{reasons.map(r => (
					<li key={r} className='b1 alert-modal__reason mb-1'>
						{r}
					</li>
				))}
			</ul>
		);
	}

	// show unordered list of HTML element reasons
	if (htmlReasons && htmlReasons.length > 0) {
		return <ul className='alert-modal__html-reasons'>{htmlReasons}</ul>;
	}

	return null;
};

const AlertModal = ({
	title,
	description,
	reasons,
	showModal,
	onConfirmClick,
	onCancelClick,
	htmlReasons,
	children = null,
}) => {
	return (
		<div className={classNames('modal alert-modal', { 'd-flex': showModal })} role='dialog'>
			<div className='alert-modal__dialog'>
				<div className='modal-content alert-modal__content'>
					<h5 className='alert-modal__content__header'>{title}</h5>
					<div className='mb-2'>{description}</div>
					{children}
					{renderAlertReasons(reasons, htmlReasons)}
					<div className='d-flex justify-content-end'>
						{onCancelClick ? (
							<button
								name='AlertModal - Cancel'
								type='button'
								className='secondary-btn mt-1 mr-3'
								onClick={onCancelClick}
							>
								Cancel
							</button>
						) : null}
						<button name='AlertModal - OK' type='button' className='primary-btn mt-1' onClick={onConfirmClick}>
							OK
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

AlertModal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	description: PropTypes.string,
	htmlReasons: PropTypes.arrayOf(PropTypes.element),
	onCancelClick: PropTypes.func,
	onConfirmClick: PropTypes.func.isRequired,
	reasons: PropTypes.arrayOf(PropTypes.string),
	showModal: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
};

AlertModal.defaultProps = {
	description: '',
	reasons: [],
	onCancelClick: null,
	htmlReasons: [],
	children: null,
};
export default AlertModal;
