import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as searchApi from '../../api/search';
import SongTable from '../../components/song/SongTable';
import { LENGTH_RANGES } from '../../constants/length-ranges';
import {
	SEARCH_COLLECTIONS,
	SEARCH_SONG_TABLE_SORTERS,
	SEARCH_SONG_TABLE_CUSTOM_COLUMNS,
} from '../../constants/search';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as ArrowBack } from '../../images/arrow-back.svg';
import { formatArtistName } from '../../utils/songs';

const PAGE_SIZE = 40;

const getSortersForCollection = collection => {
	let sorters = SEARCH_SONG_TABLE_SORTERS.default;

	if (collection === SEARCH_COLLECTIONS.SONG) {
		sorters = sorters.concat(SEARCH_SONG_TABLE_SORTERS.songs);
	}

	if (sorters.some(s => !!s.overwriteDefault)) {
		sorters = sorters.map(s => ({ ...s, default: !!s.overwriteDefault }));
	}

	return sorters;
};

const SearchResultsSongs = ({ location, history, match }) => {
	const collectionKey = (location.pathname || '')
		.split('/')
		.filter(l => !!l && Object.values(SEARCH_COLLECTIONS).includes(l))[0];
	const sorters = getSortersForCollection(collectionKey);
	const { term, identifier } = match.params;

	const urlParams = new URLSearchParams(location.search);
	const searchTerm = urlParams.get('q');
	const highlightedSong = urlParams.get('h');
	const genres = urlParams.get('genres');
	const bpmRanges = urlParams.get('bpm');
	const decades = urlParams.get('decades');
	const lengthRanges = urlParams.get('lengths');
	const filters = (genres ? genres.split(',') : [])
		.concat(bpmRanges ? bpmRanges.split(',') : [])
		.concat(decades ? decades.split(',') : [])
		.concat(
			lengthRanges
				? lengthRanges.split(',').map(l => {
						return LENGTH_RANGES.find(r => r.value === l).text;
				  })
				: []
		);

	if (searchTerm) {
		filters.push(`"${searchTerm}"`);
	}

	const [playlists, setPlaylists] = useState([]);
	const [folders, setFolders] = useState([]);
	const [topSong, setTopSong] = useState(null);

	const [sortFilter, setSortFilter] = useState(sorters.length ? sorters.find(s => s.default).value : null);
	const [topSongLoaded, setTopSongLoaded] = useState(true);
	const [songsLoaded, setSongsLoaded] = useState(true);
	const [songs, setSongs] = useState([]);
	const [total, setTotal] = useState(0);
	const [pageNum, setPageNum] = useState(1);

	const {
		createToast,
		getSearchPlaylists,
		getSearchFolders,
		searchPlaylists,
		searchFolders,
		playlistInSearchContext,
		openArtistBlacklistModal,
	} = useContext(GlobalContext);

	const handleBack = () => {
		if (term && !searchTerm) {
			history.push(`/search?q=${encodeURIComponent(term)}`);
		} else if (searchTerm) {
			history.push(`/search?q=${encodeURIComponent(searchTerm.replace('&', '%26'))}`);
		} else {
			history.goBack();
		}
	};

	const getSongsTableHeader = () => {
		const val =
			decodeURIComponent(term || '') ||
			(collectionKey === SEARCH_COLLECTIONS.SUGGESTED && topSong ? topSong.artist : '');

		switch (collectionKey) {
			case SEARCH_COLLECTIONS.SONG:
				return <span>All Songs</span>;
			case SEARCH_COLLECTIONS.ARTIST:
			case SEARCH_COLLECTIONS.SUGGESTED:
				return (
					<span>
						All Songs by &ldquo;
						<b>{formatArtistName(val)}</b>
						&rdquo;
					</span>
				);
			case SEARCH_COLLECTIONS.ALBUM:
				return (
					<span>
						Songs on &ldquo;<b>{val}</b>&rdquo;
					</span>
				);
			default:
				return 'All Songs';
		}
	};

	const fetchSongs = async ({ page, topSongResult } = {}) => {
		const song = topSongResult || topSong;
		let songsResponse = null;

		setSongsLoaded(false);
		if (term) {
			songsResponse = await searchApi.getSongsByCollection({
				collection: collectionKey,
				term,
				identifier,
				limit: PAGE_SIZE,
				page: page || pageNum,
				sorter: sortFilter === 'relevance' ? '' : sortFilter,
			});
		} else if (!term && collectionKey === SEARCH_COLLECTIONS.SUGGESTED && !!song && song.song_id === highlightedSong) {
			// When displaying suggested song display songs from artist
			songsResponse = await searchApi.getSongsByCollection({
				collection: SEARCH_COLLECTIONS.ARTIST,
				term: song.artist,
				filters,
				limit: PAGE_SIZE,
				page: page || pageNum,
				sorter: sortFilter === 'relevance' ? '' : sortFilter,
			});
		} else if (term || filters.length) {
			songsResponse = await searchApi.searchAllSongs({
				term: searchTerm,
				limit: PAGE_SIZE,
				genres,
				bpmRanges,
				decades,
				lengthRanges,
				page: page || pageNum,
				sorter: sortFilter === 'relevance' ? '' : sortFilter,
			});
		}

		setSongs(songsResponse ? songsResponse.data : []);
		setTotal(songsResponse ? songsResponse.total : 0);
		setSongsLoaded(true);
	};

	const fetchTopSong = async () => {
		let song = null;

		if (highlightedSong) {
			setTopSongLoaded(false);
			const topSongResult = await searchApi.getSong({
				songId: highlightedSong,
			});

			if (topSongResult.data && topSongResult.data[0]) {
				// eslint-disable-next-line prefer-destructuring
				song = topSongResult.data[0];
			}
		}
		setTopSong(song);
		setTopSongLoaded(true);
		return song;
	};

	// component did mount
	useEffect(() => {
		const initialDataLoad = async () => {
			const topSongResult = await fetchTopSong();

			await fetchSongs({ topSongResult });
		};

		initialDataLoad();
	}, []);

	// track page num change
	useEffect(() => {
		fetchSongs();
	}, [pageNum, sortFilter]);

	// track highlighted song change on URL
	useEffect(() => {
		if (!!topSong && highlightedSong !== topSong.song_id) {
			window.location.reload();
		}
	}, [highlightedSong]);

	// listen for new folders o playlists
	useEffect(() => {
		const loadContextCollections = async () => {
			try {
				setPlaylists(await getSearchPlaylists());
				setFolders(await getSearchFolders());
			} catch (_) {
				createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
			}
		};

		loadContextCollections();
	}, [searchPlaylists, playlistInSearchContext, searchFolders]);

	const onSorterChange = async value => {
		setPageNum(1);
		setSortFilter(value);
	};

	const onPageChange = async page => {
		setPageNum(page);
	};

	const onBookmarkSong = songId => {
		setSongs(
			songs.map(s => ({
				...s,
				...(s.song_id === songId && { ...s, is_favorite: !s.is_favorite }),
			}))
		);
		if (topSong && topSong.song_id === songId) {
			setTopSong({ ...topSong, is_favorite: !topSong.is_favorite });
		}
	};

	const handleBlacklistSongISRC = async isrc => {
		if (topSong && topSong.isrc === isrc) {
			const artistTerm = encodeURIComponent(topSong.artist.replace('&', '%26'));

			const url =
				collectionKey === SEARCH_COLLECTIONS.SUGGESTED
					? `/search/${SEARCH_COLLECTIONS.ARTIST}/${artistTerm}/songs?q=${artistTerm}`
					: `/search/songs?q=${searchTerm}`;

			history.push(url);
		} else {
			setPageNum(1);
			await fetchSongs();
		}
	};

	const handleBlacklistSongVariisID = async variisID => {
		if (topSong && topSong.variis_track_id === variisID) {
			const artistTerm = encodeURIComponent(topSong.artist.replace('&', '%26'));

			const url =
				collectionKey === SEARCH_COLLECTIONS.SUGGESTED
					? `/search/${SEARCH_COLLECTIONS.ARTIST}/${artistTerm}/songs?q=${artistTerm}`
					: `/search/songs?q=${searchTerm}`;

			history.push(url);
		} else {
			setPageNum(1);
			await fetchSongs();
		}
	};

	const handleBlacklistArtist = () => {
		openArtistBlacklistModal(decodeURIComponent(term), () => {
			history.push(`/search?q=${encodeURIComponent(term.replace('&', '%26'))}`);
		});
	};

	return (
		<div className='search'>
			<div className='search__back mb-3' onClick={handleBack}>
				<ArrowBack />
				Back
			</div>
			{highlightedSong && topSong && (
				<div className='search__top-result'>
					<SongTable
						title='Top Result'
						playlists={playlists}
						folders={folders}
						tableKey='top-results-table'
						borderless
						hideIfNoData
						navigateToNewPage
						songs={[topSong]}
						loaded={topSongLoaded}
						collectionKey={collectionKey}
						onBookmarkSong={onBookmarkSong}
						handleBlacklistSongISRC={handleBlacklistSongISRC}
						handleBlacklistSongVariisID={handleBlacklistSongVariisID}
					/>
				</div>
			)}
			{((collectionKey === SEARCH_COLLECTIONS.SUGGESTED && topSong) ||
				(!highlightedSong && collectionKey !== SEARCH_COLLECTIONS.SUGGESTED)) && (
				<SongTable
					title={getSongsTableHeader()}
					playlists={playlists}
					folders={folders}
					songs={songs}
					sorters={sorters}
					tableKey='search-results-table'
					navigateToNewPage
					loaded={songsLoaded && (topSongLoaded || !highlightedSong)}
					total={total}
					sortFilter={sortFilter}
					pageNum={pageNum}
					filters={filters}
					collectionKey={collectionKey}
					handleBlacklistSongISRC={handleBlacklistSongISRC}
					handleBlacklistSongVariisID={handleBlacklistSongVariisID}
					handleBlacklistArtist={handleBlacklistArtist}
					onSorterChange={onSorterChange}
					onPageChange={onPageChange}
					pageSize={PAGE_SIZE}
					onBookmarkSong={onBookmarkSong}
					customColumns={SEARCH_SONG_TABLE_CUSTOM_COLUMNS.filter(c => {
						// display 'cleared_timestamp' (AQUIRED DATE) only when sorting by 'cleared_timestamp'
						return c.songProperty !== 'cleared_timestamp' || sortFilter === 'cleared_timestamp';
					})}
				/>
			)}
		</div>
	);
};

SearchResultsSongs.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
	match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(SearchResultsSongs);
