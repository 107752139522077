export const LENGTH_RANGES = [
	{ text: '1:00 min or less', value: '0 - 60' },
	{ text: '1:01 - 2:00 min', value: '61 - 120' },
	{ text: '2:01 - 3:00 min', value: '121 - 180' },
	{ text: '3:01 - 4:00 min', value: '181 - 240' },
	{ text: '4:01 - 5:00 min', value: '241 - 300' },
	{ text: '5:01 - 6:00 min', value: '301 - 360' },
	{ text: '6:01 - 7:00 min', value: '361 - 420' },
	{ text: '7:01 - 8:00 min', value: '421 - 480' },
	{ text: '8:01 - 9:00 min', value: '481 - 540' },
	{ text: '9:01 - 10:00 min', value: '541 - 600' },
	{ text: '10:01 min or more', value: '601' },
];
