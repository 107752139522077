import * as qs from 'query-string';

import { BPM_RANGES } from '../constants/bpm-ranges';
import { LENGTH_RANGES } from '../constants/length-ranges';
import { apiUrl, getDefaultGetInit, parseStandardResponse } from '../utils/api';

const BROWSE_PATH = 'browse';

export async function getAllGenres(limit, page) {
	const url = `${apiUrl}/${BROWSE_PATH}/genres?${qs.stringify({
		limit,
		page,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getAllArtists(limit, page, collectionFilter = null) {
	const url = `${apiUrl}/${BROWSE_PATH}/artists?${qs.stringify({
		limit,
		page,
		artistInitialCharFilter: collectionFilter === '$' ? '#' : collectionFilter,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getAllDecades(limit, page) {
	const url = `${apiUrl}/${BROWSE_PATH}/decades?${qs.stringify({
		limit,
		page,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getBPMRanges(limit, page = 1) {
	return new Promise(resolve => {
		let offset = (page - 1) * limit;

		offset = offset >= 0 ? offset : 0;

		resolve({
			data: BPM_RANGES.slice(offset, limit).map(range => ({
				key: range,
				count: 1,
			})),
			total: BPM_RANGES.length,
		});
	});
}

export async function getLengthRanges() {
	return Promise.resolve({
		data: LENGTH_RANGES,
		total: 11,
	});
}

export async function getSongsByCollection(collection, term, limit, page = 1, sorter = '') {
	const url = `${apiUrl}/${BROWSE_PATH}/${collection}/${term}?${qs.stringify({
		limit,
		page,
		sorter,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}
