import React, { useRef } from 'react';

import PropTypes from 'prop-types';

const ImportFileInput = ({
	acceptFiles,
	handleFileChosen,
	handleFileRead,
	label,
	disabled,
	className,
	fileName = '',
	hideFilename = false,
}) => {
	const inputEl = useRef(null);

	let file = !!inputEl.current && !!inputEl.current.value ? inputEl.current.files[0] : '';

	let fileReader;

	if (fileName === '' && !!inputEl.current && !!inputEl.current.value) {
		inputEl.current.value = null;
		file = null;
	}

	const readFile = () => {
		const content = fileReader.result;

		handleFileRead(content);
	};

	const fileChosen = e => {
		const f = e.target.files[0];

		if (f) {
			if (handleFileRead) {
				fileReader = new FileReader();
				fileReader.onloadend = readFile;
				fileReader.readAsText(f);
			}
			if (handleFileChosen) handleFileChosen(f);
		}
	};

	return (
		<div
			className={['file-input', disabled ? 'file-input--disabled' : '', className || '']
				.filter(x => !!x)
				.join(' ')
				.trim()}
		>
			<div className='file-input__wrapper'>
				<label className='file-input__input btn primary-btn'>
					{label || 'Choose file'}
					<form>
						<input
							type='file'
							ref={inputEl}
							className='file-input'
							accept={acceptFiles}
							onChange={fileChosen}
							hidden
							disabled={disabled}
						/>
					</form>
				</label>
				{(!!fileName || (!!file && !!file.name)) && !hideFilename && (
					<span className='file-input__name'>{fileName || file.name}</span>
				)}
			</div>
		</div>
	);
};

ImportFileInput.propTypes = {
	acceptFiles: PropTypes.string.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	fileName: PropTypes.string,
	handleFileChosen: PropTypes.func,
	handleFileRead: PropTypes.func,
	hideFilename: PropTypes.bool,
	label: PropTypes.string,
};

ImportFileInput.defaultProps = {
	handleFileChosen: null,
	handleFileRead: null,
	disabled: false,
	label: '',
	className: '',
	fileName: '',
	hideFilename: false,
};
export default ImportFileInput;
