export const areArraysEqual = (array1, array2) => {
	if (!array1 && !array2) {
		return true;
	}

	if (!array1 || !array2) {
		return false;
	}

	if (array1.length !== array2.length) {
		return false;
	}

	if (Array.isArray(array1)) {
		const prevSet = new Set(array1.map(b => b.value));
		const currSet = new Set(array2.map(b => b.value));

		if (prevSet.size !== currSet.size) {
			return false;
		}
		return [...prevSet].every(val => currSet.has(val));
	}

	return array1.value === array2.value;
};
