import React from 'react';

import { withRouter } from 'react-router';

import * as sharedFoldersApi from '../api/shared-folders';
import Loader from '../components/Loader';
import { PaginationButtons } from '../components/PaginationButtons';
import FoldersCards from '../components/sharedFolder/FolderCards';
import * as PERMISSIONS from '../constants/permissions';
import GlobalContext from '../contexts/GlobalContext';
import { hasPermission } from '../utils/auth';

const DEFAULT_LIMIT = 100;
const DEFAULT_PAGE = 1;

class Folders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			folders: [],
			showArchivedFolders: false,
			pageSize: DEFAULT_LIMIT,
			pageNum: DEFAULT_PAGE,
			total: 0,
			loaded: false,
		};
		this.fetchFolders = this.fetchFolders.bind(this);
		this.handleNewFolder = this.handleNewFolder.bind(this);
	}

	componentDidMount() {
		this.fetchFolders(DEFAULT_LIMIT, DEFAULT_PAGE);
	}

	componentDidUpdate(prevProps, prevState) {
		const { showArchivedFolders, pageNum, pageSize } = this.state;

		if (showArchivedFolders !== prevState.showArchivedFolders) {
			this.fetchFolders(pageSize, pageNum);
		}
	}

	async handleNewFolder() {
		const { setShowNewFolderModal } = this.context;

		setShowNewFolderModal(true);
	}

	fetchFolders = async (pageSize, pageNum) => {
		const { showArchivedFolders } = this.state;
		this.setState({ loaded: false });

		const folders = await sharedFoldersApi.getFolders(pageSize, pageNum, showArchivedFolders);

		this.setState({
			folders: folders.data,
			pageSize,
			pageNum,
			total: folders.count,
			loaded: true,
		});
	};

	render() {
		const { folders, showArchivedFolders, pageNum, pageSize, total, loaded } = this.state;
		const numPages = folders && folders.length ? Math.ceil(total / pageSize) : 0;
		const canEditFolder = hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR]);

		return (
			<div>
				<h3 className='h3'>Catalog Folders</h3>
				{canEditFolder && (
					<button
						name='Folders - New Folder'
						className='primary-btn mt-2'
						onClick={() => this.handleNewFolder()}
						type='button'
					>
						New Folder
					</button>
				)}
				<Loader loaded={loaded}>
					<FoldersCards
						filter={canEditFolder}
						folders={folders}
						includeArchived={showArchivedFolders}
						toggleArchivedFolder={() => {
							this.setState({
								showArchivedFolders: !showArchivedFolders,
							});
						}}
					/>
					{numPages > 1 ? (
						<div className='d-flex align-items-center mt-5'>
							<PaginationButtons
								handlePageChange={nextPage => {
									this.fetchFolders(pageSize, nextPage);
								}}
								numPages={numPages}
								pageNum={pageNum || 0}
							/>
						</div>
					) : null}
				</Loader>
			</div>
		);
	}
}

Folders.contextType = GlobalContext;

export default withRouter(Folders);
