import React from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import { GlobalProvider } from './contexts/GlobalContext';
import Layout from './pages/Layout';
import Login from './pages/Login';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

function App() {
	return (
		<GlobalProvider>
			<BrowserRouter basename={baseUrl}>
				<Switch>
					<Route path='/login' component={Login} />
					<ProtectedRoute exact path='/' component={Layout} />
					<ProtectedRoute
						path={[
							'/dashboard',
							'/explore/:collection',
							'/my-playlists',
							'/my-songs',
							'/playlist',
							'/search/:collection(artists|albums|playlists)',
							'/search/songs',
							'/search/suggested',
							'/search/:collection(artists|albums|playlists)/:term/songs',
							'/search',
							'/catalog',
							'/playlists/:status(pending|approved|invalid|rejected)',
							'/team',
							'/validation',
							'/blacklist/:collection(genre|isrc|artist)?',
							'/show-playlists',
							'/show-playlist',
							'/genres-manager',
							'/folders',
							'/folder',
							'/licensing-dashboard/:collection(classes|playlists|songs-dropped|songs-added)?',
							'/browse/:collection(genres|artists|decades|bpms)',
							'/browse/:collection(genres|artists|decades|bpms)/:term',
							'/health',
							'/settings',
						]}
						component={Layout}
					/>
					{/* handle redirects */}
					<Redirect from='/browse' to='/browse/genres' exact />
				</Switch>
			</BrowserRouter>
		</GlobalProvider>
	);
}

export default App;
