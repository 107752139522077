import React from 'react';

import classNames from 'classnames';
import { findAll } from 'highlight-words-core';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import onClickOutside from 'react-onclickoutside';
import { withRouter } from 'react-router';

import { getAllGenres, getBPMRanges, getAllDecades, getLengthRanges } from '../../api/browse';
import * as suggesterApi from '../../api/search-suggestions';
import {
	SEARCH_KEYWORDS,
	SEARCH_SUGGESTIONS_COLLECTIONS,
	SEARCH_SUGGESTIONS_LIMIT,
	SEARCH_COLLECTIONS,
	SEARCH_ENGINE_CHARACTERS_MAPPING,
} from '../../constants/search';
import { ReactComponent as SearchIcon } from '../../images/search-white.svg';
import { canSearchOnFullCatalog, getUser } from '../../utils/auth';
import Badge from '../Badge';

const currentUser = getUser();
const RECENT_SEARCHES_KEY = `RECENT_SEARCHES_${currentUser ? currentUser.user_id : ''}`;

function FilterSelect({ options, name, placeholder, onChange, filters, loading }) {
	const filteredOptions = options.filter(o => !filters.includes(o.value));
	return (
		<select id={name} disabled={!filteredOptions.length} className='m-2' onChange={onChange} defaultValue=''>
			{loading ? (
				<option value='' disabled>
					Loading {placeholder}...
				</option>
			) : (
				<>
					<option value='' disabled>
						{placeholder}
					</option>
					{filteredOptions.map(({ text, value, disabled }) => (
						<option key={value} value={value} disabled={disabled || false}>
							{text}
						</option>
					))}
				</>
			)}
		</select>
	);
}

FilterSelect.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			value: PropTypes.string,
		})
	).isRequired,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	filters: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChange: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
};

function FilterSection({ genres, bpmRanges, decades, lengthRanges, filters, onChange }) {
	if (genres.total || bpmRanges.total || decades.total || lengthRanges.total) {
		return (
			<div className='mb-3'>
				<div className='search-bar__suggestions__section__title mt-3'>Filters</div>
				<FilterSelect
					name='genres'
					options={(genres.data || []).map(d => ({
						text: d.key,
						value: d.key,
					}))}
					loading={genres.loading}
					placeholder='Genres'
					filters={filters.genres}
					onChange={onChange}
				/>
				<FilterSelect
					name='bpmRanges'
					options={bpmRanges.data.map(d => ({ text: d.key, value: d.key }))}
					placeholder='BPM'
					filters={filters.bpmRanges}
					onChange={onChange}
					loading={false}
				/>
				<FilterSelect
					name='decades'
					options={(decades.data || []).map(d => ({
						text: d.key,
						value: d.key,
					}))}
					placeholder='Decades'
					filters={filters.decades}
					onChange={onChange}
					loading={decades.loading}
				/>
				<FilterSelect
					name='lengthRanges'
					options={lengthRanges.data}
					placeholder='Length'
					filters={filters.lengthRanges}
					onChange={onChange}
					loading={false}
				/>
			</div>
		);
	}
	return null;
}

FilterSection.propTypes = {
	genres: PropTypes.shape({
		data: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				count: PropTypes.number,
			})
		),
		loading: PropTypes.bool,
		total: PropTypes.number,
	}).isRequired,
	bpmRanges: PropTypes.shape({
		data: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				count: PropTypes.number,
			})
		),
		total: PropTypes.number,
	}).isRequired,
	decades: PropTypes.shape({
		data: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				count: PropTypes.number,
			})
		),
		loading: PropTypes.bool,
		total: PropTypes.number,
	}).isRequired,
	lengthRanges: PropTypes.shape({
		data: PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string,
				value: PropTypes.string,
			})
		),
		total: PropTypes.number,
	}).isRequired,
	filters: PropTypes.shape({
		genres: PropTypes.arrayOf(PropTypes.string),
		bpmRanges: PropTypes.arrayOf(PropTypes.string),
		decades: PropTypes.arrayOf(PropTypes.string),
		lengthRanges: PropTypes.arrayOf(PropTypes.string),
	}).isRequired,
	onChange: PropTypes.func.isRequired,
};

function parseFilters({ urlParams, lengthRanges }) {
	const genres = urlParams.get('genres');
	const bpmRanges = urlParams.get('bpm');
	const decades = urlParams.get('decades');
	const lengths = urlParams.get('lengths');
	const filters = {
		genres: genres ? genres.split(',') : [],
		bpmRanges: bpmRanges ? bpmRanges.split(',') : [],
		decades: decades ? decades.split(',') : [],
		lengthRanges: lengths ? lengths.split(',') : [],
	};
	const all = [];
	filters.genres.forEach(g => {
		all.push({ type: 'genres', value: g, text: g });
	});
	filters.bpmRanges.forEach(b => {
		all.push({ type: 'bpmRanges', value: b, text: b });
	});
	filters.decades.forEach(d => {
		all.push({ type: 'decades', value: d, text: d });
	});
	filters.lengthRanges.forEach(l => {
		const matched = lengthRanges.data.find(r => r.value === l);
		all.push({
			type: 'lengthRanges',
			text: matched.text,
			value: matched.value,
		});
	});

	filters.all = all;
	return filters;
}

class SearchBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			query: '',
			suggestionsOpen: false,
			recentSearches: [],
			albumSuggestions: [],
			artistSuggestions: [],
			songSuggestions: [],
			playlistSuggestions: [],
			genres: { data: [], total: 0, loading: true },
			bpmRanges: { data: [], total: 0 },
			decades: { data: [], total: 0, loading: true },
			lengthRanges: { data: [], total: 0 },
			filters: {
				genres: [],
				bpmRanges: [],
				decades: [],
				lengthRanges: [],
				all: [],
			},
		};

		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);

		this.container = React.createRef();
	}

	async componentDidMount() {
		const { location } = this.props;
		const bpmRanges = await getBPMRanges();
		const lengthRanges = await getLengthRanges();

		const urlParams = new URLSearchParams(location.search);
		const term = urlParams.get('q');
		const query = this.parseSearch(term) || '';
		const filters = parseFilters({ urlParams, lengthRanges });

		this.setState({
			recentSearches: JSON.parse(localStorage.getItem(RECENT_SEARCHES_KEY) || null) || [],
			...(!!query && { query }),
			suggestionsOpen: false,
			bpmRanges,
			lengthRanges,
			filters,
		});

		Promise.all([getAllGenres(50000, 1), getAllDecades(50000, 1)])
			.then(([genres, decades]) => {
				this.setState({
					genres: { ...genres, loading: false },
					decades: { ...decades, loading: false },
				});
			})
			.catch(err => {
				console.error(err);
			});
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props;
		const { lengthRanges } = this.state;

		if (prevProps.location.search !== location.search) {
			const urlParams = new URLSearchParams(location.search);
			const term = decodeURIComponent(urlParams.get('q'));
			const query = this.parseSearch(term) || '';
			const filters = parseFilters({
				urlParams,
				lengthRanges,
			});

			// eslint-disable-next-line react/no-did-update-set-state
			this.setState(
				{
					recentSearches: JSON.parse(localStorage.getItem(RECENT_SEARCHES_KEY) || null) || [],
					...(!!query && { query }),
					suggestionsOpen: false,
					filters,
				},
				this.handleClickOutside()
			);
		}
	}

	addFilter = event => {
		const { text } = event.target.options[event.target.options.selectedIndex];
		const { value, id } = event.target;
		const { filters } = this.state;
		filters[id].push(value);
		filters.all.push({ type: id, text, value });
		// eslint-disable-next-line no-param-reassign
		event.target.selectedIndex = 0;
		this.setState({ filters });
	};

	removeFilter = (name, filter) => {
		const { filters } = this.state;
		filters[name] = filters[name].filter(f => f !== filter);
		filters.all = filters.all.filter(f => !(f.type === name && f.value === filter));
		this.setState({ filters });
	};

	parseSearch = search => {
		const keywords = [
			SEARCH_KEYWORDS.GENRE,
			SEARCH_KEYWORDS.ISRC,
			SEARCH_KEYWORDS.ARTIST,
			SEARCH_KEYWORDS.SONG,
			SEARCH_KEYWORDS.ID,
			SEARCH_KEYWORDS.TRACK_ID,
			SEARCH_KEYWORDS.VARIIS_ID,
		];

		let query = qs.parse(search).q;

		if (query) {
			keywords.forEach(k => {
				if (k.includes('id')) {
					query = query.replace(new RegExp(k, 'gi'), `${k}`); // don't prepend whitespace
				} else {
					query = query.replace(new RegExp(k, 'gi'), ` ${k}`); // prepend whitespace
				}
			});
			return query.replace(/\s\s+/g, ' ').trim();
		}
		return null;
	};

	saveRecentSearch = val => {
		if (val) {
			// eslint-disable-next-line no-param-reassign
			val = val.toString();
			let recentSearches = JSON.parse(localStorage.getItem(RECENT_SEARCHES_KEY) || null) || [];

			recentSearches = recentSearches.filter(rc => rc.val !== val);
			recentSearches.unshift({ label: val, query_val: val });
			localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(recentSearches.slice(0, 3)));
		}
	};

	handleSearch = () => {
		const { history } = this.props;
		const { query, filters } = this.state;

		if (query || filters.all.length) {
			this.saveRecentSearch(query);
			const params = new URLSearchParams();
			params.append('q', query);
			params.append('genres', filters.genres.join(','));
			params.append('bpm', filters.bpmRanges.join(','));
			params.append('decades', filters.decades.join(','));
			params.append('lengths', filters.lengthRanges.join(','));
			history.push(`/search?${params.toString()}`);
		}
	};

	handleKeyPress = e => {
		if (e.keyCode === 13) {
			this.handleSearch();
		}
		if (e.keyCode === 27) {
			this.setState({ suggestionsOpen: false });
		}
	};

	formatSuggestion = (collectionKey, suggestion = {}) => {
		if (collectionKey === SEARCH_SUGGESTIONS_COLLECTIONS.ARTIST) {
			return {
				id: suggestion.name,
				label: suggestion.name,
				query_val: suggestion.name,
			};
		}
		if (collectionKey === SEARCH_SUGGESTIONS_COLLECTIONS.ALBUM) {
			return {
				id: suggestion.name,
				label: `${suggestion.name} - by ${suggestion.artist}`,
				query_val: suggestion.name,
				releaseICPN: suggestion.release_icpn,
			};
		}
		if (collectionKey === SEARCH_SUGGESTIONS_COLLECTIONS.SONG) {
			return {
				id: suggestion.song_id,
				label: `${suggestion.artist} - ${suggestion.name}`,
				query_val: suggestion.name,
				explicit: suggestion.explicit,
			};
		}
		if (collectionKey === SEARCH_SUGGESTIONS_COLLECTIONS.PLAYLIST) {
			return {
				id: suggestion.playlist_id,
				label: `${suggestion.name} - ${suggestion.playlist_status_name}`,
				query_val: suggestion.name,
			};
		}
		return null;
	};

	// eslint-disable-next-line react/sort-comp
	fetchSuggestions = debounce(async query => {
		let { albumSuggestions, artistSuggestions, songSuggestions, playlistSuggestions } = this.state;

		await suggesterApi
			.getSuggestions({
				term: query,
				limit: SEARCH_SUGGESTIONS_LIMIT,
			})
			.then(data => {
				albumSuggestions = data.albumSuggestions.map(suggestion =>
					this.formatSuggestion(SEARCH_SUGGESTIONS_COLLECTIONS.ALBUM, suggestion)
				);
				artistSuggestions = data.artistSuggestions.map(suggestion =>
					this.formatSuggestion(SEARCH_SUGGESTIONS_COLLECTIONS.ARTIST, suggestion)
				);
				songSuggestions = data.songSuggestions.map(suggestion =>
					this.formatSuggestion(SEARCH_SUGGESTIONS_COLLECTIONS.SONG, suggestion)
				);
				playlistSuggestions = data.playlistSuggestions.map(suggestion =>
					this.formatSuggestion(SEARCH_SUGGESTIONS_COLLECTIONS.PLAYLIST, suggestion)
				);
			})
			.catch(() => {
				albumSuggestions = [];
				artistSuggestions = [];
				songSuggestions = [];
				playlistSuggestions = [];
			})
			.finally(() => {
				this.setState({
					albumSuggestions,
					artistSuggestions,
					songSuggestions,
					playlistSuggestions,
				});
			});
	}, 300);

	handleSearchChange = e => {
		const query = e.target.value;

		if (query) {
			this.fetchSuggestions(query);
		}

		this.setState({ query, suggestionsOpen: true });
	};

	handleClickOutside = () => {
		const { suggestionsOpen } = this.state;

		if (suggestionsOpen) {
			this.setState({ suggestionsOpen: false });
		}
	};

	setClickOutsideRef = () => this.container.current;

	handleInputFocus = () => {
		const { query } = this.state;

		if (query) {
			this.fetchSuggestions(query);
		}

		this.setState({ suggestionsOpen: true });
	};

	handleSuggestionClick = (collectionKey, query_val, id, customKeys) => {
		const { history } = this.props;

		if (collectionKey === SEARCH_SUGGESTIONS_COLLECTIONS.RECENT) {
			this.setState({ query: query_val }, this.handleSearch);
		} else if (collectionKey === SEARCH_SUGGESTIONS_COLLECTIONS.PLAYLIST) {
			this.setState({ suggestionsOpen: false, query: query_val }, () => {
				this.saveRecentSearch(query_val);
				history.push(`/playlist/${id}`);
			});
		} else if (collectionKey === SEARCH_SUGGESTIONS_COLLECTIONS.ARTIST) {
			this.setState({ suggestionsOpen: false, query: query_val }, () => {
				this.saveRecentSearch(query_val);
				history.push(`/search/${SEARCH_COLLECTIONS.ARTIST}/${encodeURIComponent(query_val.replace('&', '%26'))}/songs`);
			});
		} else if (collectionKey === SEARCH_SUGGESTIONS_COLLECTIONS.ALBUM) {
			this.setState({ suggestionsOpen: false, query: query_val }, () => {
				const { releaseICPN } = customKeys;

				this.saveRecentSearch(query_val);
				history.push(
					`/search/${SEARCH_COLLECTIONS.ALBUM}/${encodeURIComponent(
						query_val.replace('&', '%26')
					)}/${releaseICPN}/songs`
				);
			});
		} else if (collectionKey === SEARCH_SUGGESTIONS_COLLECTIONS.SONG) {
			this.setState({ suggestionsOpen: false, query: query_val }, () => {
				this.saveRecentSearch(query_val);
				history.push(
					`/search/${SEARCH_COLLECTIONS.SUGGESTED}?q=${encodeURIComponent(query_val.replace('&', '%26'))}&h=${id}`
				);
			});
		}
	};

	highlightedTermOnSuggestion = label => {
		const { query } = this.state;
		const formattedQuery = (query || '').replace(/"/g, '');

		let unNormalizeSingleCharsVariations = [];

		let unNormalizedQuery = formattedQuery;

		SEARCH_ENGINE_CHARACTERS_MAPPING.forEach(charMap => {
			const [noNormalizedChar, normalizedChar = ''] = charMap.split('=>');
			const replaceRGX = new RegExp(normalizedChar, 'g');

			unNormalizeSingleCharsVariations = unNormalizeSingleCharsVariations.concat(
				formattedQuery.replace(replaceRGX, noNormalizedChar).split(' ')
			);
			unNormalizedQuery = unNormalizedQuery.replace(replaceRGX, noNormalizedChar);
		});

		const chunks = findAll({
			autoEscape: true,
			caseSensitive: false,
			searchWords: [
				...new Set([
					...formattedQuery.split(' '),
					...unNormalizedQuery.split(' '),
					...unNormalizeSingleCharsVariations,
				]),
			],
			textToHighlight: label,
		});

		return chunks
			.map(chunk => {
				const { end, highlight, start } = chunk;
				const text = label.substr(start, end - start);

				if (highlight) {
					return `<b>${text}</b>`;
				}
				return text;
			})
			.join('');
	};

	renderSuggestedSection = (collectionKey, title, options, highlight = true) => {
		if (!options || !options.length) {
			return null;
		}

		return (
			<div className='search-bar__suggestions__section'>
				<div className='search-bar__suggestions__section__title'>{title}</div>
				<ul>
					{options.map(({ query_val, label, id, explicit, releaseICPN }, i) => (
						<li
							className='search-bar__suggestions__section__suggestion'
							key={`${collectionKey}${i}`}
							onClick={() =>
								this.handleSuggestionClick(collectionKey, query_val, id, {
									releaseICPN,
								})
							}
						>
							{highlight ? (
								<span
									dangerouslySetInnerHTML={{
										__html: this.highlightedTermOnSuggestion(label),
									}}
								/>
							) : (
								<span>{label}</span>
							)}
							{explicit && <span className='search-bar__suggestions__section__suggestion--explicit'>EXPLICIT</span>}
						</li>
					))}
				</ul>
			</div>
		);
	};

	render() {
		if (!canSearchOnFullCatalog()) {
			return null;
		}

		const {
			query,
			suggestionsOpen,
			recentSearches,
			artistSuggestions,
			albumSuggestions,
			songSuggestions,
			playlistSuggestions,
			genres,
			bpmRanges,
			decades,
			lengthRanges,
			filters,
		} = this.state;
		const existQuery = (query || '').trim();
		const displayRecentSearches = recentSearches.length && !existQuery;

		return (
			<div
				ref={this.container}
				className={classNames('search-bar__container', {
					'search-bar__container--dropdown-open': suggestionsOpen,
				})}
			>
				<div
					className={classNames('search-bar__input', {
						'search-bar__input--dropdown-open': suggestionsOpen,
					})}
				>
					{filters.all.map(r => (
						<Badge
							key={`${r.type}-${r.value}`}
							className='song-table__filter__badge'
							text={r.text}
							closeCallback={() => this.removeFilter(r.type, r.value)}
						/>
					))}
					<div className='search-bar__input-container'>
						<input
							type='search'
							placeholder='Search by typing and/or using filters'
							value={query}
							onChange={this.handleSearchChange}
							onKeyDown={this.handleKeyPress}
							onFocus={this.handleInputFocus}
							aria-label='Search'
						/>
						<button
							name='SearchBar - Search'
							className='search-bar__input__btn primary-btn'
							type='button'
							onClick={this.handleSearch}
						>
							<SearchIcon /> Search
						</button>
					</div>
				</div>
				<div className='search-bar__suggestions__container'>
					<div
						className={classNames('search-bar__suggestions__panel', {
							'search-bar__suggestions__panel--expanded': suggestionsOpen,
						})}
					>
						<FilterSection
							lengthRanges={lengthRanges}
							genres={genres}
							bpmRanges={bpmRanges}
							decades={decades}
							filters={filters}
							onChange={this.addFilter}
						/>
						{!!displayRecentSearches &&
							this.renderSuggestedSection(
								SEARCH_SUGGESTIONS_COLLECTIONS.RECENT,
								'Recent Searches',
								recentSearches,
								false
							)}
						{existQuery &&
							!!songSuggestions.length &&
							this.renderSuggestedSection(SEARCH_SUGGESTIONS_COLLECTIONS.SONG, 'Songs', songSuggestions)}
						{existQuery &&
							!!artistSuggestions.length &&
							this.renderSuggestedSection(SEARCH_SUGGESTIONS_COLLECTIONS.ARTIST, 'Artists', artistSuggestions)}
						{existQuery &&
							!!albumSuggestions.length &&
							this.renderSuggestedSection(SEARCH_SUGGESTIONS_COLLECTIONS.ALBUM, 'Albums', albumSuggestions)}
						{existQuery &&
							!!playlistSuggestions.length &&
							this.renderSuggestedSection(SEARCH_SUGGESTIONS_COLLECTIONS.PLAYLIST, 'Playlists', playlistSuggestions)}
					</div>
				</div>
			</div>
		);
	}
}

SearchBar.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(onClickOutside(SearchBar));
