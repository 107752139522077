import React from 'react';

import { BRANDS_MAP } from '@content-playlist-creation/common/constants/brands';
import PropTypes from 'prop-types';
import Select from 'react-select';

import * as permissionsApi from '../api/permissions';
import * as playlistsApi from '../api/playlists';
import { ERRORS } from '../constants/messages';
import * as ROLES_NAMES from '../constants/roles';
import { MODES } from '../constants/user-modal-modes';
import { areArraysEqual } from '../utils/arrays';
import { isAdministrator, isContentCreator, hasRole } from '../utils/auth';

class UserModal extends React.Component {
	constructor(props) {
		super(props);

		const { user = {}, mode } = this.props;

		this.state = {
			username: user?.username || '',
			password: '',
			repeatPassword: '',
			email: user?.email || '',
			firstName: user?.first_name || '',
			middleName: user?.middle_name || '',
			lastName: user?.last_name || '',
			mode,
			roles: (user?.roles || []).map(({ name }) => name),
			brandIds: (user?.brand_ids || []).map(userBrandId => this.getBrands().filter(b => b.value === userBrandId)[0]), // [{value, label}]
			errorMsg: '',
			permissions: [],
			assignedPermissions: (user?.permissions || []).map(({ name }) => name),
			brandAsInstructor: null,
		};
		this.formRef = React.createRef();
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.fetchPermissions();
		this.fetchAUserPlaylist();
		document.querySelector('body').addEventListener('keydown', this.keyHandler);
	}

	componentDidUpdate(prevProps, prevState) {
		const { brandIds } = this.state;

		if (!areArraysEqual(prevState.brandIds, brandIds) && brandIds) {
			this.fetchPermissions();
		}
	}

	componentWillUnmount() {
		document.querySelector('body').removeEventListener('keydown', this.keyHandler);
	}

	handleSubmit(e) {
		e.preventDefault();

		const { onSubmit } = this.props;
		const {
			password,
			repeatPassword,
			username,
			email,
			firstName,
			lastName,
			middleName,
			roles,
			brandIds,
			assignedPermissions,
			permissions,
			brandAsInstructor,
		} = this.state;

		this.formRef.current.checkValidity();
		if (!this.formRef.current.reportValidity()) {
			return;
		}

		if (!!password && password !== repeatPassword) {
			this.setState({ errorMsg: ERRORS.PASSWORDS_DO_NOT_MATCH });
			return;
		}

		onSubmit({
			username,
			email,
			firstName,
			middleName,
			lastName,
			password,
			roles,
			brandIds:
				brandAsInstructor && roles.includes(ROLES_NAMES.INSTRUCTOR)
					? [brandAsInstructor]
					: (Array.isArray(brandIds) ? brandIds : [brandIds]).map(brand => brand.value),
			permissions: assignedPermissions.filter(assigned => permissions.map(p => p.name).includes(assigned)),
		});
	}

	keyHandler = e => {
		const { onCancel } = this.props;

		if (e.keyCode === 27) {
			onCancel();
		}
	};

	getBrands = () => {
		const { brands } = this.props;

		return brands.map(b => ({ label: b.description, value: b.brand_id }));
	};

	toggleRole = roleName => {
		const { permissions } = this.state;
		const { user } = this.props;

		let { roles, assignedPermissions } = this.state;

		roles = roles || [];
		roles = hasRole(roles, roleName) ? roles.filter(r => r !== roleName) : roles.concat([roleName]);

		const roleAvailablePermissions = (permissions || [])
			.filter(
				p =>
					!p.enabled_role_names ||
					p.enabled_role_names.split(',').reduce((acc, val) => acc || hasRole(roles, val.trim()), false)
			)
			.map(({ name }) => name);

		assignedPermissions = (assignedPermissions || []).filter(assigned => roleAvailablePermissions.includes(assigned));

		this.fetchAUserPlaylist();
		if (
			(roleName === ROLES_NAMES.ADMINISTRATOR || roleName === ROLES_NAMES.CONTENT_CREATOR) &&
			hasRole(roles, ROLES_NAMES.CONTENT_CREATOR)
		) {
			if (hasRole(roles, ROLES_NAMES.ADMINISTRATOR)) {
				this.setState({ roles, assignedPermissions, brandIds: this.getBrands() });
			} else {
				const brandIds = (user?.brand_ids || []).map(
					userBrandId => this.getBrands().filter(b => b.value === userBrandId)[0]
				);
				this.setState({ roles, assignedPermissions, brandIds });
			}
		} else {
			this.setState({ roles, assignedPermissions });
		}
	};

	togglePermission = permissionName => {
		let { assignedPermissions } = this.state;

		assignedPermissions = assignedPermissions || [];
		assignedPermissions = assignedPermissions.includes(permissionName)
			? assignedPermissions.filter(r => r !== permissionName)
			: assignedPermissions.concat([permissionName]);

		this.setState({ assignedPermissions });
	};

	permissionOnGroupChecked = permission => {
		const { assignedPermissions, permissions } = this.state;

		return permissions.filter(p => assignedPermissions.includes(p.name) && p.group_name === permission.group_name)
			.length;
	};

	isPermissionRequired = permission => {
		return (
			!!permission.group_name &&
			permission.group_name === 'shared-folder' &&
			this.permissionOnGroupChecked(permission) === 0
		);
	};

	handleBrandMultiselect = value => {
		this.setState({ brandAsInstructor: null, brandIds: value });
	};

	fetchAUserPlaylist = async () => {
		const { username } = this.state;

		// Used to reference what brand an instructor belongs to in the case of when a user
		// switches back from a CC+Admin account to a Instructor+Admin account.
		const playlist = await playlistsApi.getUserPlaylists({
			limit: 1, // Any playlist will do.
			includeArchived: false,
			includeSongDetails: false,
			includeThumbnails: false,
			username,
		});

		this.setState({ brandAsInstructor: playlist?.data[0]?.brand_id });
	};

	fetchPermissions() {
		const { brandIds } = this.state;

		permissionsApi.getPermissions(brandIds.length ? brandIds.map(b => b.value) : [brandIds.value]).then(data => {
			this.setState({
				permissions: data || [],
			});
		});
	}

	adminContentCreatorSection() {
		const brands = this.getBrands();
		const {
			brandIds,
			roles,
			assignedPermissions,
			permissions: permissionsInState = [],
			brandAsInstructor,
		} = this.state;
		const permissions = permissionsInState.filter(
			p =>
				!p.enabled_role_names ||
				p.enabled_role_names.split(',').reduce((acc, val) => acc || hasRole(roles, val.trim()), false)
		);

		return (
			<>
				<div className='d-flex align-items-baseline'>
					<label className='user-modal__label w-50'>Brand(s):</label>
					<Select
						className='folder-modal__form__input folder-modal__form__select select-container user-modal__brands'
						classNamePrefix='select'
						value={
							brandAsInstructor && roles.includes(ROLES_NAMES.INSTRUCTOR)
								? [{ value: brandAsInstructor, label: BRANDS_MAP[brandAsInstructor] }]
								: brandIds
						}
						onChange={this.handleBrandMultiselect}
						options={brands.map(b => ({
							value: b.value,
							label: b.label,
						}))}
						isMulti={hasRole(roles, ROLES_NAMES.CONTENT_CREATOR)}
						isDisabled={!isAdministrator()}
					/>
				</div>
				<div className='d-flex align-items-baseline'>
					<label className='user-modal__label w-50'>Roles:</label>
					<div className='user-modal__roles-container w-100'>
						<label>
							<input
								type='checkbox'
								checked={hasRole(roles, ROLES_NAMES.INSTRUCTOR)}
								disabled={!isAdministrator() || hasRole(roles, ROLES_NAMES.CONTENT_CREATOR)}
								onChange={() => {
									this.toggleRole(ROLES_NAMES.INSTRUCTOR);
								}}
								required={!roles || !roles.length}
							/>
							Instructor
						</label>
						<label>
							<input
								type='checkbox'
								checked={hasRole(roles, ROLES_NAMES.CONTENT_CREATOR)}
								disabled={!isAdministrator() || hasRole(roles, ROLES_NAMES.INSTRUCTOR)}
								onChange={() => {
									this.toggleRole(ROLES_NAMES.CONTENT_CREATOR);
									if ((brandIds || []).length > 1) {
										this.setState({ brandIds: [brandIds[0]] });
									}
								}}
								required={!roles || !roles.length}
							/>
							Content Creator
						</label>
						<label className={!isAdministrator() ? 'disabled' : ''}>
							<input
								type='checkbox'
								checked={hasRole(roles, ROLES_NAMES.ADMINISTRATOR)}
								disabled={!isAdministrator()}
								onChange={() => this.toggleRole(ROLES_NAMES.ADMINISTRATOR)}
								required={!roles || !roles.length}
							/>
							Administrator
						</label>
					</div>
				</div>
				{permissions && permissions.length > 0 && (
					<div className='d-flex align-items-baseline'>
						<label className='user-modal__label w-50'>Badges (Permissions):</label>
						<div className='user-modal__permissions-container w-100'>
							{permissions.map(permission => (
								<div key={`${permission.name}${permission.brand_id ? `-${permission.brand_id}` : ''}`}>
									<label>
										<input
											type='checkbox'
											disabled={!isAdministrator()}
											checked={assignedPermissions.includes(permission.name)}
											onChange={() => this.togglePermission(permission.name)}
											required={this.isPermissionRequired(permission)}
										/>
										{permission.display_name}
									</label>
									<p className='text-left'>{permission.description}</p>
								</div>
							))}
						</div>
					</div>
				)}
			</>
		);
	}

	render() {
		const { onCancel } = this.props;
		const { mode, password, repeatPassword, username, email, firstName, middleName, lastName, errorMsg } = this.state;

		const disabledFoInstructorsOnly = !isAdministrator() && !isContentCreator();

		return (
			<div className='user-modal modal d-block' role='dialog'>
				<div className='user-modal__dialog'>
					<form className='user-modal__content modal-content container' ref={this.formRef} onSubmit={this.handleSubmit}>
						<p className='edit-playlist-modal__title'>{mode === MODES.NEW ? 'New Team Member' : 'Edit Team Member'}</p>
						<div className='d-flex align-items-baseline'>
							<label className='user-modal__label w-50'>Username:</label>
							<input
								type='username'
								disabled={mode !== MODES.NEW}
								onChange={e => this.setState({ username: e.target.value })}
								required
								className={`user-modal__input w-100 px-2 ${mode === MODES.EDIT ? 'disabled' : ''}`}
								value={username}
								autoComplete='username'
							/>
						</div>
						<div className='d-flex align-items-baseline'>
							<label className='user-modal__label w-50'>
								{MODES.EDIT ? 'New ' : ''}
								Password:
							</label>
							<input
								type='password'
								required={mode === MODES.NEW}
								className={`user-modal__input w-100 px-2 ${errorMsg ? 'error' : ''}`}
								value={password}
								placeholder={mode === MODES.EDIT ? '****' : ''}
								onChange={e => this.setState({ password: e.target.value })}
								autoComplete='new-password'
							/>
						</div>
						<div className='d-flex align-items-baseline'>
							<label className='user-modal__label w-50'>Confirm{MODES.EDIT ? ' New ' : ''} Password:</label>
							<input
								type='password'
								required={mode === MODES.NEW}
								className={`user-modal__input w-100 px-2 ${errorMsg ? 'error' : ''}`}
								value={repeatPassword}
								placeholder={mode === MODES.EDIT ? '****' : ''}
								onChange={e => this.setState({ repeatPassword: e.target.value })}
								autoComplete='new-password'
							/>
						</div>
						<div className='d-flex align-items-baseline'>
							<label className='user-modal__label w-50'>Email:</label>
							<input
								type='email'
								required
								className='user-modal__input px-2 w-100'
								value={email}
								onChange={e => this.setState({ email: e.target.value })}
								disabled={disabledFoInstructorsOnly}
							/>
						</div>
						<div className='d-flex align-items-baseline'>
							<label className='user-modal__label w-50'>First Name:</label>
							<input
								type='text'
								required
								className='user-modal__input px-2 w-100'
								value={firstName}
								onChange={e => this.setState({ firstName: e.target.value })}
								disabled={disabledFoInstructorsOnly}
							/>
						</div>
						<div className='d-flex align-items-baseline'>
							<label className='user-modal__label w-50'>Middle Name:</label>
							<input
								type='text'
								required
								className='user-modal__input px-2 w-100'
								value={middleName}
								onChange={e => this.setState({ middleName: e.target.value })}
								disabled={disabledFoInstructorsOnly}
							/>
						</div>
						<div className='d-flex align-items-baseline'>
							<label className='user-modal__label w-50'>Last Name:</label>
							<input
								type='text'
								required
								className='user-modal__input px-2 w-100'
								value={lastName}
								onChange={e => this.setState({ lastName: e.target.value })}
								disabled={disabledFoInstructorsOnly}
							/>
						</div>
						{isAdministrator() || isContentCreator() ? this.adminContentCreatorSection() : null}
						<span className='user-modal__error'>{errorMsg}</span>
						<div className='d-flex justify-content-end'>
							<button
								name='UserModal - Cancel'
								type='reset'
								className='user-modal__cancel-btn secondary-btn'
								onClick={e => {
									e.preventDefault();
									onCancel();
								}}
							>
								Cancel
							</button>
							<button
								name='UserModal - Submit'
								type='submit'
								className='user-modal__submit-btn primary-btn'
								onClick={this.handleSubmit}
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

UserModal.propTypes = {
	brands: PropTypes.arrayOf(PropTypes.object).isRequired,
	mode: PropTypes.string,
	onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	user: PropTypes.oneOfType([PropTypes.object]),
};

UserModal.defaultProps = {
	user: {},
	mode: '',
};
export default UserModal;
