import * as qs from 'query-string';

import { apiUrl, getDefaultGetInit, parseStandardResponse } from '../utils/api';

const DASHBOARD_PATH = 'dashboard';

export async function getDashboardNumbers(purged, validOnly) {
	const url = `${apiUrl}/${DASHBOARD_PATH}?${qs.stringify({
		purged,
		validOnly,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getDashboardGenresBreakdown(purged, validOnly) {
	const url = `${apiUrl}/${DASHBOARD_PATH}/breakdown/genres?${qs.stringify({
		purged,
		validOnly,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getDashboardLabelsBreakdown(purged, validOnly) {
	const url = `${apiUrl}/${DASHBOARD_PATH}/breakdown/labels?${qs.stringify({
		purged,
		validOnly,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getDashboardPublishersBreakdown(purged, validOnly) {
	const url = `${apiUrl}/${DASHBOARD_PATH}/breakdown/publishers?${qs.stringify({
		purged,
		validOnly,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}
