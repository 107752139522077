import React from 'react';

import { withRouter } from 'react-router';

import * as authApi from '../api/auth';
import * as brandsApi from '../api/brands';
import * as teamApi from '../api/team';
import AlertModal from '../components/AlertModal';
import { PaginationButtons } from '../components/PaginationButtons';
import TeamGrid from '../components/TeamGrid';
import UserModal from '../components/UserModal';
import { ADMINISTRATOR } from '../constants/roles';
import { TOAST_LEVEL } from '../constants/toast-levels';
import { MODES } from '../constants/user-modal-modes';
import GlobalContext from '../contexts/GlobalContext';
import arrowDownImg from '../images/arrow-down.svg';
import { isAdministrator, setUser, getUsername, hasRole } from '../utils/auth';

const DEFAULT_LIMIT = 100;
const DEFAULT_PAGE = 1;
const DEFAULT_SORT_ORDER = 'DESC';

class Team extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			users: {
				count: 0,
				data: [],
			},
			brands: [],
			sortOrder: DEFAULT_SORT_ORDER,
			showUserModal: false,
			user: null,
			showRemoveModal: false,
			removalUser: null,
			pageSize: DEFAULT_LIMIT,
			pageNum: DEFAULT_PAGE,
		};

		this.fetchUsers = this.fetchUsers.bind(this);
		this.fetchBrands = this.fetchBrands.bind(this);
		this.toggleSortOrder = this.toggleSortOrder.bind(this);
		this.handleEditClick = this.handleEditClick.bind(this);
		this.handleModalSubmit = this.handleModalSubmit.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
	}

	componentDidMount() {
		this.fetchUsers(DEFAULT_LIMIT, DEFAULT_PAGE, DEFAULT_SORT_ORDER);
		this.fetchBrands();
	}

	handleEditClick(user) {
		this.setState({
			user,
			mode: MODES.EDIT,
			showUserModal: true,
		});
	}

	async handleModalSubmit(user) {
		const { mode, sortOrder, pageSize } = this.state;
		const { createToast, getSearchPlaylists, getSearchFolders } = this.context;

		this.setState({
			showUserModal: false,
		});

		if (mode === MODES.NEW) {
			authApi
				.registerUser(user)
				.then(() => {
					createToast('Successfully created user!', TOAST_LEVEL.SUCCESS, true);
					this.fetchUsers(pageSize, DEFAULT_PAGE, sortOrder);
				})
				.catch(err => {
					createToast(`Failed to create user, please try again later. ${err.message || ''}`, TOAST_LEVEL.ERROR, true);
				});
		} else {
			authApi
				.editUser(user)
				.then(async () => {
					// modifying it self, update the user session data
					if (user.username === getUsername()) {
						setUser({
							email: user.email,
							first_name: user.firstName,
							last_name: user.lastName,
							middle_name: user.middleName,
							roles: user.roles,
							permissions: user.permissions,
							username: user.username,
							brand_ids: user.brandIds,
							shared_folders_only: user.shared_folders_only,
						});
						await getSearchPlaylists(true);
						await getSearchFolders(true);
					}
					createToast('Successfully edited user!', TOAST_LEVEL.SUCCESS, true);
					this.fetchUsers(DEFAULT_LIMIT, DEFAULT_PAGE, sortOrder);
				})
				.catch(err => {
					createToast(`There was an error while updating the user. ${err.message || ''}`, TOAST_LEVEL.ERROR, true);
				});
		}
	}

	async handleRemove() {
		const { removalUser, sortOrder } = this.state;
		const { createToast } = this.context;

		if (removalUser && hasRole(removalUser.roles, ADMINISTRATOR)) {
			createToast(
				'There was an error while deleting the user. Administrator user cannot be deleted by other administrators',
				TOAST_LEVEL.ERROR,
				true
			);
		} else {
			teamApi
				.deleteUser(removalUser.username)
				.then(() => {
					this.fetchUsers(DEFAULT_LIMIT, DEFAULT_PAGE, sortOrder);
				})
				.catch(() => {
					createToast('There was an error while deleting the user. Please try again later', TOAST_LEVEL.ERROR, true);
				});
		}
		this.setState({
			removalUser: null,
			showRemoveModal: false,
		});
	}

	getRemovalUserDescription() {
		const { removalUser } = this.state;

		if (!removalUser) {
			return '';
		}

		if (removalUser.first_name && removalUser.last_name) {
			return `${removalUser.first_name} ${removalUser.last_name}`;
		}

		return removalUser.username;
	}

	handlePageChange = nextPage => {
		const { sortOrder, pageSize } = this.state;

		this.fetchUsers(pageSize, nextPage, sortOrder);
	};

	async fetchUsers(limit, page, sortOrder) {
		const users = await teamApi.getTeam(limit, page, sortOrder);

		this.setState({ users, pageNum: page, sortOrder });
	}

	async fetchBrands() {
		const brands = await brandsApi.getBrands();

		this.setState({ brands });
	}

	toggleSortOrder() {
		const { sortOrder } = this.state;
		const newSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
		this.fetchUsers(DEFAULT_LIMIT, DEFAULT_PAGE, newSortOrder);
	}

	render() {
		const { showRemoveModal, showUserModal, mode, user, brands, users, sortOrder, pageNum, pageSize } = this.state;
		const total = users ? users.count : 0;
		const numPages = Math.ceil(total / pageSize);

		return (
			<div className='team'>
				<AlertModal
					title='Confirm Removal'
					description={`Are you sure you want to remove user ${this.getRemovalUserDescription()}?`}
					showModal={showRemoveModal}
					onConfirmClick={() => this.handleRemove()}
					onCancelClick={() => this.setState({ showRemoveModal: false })}
				/>
				{showUserModal ? (
					<UserModal
						mode={mode}
						user={user}
						brands={brands}
						onCancel={() => this.setState({ showUserModal: false })}
						onSubmit={user => this.handleModalSubmit(user)}
					/>
				) : null}
				<h3 className='h3'>Your Team</h3>
				<p className='team__staff-data'>{users.count} Staff members</p>
				{isAdministrator() ? (
					<button
						name='Team - Create New'
						className='primary-btn'
						type='button'
						onClick={() =>
							this.setState({
								showUserModal: true,
								mode: MODES.NEW,
								user: null,
							})
						}
					>
						Create New
					</button>
				) : null}
				<div className='team__filters'>
					<div className='team__sort-control' onClick={() => this.toggleSortOrder()}>
						<span>Recently Added &nbsp;</span>
						<img className={sortOrder === 'ASC' ? 'inverse' : ''} src={arrowDownImg} alt='Toggle team order' />
					</div>
				</div>
				<div className='team__grid'>
					<TeamGrid
						users={users.data || []}
						onEditClick={user => this.handleEditClick(user)}
						onRemoveClick={user =>
							this.setState({
								removalUser: user,
								showRemoveModal: true,
							})
						}
						brands={brands}
					/>
					<div className='d-flex align-items-center mt-5'>
						<PaginationButtons handlePageChange={this.handlePageChange} numPages={numPages} pageNum={pageNum || 0} />
					</div>
				</div>
			</div>
		);
	}
}

Team.contextType = GlobalContext;

export default withRouter(Team);
