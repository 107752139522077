import React, { useState, useEffect, useContext } from 'react';

import { PLAYLIST_STATUS } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as browseApi from '../../api/browse';
import * as playlistsApi from '../../api/playlists';
import * as sharedFoldersApi from '../../api/shared-folders';
import SongTable from '../../components/song/SongTable';
import {
	BROWSE_SONG_TABLE_SORTERS,
	COLLECTIONS,
	BROWSE_SONG_TABLE_CUSTOM_COLUMNS,
} from '../../constants/browse-catalog';
import * as PERMISSIONS from '../../constants/permissions';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as ArrowBack } from '../../images/arrow-back.svg';
import { isInstructor, isSharedFolderUser, hasPermission } from '../../utils/auth';
import { formatArtistName } from '../../utils/songs';

const PAGE_SIZE = 40;

const getSortersForCollection = collection => {
	let sorters = BROWSE_SONG_TABLE_SORTERS.default;

	switch (collection) {
		case COLLECTIONS.bpms:
			sorters = sorters.concat(BROWSE_SONG_TABLE_SORTERS.bpms);
			break;
		case COLLECTIONS.genres:
			sorters = sorters.concat(BROWSE_SONG_TABLE_SORTERS.genres);
			break;
		case COLLECTIONS.artists:
			sorters = sorters.concat(BROWSE_SONG_TABLE_SORTERS.artists);
			break;
		case COLLECTIONS.decades:
			sorters = sorters.concat(BROWSE_SONG_TABLE_SORTERS.decades);
			break;
		default:
			break;
	}

	return sorters;
};

const BrowseResults = ({ history, match }) => {
	const { term, collection: collectionKey } = match.params;
	const sorters = getSortersForCollection(collectionKey);
	const [playlists, setPlaylists] = useState([]);
	const [folders, setFolders] = useState([]);
	const [sortFilter, setSortFilter] = useState(sorters.length ? sorters.find(s => s.default).value : null);
	const [songsLoaded, setSongsLoaded] = useState(true);
	const [songs, setSongs] = useState([]);
	const [total, setTotal] = useState(0);
	const [pageNum, setPageNum] = useState(1);
	const { createToast, playlistInSearchContext, openArtistBlacklistModal } = useContext(GlobalContext);

	const handleBack = () => {
		history.goBack();
	};

	const fetchSongs = async () => {
		setSongsLoaded(false);
		const songsResponse = await browseApi.getSongsByCollection(collectionKey, term, PAGE_SIZE, pageNum, sortFilter);

		setSongs(songsResponse.data || []);
		setTotal(songsResponse.total || 0);
		setSongsLoaded(true);
	};

	const fetchPlaylists = async () => {
		setPlaylists(
			isInstructor()
				? (await playlistsApi.getUserPlaylists({ playlistStatusIds: [PLAYLIST_STATUS.DRAFT] })).data
				: (
						await playlistsApi.getPlaylists({
							status: [PLAYLIST_STATUS.SUBMITTED, PLAYLIST_STATUS.REJECTED],
							limit: 25,
							page: 1,
							sortBy: 'updated-date',
							sortOrder: 'DESC',
							playlistIds: playlistInSearchContext ? [playlistInSearchContext.playlist_id] : [],
							playlistTypeFilter: -1, // all types
						})
				  ).data
		);
	};

	const fetchFolders = async () => {
		if (isSharedFolderUser() && hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR])) {
			const result = await sharedFoldersApi.getFolders(null, null, false);

			setFolders(result.data || []);
		}
	};

	useEffect(() => {
		const loadContextCollections = async () => {
			await Promise.all([fetchPlaylists(), fetchFolders()]).catch(() => {
				createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
			});
		};

		loadContextCollections();
	}, [playlistInSearchContext]);

	// track page num change
	useEffect(() => {
		fetchSongs();
	}, [pageNum, sortFilter]);

	const onSorterChange = value => {
		setPageNum(1);
		setSortFilter(value);
	};

	const onPageChange = page => {
		setPageNum(page);
	};

	const handleBlacklistSong = () => {
		setPageNum(1);
		fetchSongs();
	};

	const handleBlacklistArtist = () => {
		openArtistBlacklistModal(decodeURIComponent(term), () => {
			history.goBack();
		});
	};

	const onBookmarkSong = songId => {
		setSongs(
			songs.map(s => ({
				...s,
				...(s.song_id === songId && { ...s, is_favorite: !s.is_favorite }),
			}))
		);
	};

	return (
		<div className='browse browse-detail'>
			<div className='browse__back mb-3' onClick={handleBack}>
				<ArrowBack />
				Back
			</div>
			<h3 className='mb-5'>
				{COLLECTIONS.artists === collectionKey ? formatArtistName(decodeURIComponent(term)) : decodeURIComponent(term)}
			</h3>
			<div className='browse-detail'>
				<SongTable
					title='All Songs'
					playlists={playlists}
					folders={folders}
					songs={songs}
					sorters={sorters}
					tableKey='browse-results-table'
					navigateToNewPage
					loaded={songsLoaded}
					total={total}
					sortFilter={sortFilter}
					pageNum={pageNum}
					collectionKey={collectionKey}
					handleBlacklistSongISRC={handleBlacklistSong}
					handleBlacklistSongVariisID={handleBlacklistSong}
					onSorterChange={onSorterChange}
					onPageChange={onPageChange}
					handleBlacklistArtist={handleBlacklistArtist}
					pageSize={PAGE_SIZE}
					onBookmarkSong={onBookmarkSong}
					customColumns={BROWSE_SONG_TABLE_CUSTOM_COLUMNS.filter(c => {
						// display 'cleared_timestamp' (AQUIRED DATE) only when sorting by 'cleared_timestamp'
						return c.songProperty !== 'cleared_timestamp' || sortFilter === 'cleared_timestamp';
					})}
				/>
			</div>
		</div>
	);
};

BrowseResults.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(BrowseResults);
