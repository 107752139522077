import React, { useContext } from 'react';

import { PLAYLIST_STATUS } from '@content-playlist-creation/common/constants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Switch from 'react-switch';

import GlobalContext from '../../contexts/GlobalContext';
import { getAdjustedDuration, getTotalDuration } from '../../utils/playlist';
import Badge from '../Badge';

import PlaylistCard from './PlaylistCard';

const PlaylistCards = ({
	playlists,
	limit,
	header,
	history,
	filterControls = [],
	filters = [],
	resetFilters,
	removeFilter,
	toggleArchivedFolder,
	includeArchived,
	seeAllRedirectPath,
}) => {
	const { zoneKeys } = useContext(GlobalContext);

	if (limit) {
		// eslint-disable-next-line no-param-reassign
		playlists = playlists.slice(0, limit);
	}

	const renderFilters = () => {
		return (
			<div className='song-table__controls'>
				<>
					<div
						className={classNames('song-table__controls__row d-flex', {
							'justify-content-end': !filterControls.length,
							'justify-content-between': filterControls.length,
						})}
					>
						{filterControls.length ? (
							<div className='song-table__filter'>
								<span>Filter By: </span>
								{filterControls.map((control, index) => (
									<div key={`${index}`}>{control}</div> // eslint-disable-line react/no-array-index-key
								))}
							</div>
						) : null}
					</div>

					{filters.length ? (
						<div className='song-table__controls__row'>
							<div className='song-table__filter  song-table__filter--wrappable'>
								<span>Your Selection: </span>
								{filters.map((filter, index) => (
									<Badge
										key={`${filter}_${index}`} // eslint-disable-line react/no-array-index-key
										className='song-table__filter__badge'
										text={filter}
										onClick={() => removeFilter(index)}
										closeCallback={() => removeFilter(index)}
									/>
								))}
								<span className='song-table__controls__action' onClick={() => resetFilters()}>
									Clear All
								</span>
							</div>
						</div>
					) : null}
				</>
			</div>
		);
	};

	return (
		<div className='w-100'>
			{header && header !== '' ? (
				<div className='playlists__header'>
					<span>{header}</span>
					<span
						className='playlists__header__see-all'
						onClick={() => history.push(seeAllRedirectPath || '/my-playlists')}
					>
						See All
					</span>
				</div>
			) : null}
			<div className='mt-3 d-flex justify-content-between'>
				{filterControls.length && renderFilters()}

				<label className='p-2 mr-4 d-flex'>
					<span className='playlist__switch__label'>Show Archived</span>
					<Switch
						onChange={toggleArchivedFolder}
						checked={includeArchived}
						checkedIcon={false}
						uncheckedIcon={false}
						height={20}
						width={40}
						className='mt-1'
						onColor='#000'
						offColor='#d2d2d2'
					/>
				</label>
			</div>
			<div className='playlists__list'>
				{playlists
					.filter(p => includeArchived || p.playlist_status_id !== PLAYLIST_STATUS.ARCHIVED)
					.map(p => {
						const playlistBrandId = p.brand_id;
						const playlistZoneKeys = zoneKeys.filter(zone => !playlistBrandId || zone.brand_id === playlistBrandId);

						return (
							<PlaylistCard
								statusId={p.playlist_status_id}
								title={p.name}
								playlistId={p.playlist_id}
								coverImages={p.cover_thumbnail_images || p.cover_images}
								flagged={p.playlist_status_id === PLAYLIST_STATUS.INVALID}
								key={p.playlist_id}
								numSongs={p.num_songs}
								totalDuration={getTotalDuration(p.songs)}
								adjustedDuration={getAdjustedDuration(p.songs, playlistZoneKeys)}
								notes={p.notes}
							/>
						);
					})}
			</div>
		</div>
	);
};

PlaylistCards.propTypes = {
	header: PropTypes.string,
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	filterControls: PropTypes.arrayOf(PropTypes.object).isRequired,
	filters: PropTypes.arrayOf(PropTypes.string).isRequired,
	resetFilters: PropTypes.func.isRequired,
	removeFilter: PropTypes.func.isRequired,
	includeArchived: PropTypes.bool,
	limit: PropTypes.number,
	playlists: PropTypes.arrayOf(PropTypes.object).isRequired,
	seeAllRedirectPath: PropTypes.string,
	toggleArchivedFolder: PropTypes.func,
};

PlaylistCards.defaultProps = {
	header: '',
	seeAllRedirectPath: '',
	limit: 0,
	includeArchived: false,
	toggleArchivedFolder: null,
};

export default withRouter(PlaylistCards);
