module.exports = {
	SUBPAGES_LABELS: {
		genres: 'Genres',
		bpms: 'BPMs',
		artists: 'Artists',
		decades: 'Decades',
	},
	COLLECTIONS: {
		genres: 'genres',
		bpms: 'bpms',
		artists: 'artists',
		decades: 'decades',
	},
	COLLECTIONS_LABELS: {
		genres: 'Genres',
		bpms: 'BPMs',
		artists: 'All Artists',
		decades: 'Decade',
	},
	BROWSE_SONG_TABLE_SORTERS: {
		genres: [{ value: 'bpm', label: 'BPM' }],
		bpms: [{ value: 'genre', label: 'Genre' }],
		artists: [
			{ value: 'genre', label: 'Genre' },
			{ value: 'bpm', label: 'BPM' },
		],
		decades: [
			{ value: 'genre', label: 'Genre' },
			{ value: 'bpm', label: 'BPM' },
		],
		default: [
			{ value: 'artist', label: 'Artist' },
			{ value: 'name', label: 'Song' },
			{ value: 'album', label: 'Album' },
			{ value: 'cleared_timestamp', label: 'Acquired Date', default: true },
			{ value: 'track_source', label: 'Track Source' },
		],
	},
	BROWSE_SONG_TABLE_CUSTOM_COLUMNS: [
		{
			columnHeader: 'TRACK SOURCE',
			fixedWidth: '15%',
			songProperty: 'track_source',
		},
		{
			columnHeader: 'ACQUIRED DATE',
			headerClassName: 'text-center',
			fixedWidth: '15%',
			songProperty: 'cleared_timestamp',
			cellContentClassName: 'text-center',
		},
	],
};
