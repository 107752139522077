import { apiUrl, getDefaultGetInit, parseStandardResponse } from '../utils/api';

const TRANSITIONS_PATH = 'transitions';

export async function getTransitions() {
	const url = `${apiUrl}/${TRANSITIONS_PATH}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}
