import React from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { getRoles, getUser, hasRole, isAny, hasPermission } from '../utils/auth';

const rolesAllowed = roles => {
	let allowed = false;

	if (roles && roles.length) {
		const userRoles = getRoles();

		for (let i = 0; i < roles.length; i++) {
			allowed = hasRole(userRoles, roles[i]);
			if (allowed) {
				break;
			}
		}
	} else {
		allowed = isAny();
	}

	return allowed;
};

const permissionsAllowed = permissions => {
	let allowed = true;

	if (permissions && permissions.length) {
		allowed = hasPermission(permissions, true);
	}

	return allowed;
};

const ProtectedRoute = ({ component: Component, roles, permissions, restricted, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			let allowed = rolesAllowed(roles);

			allowed = allowed && permissionsAllowed(permissions);

			allowed = restricted ? false : allowed;

			return allowed === true ? (
				<Component {...props} {...rest} />
			) : (
				<Redirect
					to={{
						pathname: getUser() ? '/' : '/login',
						state: { from: props.location },
					}}
				/>
			);
		}}
	/>
);

ProtectedRoute.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	component: PropTypes.any.isRequired,
	location: PropTypes.objectOf(PropTypes.any),
	roles: PropTypes.arrayOf(PropTypes.string),
};

ProtectedRoute.defaultProps = {
	location: null,
	roles: null,
};

export default withRouter(ProtectedRoute);
