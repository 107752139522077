import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { formatBPM, formatSongDuration } from '../../utils/songs';

const SongResult = ({ name, artist, image, bpm, duration, redirectTo, explicit }) => {
	const ConditionalLink = redirectTo ? Link : React.Fragment;
	const linkProps = redirectTo ? { to: redirectTo } : {};

	return (
		<div className='song-result'>
			<ConditionalLink {...linkProps}>
				<div className='song-result__metadata'>
					{image ? (
						<div
							style={{
								backgroundImage: `url(${image})`,
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
							}}
							className='song-result__metadata__image--img'
							alt='song result image'
						/>
					) : (
						<div className='song-result__metadata__image--default' />
					)}
					<div className='song-result__metadata__song'>
						<div className='song-result__metadata__song--name'>{name}</div>
						<div className='song-result__metadata__song--secondary'>{artist}</div>
						<div className='song-result__metadata__song--secondary'>
							{[formatBPM(bpm), formatSongDuration(duration)].filter(s => !!s).join(' - ')}
						</div>
						{explicit && <span className='song-result__metadata__song__explicit'>EXPLICIT</span>}
					</div>
				</div>
			</ConditionalLink>
		</div>
	);
};

SongResult.propTypes = {
	artist: PropTypes.string.isRequired,
	bpm: PropTypes.number,
	explicit: PropTypes.bool,
	image: PropTypes.string,
	length: PropTypes.string,
	name: PropTypes.string.isRequired,
};

SongResult.defaultProps = {
	bpm: '',
	length: '',
	image: '',
	explicit: false,
};

export default SongResult;
