import React, { useState, useEffect, useContext } from 'react';

import { PLAYLIST_STATUS, PLAYLIST_TYPE_MAP } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';

import * as songsApi from '../../api/songs';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import AlertModal from '../AlertModal';

const getInstructorSongPlaylistUsages = isrcPlaylistUsages =>
	isrcPlaylistUsages.map((playlist, idx) => {
		const url = `/playlist/${playlist.playlist_id}`;
		const canClick = playlist.playlist_status_id !== PLAYLIST_STATUS.DRAFT; // CCs and Admins cannot visit DRAFT playlists, server does not return ARCHIVED
		const navigateToPlaylist = () => {
			if (canClick) {
				window.location = url;
			}
		};

		return (
			<li key={idx}>
				<span
					onClick={navigateToPlaylist}
					className={`${canClick ? 'alert-modal__html-reasons--link' : ''} float-left`}
				>
					{playlist['Playlist Name']}
				</span>
				{playlist.playlist_type_id && <span>&nbsp;{`(${PLAYLIST_TYPE_MAP[playlist.playlist_type_id]})`}</span>}
				<span className='float-right'>{`${playlist.status} (${playlist['Playlist Song Count'] || 1})`}</span>
			</li>
		);
	});

const InstructorPlaylistModal = ({ onConfirmClick, showModal }) => {
	const [playlistUsage, setPlaylistUsages] = useState([]);
	const { createToast, isrcToPlayCount: isrc } = useContext(GlobalContext);

	useEffect(() => {
		const fetchPlaylistUsageBySong = async () => {
			if (isrc) {
				songsApi
					.getSongUsageCountByISRCAndUser(isrc)
					.then(res => {
						setPlaylistUsages(res ? res.data || [] : []);
					})
					.catch(() => {
						createToast(
							'There was an error retrieving playlist usage. Please refresh the page and try again later',
							TOAST_LEVEL.ERROR,
							true
						);
					});
			}
		};

		fetchPlaylistUsageBySong();
	}, [isrc, createToast]);

	if (!showModal) {
		return null;
	}

	return (
		<AlertModal
			title='Play Count'
			description={
				playlistUsage && playlistUsage.length > 0
					? `ISRC ${isrc} appears in your playlist(s) here with these usage counts.`
					: `ISRC ${isrc} is not in any of your playlists.`
			}
			showModal={showModal}
			onConfirmClick={onConfirmClick}
			htmlReasons={getInstructorSongPlaylistUsages(playlistUsage)}
		/>
	);
};

InstructorPlaylistModal.propTypes = {
	onConfirmClick: PropTypes.func.isRequired,
	showModal: PropTypes.bool,
};

InstructorPlaylistModal.defaultProps = {
	showModal: true,
};
export default InstructorPlaylistModal;
