import React from 'react';

import { Link } from 'react-router-dom';

import BACKGROUND_POSITION_BY_INDEX from '../../constants/playlist-thumbnail-positions';

const buildImageThumbnail = playlistThumbnails => {
	return (
		<div
			className='collection__card__image'
			style={{
				backgroundImage: `${
					Array.isArray(playlistThumbnails)
						? playlistThumbnails.map(img => `url(${img})`).join(',')
						: `url(${playlistThumbnails}) `
				}`,
				backgroundPosition: `${
					Array.isArray(playlistThumbnails) && playlistThumbnails.length === 4
						? playlistThumbnails.map((_, i) => `${BACKGROUND_POSITION_BY_INDEX[i]}`).join(',')
						: ''
				}`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: `${
					Array.isArray(playlistThumbnails) && playlistThumbnails.length === 4
						? '50% 50%, 50% 50%, 50% 50%, 50% 50%'
						: 'cover'
				}`,
			}}
		/>
	);
};

const getCollectionCardClassName = ({ redirectTo, inPlaylistRail, isCarouselItem, className = '' }) => {
	let result = `${className} collection__card `;

	// whole Card would have a grey background and cursor:pointer onHover if not a Carousel Item and there is a link provided
	if (!isCarouselItem && redirectTo) {
		result = result.concat('collection__card__clickable ');
		if (inPlaylistRail) {
			result = result.concat('collection__card__clickable--black ');
		} else {
			result = result.concat('collection__card__clickable--white ');
		}
	}
	if (!isCarouselItem) {
		result = result.concat('d-flex');
	} else {
		result = result.concat('collection__card--carousel');
	}
	return result;
};

const renderCollectionImage = (isCarouselItem, image, playlistThumbnails, redirectTo) => {
	// render smaller image for cards that are not in Carousel
	if (!isCarouselItem) {
		// if an image was provided, build the playlistThumbnails collage using the image(s)
		if (image) {
			return buildImageThumbnail(playlistThumbnails);
		}

		// no image provided: use default image
		return <div className='collection__card__image collection__card__image--default' />;
	}

	// render Carousel Item
	if (isCarouselItem) {
		if (image) {
			return (
				<div
					className={`collection__card__image__carousel ${
						redirectTo ? 'collection__card__image__carousel__clickable' : ''
					}`}
					style={{ background: `url(${image}) no-repeat`, backgroundSize: 'cover' }}
				/>
			);
		}

		return <div className='collection__card__image__carousel collection__card__image--default' />;
	}

	return null;
};

const getMetadataClassName = (isCarouselItem, inPlaylistRail, redirectTo) => {
	let className = 'collection__card__metadata ';

	// if not a carousel item, add margin-left
	if (!isCarouselItem) {
		className = className.concat('ml-3 ');
	}

	// metadata should use different font colors depending if the Card is inside the PlaylistRail
	if (inPlaylistRail) {
		className = className.concat('collection__card__metadata--white ');
	} else {
		className = className.concat('collection__card__metadata--black ');
	}

	// if carousel item and has a link, show cursor:pointer and onhover state
	if (isCarouselItem && redirectTo) {
		className = className.concat('collection__card__metadata__clickable ');
	}

	return className;
};

export const CollectionCardStatus = ({ status, className = '', darkMode = false }) => {
	return (
		<>
			{status && (
				<div
					className={`collection__card__metadata__status ${className} ${
						darkMode ? 'collection__card__metadata__status--white' : 'collection__card__metadata__status--black'
					}`}
				>
					{status.toUpperCase()}
				</div>
			)}
		</>
	);
};

const CollectionCard = (
	{ className, title, subtitle, image, isCarouselItem, redirectTo, status, inPlaylistRail },
	ref
) => {
	const ConditionalLink = redirectTo ? Link : React.Fragment;
	const linkProps = redirectTo ? { to: redirectTo } : {};

	let playlistThumbnails = (image || '').split(',').filter(i => !!i);

	playlistThumbnails =
		!!playlistThumbnails.length && playlistThumbnails.length === 4 ? playlistThumbnails : playlistThumbnails[0];

	return (
		<div ref={ref} className={getCollectionCardClassName({ redirectTo, inPlaylistRail, isCarouselItem, className })}>
			<ConditionalLink {...linkProps}>
				<div className={`${isCarouselItem ? 'w-100' : ''}`}>
					{renderCollectionImage(isCarouselItem, image, playlistThumbnails, redirectTo)}
				</div>
				<div className={getMetadataClassName(isCarouselItem, inPlaylistRail, redirectTo)}>
					<div>{title}</div>
					<div className={`${isCarouselItem ? 'collection__card__metadata__subtitle' : ''}`}>{subtitle}</div>
					<CollectionCardStatus status={status} darkMode={inPlaylistRail} />
				</div>
			</ConditionalLink>
		</div>
	);
};

export default React.forwardRef(CollectionCard);
