import React, { useState, useEffect, useContext } from 'react';

import debounce from 'lodash/debounce';

import { getAllGenres, getBPMRanges } from '../api/browse';
import { getLabelMusicFeeds } from '../api/metadata-feeds';
import * as songsApi from '../api/songs';
import * as userSongsApi from '../api/user-songs';
import DropdownButton from '../components/DropdownButton';
import { NotesPanel } from '../components/NotesPanel';
import SongTable from '../components/song/SongTable';
import { MY_SONGS_SORT_OPTIONS } from '../constants/sort-options';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';

const PAGE_SIZE = 40;

const MY_SONGS_TABLE_CUSTOM_COLUMNS = [
	{
		columnHeader: 'ZONE KEY',
		songProperty: 'zone_key_input',
		cellContentClassName: 'd-flex',
	},
	{
		columnHeader: 'NOTES',
		songProperty: 'notes_input',
		cellContentClassName: 'd-flex',
		cellClassName: 'enabled-always',
	},
	{
		columnHeader: 'TRACK SOURCE',
		fixedWidth: '15%',
		songProperty: 'track_source',
		cellContentClassName: 'text-center',
	},
	{
		columnHeader: 'DATE ADDED',
		headerClassName: 'text-center',
		songProperty: 'added_timestamp',
		cellContentClassName: 'text-center',
	},
];

const MySongs = React.memo(() => {
	const sorters = MY_SONGS_SORT_OPTIONS;
	const [playlists, setPlaylists] = useState([]);
	const [folders, setFolders] = useState([]);
	const [genres, setGenres] = useState([]);
	const [musicFeeds, setMusicFeeds] = useState([]);
	const [musicFeedFilters, setMusicFeedFilters] = useState([]);
	const [bpmRanges, setBpmRanges] = useState([]);
	const [sorter, setSorter] = useState(sorters.length ? sorters.find(s => s.default).value : null);
	const [songsData, setSongsData] = useState({ songs: [], total: 0 });
	const [pageNum, setPageNum] = useState(1);
	const [formattedSongs, setFormattedSongs] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [filters, setFilters] = useState([]);
	const [termQuery, setTermQuery] = useState('');
	const debounceTermQuerySet = debounce(setTermQuery, 500);
	const { createToast, getSearchPlaylists, getSearchFolders, searchPlaylists, searchFolders, zoneKeys } =
		useContext(GlobalContext);
	const artists = (songsData || {}).artists || [];

	const fetchCollections = async () => {
		try {
			const [playlists, folders] = await Promise.all([getSearchPlaylists(), getSearchFolders()]);
			setPlaylists(playlists);
			setFolders(folders);
		} catch (_) {
			createToast('There was an error getting information, please refresh', TOAST_LEVEL.ERROR, true);
		}
	};

	const fetchFilterCollections = async () => {
		try {
			const [genres, bpmRanges, musicLabels] = await Promise.all([
				getAllGenres(50000, 1),
				getBPMRanges(),
				getLabelMusicFeeds(),
			]);
			setGenres(genres.data.map(({ key }) => key));
			setBpmRanges(bpmRanges.data.map(({ key }) => key));
			setMusicFeeds(musicLabels);
		} catch (_) {
			createToast('There was an error getting information, please refresh', TOAST_LEVEL.ERROR, true);
		}
	};

	const fetchSongs = async () => {
		setLoaded(false);
		const bpmsFilters = filters.filter(f => bpmRanges.includes(f));
		const genresFilters = filters.filter(f => genres.includes(f));
		const artistsFilters = filters.filter(f => artists.includes(f));

		const songsResponse = await songsApi.getUserSongs({
			limit: PAGE_SIZE,
			page: pageNum,
			bpms: bpmsFilters,
			genres: genresFilters,
			artists: artistsFilters,
			musicFeedIDs: musicFeedFilters,
			sorter,
			sortOrder: MY_SONGS_SORT_OPTIONS.find(s => s.value === sorter).order,
			query: termQuery,
		});
		setSongsData({
			songs: songsResponse.data,
			total: songsResponse.count || 0,
			artists: songsResponse.artists,
		});
	};

	const handleUserSongUpdate = async ({ userSongId, zoneKeyId, songNotes }) => {
		await userSongsApi.updateUserSongs(userSongId, zoneKeyId, songNotes);
	};

	const formatMySongsRows = (songs, zoneKeys) => {
		const zoneKeysOptions = (zoneKeys || []).map(z => ({ label: z.name, value: z.zone_key_id }));
		zoneKeysOptions.unshift({ label: 'None', value: null });

		return (songs || []).map(song => {
			const songZoneKey = zoneKeys.find(z => z.zone_key_id === song.zone_key_id) || {};
			// Match custom columns on constants
			const zone_key_input = (
				<DropdownButton
					disabled={!song.valid || song.is_blacklisted}
					onChange={e => handleUserSongUpdate({ userSongId: song.user_song_id, zoneKeyId: e.value })}
					options={[...zoneKeysOptions]}
					showArrowIcon
					showFilterIcon={false}
					value={songZoneKey.name || 'None'}
					alignCenter
					menuClassName='my-songs__song__dropdown__menu'
				/>
			);

			const notes_input = (
				<NotesPanel
					note={song.notes}
					onNoteUpdate={newNote => handleUserSongUpdate({ userSongId: song.user_song_id, songNotes: newNote })}
					tooltipHeader='Songs Notes'
					tooltipDetail={song.name}
					expandableHeight
				/>
			);

			return {
				...song,
				key: song.user_song_id,
				zone_key_input,
				notes_input,
				invalidated_song: !song.valid || song.is_blacklisted,
			};
		});
	};

	// track page num change
	useEffect(() => {
		fetchSongs();
	}, [pageNum]);

	// track filter, sorter, termQuery change
	useEffect(() => {
		if (pageNum === 1) {
			fetchSongs();
		} else {
			setPageNum(1);
		}
	}, [sorter, filters, termQuery]);

	// listen for new folders or playlists
	useEffect(() => {
		fetchCollections();
	}, [searchPlaylists, searchFolders]);

	useEffect(() => {
		fetchFilterCollections();
	}, []);

	useEffect(() => {
		if (zoneKeys.length) {
			setFormattedSongs([...formatMySongsRows(songsData.songs || [], zoneKeys)]);
			setLoaded(true);
		}
	}, [zoneKeys, songsData.songs]);

	const onSorterChange = async value => {
		setPageNum(1);
		setSorter(value);
	};

	const onPageChange = async page => {
		setPageNum(page);
	};

	const onBookmarkSong = songId => {
		const { songs } = songsData;
		setSongsData({
			...songsData,
			songs: songs.filter(s => s.song_id !== songId),
		});
	};

	const handleSearchQuery = e => {
		debounceTermQuerySet(e.target.value);
	};

	return (
		<div className='my-songs'>
			<SongTable
				title='My Songs'
				playlists={playlists}
				folders={folders}
				songs={formattedSongs}
				sorters={MY_SONGS_SORT_OPTIONS}
				tableKey='my-songs-table'
				navigateToNewPage
				loaded={loaded}
				total={songsData.total}
				sorter={sorter}
				pageNum={pageNum}
				collectionKey='MY_SONGS'
				onSorterChange={onSorterChange}
				onPageChange={onPageChange}
				pageSize={PAGE_SIZE}
				handleBlacklistSongISRC={async () => {
					await fetchSongs();
				}}
				handleBlacklistSongVariisID={async () => {
					await fetchSongs();
				}}
				onBookmarkSong={onBookmarkSong}
				customColumns={MY_SONGS_TABLE_CUSTOM_COLUMNS}
				widthOverwrites={['6%', '22%', '8%', '5%', '8%', '10%', '17%', '10%', '10%', '5%']}
				showUnavailable
				filters={filters}
				filterControls={[
					<div key='bpmFilter' className='song-table__filter__dropdown'>
						<DropdownButton
							options={[...bpmRanges].filter(f => !filters.includes(f)).map(f => ({ label: f, value: f }))}
							onChange={f => setFilters([...filters, f.value])}
							placeholder='BPM'
							value='BPM'
							showArrowIcon
							menuClassName='song-table__filter__dropdown__menu'
							alignCenter
							disabled={![...bpmRanges].filter(f => !filters.includes(f)).length}
						/>
					</div>,
					<div key='genreFilter' className='song-table__filter__dropdown song-table__filter__dropdown--large'>
						<DropdownButton
							options={[...genres].filter(f => !filters.includes(f)).map(f => ({ label: f, value: f }))}
							onChange={f => setFilters([...filters, f.value])}
							placeholder='Genre'
							value='Genre'
							showArrowIcon
							menuClassName='song-table__filter__dropdown__menu song-table__filter__dropdown__menu--large'
							alignCenter
							disabled={![...genres].filter(f => !filters.includes(f)).length}
						/>
					</div>,
					songsData.artists && songsData.artists.length ? (
						<div key='artistFilter' className='song-table__filter__dropdown song-table__filter__dropdown--large'>
							<DropdownButton
								options={[...songsData.artists].filter(f => !filters.includes(f)).map(f => ({ label: f, value: f }))}
								onChange={f => setFilters([...filters, f.value])}
								placeholder='Artist'
								value='Artist'
								showArrowIcon
								menuClassName='song-table__filter__dropdown__menu song-table__filter__dropdown__menu--large'
								alignCenter
								disabled={![...songsData.artists].filter(f => !filters.includes(f)).length}
							/>
						</div>
					) : null,
					<div key='trackSrcFilter' className='song-table__filter__dropdown song-table__filter__dropdown--large'>
						<DropdownButton
							options={[...musicFeeds]
								.filter(f => !musicFeedFilters.includes(f.music_metadata_feed_id))
								.map(label => ({ label: label.feed_name, value: label.music_metadata_feed_id }))}
							onChange={f => {
								setMusicFeedFilters([...musicFeedFilters, f.value]);
								setFilters([...filters, f.label]);
							}}
							placeholder='Track Source'
							value='Track Source'
							showArrowIcon
							menuClassName='song-table__filter__dropdown__menu song-table__filter__dropdown__menu--large'
							alignCenter
							disabled={![...musicFeeds].filter(f => !musicFeedFilters.includes(f.music_metadata_feed_id)).length}
						/>
					</div>,
				]}
				removeFilter={filterIndex => {
					const newFilters = [...filters];
					const currentFilterValue = newFilters[filterIndex];
					const musicFeed = musicFeeds.find(mf => mf.feed_name === currentFilterValue);

					if (musicFeed && musicFeedFilters.includes(musicFeed.music_metadata_feed_id)) {
						setMusicFeedFilters(musicFeedFilters.filter(f => f !== musicFeed.music_metadata_feed_id));
					}

					newFilters.splice(filterIndex, 1);
					setFilters(newFilters);
				}}
				resetFilters={() => {
					setMusicFeedFilters([]);
					setFilters([]);
				}}
				handleSearchTermOnCollection={handleSearchQuery}
			/>
			{loaded && songsData && !songsData.total ? (
				<div className='my-songs__empty w-100 h-100 d-flex justify-content-center'>
					<div className='my-songs__empty__container'>
						<div className='my-songs__empty__header'>Your bookmarked songs will appear here</div>
						<div className='my-songs__empty__sub'>Begin bookmarkings new songs or adjust your query</div>
					</div>
				</div>
			) : null}
		</div>
	);
});

export default MySongs;
