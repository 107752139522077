export const BRANDS = {
	SOULCYCLE: 1,
	EQUINOX: 2,
	RUMBLE_BOXING: 3,
	SOLIDCORE: 4,
	PRECISION_RUN: 5,
};

export const BRANDS_MAP = {
	1: 'SoulCycle',
	2: 'Equinox',
	3: 'Rumble Boxing',
	4: '[solidcore]',
	5: 'Precision Run',
};
