export const secondsToHHMMSSFormatter = s => {
	if (s) {
		const secNum = typeof s === 'string' ? parseInt(s, 10, 10) : s;

		let hours = Math.floor(secNum / 3600);

		let minutes = Math.floor((secNum - hours * 3600) / 60);

		let seconds = secNum - hours * 3600 - minutes * 60;

		if (hours < 10) {
			hours = `0${hours}`;
		}
		if (minutes < 10) {
			minutes = `0${minutes}`;
		}
		if (seconds < 10) {
			seconds = `0${seconds}`;
		}
		return `${hours}:${minutes}:${seconds}`;
	}
	return '';
};

export const secondsToMMSSFormatter = s => {
	if (s) {
		const secNum = typeof s === 'string' ? parseInt(s, 10) : s;
		const hours = Math.floor(secNum / 3600);

		let minutes = Math.floor((secNum - hours * 3600) / 60);

		let seconds = secNum - hours * 3600 - minutes * 60;

		if (minutes < 10) {
			minutes = `0${minutes}`;
		}
		if (seconds < 10) {
			seconds = `0${seconds}`;
		}
		return `${minutes}:${seconds}`;
	}
	return '';
};

export const secondsToMSSFormatter = s => {
	if (s) {
		const secNum = typeof s === 'string' ? parseInt(s, 10) : s;
		const hours = Math.floor(secNum / 3600);
		const minutes = Math.floor((secNum - hours * 3600) / 60);

		let seconds = secNum - hours * 3600 - minutes * 60;

		if (seconds < 10) {
			seconds = `0${seconds}`;
		}
		return `${minutes}:${seconds}`;
	}
	return '';
};

export const mmssToSecondsFormatter = s => {
	if (s) {
		const seconds = parseInt(s.split(':').pop(), 10) || 0;
		const minutes = parseInt(s.split(':')[0], 10) || 0;

		return minutes * 60 + seconds;
	}
	return '';
};

export const hhmmssToSecondsFormatter = s => {
	if (s) {
		const seconds = parseInt(s.split(':').pop(), 10) || 0;
		const minutes = parseInt(s.split(':')[1], 10) || 0;
		const hours = parseInt(s.split(':')[0], 10) || 0;

		return hours * 3600 + minutes * 60 + seconds;
	}
	return '';
};

export const minutesToHHMMSSFormatter = s => {
	if (s) {
		const minutesNum = typeof s === 'string' ? parseInt(s, 10) : s;
		const hours = Math.floor(minutesNum / 60);

		let minutes;

		if (minutesNum >= 60) {
			minutes = minutesNum - 60;
		} else {
			minutes = minutesNum;
		}
		return `${hours / 10 < 1 ? `0${hours}` : hours}:${minutes / 10 < 1 ? `0${minutes}` : minutes}:00`;
	}
	return '';
};

export const getISODateTimeString = () => new Date().toISOString().replace(/-|T|:/g, '').split('.')[0];

export const getISODateString = () => new Date().toISOString().replace(/-|T|:/g, '').substring(0, 8);

export const getFormattedISODateTimeString = () =>
	new Date().toISOString().replace(/:/g, '-').replace(/T/g, '_').split('.')[0];

export const dateToMMMDDYYYYFormatter = date =>
	new Date(date)
		.toDateString() // Format is [Day of Week] [Month] [Day] [Year]
		.split(' ')
		.slice(1) // remove [Day of Week]
		.join(' ');
