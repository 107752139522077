import React from 'react';

import { PLAYLIST_STATUS } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as playlistsApi from '../../api/playlists';
import * as sharedFoldersApi from '../../api/shared-folders';
import * as songsApi from '../../api/songs';
import PlaylistCard from '../../components/playlist/PlaylistCard';
import Collection from '../../components/search/Collection';
import CollectionCard from '../../components/search/CollectionCard';
import FolderCard from '../../components/sharedFolder/FolderCard';
import GlobalContext from '../../contexts/GlobalContext';
import { isSharedFolderUser } from '../../utils/auth';

const FOLDERS_LIMIT = 100;
const PLAYLIST_LIMIT = 100;

class InstructorDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			playlists: [],
			folders: [],
			songs: [],
		};
		this.fetchPlaylists = this.fetchPlaylists.bind(this);
		this.fetchSongs = this.fetchSongs.bind(this);
		this.fetchFolders = this.fetchFolders.bind(this);
	}

	componentDidMount() {
		const { loaderCallback } = this.props;

		this.fetchFolders();
		this.fetchPlaylists();
		this.fetchSongs();
		loaderCallback();
	}

	fetchPlaylists = async () => {
		const playlists = await playlistsApi.getUserPlaylists({
			page: 1,
			limit: PLAYLIST_LIMIT,
			includeSongDetails: true,
			includeThumbnails: true,
		});

		this.setState({
			playlists: playlists.data,
		});
	};

	fetchSongs = async () => {
		const songs = await songsApi.getUserDashboardSongs(10, 1);

		this.setState({
			songs: songs.data,
		});
	};

	fetchFolders = async () => {
		if (isSharedFolderUser()) {
			const folders = await sharedFoldersApi.getFolders(FOLDERS_LIMIT, 1);

			this.setState({
				folders: folders.data,
			});
		}
	};

	render() {
		const { folders, playlists, songs } = this.state;
		return (
			<div className='w-100 d-block'>
				{isSharedFolderUser() && (
					<>
						<Collection
							header='Catalog Folders'
							seeAllLink='/folders'
							enableCarousel
							showHeaderUnderline
							itemsPerRow={4}
							largeCollection
						>
							{(folders || []).map(folder => (
								<FolderCard
									className='dashboard__collection__folder-card'
									title={folder.name}
									folderID={folder.shared_folder_id}
									coverImages={folder.cover_thumbnail_images || folder.cover_images}
									key={folder.shared_folder_id}
									numSongs={folder.num_songs}
									isDeleted={!!folder.deleted_timestamp}
									totalViewers={folder.total_folder_viewers}
									totalCreators={folder.total_folder_creator_shares}
								/>
							))}
						</Collection>
						<br />
					</>
				)}
				<Collection
					header='My Playlists'
					seeAllLink='/my-playlists'
					enableCarousel
					showHeaderUnderline
					itemsPerRow={4}
					largeCollection
				>
					{(playlists.filter(p => p.playlist_status_id !== PLAYLIST_STATUS.ARCHIVED) || []).map(playlist => (
						<PlaylistCard
							className='dashboard__collection__playlist-card'
							title={playlist.name}
							statusId={playlist.playlist_status_id}
							playlistId={playlist.playlist_id}
							coverImages={playlist.cover_thumbnail_images || playlist.cover_images}
							flagged={playlist.playlist_status_id === PLAYLIST_STATUS.INVALID}
							key={playlist.playlist_id}
							numSongs={playlist.num_songs}
							notes={playlist.notes}
						/>
					))}
				</Collection>
				<br />
				<Collection header='My Songs' seeAllLink='/my-songs' enableCarousel showHeaderUnderline itemsPerRow={5}>
					{(songs || []).map(song => (
						<CollectionCard
							image={song.thumbnail_image || song.image}
							title={song.name}
							subtitle={song.artist}
							isCarouselItem
							key={song.song_id}
						/>
					))}
				</Collection>
			</div>
		);
	}
}

InstructorDashboard.propTypes = {
	loaderCallback: PropTypes.func.isRequired,
};

InstructorDashboard.contextType = GlobalContext;

export default withRouter(InstructorDashboard);
