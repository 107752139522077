import React, { useState, useEffect, useContext } from 'react';

import * as blacklistApi from '../../api/blacklist';
import AlertModal from '../../components/AlertModal';
import TextInputForm from '../../components/forms/TextInputForm';
import DataTable from '../../components/layout/DataTable';
import Loader from '../../components/Loader';
import { NotesPanel } from '../../components/NotesPanel';
import { PaginationButtons } from '../../components/PaginationButtons';
import { BLACKLIST_TYPE } from '../../constants/blacklist';
import ISRC_REGEX_PATTERN from '../../constants/isrc';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as RemoveImg } from '../../images/close.svg';
import { dateToMMMDDYYYYFormatter } from '../../utils/date-time';

const PAGE_SIZE = 50;

const SongByISRCBlacklist = () => {
	const [blacklistedSongs, setBlacklistedSongs] = useState([]);
	const [selectedISRC, setSelectedISRC] = useState(null);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const { createToast, openISRCBlacklistModal } = useContext(GlobalContext);
	const [total, setTotal] = useState(0);
	const [pageNum, setPageNum] = useState(1);

	const showRemoveISRCModal = isrc => {
		setSelectedISRC(isrc);
		setShowRemoveModal(true);
	};

	const renderPlaylistCell = (playlistNames = []) => {
		return (
			<div className='blacklist__playlist-metadata  my-3'>
				{playlistNames.map((playlist, index) => (
					<div className='pr-3' key={index}>
						{playlist}
					</div>
				))}
			</div>
		);
	};

	const renderSongCell = songs => {
		return songs.map(
			(
				song,
				index // Song Metadata
			) => (
				<div className='blacklist__song-metadata my-3' key={index}>
					<div>
						<b>Song Title</b>:{song.name}
					</div>
					<div>
						<b>Artist</b>:{song.artist}
					</div>
					<div>
						<b>Album</b>:{song.album}
					</div>
				</div>
			)
		);
	};

	const renderRemoveButton = blacklistedISRC => (
		<div className='blacklist__btn-remove' alt='remove' onClick={() => showRemoveISRCModal(blacklistedISRC)}>
			<RemoveImg />
		</div>
	);

	const handleNoteUpdate = async (isrc, newNote) => {
		await blacklistApi.updateBlacklistRecord(BLACKLIST_TYPE.ISRC, isrc, newNote);
		// eslint-disable-next-line no-use-before-define
		await fetchBlacklistedSongs();
	};

	const formatRows = data => {
		return data.map(blacklistedISRC => [
			blacklistedISRC.value, // isrc
			renderSongCell(blacklistedISRC.songs || []), // Song Metadata
			renderPlaylistCell([
				...(blacklistedISRC.affected_internal_playlists ? blacklistedISRC.affected_internal_playlists.split(',') : []),
				...(blacklistedISRC.affected_external_playlists ? blacklistedISRC.affected_external_playlists.split(',') : []),
			]), // Affected Playlists
			dateToMMMDDYYYYFormatter(blacklistedISRC.created_timestamp), // Blacklisted Date
			blacklistedISRC.blacklisted_by, // Blacklisted By
			<NotesPanel // Notes
				note={blacklistedISRC.notes}
				onNoteUpdate={newNote => handleNoteUpdate(blacklistedISRC.value, newNote)}
				tooltipHeader='Songs Notes'
				tooltipDetail={blacklistedISRC.value}
			/>, // Notes
			renderRemoveButton(blacklistedISRC.value),
		]);
	};

	const fetchBlacklistedSongs = async (page = null) => {
		await blacklistApi
			.getBlacklistedSongsByISRC(PAGE_SIZE, page || pageNum)
			.then(res => {
				setBlacklistedSongs(formatRows(res.data || []));
				setTotal(res.count || 0);
				setLoaded(true);
				if (!!page && page !== pageNum) {
					setPageNum(page || pageNum);
				}
			})
			.catch(() => {
				createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
			});
	};

	const handleRemoveISRC = async () => {
		const numDeleted = await blacklistApi.removeBlacklistedISRC(selectedISRC);

		setShowRemoveModal(false);
		await fetchBlacklistedSongs(1);

		// show toast
		if (numDeleted > 0) {
			createToast(`Successfully removed ${selectedISRC} from ISRC filter!`, TOAST_LEVEL.SUCCESS, true);
		} else {
			createToast('There was an error while updating the ISRC filter. Please try again later', TOAST_LEVEL.ERROR, true);
		}
	};

	const handleAddSongToBlacklist = async () => {
		await fetchBlacklistedSongs(1);
		setSelectedISRC(null);
	};

	const handleAddISRCOnSubmit = async isrc => {
		setSelectedISRC(isrc);
		openISRCBlacklistModal(isrc, handleAddSongToBlacklist);
	};

	// componentDidMount
	useEffect(() => {
		fetchBlacklistedSongs();
	}, [pageNum]);

	// table information
	const tableHeaders = [
		'ISRC',
		'Song Metadata',
		'Affected Playlists',
		'Blacklisted Date',
		'Blacklisted By',
		'Notes',
		'',
	];

	const tableWidths = ['10%', '30%', '15%', '10%', '15%', '18%', '2%'];

	return (
		<Loader loaded={loaded} className='licesing-dashboard__loader'>
			<AlertModal
				title='Remove ISRC From Blacklist'
				showModal={showRemoveModal}
				description={`Are you sure you want to remove the ISRC ${selectedISRC} from the blacklist?`}
				onConfirmClick={handleRemoveISRC}
				onCancelClick={() => setShowRemoveModal(false)}
			/>
			<div className='mb-3 d-flex flex-column'>
				<div className='d-flex justify-content-between mb-3'>
					<h4 className='h4 mb-1'>ISRCs</h4>
					<TextInputForm
						label='Add ISRC To Blacklist'
						onSubmit={isrc => handleAddISRCOnSubmit(isrc)}
						submitText='Submit'
						inputValidationRegexp={ISRC_REGEX_PATTERN.REGEX_PATTERN}
						inputValidationFailMessage='The provided ISRC is not formatted correctly. Please enter a valid ISRC.'
						useValidationErrorPopup={false}
					/>
				</div>
				<div>
					<DataTable
						className='blacklist__table'
						fixedLayout
						data={blacklistedSongs}
						headerRow={tableHeaders}
						widths={tableWidths}
					/>
				</div>
				{loaded && total > PAGE_SIZE && (
					<div className='d-flex mt-5'>
						<PaginationButtons
							handlePageChange={p => setPageNum(p)}
							numPages={Math.ceil(total / PAGE_SIZE)}
							pageNum={pageNum}
						/>
					</div>
				)}
			</div>
		</Loader>
	);
};

export default SongByISRCBlacklist;
