module.exports = {
	AIRTABLE_TASK_ACTION_KEY: {
		MUSIC_DROP_BASE_PUSH: 'MUSIC_DROP_BASE_PUSH',
		MUSIC_DROP_BASE_PATCH: 'MUSIC_DROP_BASE_PATCH',
	},
	MUSIC_DROP_PLAYLIST_STATUS: {
		APPROVED: 'Approved',
		REJECTED: 'Rejected',
		ANNULLED: 'Annulled',
		INVALID: 'Invalid',
	},
	AIRTABLE_ERROR_TYPE_KEY: {
		ROW_DOES_NOT_EXIST: 'ROW_DOES_NOT_EXIST',
	},
	AIRTABLE_ERROR_MESSAGES: {
		SERVICE_UNAVAILABLE: 'Airtable API service is unavailable, please check to verify https://status.airtable.com/',
	},
};
