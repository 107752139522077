import React, { useContext } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import GlobalContext from '../../contexts/GlobalContext';

const getNavLink = (navigateTo, imageSrc, imageAlt, label, isDragging, droppable = false, forceActive = false) => (
	<NavLink
		className={classNames('sidenav__item', {
			'sidenav__item--selected': forceActive,
			'sidenav__item--drop-disabled': isDragging && !droppable,
		})}
		to={navigateTo}
		activeClassName='sidenav__item--selected'
	>
		<img src={imageSrc} alt={imageAlt} className='mr-0' />
		<span className='align-self-center'>{label}</span>
	</NavLink>
);

const NavItem = ({
	navigateTo,
	imageSrc,
	imageAlt,
	label,
	droppableID,
	droppablePlaceholder = 'Place here',
	forceActive = false,
}) => {
	const { isDragging } = useContext(GlobalContext);

	if (droppableID) {
		return (
			<Droppable droppableId={droppableID}>
				{(provided, snapshot) => (
					<div
						className={classNames('sidenav__drop-zone', {
							'sidenav__drop-zone--dragging-over': snapshot.isDraggingOver,
						})}
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						{getNavLink(navigateTo, imageSrc, imageAlt, label, isDragging, true, forceActive)}
						<div hidden>{provided.placeholder}</div>
						{snapshot.isDraggingOver && <div className='sidenav__drop-zone__placeholder'>{droppablePlaceholder}</div>}
					</div>
				)}
			</Droppable>
		);
	}
	return (
		<div className='sidenav__no-drop'>
			{getNavLink(navigateTo, imageSrc, imageAlt, label, isDragging, false, forceActive)}
		</div>
	);
};

NavItem.propTypes = {
	droppableID: PropTypes.string,
	droppablePlaceholder: PropTypes.string,
	imageAlt: PropTypes.string,
	imageSrc: PropTypes.string,
	label: PropTypes.string,
	navigateTo: PropTypes.string,
};

NavItem.defaultProps = {
	droppableID: '',
	droppablePlaceholder: '',
	imageAlt: '',
	imageSrc: '',
	label: '',
	navigateTo: '',
};

export default withRouter(NavItem);
