import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import BACKGROUND_POSITION_BY_INDEX from '../../constants/playlist-thumbnail-positions';

const buildThumbnail = (image, roundImage) => {
	let thumbnails = (image || '').split(',').filter(i => !!i);

	thumbnails = !!thumbnails.length && thumbnails.length === 4 ? thumbnails : thumbnails[0];

	return (
		<div
			className={`top-result__image--img ${roundImage ? 'top-result__image--rounded' : ''}`}
			style={{
				backgroundImage: `${
					Array.isArray(thumbnails) ? thumbnails.map(img => `url(${img})`).join(',') : `url(${thumbnails}) `
				}`,
				backgroundPosition: `${
					thumbnails.length === 4 ? thumbnails.map((_, i) => `${BACKGROUND_POSITION_BY_INDEX[i]}`).join(',') : ''
				}`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: `${thumbnails.length === 4 ? '50%' : 'cover'}`,
			}}
		/>
	);
};

const TopResultCard = ({ title, subtitle, image, imagePlaceholder, roundImage, redirectTo, explicit }) => {
	const ConditionalLink = redirectTo ? Link : React.Fragment;
	const linkProps = redirectTo ? { to: redirectTo } : {};

	let subtitleLines = [];

	if (subtitle) {
		subtitleLines = Array.isArray(subtitle) ? subtitle : [subtitle];
	}

	return (
		<div className={`top-result ${redirectTo ? 'top-result--clickable' : ''}`}>
			<ConditionalLink {...linkProps}>
				{image && buildThumbnail(image, roundImage)}
				{!image && (
					<div className={`top-result__image--default ${roundImage ? 'top-result__image--rounded' : ''}`}>
						{imagePlaceholder ? <span className='top-result__image__placeholder'>{imagePlaceholder}</span> : ''}
					</div>
				)}
				<div className='top-result__metadata'>
					<div className='top-result__metadata--title'>{title}</div>
					{subtitleLines.map((subtitleLine, i) => (
						<div className='top-result__metadata--subtitle' key={i}>
							{subtitleLine}
						</div>
					))}
					{explicit && <span className='top-result__metadata__explicit'>EXPLICIT</span>}
				</div>
			</ConditionalLink>
		</div>
	);
};

TopResultCard.propTypes = {
	description: PropTypes.string,
	explicit: PropTypes.bool,
	image: PropTypes.string,
	imagePlaceholder: PropTypes.string,
	redirectTo: PropTypes.string,
	roundImage: PropTypes.bool,
	title: PropTypes.string,
	subtitle: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

TopResultCard.defaultProps = {
	description: '',
	image: '',
	imagePlaceholder: '',
	redirectTo: '',
	roundImage: false,
	subtitle: '',
	title: '',
	explicit: false,
};

export default TopResultCard;
