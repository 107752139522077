import { apiUrl, getDefaultPostInit, parseStandardResponse } from '../utils/api';

const PLAYLIST_SONGS_PATH = 'playlist-songs';

export async function updatePlaylistSongZoneKey(zoneKeyId, playlistSongId, playlistId) {
	const url = `${apiUrl}/${PLAYLIST_SONGS_PATH}/${playlistSongId}`;
	const init = getDefaultPostInit({ zoneKeyId, playlistId });

	return parseStandardResponse(fetch(url, init));
}

export async function updatePlaylistSongTransition(transitionId, playlistSongId, playlistId) {
	const url = `${apiUrl}/${PLAYLIST_SONGS_PATH}/${playlistSongId}`;
	const init = getDefaultPostInit({ transitionId, playlistId });

	return parseStandardResponse(fetch(url, init));
}

export async function updatePlaylistSongInVal(inVal, playlistSongId, playlistId) {
	const url = `${apiUrl}/${PLAYLIST_SONGS_PATH}/${playlistSongId}`;
	const init = getDefaultPostInit({ inVal, playlistId });

	return parseStandardResponse(fetch(url, init));
}

export async function updatePlaylistSongOutVal(outVal, playlistSongId, playlistId) {
	const url = `${apiUrl}/${PLAYLIST_SONGS_PATH}/${playlistSongId}`;
	const init = getDefaultPostInit({ outVal, playlistId });

	return parseStandardResponse(fetch(url, init));
}

export async function updatePlaylistSongNotes(notes, playlistSongId, playlistId) {
	const url = `${apiUrl}/${PLAYLIST_SONGS_PATH}/${playlistSongId}`;
	const init = getDefaultPostInit({ notes, playlistId });

	return parseStandardResponse(fetch(url, init));
}
