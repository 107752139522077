import React from 'react';

function Tooltip({ text, children }) {
	// const style =
	return (
		<span className='tooltip-container'>
			{children}
			<span className='tooltip-container__tooltip'>{text}</span>
		</span>
	);
}

export default Tooltip;
