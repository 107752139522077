import React, { useState, useEffect } from 'react';

import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import * as sharedFoldersApi from '../../api/shared-folders';
import Loader from '../../components/Loader';
import Collection from '../../components/search/Collection';
import CollectionCard from '../../components/search/CollectionCard';
import { COLLECTIONS, SUBPAGES_LABELS } from '../../constants/browse-catalog';
import { isContentCreator, isInstructor, isSharedFolderUser } from '../../utils/auth';

import ContentCreatorDashboard from './ContentCreatorDashboard';
import InstructorDashboard from './InstructorDashboard';

const Dashboard = () => {
	const [highlightedFolders, setHighlightedFolders] = useState([]);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const loadContent = async () => {
			if (isSharedFolderUser()) {
				setHighlightedFolders((await sharedFoldersApi.getFolders(2, 1, false, true)).data);
			}

			setLoaded(true);
		};

		setLoaded(false);
		loadContent();
	}, []);

	const subPagesTabs = Object.keys(COLLECTIONS).map(sp => (
		<NavLink key={sp} className='dashboard mr-5' activeClassName='active' to={`/browse/${sp}`} exact>
			<h3>{SUBPAGES_LABELS[sp]}</h3>
		</NavLink>
	));

	let CustomDashboard = null;

	// CC dashboard higher precedence over admin dashboard if user shares CC role with admin role
	CustomDashboard = isContentCreator() ? ContentCreatorDashboard : CustomDashboard;
	// Instructor dashboard higher precedence over CC dashboard if user shares Instructor role with Admin or CC role
	CustomDashboard = isInstructor() ? InstructorDashboard : CustomDashboard;

	return (
		<div className='dashboard'>
			<h2 className='h2 mb-4'>Browse</h2>
			<nav className='dashboard-tabs mb-4'>{subPagesTabs}</nav>
			<Loader loaded={loaded}>
				{highlightedFolders.map(folder => (
					<Collection
						key={folder.shared_folder_id}
						header={folder.name}
						seeAllLink={`/folder/${folder.shared_folder_id}`}
						itemsPerRow={5}
						enableCarousel
						showHeaderUnderline
					>
						{(folder.songs || []).map(song => (
							<CollectionCard
								image={song.thumbnail_image || song.image}
								title={song.name}
								subtitle={song.display_artist}
								isCarouselItem
								key={`${folder.shared_folder_id}${song.song_id}`}
							/>
						))}
					</Collection>
				))}
				<div className='mt-5'>
					<CustomDashboard
						loaderCallback={() => {
							setLoaded(true);
						}}
					/>
				</div>
			</Loader>
		</div>
	);
};

export default withRouter(Dashboard);
