import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';

import GlobalContext from '../../contexts/GlobalContext';
import { isViewOnly } from '../../utils/auth';

const NewPlaylistModal = ({ onClose, onSubmit }) => {
	const { showNewPlaylistModal, setShowNewPlaylistModal, playlistTypes, redirectToPlaylist } =
		useContext(GlobalContext);
	const [playlistType, setPlaylistType] = useState(null);

	const getPlaylistTypeDropdownOptions = () => {
		return playlistTypes.map(type => ({
			value: type.playlist_type_id,
			label: type.name,
		}));
	};

	return (
		<div className={`modal new-playlist__modal ${showNewPlaylistModal ? 'd-block' : ''}`} role='dialog'>
			<div className='new-playlist__modal__dialog'>
				<div className='modal-content new-playlist__modal__content'>
					<h5 className='new-playlist__modal__content__header'>New Playlist</h5>
					<div>
						<label className='new-playlist__modal__form__label'>Playlist Type</label>
						<Select
							className='folder-modal__form__input folder-modal__form__select select-container'
							classNamePrefix='select'
							value={playlistType}
							onChange={e => setPlaylistType(e)}
							options={getPlaylistTypeDropdownOptions()}
							isMulti={false}
							isDisabled={isViewOnly()}
						/>
						<div className='new-playlist__modal__form__buttons'>
							<button name='NewPlaylistModal - Close' type='button' className='secondary-btn mr-3' onClick={onClose}>
								Close
							</button>
							<button
								name='NewPlaylistModal - Create'
								type='submit'
								className='primary-btn'
								onClick={() => onSubmit(playlistType.value, redirectToPlaylist)}
								disabled={!playlistType}
							>
								Create
							</button>
						</div>
					</div>
					<button
						name='NewPlaylistModal - New Playlist Modal'
						type='button'
						className='new-playlist__modal__close'
						onClick={() => setShowNewPlaylistModal(false)}
						aria-hidden
					/>
				</div>
			</div>
		</div>
	);
};

NewPlaylistModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default NewPlaylistModal;
