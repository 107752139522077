import React, { useState, useEffect, useContext } from 'react';

import { BRANDS_MAP, PLAYLIST_STATUS_MAP } from '@content-playlist-creation/common/constants';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import * as licensingApi from '../../api/licensing';
import Badge from '../../components/Badge';
import DropdownButton from '../../components/DropdownButton';
import DataTable from '../../components/layout/DataTable';
import Loader from '../../components/Loader';
import { PaginationButtons } from '../../components/PaginationButtons';
import PLAYLIST_SOURCE from '../../constants/playlist-sources';
import { SORT_ORDER } from '../../constants/sort-options';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as ArrowDownImg } from '../../images/arrow-down.svg';
import { ReactComponent as DownloadImg } from '../../images/download.svg';
import { areArraysEqual } from '../../utils/arrays';
import { getISODateTimeString } from '../../utils/date-time';
import { formatPlaylistFilters } from '../../utils/playlist';

const DEFAULT_PAGE_SIZE = 100;

const PlaylistsImpacted = React.memo(
	({ countSingleOcurrences, parentLoaded, filters, setFilters, playlistSourceFilter, handlePlaylistFilterChange }) => {
		const [loaded, setLoaded] = useState(false);
		const [page, setPage] = useState(1);
		const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
		const [total, setTotal] = useState(0);
		const [data, setData] = useState([]);
		const [sorters, setSorters] = useState([
			{
				field: 'variis_track_id',
				order: SORT_ORDER.ASC,
			},
		]);

		const [savedSourceFilter, setSavedSourceFilter] = useState('ALL');
		const [savedExtraFilters, setSavedExtraFilters] = useState([]);
		const [showSaveFilters, setShowSaveFilters] = useState(false);

		const { createToast, externalPlaylistStatuses } = useContext(GlobalContext);

		const defaultPlaylistFetch = () => fetchPlaylists(DEFAULT_PAGE_SIZE, 1, sorters);

		useEffect(() => defaultPlaylistFetch(), []); // eslint-disable-line react-hooks/exhaustive-deps
		useEffect(() => {
			areArraysEqual(
				filters.map(f => ({ value: f })),
				savedExtraFilters.map(f => ({ value: f }))
			) && playlistSourceFilter === savedSourceFilter
				? setShowSaveFilters(false)
				: setShowSaveFilters(true);
		}, [filters, playlistSourceFilter]); // eslint-disable-line react-hooks/exhaustive-deps

		const getSortIcon = key => {
			const existingSorter = sorters.find(s => s.field === key);

			if (!existingSorter) {
				return null;
			}
			if (existingSorter.order === SORT_ORDER.DESC) {
				return (
					<span className='licesing-dashboard__report__table__header__icon'>
						<ArrowDownImg />
					</span>
				);
			}
			return (
				<span className='licesing-dashboard__report__table__header__icon licesing-dashboard__report__table__header__icon--inverse'>
					<ArrowDownImg />
				</span>
			);
		};

		const downloadReport = async () => {
			createToast('Generating Playlist Impacted Report...', TOAST_LEVEL.INFO, true);

			const data = await licensingApi.exportPlaylistsImpactedReportCSV(
				formatPlaylistFilters(filters, playlistSourceFilter, externalPlaylistStatuses)
			);

			const csvURL = window.URL.createObjectURL(data);
			const tempLink = document.createElement('a');
			tempLink.href = csvURL;
			tempLink.setAttribute('download', `variis_licesing_playlists_impacted_${getISODateTimeString()}.csv`);
			tempLink.click();
			tempLink.remove();
			window.URL.revokeObjectURL(data);
		};

		const fetchPlaylists = async (pageSize, page, sorters = null) => {
			setLoaded(false);
			await licensingApi
				.getPlaylistsImpactedReport({
					pageSize,
					page,
					sorters,
					...formatPlaylistFilters(filters, playlistSourceFilter, externalPlaylistStatuses),
				})
				.then(response => {
					setPage(page);
					setPageSize(pageSize);
					setTotal(response.count);
					setData(response.data || []);
					if (sorters) {
						setSorters(sorters);
					}
				})
				.catch(() => {
					createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);

					setPage(page);
					setPageSize(pageSize);
					setTotal(0);
					setData([]);
				})
				.finally(() => {
					setLoaded(true);
				});
		};

		const toggleSort = key => {
			const existingSorter = sorters.find(s => s.field === key);

			if (!existingSorter) {
				sorters.push({ field: key, order: SORT_ORDER.ASC });
				fetchPlaylists(pageSize, 1, sorters);
			} else if (existingSorter.order === SORT_ORDER.ASC) {
				fetchPlaylists(
					pageSize,
					1,
					sorters.map(s => ({ ...s, ...(s.field === key && { order: SORT_ORDER.DESC }) }))
				);
			} else {
				fetchPlaylists(
					pageSize,
					1,
					sorters.filter(s => s.field !== key)
				);
			}
		};

		const renderMultiLineCell = (data, delimiter, dataTransformation = null) => (
			<div className='licesing-dashboard__report__multi-line-cell'>
				{(data || '').split(delimiter).map((line, idx) => (
					// eslint-disable-next-line react/no-array-index-key
					<div key={idx}>{dataTransformation ? dataTransformation(line) : line}</div>
				))}
			</div>
		);

		const handleExtraFilterChange = filter => setFilters([...filters, filter.value]);
		const removeExtraFilter = filter => setFilters([...filters].filter(f => f !== filter));
		const resetFilters = () => {
			handlePlaylistFilterChange('ALL');
			setFilters([]);
		};
		const saveFilters = () => {
			defaultPlaylistFetch();
			setSavedSourceFilter(playlistSourceFilter);
			setSavedExtraFilters(filters);
			setShowSaveFilters(false);
		};
		const renderFilters = () => {
			let statusValues = [];

			if (playlistSourceFilter === PLAYLIST_SOURCE.INTERNAL_PLAYLISTS.key || playlistSourceFilter === 'ALL') {
				statusValues = statusValues.concat([
					PLAYLIST_STATUS_MAP[1],
					PLAYLIST_STATUS_MAP[2],
					PLAYLIST_STATUS_MAP[3],
					PLAYLIST_STATUS_MAP[4],
				]);
			}

			if (playlistSourceFilter === PLAYLIST_SOURCE.EXTERNAL_SHOW_PLAYLISTS.key || playlistSourceFilter === 'ALL') {
				statusValues = statusValues.concat([...externalPlaylistStatuses]);
			}

			const filterControls = [
				<div key='sourceFilter' className='licesing-dashboard__extra-filter__dropdown'>
					<DropdownButton
						options={[
							{ label: 'All', value: 'ALL' },
							{ label: 'Playlists', value: PLAYLIST_SOURCE.INTERNAL_PLAYLISTS.key },
							{ label: 'Show Playlists', value: PLAYLIST_SOURCE.EXTERNAL_SHOW_PLAYLISTS.key },
						]}
						onChange={e => handlePlaylistFilterChange(e.value)}
						placeholder='Source'
						value={playlistSourceFilter}
						showArrowIcon
						menuClassName='licesing-dashboard__extra-filter__dropdown__menu'
						alignCenter
					/>
				</div>,
				<div key='brandFilter' className='licesing-dashboard__extra-filter__dropdown'>
					<DropdownButton
						options={Object.values(BRANDS_MAP)
							.filter(f => !filters.includes(f))
							.map(v => ({ value: v, label: v }))}
						onChange={f => handleExtraFilterChange(f)}
						placeholder='Brand'
						value='Brand'
						showArrowIcon
						menuClassName='licesing-dashboard__extra-filter__dropdown__menu'
						alignCenter
						disabled={![...Object.values(BRANDS_MAP)].filter(f => !filters.includes(f)).length}
					/>
				</div>,
				<div key='statusFilter' className='licesing-dashboard__extra-filter__dropdown'>
					<DropdownButton
						options={statusValues.filter(f => !filters.includes(f)).map(v => ({ value: v, label: v }))}
						onChange={f => handleExtraFilterChange(f)}
						placeholder='Status'
						value='Status'
						showArrowIcon
						menuClassName='licesing-dashboard__extra-filter__dropdown__menu'
						alignCenter
						disabled={!statusValues.filter(f => !filters.includes(f)).length}
					/>
				</div>,
			];

			return (
				<div className='licesing-dashboard__extra-filter-controls'>
					<>
						<div
							className={classNames('licesing-dashboard__extra-filter-controls__row d-flex', {
								'justify-content-end': !filterControls.length,
								'justify-content-between': filterControls.length,
							})}
						>
							{filterControls.length ? (
								<div className='licesing-dashboard__extra-filter'>
									<span>Filter By: </span>
									{filterControls.map((control, index) => (
										<div key={`${index}`}>{control}</div>
									))}

									{showSaveFilters ? (
										<button
											name='PlaylistImpacted - Filter'
											type='button'
											className='licesing-dashboard__extra-filter-controls__action primary-btn'
											onClick={() => saveFilters()}
										>
											Filter
										</button>
									) : (
										<button
											name='PlaylistImpacted - Refresh'
											type='button'
											className='licesing-dashboard__extra-filter-controls__action secondary-btn'
											onClick={() => defaultPlaylistFetch()}
										>
											Refresh
										</button>
									)}
								</div>
							) : null}
						</div>

						{filters.length ? (
							<div className='licesing-dashboard__extra-filter-controls__row'>
								<div className='licesing-dashboard__extra-filter licesing-dashboard__extra-filter--wrappable'>
									<span>Your Selection(s): </span>
									{filters.map(filter => (
										<Badge
											key={filter}
											className='song-table__filter__badge'
											text={filter}
											onClick={() => removeExtraFilter(filter)}
											closeCallback={() => removeExtraFilter(filter)}
										/>
									))}
									<span className='licesing-dashboard__extra-filter-controls__action' onClick={() => resetFilters()}>
										Clear All
									</span>
								</div>
							</div>
						) : null}
					</>
				</div>
			);
		};

		const numPages = Math.ceil(total / pageSize);
		const headers = [
			{ label: 'Playlist ID', key: 'playlist_id' },
			{ label: 'Asset ID', key: 'asset_id' },
			{ label: 'Playlist Source', key: 'is_external' },
			{ label: 'Playlist Status', key: 'playlist_status' },
			{ label: 'VARIIS ID', key: 'variis_track_id' },
			{ label: 'Song Title', key: 'name' },
			{ label: 'Artist Name', key: 'artist' },
			{ label: 'Drop Reason', key: 'dropped_reason' },
			{ label: 'Date', key: 'dropped_timestamp' },
		];

		return (
			<>
				<div className='licesing-dashboard__header'>
					<h4>
						{countSingleOcurrences} Playlist{countSingleOcurrences && countSingleOcurrences > 1 ? 's' : ''} Impacted
					</h4>
					<button
						name='PlaylistImpacted - Download CSV'
						className='secondary-btn'
						disabled={!total}
						onClick={() => downloadReport()}
						type='button'
					>
						<DownloadImg /> Download CSV
					</button>
				</div>

				<p>
					Playlists &amp; Show Playlists not currently live on the app that contain songs impacted by LSR drops or label
					purges
				</p>

				<Loader loaded={parentLoaded && loaded} className='licesing-dashboard__loader'>
					<div className='licesing-dashboard__filter'>{renderFilters()}</div>

					<DataTable
						className='licesing-dashboard__report__table'
						headerRow={headers.map(h =>
							h.sortDisabled ? (
								h.label
							) : (
								<div
									key={h.key}
									className='licesing-dashboard__report__table__header'
									onClick={() => toggleSort(h.key)}
								>
									{h.label}
									{getSortIcon(h.key)}
								</div>
							)
						)}
						widths={['12%', '12%', '8.5%', '10.5%', '10%', '12.5%', '11.5%', '15.5%', '7.5%']}
						fixedLayout={false}
						data={data.map(
							({
								variis_track_id,
								name,
								artist,
								playlist_name,
								asset_id,
								playlist_status,
								dropped_reason,
								dropped_timestamp,
								is_external,
							}) => [
								playlist_name,
								asset_id,
								is_external ? PLAYLIST_SOURCE.EXTERNAL_SHOW_PLAYLISTS.label : PLAYLIST_SOURCE.INTERNAL_PLAYLISTS.label,
								playlist_status,
								variis_track_id,
								name,
								artist,
								renderMultiLineCell(dropped_reason, ', '),
								renderMultiLineCell(dropped_timestamp, ', ', droppedDate =>
									droppedDate ? new Date(droppedDate).toLocaleDateString() : 'N/A'
								),
							]
						)}
					/>

					{numPages > 1 && (
						<div className='d-flex align-items-center mt-5'>
							<PaginationButtons
								handlePageChange={nextPage => fetchPlaylists(pageSize, nextPage, sorters)}
								numPages={numPages}
								pageNum={page || 1}
							/>
						</div>
					)}
				</Loader>
			</>
		);
	}
);

PlaylistsImpacted.propTypes = {
	countSingleOcurrences: PropTypes.number.isRequired,
	parentLoaded: PropTypes.bool.isRequired,

	filters: PropTypes.arrayOf(PropTypes.string).isRequired,
	setFilters: PropTypes.func.isRequired,

	playlistSourceFilter: PropTypes.string.isRequired,
	handlePlaylistFilterChange: PropTypes.func.isRequired,
};

PlaylistsImpacted.contextType = GlobalContext;

export default PlaylistsImpacted;
