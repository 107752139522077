import find from 'lodash/find';

import * as playlistsApi from '../api/playlists';
import * as songsApi from '../api/songs';
import { DROP_ZONES } from '../constants/drop-zones';
import { TOAST_LEVEL } from '../constants/toast-levels';

function reOrderPlaylist(context, result) {
	const songs = context.currentPlaylist || [];

	const updates = [];

	let reordered = Array.from(songs);
	const [removed] = reordered.splice(result.source.index, 1);

	reordered.splice(result.destination.index, 0, removed);

	reordered = reordered.map((r, index) => ({
		...r,
		order: index + 1, // transform to one-based numbering
	}));

	songs.forEach(s => {
		const found = find(reordered, { playlist_song_id: s.playlist_song_id });

		if (found && found.order !== s.order) {
			updates.push({
				playlist_song_id: found.playlist_song_id,
				order: found.order,
			});
		}
	});

	if (updates.length) {
		playlistsApi.arrangeSongs(updates);
	}

	context.setCurrentPlaylist(reordered);
	if (!!context.playerPlaylist && context.currentPlaylistID === context.playerPlaylist.playlist_id) {
		context.loadPlaylistIntoPlayer(reordered, null, null, true);
	}
}

export async function markAsFavorite(context, songId) {
	await songsApi.addSongToFavorites(songId);

	context.setCurrentPlaylist(
		(context.currentPlaylist || []).map(s => ({ ...s, ...(s.song_id === songId && { is_favorite: true }) }))
	);
	context.setNewSearchDraggedFavoriteSong(songId);
}

export async function handleAddSongToPlaylist(context, songId, playlistId) {
	await playlistsApi
		.addSongToPlaylist(playlistId, songId)
		.then(async () => {
			await context.getSearchPlaylists(true);
			context.createToast('Successfully added song to playlist!', TOAST_LEVEL.SUCCESS, true);
		})
		.catch(() => {
			context.createToast(
				'There was an error adding song to a playlist, please refresh and try later',
				TOAST_LEVEL.ERROR,
				true
			);
		});
}

export async function onDragEnd(context, result) {
	if (!result.destination) {
		return;
	}

	if (
		result.destination.droppableId === DROP_ZONES.INSTRUCTOR_PLAYLIST &&
		result.source.droppableId === DROP_ZONES.INSTRUCTOR_PLAYLIST
	) {
		reOrderPlaylist(context, result);
		return;
	}

	if (
		result.destination.droppableId === DROP_ZONES.MY_SONG_NAV &&
		(result.source.droppableId === DROP_ZONES.INSTRUCTOR_PLAYLIST ||
			result.source.droppableId === DROP_ZONES.SONGS_TABLE)
	) {
		await markAsFavorite(context, Number(result.draggableId.split('_')[0]));
	}

	if (
		result.destination.droppableId.indexOf(DROP_ZONES.PLAYLIST_RAIL_PLAYLIST) >= 0 &&
		result.source.droppableId === DROP_ZONES.SONGS_TABLE
	) {
		await handleAddSongToPlaylist(
			context,
			Number(result.draggableId.split('_')[0]),
			Number(result.destination.droppableId.split('_')[1])
		);
	}
}
