import React from 'react';

import PropTypes from 'prop-types';

import Badge from '../Badge';

const SearchContext = ({ playlistInSearchContext, setPlaylistToSearchContext }) => {
	return (
		playlistInSearchContext && (
			<div>
				<div className='global-header__playlist_context'>
					<div className='d-flex align-items-center'>
						<span className='font-weight-bold mr-3'>Playlist:</span>
						<Badge text={playlistInSearchContext.name} closeCallback={() => setPlaylistToSearchContext(null)} />
					</div>
				</div>
			</div>
		)
	);
};

SearchContext.propTypes = {
	playlistInSearchContext: PropTypes.oneOfType([PropTypes.object]),
	setPlaylistToSearchContext: PropTypes.func.isRequired,
};

SearchContext.defaultProps = {
	playlistInSearchContext: null,
};

export default SearchContext;
