import React from 'react';

import Switch from 'react-switch';

import * as genresAPI from '../api/genres';
import DropdownButton from '../components/DropdownButton';
import DataTable from '../components/layout/DataTable';
import { PaginationButtons } from '../components/PaginationButtons';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import closeImg from '../images/close.svg';

const PAGE_SIZE = 50;

class GenresManager extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPageNum: 1,
			clearedGenres: [],
			genresMappings: [],
			includeUnmappedOnly: false,
			includeBlacklisted: true,
			newClearedGenre: '',
		};

		this.fetchGenresMappings = this.fetchGenresMappings.bind(this);
		this.fetchClearedGenres = this.fetchClearedGenres.bind(this);
		this.updateMapping = this.updateMapping.bind(this);
		this.addClearedGenre = this.addClearedGenre.bind(this);
		this.removeClearedGenre = this.removeClearedGenre.bind(this);
	}

	componentDidMount() {
		this.fetchGenresMappings();
		this.fetchClearedGenres();
	}

	componentDidUpdate(prevProps, prevState) {
		const { includeUnmappedOnly, includeBlacklisted } = this.state;

		if (prevState.includeUnmappedOnly !== includeUnmappedOnly || prevState.includeBlacklisted !== includeBlacklisted) {
			this.fetchGenresMappings();
		}
	}

	getGenresMappingTable = () => {
		const { clearedGenres, genresMappings, currentPageNum, includeUnmappedOnly, includeBlacklisted } = this.state;
		const currentPageData = genresMappings.slice(
			(currentPageNum - 1) * PAGE_SIZE,
			(currentPageNum - 1) * PAGE_SIZE + PAGE_SIZE
		);
		const numPages = Math.ceil(genresMappings.length / PAGE_SIZE);

		return (
			<>
				<div className='genre-manager__mappings__header'>
					<h4 className='h4'>Cleared Genres Mapping</h4>
					<div>
						<label className='mr-4'>
							<span className='genre-manager__switch__label'>Non Cleared Genres Only</span>
							<Switch
								onChange={() =>
									this.setState({
										includeUnmappedOnly: !includeUnmappedOnly,
									})
								}
								checked={includeUnmappedOnly}
								checkedIcon={false}
								uncheckedIcon={false}
								height={20}
								width={40}
								className='mt-1'
								onColor='#000'
								offColor='#d2d2d2'
							/>
						</label>
						<label className='mr-4'>
							<span className='genre-manager__switch__label'>Include Blacklisted Genres</span>
							<Switch
								onChange={() =>
									this.setState({
										includeBlacklisted: !includeBlacklisted,
									})
								}
								checked={includeBlacklisted}
								checkedIcon={false}
								uncheckedIcon={false}
								height={20}
								width={40}
								className='mt-1'
								onColor='#000'
								offColor='#d2d2d2'
							/>
						</label>
					</div>
				</div>

				<DataTable
					headerRow={['Genre', 'Cleared Genre']}
					fixedLayout={false}
					data={currentPageData.map(m => [
						m.genre,
						<DropdownButton
							key={`db-cgm-${m.genre}`}
							menuClassName='genre-manager__dropdown'
							onChange={e => this.updateMapping(m.genre, e.value)}
							options={[{ label: 'None', value: null }, ...clearedGenres.map(z => ({ label: z, value: z }))]}
							showArrowIcon
							showFilterIcon={false}
							value={m.cleared_genre || 'None'}
						/>,
					])}
				/>

				{numPages > 1 && (
					<div className='d-flex align-items-center mt-2'>
						<PaginationButtons
							handlePageChange={nextPage => {
								this.setState({ currentPageNum: nextPage });
							}}
							numPages={numPages}
							pageNum={currentPageNum}
						/>
					</div>
				)}
			</>
		);
	};

	getClearedGenreList = () => {
		const { clearedGenres, newClearedGenre } = this.state;

		return (
			<>
				<h4 className='h4 mb-4'>Cleared Genres List</h4>
				<DataTable
					className='genre-manager__cleared-list__table'
					headerRow={['Value']}
					data={clearedGenres.map((c, idx) => [
						// eslint-disable-next-line react/no-array-index-key
						<div key={`${c}_${idx}`} className='genre-manager__cleared-list__data-cell'>
							{c}
							<div className='genre-manager__cleared-list__btn-icon'>
								<img
									src={closeImg}
									alt='remove'
									onClick={() => this.removeClearedGenre(c)}
									style={{ height: '100%', width: '100%' }}
								/>
							</div>
						</div>,
					])}
					footerRow={[
						<div key='genre-manager__cleared-list__table-footer' className='genre-manager__cleared-list__table-footer'>
							+
							<input
								className='genre-manager__cleared-list__input'
								type='text'
								value={newClearedGenre}
								onChange={e => {
									this.setState({
										newClearedGenre: (e.target.value || '').trim(),
									});
								}}
								placeholder='Add new cleared genre'
							/>
							<div>
								<button
									name='GenresManger - Reset'
									className='m-0 secondary-btn'
									onClick={() => {
										this.setState({ newClearedGenre: '' });
									}}
									disabled={!newClearedGenre}
									type='button'
								>
									Reset
								</button>
								<button
									name='GenresManger - Submit'
									className='m-0 primary-btn'
									onClick={this.addClearedGenre}
									disabled={!newClearedGenre}
									type='submit'
								>
									Submit
								</button>
							</div>
						</div>,
					]}
				/>
			</>
		);
	};

	async fetchGenresMappings() {
		const { includeUnmappedOnly, includeBlacklisted } = this.state;
		const genresMappings = await genresAPI.getGenresMappings(includeUnmappedOnly, includeBlacklisted);

		this.setState({ genresMappings });
	}

	async fetchClearedGenres() {
		const clearedGenres = await genresAPI.getClearedGenres();

		this.setState({ clearedGenres });
	}

	async updateMapping(genre, clearedGenre) {
		const { createToast } = this.context;
		const { genresMappings } = this.state;

		await genresAPI
			.updateGenreMapping(genre, clearedGenre)
			.then(() => {
				createToast(`Mapped genre ${genre} to ${clearedGenre || 'None'}`, TOAST_LEVEL.SUCCESS, true);
				this.setState({
					genresMappings: genresMappings.map(m => ({
						...m,
						...(m.genre === genre && { cleared_genre: clearedGenre }),
					})),
				});
			})
			.catch(() => {
				createToast(
					`Could not map genre ${genre} with ${clearedGenre}, please refresh the page and try later`,
					TOAST_LEVEL.ERROR,
					true
				);
			});
	}

	async addClearedGenre() {
		const { createToast } = this.context;
		const { newClearedGenre } = this.state;

		if (newClearedGenre) {
			await genresAPI
				.addClearedGenre(newClearedGenre)
				.then(() => {
					this.setState({ newClearedGenre: '' });
					this.fetchClearedGenres();
				})
				.catch(() => {
					createToast(
						`Could not create new cleared genre ${newClearedGenre}, please refresh the page and try later`,
						TOAST_LEVEL.ERROR,
						true
					);
				});
		}
	}

	async removeClearedGenre(clearedGenre) {
		const { createToast } = this.context;

		if (clearedGenre) {
			await genresAPI
				.removeClearedGenre(clearedGenre)
				.then(() => {
					this.fetchClearedGenres();
					this.fetchGenresMappings();
				})
				.catch(() => {
					createToast(
						`Could not delete cleared genre ${clearedGenre}, please refresh the page and try later`,
						TOAST_LEVEL.ERROR,
						true
					);
				});
		}
	}

	render() {
		return (
			<div className='genre-manager'>
				<h3 className='h3 mb-5'>Genres Clearance Manager</h3>
				<div className='row'>
					<div className='col-md-4'>{this.getClearedGenreList()}</div>
					<div className='col-md-8'>{this.getGenresMappingTable()}</div>
				</div>
			</div>
		);
	}
}

GenresManager.contextType = GlobalContext;

export default GenresManager;
