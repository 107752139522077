import React from 'react';

import PropTypes from 'prop-types';

import { secondsToMSSFormatter, mmssToSecondsFormatter } from '../utils/date-time';

// Allow inputs 59:59:59.999
const DURATION_REGEXP = /([0-5]{1}[0-9]{1}:){0,2}[0-5]{0,1}[0-9]{1}(\.\d+)?/;
const ALLOWED_CHARS_REGEXP = /[0-9:]+/;
const COLON_REGEXP = /[:]/g;

class InOutField extends React.Component {
	constructor(props) {
		super(props);
		const duration = props.duration ? secondsToMSSFormatter(props.duration) : '';

		this.state = {
			inVal: props.inVal ? props.inVal : '0:00',
			outVal: props.outVal || duration,
			duration,
		};
		this.inValInputRef = React.createRef();
		this.outValInputRef = React.createRef();
	}

	handleInValSubmit(value) {
		const { duration, onInValSubmit } = this.props;
		const { duration: durationInState, outVal } = this.state;
		const useDuration = mmssToSecondsFormatter(value) > duration; // if new duration goes over the song's duration set it to the duration instead
		const reformattedValue = useDuration ? this.reformatValue(durationInState) : this.reformatValue(value);

		this.setState({ inVal: reformattedValue });
		onInValSubmit(reformattedValue);

		// if new value is greather than outVal, focus outVal
		// eslint-disable-next-line react/destructuring-assignment
		if (mmssToSecondsFormatter(reformattedValue) > mmssToSecondsFormatter(outVal)) {
			this.outValInputRef.current.focus();
		}
	}

	handleOutValSubmit(value) {
		const { duration, onOutValSubmit } = this.props;
		const { duration: durationInState, inVal } = this.state;
		const useDuration =
			!value || // no current value
			mmssToSecondsFormatter(value) > duration || // if new duration goes over the song's duration set it to the duration instead
			mmssToSecondsFormatter(value) < mmssToSecondsFormatter(inVal); // if new out duration is less than in duration
		const reformattedValue = useDuration ? this.reformatValue(durationInState) : this.reformatValue(value);

		this.setState({ outVal: reformattedValue });
		onOutValSubmit(reformattedValue);
	}

	handleInOutValChange(property, value) {
		this.setState({ [property]: value });
	}

	reformatValue = rawValue => {
		// if no value, just set it back to 0:00
		if (!(rawValue || '').trim().length) {
			return '0:00';
		}

		// if no colon (:), then assume seconds: reformat
		if (rawValue.indexOf(':') === -1) {
			return secondsToMSSFormatter(rawValue);
		}

		// if there are multiple colons or no value, messed up format, just set it back to 0:00
		if ((rawValue.match(COLON_REGEXP) || []).length > 1) {
			return '0:00';
		}

		// if missing number in front of colon (minute value), assume minute is 0
		if (!rawValue.split(':')[0].length) {
			// if only 1 digit after colon, append 0 as well
			if (rawValue.split(':').pop().length === 1) {
				return `0${rawValue}0`;
			}
			return `0${rawValue}`;
		}

		// if missing number after colon (seconds value), assume seconds is 0
		if (!rawValue.split(':').pop().length) {
			return `${rawValue}00`;
		}

		// if only 1 digit after colon, append digit with 0
		if (rawValue.split(':').pop().length === 1) {
			return `${rawValue}0`;
		}

		// trim leading or trailing zeros
		return `${parseInt(rawValue.split(':')[0], 10)}:${rawValue.split(':')[1].slice(0, 2)}`;
	};

	blurInput = elementRef => {
		if (elementRef && elementRef.current) {
			elementRef.current.blur();
		}
	};

	handleKeyPress = (event, property, elementRef) => {
		// On escape reset value and then blur input
		if (event.keyCode === 27) {
			// eslint-disable-next-line react/destructuring-assignment
			this.setState({ [property]: this.props[property] }, () => this.blurInput(elementRef));
		}

		// On enter trigger blur event
		if (event.keyCode === 13) {
			this.blurInput(elementRef);
		}
	};

	validInput = e => {
		if (!ALLOWED_CHARS_REGEXP.test(e.data)) {
			e.preventDefault();
		}
	};

	render() {
		const { disabled } = this.props;
		const { inVal, outVal } = this.state;

		return (
			<span className='songslist__row__zone-transition-io'>
				<div className='songslist__row__zone-transition-io__field songslist__row__zone-transition-io__field--io px-2'>
					<label className='mr-4 mb-0'>
						<b>In:</b>
					</label>
					<input
						ref={this.inValInputRef}
						disabled={disabled}
						type='text'
						value={inVal}
						onChange={e => this.handleInOutValChange('inVal', e.target.value)}
						style={{ width: '55%', border: 'none' }}
						placeholder='0:00'
						pattern={DURATION_REGEXP}
						onBeforeInput={this.validInput}
						onBlur={e => this.handleInValSubmit(e.target.value)}
						onKeyDown={e => this.handleKeyPress(e, 'inVal', this.inValInputRef)}
					/>
				</div>
				<div className='songslist__row__zone-transition-io__field songslist__row__zone-transition-io__field--io px-2'>
					<label className='mb-0'>
						<b>Out:</b>
					</label>
					<input
						ref={this.outValInputRef}
						disabled={disabled}
						type='text'
						value={outVal}
						onChange={e => this.handleInOutValChange('outVal', e.target.value)}
						style={{ width: '55%', border: 'none', marginLeft: '0.65rem' }}
						placeholder='0:00'
						pattern={DURATION_REGEXP}
						onBeforeInput={this.validInput}
						onBlur={e => this.handleOutValSubmit(e.target.value)}
						onKeyDown={e => this.handleKeyPress(e, 'outVal', this.outValInputRef)}
					/>
				</div>
			</span>
		);
	}
}
InOutField.propTypes = {
	disabled: PropTypes.bool,
	duration: PropTypes.number.isRequired,
	inVal: PropTypes.string,
	onInValSubmit: PropTypes.func.isRequired,
	onOutValSubmit: PropTypes.func.isRequired,
	outVal: PropTypes.string,
};

InOutField.defaultProps = {
	inVal: '',
	outVal: '',
	disabled: false,
};

export default InOutField;
