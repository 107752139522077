import React from 'react';

import PropTypes from 'prop-types';

import { getUser, isAdministrator } from '../utils/auth';

const currentUser = getUser();
const isCurrentUserAdmin = isAdministrator();

const TeamGrid = ({ users, onEditClick, onRemoveClick, brands }) => (
	<table className='team-grid'>
		<thead>
			<tr className='team-grid__header-row'>
				<th style={{ width: '25%' }} className='team-grid__header-cell'>
					Name
				</th>
				<th style={{ width: '25%' }} className='team-grid__header-cell'>
					Role
				</th>
				<th style={{ width: '25%' }} className='team-grid__header-cell'>
					Brand(s)
				</th>
				<th style={{ width: '25%' }} className='team-grid__header-cell' aria-hidden />
			</tr>
		</thead>
		<tbody>
			{users.map(u => {
				const isCurrentUser = u.username === currentUser.username;

				return (
					<tr key={u.username} className='team-grid__row'>
						<td>
							{(u.first_name && u.last_name && u.middle_name) || u.first_name
								? `${u.first_name} ${u.middle_name} ${u.last_name}`
								: u.username}
						</td>
						<td>{(u.roles ? u.roles : []).map(r => r.description).join(', ')}</td>
						<td>
							{brands && brands.length > 0 && u.brand_ids
								? brands
										.filter(b => u.brand_ids.includes(b.brand_id))
										.map(b => b.description)
										.join(', ')
								: null}
						</td>
						<td className='team-grid__actions-cell'>
							{isCurrentUser || isCurrentUserAdmin ? (
								<button
									name='TeamGrid - Edit'
									className='team-grid__grid-btn'
									onClick={() => onEditClick(u)}
									type='button'
								>
									Edit
								</button>
							) : null}
							{isCurrentUserAdmin ? (
								<button
									name='TeamGrid - Remove'
									className='team-grid__grid-btn'
									onClick={() => onRemoveClick(u)}
									type='button'
								>
									Remove
								</button>
							) : null}
						</td>
					</tr>
				);
			})}
		</tbody>
	</table>
);

TeamGrid.propTypes = {
	brands: PropTypes.arrayOf(PropTypes.object),
	onEditClick: PropTypes.func.isRequired,
	onRemoveClick: PropTypes.func.isRequired,
	users: PropTypes.arrayOf(PropTypes.object),
};

TeamGrid.defaultProps = {
	brands: [],
	users: [],
};

export default TeamGrid;
