module.exports = {
	ERRORS: {
		USER_NOT_FOUND: 'User not found.',
		INCORRECT_PASSWORD: 'Incorrect password.',
		NOT_LOGGED_IN: 'Not logged in.',
		UNAUTHORIZED: 'Unauthorized.',
		FORBIDDEN: 'Forbidden.',
		PLAYLIST_NOT_FOUND: 'Playlist not found.',
		PASSWORD_DOES_NOT_MATCH: 'Password does not match.',
		PLAYLIST_CONTAINS_INVALID_SONGS: 'Playlist contains invalid songs.',
		NOTIFICATION_NOT_FOUND: 'Notification not found.',
		INVALID_SONG: 'Song is invalid.',
		MISSING_PARAMETERS: 'Missing parameters.',
		USERNAME_ALREADY_EXISTS: 'Username already exists.',
		MISSING_SONG_URL: 'Missing song url.',
		ASSET_NOT_FOUND: 'Asset not found.',
		INVALID_ASSET: 'Invalid asset request.',
		INVALID_ARGUMENTS: 'There are invalid arguments within your request please revise.',
		SHARED_FOLDER_ALREADY_EXISTS: 'Another folder with the same name already exists for the brand.',
		NOT_ENOUGH_RIGHTS: 'Insufficient rights to perform this action.',
		INSTRUCTOR_BRAND_CHANGE: 'Instructor brand cannot be changed.',
		PLAYLIST_BRAND_ACCESS: 'This playlist cannot be viewed or modified based on your brand(s).',
		JOB_RUN_NOT_ALLOWED: 'This job can not be started, there is a job currently running',
		INVALID_EMAIL_FORMAT: 'Invalid email format',
		EMAIL_ALREADY_EXISTS: 'The email already exists',
	},
};
