import React, { useState, useContext, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as searchApi from '../../api/search';
import Badge from '../../components/Badge';
import Loader from '../../components/Loader';
import ArtistCard from '../../components/search/ArtistCard';
import Collection from '../../components/search/Collection';
import CollectionCard, { CollectionCardStatus } from '../../components/search/CollectionCard';
import SongResult from '../../components/search/SongResult';
import TopResultCard from '../../components/search/TopResultCard';
import { LENGTH_RANGES } from '../../constants/length-ranges';
import { SEARCH_COLLECTIONS } from '../../constants/search';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as ArrowBack } from '../../images/arrow-back.svg';
import {
	getArtistInitials,
	formatBPM,
	formatSongDuration,
	getSongsCountLabel,
	formatArtistName,
} from '../../utils/songs';

const SearchResults = ({ history, location }) => {
	const urlParams = new URLSearchParams(location.search);
	const term = urlParams.get('q');
	const genres = urlParams.get('genres');
	const bpmRanges = urlParams.get('bpm');
	const decades = urlParams.get('decades');
	const lengthRanges = urlParams.get('lengths');
	const filters = (genres ? genres.split(',') : [])
		.concat(bpmRanges ? bpmRanges.split(',') : [])
		.concat(decades ? decades.split(',') : [])
		.concat(
			lengthRanges
				? lengthRanges.split(',').map(l => {
						return LENGTH_RANGES.find(r => r.value === l).text;
				  })
				: []
		);

	if (term) {
		filters.push(`"${term}"`);
	}

	const urlEncodeTerm = encodeURIComponent(term.replace('&', '%26'));
	const [loaded, setLoaded] = useState(false);
	const [results, setResults] = useState({});
	const { createToast } = useContext(GlobalContext);
	const { songs, topSong, playlists, topPlaylist, albums, topAlbum, artists, topArtist } = results;
	const isCollectionEmpty = (collection = []) => !collection.length;
	const isEmpty =
		isCollectionEmpty(songs) && isCollectionEmpty(albums) && isCollectionEmpty(artists) && isCollectionEmpty(playlists);

	const handleBack = () => {
		history.push('/');
	};

	useEffect(() => {
		const loadResults = async () => {
			setLoaded(false);

			await searchApi
				.search({ term, genres, bpmRanges, decades, lengthRanges })
				.then(res => setResults(res))
				.catch(() => {
					createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
				});

			setLoaded(true);
		};

		loadResults();
	}, [term, genres, bpmRanges, decades, lengthRanges, createToast]);

	return (
		<div className='search'>
			<div className='search__back mb-3' onClick={handleBack}>
				<ArrowBack />
				Back
			</div>
			<Loader loaded={loaded} className='search__loader' title='Searching...'>
				<div className='search__filters'>
					<span>Your Selection:</span>
					{filters.map(f => (
						<Badge key={f} className='song-table__filter__badge' text={f} />
					))}
				</div>
				{isEmpty ? (
					<div className='search__empty'>
						<div className='search__empty__container'>
							<div className='search__empty__header'>No search results</div>
							<div className='search__empty__sub'>Try a different keyword or filter</div>
						</div>
					</div>
				) : (
					<>
						<h3 className='search__header'>Top Results</h3>
						<div className='d-flex my-4'>
							{topSong && (
								<TopResultCard
									title={topSong.name}
									subtitle={[
										topSong.display_artist,
										[formatBPM(topSong.bpm), formatSongDuration(topSong.duration)].filter(s => !!s).join(' - '),
									]}
									image={topSong.thumbnail_image || topSong.image}
									key='top_song'
									explicit={topSong.explicit}
									redirectTo={`/search/${SEARCH_COLLECTIONS.SONG}?q=${urlEncodeTerm}&h=${topSong.song_id}`}
								/>
							)}
							{topArtist && (
								<TopResultCard
									title={formatArtistName(topArtist.display_artist)}
									subtitle={getSongsCountLabel(topArtist.songsCount)}
									imagePlaceholder={getArtistInitials(topArtist.name)}
									key='top_artist'
									roundImage
									redirectTo={`/search/${SEARCH_COLLECTIONS.ARTIST}/${encodeURIComponent(
										topArtist.name.replace('&', '%26')
									)}/songs?q=${urlEncodeTerm}`}
								/>
							)}
							{topAlbum && (
								<TopResultCard
									title={topAlbum.name}
									subtitle={[topAlbum.display_artist, `~${getSongsCountLabel(topAlbum.count)}`]}
									image={topAlbum.thumbnail_image || topAlbum.image}
									key='top_album'
									redirectTo={`/search/${SEARCH_COLLECTIONS.ALBUM}/${encodeURIComponent(
										topAlbum.name.replace('&', '%26')
									)}/${topAlbum.release_icpn}/songs?q=${urlEncodeTerm}`}
								/>
							)}
							{topPlaylist && (
								<TopResultCard
									title={topPlaylist.name}
									image={topPlaylist.cover_thumb_images || topPlaylist.cover_images}
									subtitle={[
										getSongsCountLabel((topPlaylist.song || []).length),
										<CollectionCardStatus
											status={topPlaylist.playlist_status_name}
											key={`${topPlaylist.playlist_id}_status`}
										/>,
									]}
									status={topPlaylist.playlist_status_name}
									key='top_playlist'
									redirectTo={`/playlist/${topPlaylist.playlist_id}`}
								/>
							)}
						</div>

						{!!(songs || []).length && (
							<Collection
								className='search__collection'
								header='Songs'
								seeAllLink={`/search/${SEARCH_COLLECTIONS.SONG}?${urlParams.toString()}`}
								itemsPerRow={3}
							>
								{songs.map(song => (
									<SongResult
										name={song.name}
										artist={song.display_artist}
										bpm={song.bpm}
										duration={song.duration}
										image={song.thumbnail_image || song.image}
										key={song.variis_track_id}
										redirectTo={`/search/${SEARCH_COLLECTIONS.SONG}?q=${urlEncodeTerm}&h=${song.song_id}`}
										explicit={song.explicit}
									/>
								))}
							</Collection>
						)}

						{!!(artists || []).length && (
							<Collection
								header='Artists'
								seeAllLink={`/search/${SEARCH_COLLECTIONS.ARTIST}?q=${urlEncodeTerm}`}
								itemsPerRow={3}
							>
								{artists.map((artist, i) => (
									<ArtistCard
										name={artist.display_artist}
										description={getSongsCountLabel(artist.songsCount)}
										showInitials
										key={artist.display_artist}
										redirectTo={`/search/${SEARCH_COLLECTIONS.ARTIST}/${encodeURIComponent(
											artist.name.replace('&', '%26')
										)}/songs?q=${urlEncodeTerm}`}
										handleBlacklistArtist={() => {
											window.location.reload();
										}}
									/>
								))}
							</Collection>
						)}

						{!!(albums || []).length && (
							<Collection
								header='Albums'
								seeAllLink={`/search/${SEARCH_COLLECTIONS.ALBUM}?q=${urlEncodeTerm}`}
								itemsPerRow={3}
							>
								{albums.map((album, i) => (
									<CollectionCard
										image={album.thumbnail_image || album.image}
										title={album.name}
										subtitle={album.display_artist}
										key={album.name.replace('&', '%26')}
										redirectTo={`/search/${SEARCH_COLLECTIONS.ALBUM}/${encodeURIComponent(
											album.name.replace('&', '%26')
										)}/${album.release_icpn}/songs?q=${urlEncodeTerm}`}
									/>
								))}
							</Collection>
						)}

						{!!(playlists || []).length && (
							<Collection
								header='Playlists'
								seeAllLink={`/search/${SEARCH_COLLECTIONS.PLAYLIST}?q=${urlEncodeTerm}`}
								itemsPerRow={3}
							>
								{playlists.map(playlist => (
									<CollectionCard
										image={playlist.cover_thumb_images || playlist.cover_images}
										title={playlist.name}
										subtitle={getSongsCountLabel((playlist.song || []).length)}
										key={playlist.playlist_id}
										status={playlist.playlist_status_name}
										redirectTo={`/playlist/${playlist.playlist_id}`}
									/>
								))}
							</Collection>
						)}
					</>
				)}
			</Loader>
		</div>
	);
};

SearchResults.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(SearchResults);
