module.exports = {
	PLAYLIST_EVENTS: {
		PLAYLIST_STATUS_CHANGE: 'PLAYLIST_STATUS_CHANGE',
		PLAYLIST_VERSION_CREATED: 'PLAYLIST_VERSION_CREATED',
		PLAYLIST_VERSION_PUSHED_AIRTABLE: 'PLAYLIST_VERSION_PUSHED_AIRTABLE',
		PLAYLIST_VERSION_UPDATED_AIRTABLE: 'PLAYLIST_VERSION_UPDATED_AIRTABLE',
	},
	PLAYLIST_STATUS_MAP: {
		1: 'Draft',
		2: 'Submitted',
		3: 'Approved',
		4: 'Rejected',
		5: 'Invalid',
		6: 'Archived',
		7: 'Valid',
	},
	PLAYLIST_STATUS: {
		DRAFT: 1,
		SUBMITTED: 2,
		APPROVED: 3,
		REJECTED: 4,
		INVALID: 5,
		ARCHIVED: 6,
		VALID: 7,
	},
	PLAYLIST_TYPE_MAP: {
		1: 'Live',
		2: 'VoD',
	},
	PLAYLIST_TYPE: {
		LIVE: 1,
		VOD: 2,
	},
};
