import React from 'react';

import PropTypes from 'prop-types';

import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import cancelGreyImg from '../images/cancel-grey.svg';

const DEFAULT_TOAST_AUTOHIDE_DELAY = 5000; // 5 seconds

class Toast extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showToast: false,
		};
		this.removeToast = this.removeToast.bind(this);
		this.fadeInToast = this.fadeInToast.bind(this);
	}

	componentDidMount() {
		const { hideTimeout, autoHide } = this.props;

		this.fadeInToast();
		if (autoHide) {
			this.autoHideTimer = setTimeout(async () => {
				this.setState({ showToast: false });
				this.fadeOutRemoveToast();
			}, hideTimeout || DEFAULT_TOAST_AUTOHIDE_DELAY);
		}
	}

	componentWillUnmount() {
		this.clearTimers();
	}

	clearTimers = () => {
		if (this.autoHideTimer) {
			clearTimeout(this.autoHideTimer);
			this.autoHideTimer = 0;
		}

		if (this.fadeInTimer) {
			clearTimeout(this.fadeInTimer);
			this.fadeInTimer = 0;
		}

		if (this.fadeOutTimer) {
			clearTimeout(this.fadeOutTimer);
			this.fadeOutTimer = 0;
		}
	};

	getHeaderColor = level => {
		if (level === TOAST_LEVEL.SUCCESS) {
			return 'toast--success';
		}
		if (level === TOAST_LEVEL.ERROR) {
			return 'toast--error';
		}
		if (level === TOAST_LEVEL.WARNING) {
			return 'toast--warning';
		}
		return '';
	};

	fadeInToast() {
		this.fadeInTimer = setTimeout(() => {
			this.setState({ showToast: true });
			this.fadeInTimer = 0;
		}, 200);
	}

	fadeOutRemoveToast() {
		this.fadeOutTimer = setTimeout(() => {
			this.removeToast();
			this.fadeOutTimer = 0;
		}, 250);
	}

	async removeToast() {
		const { message, callback } = this.props;
		const { removeToast } = this.context;

		removeToast(message);
		if (callback) {
			await callback();
		}
	}

	render() {
		const { level, message } = this.props;
		const { showToast } = this.state;

		return (
			<div
				className={`toast ${showToast ? 'toast--visible' : 'toast--hidden'}`}
				role='alert'
				aria-live='assertive'
				aria-atomic='true'
			>
				<div className={`toast-header d-flex justify-content-between ${this.getHeaderColor(level)}`}>
					{level}
					<img src={cancelGreyImg} alt='close notification' className='toast__close' onClick={this.removeToast} />
				</div>
				<div className='toast-body'>{message}</div>
			</div>
		);
	}
}
Toast.contextType = GlobalContext;

Toast.propTypes = {
	autoHide: PropTypes.bool,
	callback: PropTypes.func,
	hideTimeout: PropTypes.number,
	level: PropTypes.string,
	message: PropTypes.string,
};

Toast.defaultProps = {
	autoHide: false,
	callback: null,
	hideTimeout: 0,
	level: '',
	message: '',
};

const Toasts = ({ toasts }) => (
	<div className='toast__container'>
		{toasts.map((toast, index) => (
			<Toast
				message={toast.message}
				level={toast.level}
				autoHide={toast.autoHide}
				hideTimeout={toast.hideTimeout}
				key={index}
				callback={toast.callback}
			/>
		))}
	</div>
);

Toasts.propTypes = {
	toasts: PropTypes.arrayOf(PropTypes.object),
};

Toasts.defaultProps = {
	toasts: [],
};

export default Toasts;
