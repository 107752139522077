module.exports = {
	SCHEDULED_JOBS: {
		AIRTABLE_MUSIC_DROP_SYNC: {
			key: 'AIRTABLE_MUSIC_DROP_SYNC',
			name: 'Music Drop base sync',
			awsTaskDefinitionName: 'AirtableMusicDropSyncTaskDefinition',
			cronExpression: '*/2 * * * *',
			description: 'Sync playlist versions that have been approved at least once into Music Drop Airtable base',
			notifyOnError: true,
			preventRetries: true,
			keepAliveMinutes: 300, // keep alive for 5 hrs before before sending hung process error
			canReset: true,
			canRun: false,
			prodOnly: false,
		},
		ARCHIVE_XMLS: {
			key: 'ARCHIVE_XMLS',
			name: 'Archive XMLs',
			awsTaskDefinitionName: 'ArchiveXMLsTaskDefinition',
			cronExpression: '0 18 * * *',
			description: 'Uploads old metadata XMLs to S3 Glacier for long-term storage',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		AUDIO_FEATURES_ENRICH: {
			key: 'AUDIO_FEATURES_ENRICH',
			name: 'BPM Spotify enrichment',
			awsTaskDefinitionName: 'EnrichTaskDefinition',
			cronExpression: '*/5 * * * *',
			description: 'Fills BPM for new ingested music from music labels using Spotify API',
			notifyOnError: false,
			preventRetries: false,
			keepAliveMinutes: 1440, // keep alive for 24 hrs as it will try to enrich all catalog that has no BPM data
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		ALBUM_IMAGE_RESIZE: {
			key: 'ALBUM_IMAGE_RESIZE',
			name: 'Album cover thumbnails generator',
			awsTaskDefinitionName: 'ResizeImagesTaskDefinition',
			cronExpression: '0 18 * * *',
			description:
				'Checks for songs without a thumbnail album coverart image and generates it to reduce load speeds within UI',
			notifyOnError: false,
			preventRetries: false,
			keepAliveMinutes: 1440, // keep alive for 24 hrs as it will try against full catalog
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		EXPORT_MRI_CLM: {
			key: 'EXPORT_MRI_CLM',
			name: 'MRI CLM export ',
			awsTaskDefinitionName: 'ExportMRITaskDefinition',
			cronExpression: '0 1 * * *',
			description:
				'Generates CLM with new music that have ingested after latest CLM or music that has not been validated by MRI in more than 2 weeks or music that has been updated since lastest CLM generated. The generated CLM will be uploaded to MRI SFTP',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			canRun: true,
			prodOnly: false,
		},
		IMPORT_MRI_LSR: {
			key: 'IMPORT_MRI_LSR',
			name: 'MRI LSR import',
			awsTaskDefinitionName: 'ImportMRITaskDefinition',
			cronExpression: '0 18 * * 1-5',
			description:
				'Ingest daily LSRs that have being uploaded within MRI SFTP, updates songs and playlists validity statuses within playlist tool database. Send email notifications on playlists invalidation',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			prodOnly: false,
		},
		EXTERNAL_SHOW_PLAYLISTS_SYNC: {
			key: 'EXTERNAL_SHOW_PLAYLISTS_SYNC',
			name: 'Show playlists sync',
			awsTaskDefinitionName: 'SyncExternalPlaylistsTaskDefinition',
			cronExpression: '0 3 * * *',
			description:
				'Sync and ingest back show playlist from "Show Playlist - Konrad view" table from various brand bases (SoulCycle, SoulCycle Live, Rumble, PRR, EQX, EQX Live, ...)',
			notifyOnError: true,
			preventRetries: false,
			canReset: false,
			canRun: true,
			prodOnly: false,
		},
		CATALOG_SANITY_CHECKS: {
			key: 'CATALOG_SANITY_CHECKS',
			name: 'Catalog sanity checks',
			awsTaskDefinitionName: 'MusicClearanceSanityCheckTaskDefinition',
			cronExpression: '0 20 * * *',
			description: 'Sanity checks to inspect music catalog inconsistencies that could affect music validity statuses',
			notifyOnError: false,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			canRun: true,
			prodOnly: false,
		},
		REFRESH_SONG_VALIDITY: {
			key: 'REFRESH_SONG_VALIDITY',
			name: 'Songs validity review',
			awsTaskDefinitionName: 'RefreshSongValidityTaskDefinition',
			cronExpression: '0 1,10 * * *',
			description:
				"Review song's validity status up to date ,in order to validate releases with a future release date that has been reached or invalidate expired releases. When invalidating notifications will be sent and search index updated",
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			canRun: true,
			prodOnly: false,
		},
		WARNER_QUEUE_RELEASES: {
			key: 'WARNER_QUEUE_RELEASES',
			name: 'Warner queue releases',
			awsTaskDefinitionName: 'WarnerQueueReleasesTaskDefinition',
			cronExpression: '*/5 * * * *',
			description: 'Adds releases in the Warner S3 bucket into the database queue to be processed later',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 60, // keep alive for 1 hr before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		WARNER_INGEST: {
			key: 'WARNER_INGEST',
			name: 'Warner releases ingestion pipeline',
			awsTaskDefinitionName: 'WarnerIngestTaskDefinition',
			cronExpression: '*/30 * * * *',
			description:
				'Ingests a set number of Warner releases in the database ingestion queue. For each queued item, the corresponding DDEX v3.1 (ERN-3) release message in the Warner S3 bucket is read to determine the action to be taken (add, update or purge release)',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		ORCHARD_QUEUE_RELEASES: {
			key: 'ORCHARD_QUEUE_RELEASES',
			name: 'Orchard queue releases',
			awsTaskDefinitionName: 'OrchardQueueReleasesTaskDefinition',
			cronExpression: '*/5 * * * *',
			description: 'Adds releases in the Orchard S3 bucket into the database queue to be processed later',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		ORCHARD_INGEST: {
			key: 'ORCHARD_INGEST',
			name: 'Orchard releases ingestion pipeline',
			awsTaskDefinitionName: 'OrchardIngestTaskDefinition',
			cronExpression: '*/2 * * * *',
			description:
				'Ingests a set number of Orchard releases in the database ingestion queue. For each queued item, the corresponding DDEX v3.1 (ERN-3) release message in the Orchard S3 bucket is read to determine the action to be taken (add, update or purge release)',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		SONY_INGEST: {
			key: 'SONY_INGEST',
			name: 'Sony releases ingestion pipeline',
			awsTaskDefinitionName: 'SonyIngestTaskDefinition',
			cronExpression: '*/5 * * * *',
			description:
				'Ingestion pipeline for Sony Music Entertainment, process DDEX v3.1 (ERN-3) messages to ingest new releases, update releases and purge releases',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 720, // keep alive for half day before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		SONY_INGEST_RETRY: {
			key: 'SONY_INGEST_RETRY',
			name: 'Sony retry releases ingestion pipeline',
			awsTaskDefinitionName: 'SonyRetryReleasesTaskDefinition',
			cronExpression: '*/20 * * * *',
			description: 'Retry failed release ingestion for Sony Music Entertainment',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 720, // keep alive for half day before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		UMG_INGEST: {
			key: 'UMG_INGEST',
			name: 'UMG products ingestion pipeline',
			awsTaskDefinitionName: 'UMGIngestTaskDefinition',
			cronExpression: '*/5 * * * *',
			description:
				'Ingest UMG products that are in the database ingestion queue for products to be ingested until no other product is pending to ingest. Every minute will process the entire queue until no more products need to be ingested. Ingestion pipeline uses DDEX v4.2 (ERN-4) and takes care of new music ingestion and updates. Songs purges are handled though updates by expiring corresponding deal dates',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		TELEPORTER_JS_DOWNLOAD: {
			key: 'TELEPORTER_JS_DOWNLOAD',
			name: 'UMG Teleporter download',
			awsTaskDefinitionName: 'TeleporterTaskDefinition',
			cronExpression: '0,15,30,45 * * * *',
			description:
				"Executes Teleporter to download all pending orders with products to be ingest from Universal Music's Unified Content Site (UCS), orders metadata will be stored within server filesystem to be queued and ingested by other scheduled jobs",
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 720, // keep alive for half day before sending hung process error
			canRun: false,
			canReset: false,
			prodOnly: true,
		},
		UMG_QUEUE_SYNC: {
			key: 'UMG_QUEUE_SYNC',
			name: 'UMG ingestion queue sync',
			awsTaskDefinitionName: 'SyncUMGProductsTaskDefinition',
			cronExpression: '10,25,40,55 * * * *',
			description: 'Transfer downloaded products into the database queue to be ingested within music catalog system',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		GENERATE_CATALOG_REPORTS: {
			key: 'GENERATE_CATALOG_REPORTS',
			name: 'Generate Downloadable Catalog Reports',
			awsTaskDefinitionName: 'GenerateCatalogReportsTaskDefinition',
			cronExpression: '0 */1 * * *',
			description: 'Generate downloadable catalog reports',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		EXPIRING_SONG_DEAL_NOTIFICATIONS: {
			key: 'EXPIRING_SONG_DEAL_NOTIFICATIONS',
			name: 'Expiring Song Deal Notifications',
			awsTaskDefinitionName: 'ExpiringSongDealNotificationsTaskDefinition',
			cronExpression: '0 0 * * *',
			description:
				'Email notifications for songs (UMG, Sony & Orchard) that have been used in playlists/show playlists and are being expired',
			notifyOnError: true,
			preventRetries: false,
			keepAliveMinutes: 300, // keep alive for 5 hrs before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: false,
		},
		REINDEX_SONGS_INDEX: {
			key: 'REINDEX_SONGS_INDEX',
			name: 'Reindex Songs Index',
			cronExpression: null,
			description: 'Index full cleared catalog songs for search engine SONGS index',
			keepAliveMinutes: 900, // keep alive for 15 hrs before before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: true,
		},
		REINDEX_PLAYLISTS_INDEX: {
			key: 'REINDEX_PLAYLISTS_INDEX',
			name: 'Reindex Playlists Index',
			cronExpression: null,
			description: 'Index all playlists for search engine PLAYLISTS index',
			keepAliveMinutes: 900, // keep alive for 15 hrs before before sending hung process error
			canReset: false,
			canRun: false,
			prodOnly: true,
		},
	},
	SCHEDULED_JOBS_STATUSES: {
		RUNNING: 'RUNNING',
		STARTING: 'STARTING',
		COMPLETED: 'COMPLETED',
		FAILED: 'FAILED',
	},
};
