import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { withRouter } from 'react-router';
import { useVirtual } from 'react-virtual';

import { acknowledgeNotification, acknowledgeNotificationPopup, getUserNotifications } from '../api/notifications';
import { NOTIFICATION_EVENT } from '../constants/notification_event';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import alertBlackImg from '../images/alert-black.svg';
import bellEmptyImgBlack from '../images/bell-empty-black.svg';
import bellEmptyImgWhite from '../images/bell-empty.svg';
import bellNotificationImgBlack from '../images/bell-notifs-black.svg';
import bellNotificationImgWhite from '../images/bell-notifs.svg';
import bellRingingImg from '../images/bell-ringing.svg';

const NOTIFICATION_TIMEOUT_MSEC = 15000;

const getIcon = eventName => {
	// show urgent Alert image for notifications about invalid songs or playlists
	if (
		[
			NOTIFICATION_EVENT.SONG_INVALIDATED_SONG,
			NOTIFICATION_EVENT.PLAYLIST_INVALIDATED_PLAYLIST,
			NOTIFICATION_EVENT.PLAYLIST_CONTENT_CREATOR_INVALIDATED_PLAYLIST,
			NOTIFICATION_EVENT.PLAYLIST_CONTENT_CREATOR_INVALIDATED_EXTERNAL_PLAYLIST,
		].includes(eventName)
	) {
		return alertBlackImg;
	}
	// show generic notification icon for the rest
	return bellRingingImg;
};

const getText = (eventName, name, firstName, lastName, globalTrackerShowId) => {
	if (eventName === NOTIFICATION_EVENT.SONG_INVALIDATED_SONG) {
		return 'A song in your library has become unavailable.';
	}
	if (eventName === NOTIFICATION_EVENT.PLAYLIST_SUBMITTED_FOR_APPROVAL) {
		return `Playlist "${name}" by ${firstName} ${lastName} has been submitted and is ready for approval`;
	}
	if (eventName === NOTIFICATION_EVENT.PLAYLIST_INVALIDATED_PLAYLIST) {
		return 'A playlist has been flagged as invalid.';
	}
	if (eventName === NOTIFICATION_EVENT.PLAYLIST_CONTENT_CREATOR_INVALIDATED_PLAYLIST) {
		return `Playlist "${name}" has been flagged as invalid.`;
	}
	if (eventName === NOTIFICATION_EVENT.PLAYLIST_CONTENT_CREATOR_INVALIDATED_EXTERNAL_PLAYLIST) {
		return `Show Playlist "${globalTrackerShowId}" has been flagged as invalid.`;
	}
	if (eventName === NOTIFICATION_EVENT.PLAYLIST_APPROVED_PLAYLIST) {
		return `Playlist "${name}" has been approved!`;
	}
	if (eventName === NOTIFICATION_EVENT.PLAYLIST_REJECTED_PLAYLIST) {
		return `Playlist "${name}" has been rejected, please revise the playlist.`;
	}
	if (eventName === NOTIFICATION_EVENT.PLAYLIST_REJECTED_PLAYLIST) {
		return `Playlist "${name}" has been rejected, please revise the playlist.`;
	}
	if (eventName === NOTIFICATION_EVENT.PLAYLIST_VERSION_SYNCED_TO_AIRTABLE) {
		return `Playlist "${name}" version has been synced to airtable, please revise the playlist.`;
	}
	if (eventName === NOTIFICATION_EVENT.SONG_BLACKLISTED_SONG_RESTORED) {
		return `Songs in playlist "${name}" have become available.`;
	}
	if (eventName === NOTIFICATION_EVENT.SONG_BLACKLISTED_SONG) {
		return `Songs in your library have become unavailable.`;
	}
	if (eventName === NOTIFICATION_EVENT.ARTIST_BLACKLISTED_ARTIST) {
		return `Artist(s) in your library have become unavailable.`;
	}
	if (eventName === NOTIFICATION_EVENT.ARTIST_BLACKLISTED_ARTIST_RESTORED) {
		return `Artist(s) in playlist "${name}" have become available.`;
	}
	return null;
};

const onNotificationClick = async (
	eventName,
	notificationId,
	playlistId,
	history,
	doAfterAcknowledge,
	globalTrackerShowId
) => {
	let acknowledgedNotification = false;
	// send Acknowledge Notification request on notifications that are dismissible
	if (
		[
			NOTIFICATION_EVENT.SONG_INVALIDATED_SONG, // instructor acks invalid song in library
			NOTIFICATION_EVENT.PLAYLIST_INVALIDATED_PLAYLIST, // instructor acks invalid playlist
			NOTIFICATION_EVENT.PLAYLIST_APPROVED_PLAYLIST, // instructor acks their playlist has been approved
			NOTIFICATION_EVENT.PLAYLIST_REJECTED_PLAYLIST, // instructor acks their playlist has been rejected
			NOTIFICATION_EVENT.PLAYLIST_VERSION_SYNCED_TO_AIRTABLE, // Playlist version has been approved and automacally pushed to airtable or updated and pushed edits to airtable
			NOTIFICATION_EVENT.SONG_BLACKLISTED_SONG_RESTORED, // instructor acks blacklisted song that got un-blacklisted
			NOTIFICATION_EVENT.SONG_BLACKLISTED_SONG, // instructor acks blacklisted song that got blacklisted
			NOTIFICATION_EVENT.ARTIST_BLACKLISTED_ARTIST, // instructor acks blacklisted artist that got blacklisted
		].includes(eventName)
	) {
		await acknowledgeNotification(notificationId);
		acknowledgedNotification = true;
	}

	// redirect user to Playlist Pending Approval or Invalid Playlist or for Airtable notifications, or playlist containing a (un)blacklisted song
	if (
		[
			NOTIFICATION_EVENT.PLAYLIST_SUBMITTED_FOR_APPROVAL,
			NOTIFICATION_EVENT.PLAYLIST_INVALIDATED_PLAYLIST,
			NOTIFICATION_EVENT.PLAYLIST_CONTENT_CREATOR_INVALIDATED_PLAYLIST,
			NOTIFICATION_EVENT.SONG_BLACKLISTED_SONG_RESTORED,
			NOTIFICATION_EVENT.SONG_BLACKLISTED_SONG,
			NOTIFICATION_EVENT.ARTIST_BLACKLISTED_ARTIST,
		].includes(eventName)
	) {
		history.push(`/playlist/${playlistId}`);
	}
	if (eventName === NOTIFICATION_EVENT.PLAYLIST_CONTENT_CREATOR_INVALIDATED_EXTERNAL_PLAYLIST) {
		history.push(`/show-playlist/${globalTrackerShowId}`);
	}
	if (eventName === NOTIFICATION_EVENT.SONG_INVALIDATED_SONG) {
		history.push('/my-songs');
	}
	if (acknowledgedNotification && doAfterAcknowledge && typeof doAfterAcknowledge === 'function') {
		doAfterAcknowledge();
	}
};

const showDateString = eventName =>
	eventName === NOTIFICATION_EVENT.PLAYLIST_SUBMITTED_FOR_APPROVAL ||
	eventName === NOTIFICATION_EVENT.PLAYLIST_INVALIDATED_PLAYLIST ||
	eventName === NOTIFICATION_EVENT.PLAYLIST_CONTENT_CREATOR_INVALIDATED_PLAYLIST ||
	eventName === NOTIFICATION_EVENT.PLAYLIST_CONTENT_CREATOR_INVALIDATED_EXTERNAL_PLAYLIST;

const getNotificationButtonImage = (isInSearchResults, notifications) => {
	if (isInSearchResults) {
		if (notifications && notifications.length > 0) {
			return bellNotificationImgWhite;
		}
		return bellEmptyImgWhite;
	}

	// not in Search Results -- no playlist rail
	if (notifications && notifications.length > 0) {
		return bellNotificationImgBlack;
	}
	return bellEmptyImgBlack;
};

const Notifications = ({ location, history }) => {
	const [openPanel, setOpenPanel] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [currentPopupNotifications, setCurrentPopupNotifications] = useState([]);
	const [textRefHeights, setTextRefHeights] = useState({});

	const { createToast } = useContext(GlobalContext);

	const parentRef = useRef();
	const listPanelRef = useRef();

	Notifications.handleClickOutside = () => setOpenPanel(false);
	Notifications.setClickOutsideRef = () => parentRef.current;

	const rowVirtualizer = useVirtual({
		size: notifications.length,
		parentRef: listPanelRef,
		estimateSize: React.useCallback(() => 100, []), // Best guess height of each item
		overscan: notifications.length,
	});

	const isInSearchResults = () => {
		return location && location.pathname.startsWith('/search');
	};

	const displayPopupNotfication = useCallback(
		notification => {
			if (
				notification.event_name === NOTIFICATION_EVENT.PLAYLIST_VERSION_SYNCED_TO_AIRTABLE &&
				notification.popup &&
				location.pathname === `/playlist/${notification.playlist_id}`
			) {
				createToast(
					'The last playlist version has been synced to Airtable system, please refresh the page to revise',
					TOAST_LEVEL.INFO,
					true,
					async () => acknowledgeNotificationPopup(notification.notification_id),
					5000
				);
			}
		},
		[createToast, location]
	);

	const checkForPopUpNotifications = useCallback(
		notifs => {
			const currentPopupNotifs = currentPopupNotifications || [];
			notifs
				.filter(n => n.popup)
				.forEach(n => {
					if (!currentPopupNotifs.includes(n.notification_id)) {
						displayPopupNotfication(n);
						currentPopupNotifs.push(n.notification_id);
						setCurrentPopupNotifications(currentPopupNotifs);
					}
				});
		},
		[currentPopupNotifications, displayPopupNotfication]
	);

	const fetchNotifications = useCallback(() => {
		getUserNotifications().then((notifs = []) => {
			checkForPopUpNotifications(notifs);
			setNotifications(notifs);
			setTextRefHeights({});
		});
	}, [checkForPopUpNotifications]);

	useEffect(() => {
		let interval;

		async function initFetchNotifications() {
			await fetchNotifications();

			interval = setInterval(async () => {
				await fetchNotifications();
			}, NOTIFICATION_TIMEOUT_MSEC);
		}
		initFetchNotifications();

		return () => clearInterval(interval);
	}, [fetchNotifications]);

	// componentDidMount
	useEffect(() => {
		if (openPanel) {
			fetchNotifications();
		}
	}, [openPanel, fetchNotifications]);

	const togglePanel = () => {
		setOpenPanel(!openPanel);
	};

	const textRef = useCallback((node, idx) => {
		const currHeight = node?.offsetHeight;
		if (currHeight) {
			setTextRefHeights(textRefHeights => {
				return !textRefHeights[idx]
					? {
							...textRefHeights,
							[idx]: currHeight * 1.15,
					  }
					: textRefHeights;
			});
		}
	}, []);

	const getTextRefYStartTranslate = (currIdx, textRefHeights) => {
		let yStartTranslate = 0;
		for (const key of Object.keys(textRefHeights).sort()) {
			if (key === `${currIdx}`) {
				return yStartTranslate;
			}
			yStartTranslate += textRefHeights[key];
		}
		return yStartTranslate;
	};

	return (
		<div className='songslist__song__btn songslist__tooltip' ref={parentRef}>
			<img
				src={getNotificationButtonImage(isInSearchResults(), notifications)}
				alt='more'
				onClick={togglePanel}
				className='notifications__icon'
			/>

			<div className={`notifications__panel shadow ${openPanel ? 'notifications__panel--visible' : ''}`}>
				<div className='notifications__item notifications__header' onClick={togglePanel}>
					Notifications
				</div>
				<div className='mb-0 pl-0 notifications__list' ref={listPanelRef}>
					{!notifications.length && <div className='notifications__item'>No notifications</div>}
					{!!notifications.length && rowVirtualizer.virtualItems.length === notifications.length && openPanel && (
						<ul
							className='mb-0 pl-0 notifications__list-inner'
							style={{
								height: `${
									textRefHeights[notifications.length - 1] &&
									getTextRefYStartTranslate(notifications.length - 1, textRefHeights) +
										textRefHeights[notifications.length - 1]
								}px`,
							}}
						>
							{rowVirtualizer.virtualItems.map(virtualRow => {
								const notification = notifications[virtualRow.index];
								const textRefHeight = textRefHeights[virtualRow.index];
								/* eslint-disable camelcase */
								const {
									notification_id,
									playlist_id,
									event_name,
									name,
									first_name,
									last_name,
									global_tracker_show_id,
									created_timestamp,
								} = notification || {};
								/* eslint-enable camelcase */

								return (
									<li
										className='notifications__item d-flex notifications__item__clickable'
										key={virtualRow.index}
										onClick={() =>
											onNotificationClick(
												event_name,
												notification_id,
												playlist_id,
												history,
												fetchNotifications,
												global_tracker_show_id
											)
										}
										style={{
											visibility: textRefHeight ? 'visible' : 'hidden',
											position: 'absolute',
											top: 0,
											left: 0,
											height: `${textRefHeight}px`,
											transform: `translateY(${
												textRefHeight && getTextRefYStartTranslate(virtualRow.index, textRefHeights)
											}px)`,
										}}
									>
										<img
											src={getIcon(event_name)}
											style={{ height: '30px', marginRight: '12px' }}
											alt='alert'
											onClick={togglePanel}
										/>
										<div ref={node => textRef(node, virtualRow.index)}>
											{getText(event_name, name, first_name, last_name, global_tracker_show_id)}
											{showDateString(event_name) && (
												<div>
													<small>{new Date(created_timestamp).toLocaleString()}</small>
												</div>
											)}
										</div>
									</li>
								);
							})}
						</ul>
					)}
				</div>
			</div>
			<div
				className={`songslist__tooltip__overlay ${openPanel ? 'd-block' : 'd-none'}`}
				onClick={() => setOpenPanel(false)}
			/>
		</div>
	);
};

const clickOutsideConfig = {
	handleClickOutside: () => Notifications.handleClickOutside,
	setClickOutsideRef: () => Notifications.setClickOutsideRef,
};

Notifications.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default onClickOutside(withRouter(Notifications), clickOutsideConfig);
