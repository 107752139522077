import { apiUrl, getDefaultGetInit, getDefaultPostInit, parseStandardResponse } from '../utils/api';

const NOTIFICATIONS_PATH = 'notifications';

export async function getUserNotifications() {
	const url = `${apiUrl}/${NOTIFICATIONS_PATH}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init), false);
}

export async function acknowledgeNotification(notificationId) {
	const url = `${apiUrl}/${NOTIFICATIONS_PATH}/${notificationId}/acknowledge`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init));
}

export async function acknowledgeNotificationPopup(notificationId) {
	const url = `${apiUrl}/${NOTIFICATIONS_PATH}/${notificationId}/acknowledgePopUp`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init));
}
