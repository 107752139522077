import React, { useState } from 'react';

import PropTypes from 'prop-types';

import AlertModal from '../AlertModal';

const TextInputForm = ({
	label,
	submitText,
	onSubmit,
	inputValidationRegexp,
	inputValidationFailMessage,
	useValidationErrorPopup,
}) => {
	const [value, setValue] = useState('');
	const [invalidInputError, setInvalidInputError] = useState(false);

	const handleOnSubmit = async () => {
		const isValidInput = inputValidationRegexp ? new RegExp(inputValidationRegexp).test(value.trim()) : true;

		// if an input verification RegExp was provided, ensure it matches -- show error if not
		if (!isValidInput) {
			setInvalidInputError(true);
			return;
		}

		setInvalidInputError(false);
		onSubmit(value.trim());
	};

	return (
		<div className='d-flex flex-column align-items-start'>
			<div className='text-input'>
				{useValidationErrorPopup && (
					<AlertModal
						title='Invalid Input'
						description={inputValidationFailMessage}
						showModal={invalidInputError}
						onConfirmClick={() => setInvalidInputError(false)}
						onCancelClick={() => setInvalidInputError(false)}
					/>
				)}

				<label className='text-input__field'>{label}</label>
				<input
					value={value}
					onChange={e => setValue(e.target.value)}
					onKeyPress={e => (e.key === 'Enter' ? handleOnSubmit() : null)}
					type='text'
					className='user-modal__input text-input__field'
				/>
				<button
					name={`TextInputForm - ${submitText}`}
					className='btn primary-btn text-input__button'
					onClick={() => handleOnSubmit()}
					type='button'
				>
					{submitText}
				</button>
			</div>
			{inputValidationFailMessage && !useValidationErrorPopup && !!invalidInputError && (
				<div
					className='error'
					style={{ fontSize: '0.75rem', textAlign: 'center', paddingTop: '0.25rem', marginTop: '0.15ren' }}
				>
					* {inputValidationFailMessage}
				</div>
			)}
		</div>
	);
};

TextInputForm.propTypes = {
	inputValidationFailMessage: PropTypes.string,
	inputValidationRegexp: PropTypes.instanceOf(RegExp),
	label: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	submitText: PropTypes.string.isRequired,
	useValidationErrorPopup: PropTypes.bool,
};

TextInputForm.defaultProps = {
	useValidationErrorPopup: true,
	inputValidationRegexp: undefined,
	inputValidationFailMessage: 'The provided input was invalid, please ensure it is formatted correctly.',
};

export default TextInputForm;
