import { PLAYLIST_TYPE } from '@content-playlist-creation/common/constants';

import { BRANDS } from '../constants/brands';
import SCS_PLAYLIST_SUBMISSION_RULES from '../constants/playlist-scs-submission-rules';
import { ZONE_KEYS_NAMES } from '../constants/zone_keys';

import { getBrands } from './auth';
import { hhmmssToSecondsFormatter } from './date-time';
import {
	getAdjustedDurationInSeconds,
	getAdjustedTargetDurationDifference,
	getStretchSong,
	getCardioDuration,
	getSongAdjustedDurationInSeconds,
} from './playlist';

const canBypassSubmissionRules = () => {
	const brandExceptionList = [BRANDS.EQUINOX, BRANDS.RUMBLE_BOXING, BRANDS.SOLIDCORE, BRANDS.PRECISION_RUN];
	for (const brand of getBrands()) {
		if (brandExceptionList.includes(brand)) {
			return true;
		}
	}
	return false;
};

const isPlaylistDurationValid = (playlist, zoneKeys = []) => {
	const diff = getAdjustedTargetDurationDifference(playlist, zoneKeys);

	// SoulCycle VoD/Live playlists rules for Adjusted Duration are different
	if (
		playlist.brand_id === BRANDS.SOULCYCLE &&
		[PLAYLIST_TYPE.VOD, PLAYLIST_TYPE.LIVE].includes(playlist.playlist_type_id)
	) {
		// valid if there are no duration rules that are invalid
		return (
			// eslint-disable-next-line no-use-before-define
			getPlaylistSubmissionRules(playlist, zoneKeys).filter(
				r => !r.valid && (r.isAdjustedDurationRule || r.isCardioDurationRule)
			).length === 0
		);
	}

	// use existing validation: Playlist's adjusted duration is valid if -2 mins or + 1 minute of target duration
	return diff >= -120 && diff <= 60;
};

/**
 *  Handles Cardio Duration rules: Cardio Duration must be between certain ranges in order to submit
 * @param {object} playlist Playlist within current component state
 * @param {array} rules Array of rules to aggreate cardio duration rules to
 * @param {array} zoneKeys Array of zoneKeys within app context
 * @return {array} array of { valid: true, text: '' } objects including new rules
 */
const pushCardioDurationRules = (playlist, rules, zoneKeys = []) => {
	if (!playlist.target_duration) {
		return;
	}

	const cardioDurationSecs = hhmmssToSecondsFormatter(getCardioDuration(playlist, zoneKeys));
	const hasStretchSong = getStretchSong(playlist, zoneKeys).length > 0;

	if (
		playlist.target_duration === 30 ||
		playlist.target_duration === 45 ||
		playlist.target_duration === 60 ||
		playlist.target_duration === 90
	) {
		// Cardio Duration requires a minimum
		const min = SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].CARDIO_DURATION_MIN;

		rules.push({
			valid: cardioDurationSecs >= min * 60,
			text: `Cardio Duration must be at least ${min} minutes.`,
			isCardioDurationRule: true,
		});
	}

	if (playlist.target_duration === 20) {
		// Cardio Durations depends if there is a Stretch song or not
		if (hasStretchSong) {
			// Cardio Duration must be at least min minutes
			const min = SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].CARDIO_DURATION_STRETCH_MIN;

			rules.push({
				valid: cardioDurationSecs >= min * 60,
				text: `Cardio Duration must be at least ${min} minutes.`,
				isCardioDurationRule: true,
			});
		} else {
			// Cardio Duration and Adjusted Duration must be between min-max minutes
			const min = SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].CARDIO_DURATION_NO_STRETCH_MIN;
			const max = SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].CARDIO_DURATION_NO_STRETCH_MAX;

			rules.push({
				valid: cardioDurationSecs >= min * 60 && cardioDurationSecs <= max * 60,
				text: `Cardio Duration must be between ${min}-${max} minutes.`,
				isCardioDurationRule: true,
			});
		}
	}
};

/**
 *  Handles Adjusted Duration rules: Adjusted Duration must be between certain ranges in order to submit
 * @param {object} playlist Playlist within current component state
 * @param {array} rules Array of rules to aggreate adjusted duration rules to
 * @param {array} zoneKeys Array of zoneKeys within app context
 * @return {array} array of { valid: true, text: '' } objects including new rules
 */
const pushAdjustedDurationRules = (playlist, rules, zoneKeys = []) => {
	if (
		!playlist.target_duration ||
		!SCS_PLAYLIST_SUBMISSION_RULES ||
		!SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration]
	) {
		return;
	}

	const adjustedDurationSecs = getAdjustedDurationInSeconds(playlist, zoneKeys);

	rules.push({
		valid:
			adjustedDurationSecs >= SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].ADJUSTED_DURATION_MIN * 60 &&
			adjustedDurationSecs <= SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].ADJUSTED_DURATION_MAX * 60,
		text: `Adjusted Duration must be between ${
			SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].ADJUSTED_DURATION_MIN
		}-${SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].ADJUSTED_DURATION_MAX} minutes.`,
		isAdjustedDurationRule: true,
	});
};

/**
 * Handles Stretch rules
 * @param {object} playlist Playlist within current component state
 * @param {array} rules Array of rules to aggreate strech rules to
 * @param {array} zoneKeys Array of zoneKeys within app context
 * @return {array} array of { valid: true, text: '' } objects including new rules
 */
const pushStretchSongsRules = (playlist, rules, zoneKeys = []) => {
	const stretchZoneKeyIds = zoneKeys.filter(zk => zk.name === ZONE_KEYS_NAMES.STRETCH).map(zk => zk.zone_key_id);

	// stretch songs are optional for playlists with a target duration of 5, 10, 15
	if (
		playlist.playlist_type_id === PLAYLIST_TYPE.VOD &&
		(playlist.target_duration === 5 || playlist.target_duration === 10 || playlist.target_duration === 15)
	) {
		rules.push({
			valid: true,
			text: `Stretch songs optional.`,
		});
	}

	// for SCS Live/VOD playlists with a target duration of 20, depends on the number of stretch songs
	if (playlist.target_duration === 20) {
		// only 1 stretch song allowed in playlist
		rules.push({
			valid: playlist.songs.filter(song => stretchZoneKeyIds.includes(song.zone_key_id)).length <= 1,
			text: `Maximum of 1 Stretch song.`,
		});
	}

	// for SCS Live/VOD playlists  with a target duration of 30, 45, 60, and 90 require a stretch song
	if (
		playlist.target_duration === 30 ||
		playlist.target_duration === 45 ||
		playlist.target_duration === 60 ||
		playlist.target_duration === 90
	) {
		rules.push({
			valid: playlist.songs.filter(song => stretchZoneKeyIds.includes(song.zone_key_id)).length === 1,
			text: `Must have 1 Stretch song.`,
		});
	}
};

/**
 * Returns an array of rules and whether that rule is met or not.
 * @param {object} playlist Playlist within current component state
 * @param {array} zoneKeys Array of zoneKeys within app context
 * @return {array} array of { valid: true, text: '' } objects
 */
const getPlaylistSubmissionRules = (playlist, zoneKeys = []) => {
	const rules = [];

	// all playlists must have a Target Duration
	rules.push({
		valid: !!playlist.target_duration,
		text: 'Selected a Target Duration',
	});

	// if a playlist is non-SoulCycle, or is a previous playlist, follow existing playlist submission logic
	if (playlist.brand_id !== BRANDS.SOULCYCLE || !playlist.playlist_type_id) {
		// check target duration
		rules.push({
			valid: isPlaylistDurationValid(playlist, zoneKeys),
			text: 'Adjusted Duration must be 2 minutes shorter, or 1 minute longer, than the Target Duration.',
		});
		return rules;
	}

	/** playlist is a SoulCycle VoD or SoulCycle Live Playlist, follow new submission logic * */

	if (playlist.playlist_type_id === PLAYLIST_TYPE.VOD) {
		// handle Walk On or Walk Off rules: cannot be submitted if any
		// get zone key IDs for Walk On/Walk Off from the global context (aka from database)
		const walkOnOffZoneKeyIds = zoneKeys
			.filter(zk => [ZONE_KEYS_NAMES.WALK_ON, ZONE_KEYS_NAMES.WALK_OFF].includes(zk.name))
			.map(zk => zk.zone_key_id);

		// cannot be submitted if there are any Walk On or Walk Off Zone Keys
		rules.push({
			valid: playlist.songs.filter(song => walkOnOffZoneKeyIds.includes(song.zone_key_id)).length === 0,
			text: 'No Walk On or Walk Off Zone Keys.',
		});

		// handle Adjusted Duration rules: Adjusted Duration must be between certain ranges in order to submit
		pushAdjustedDurationRules(playlist, rules, zoneKeys);

		// handle Stretch rules
		pushStretchSongsRules(playlist, rules, zoneKeys);

		// handle Cardio Duration rules
		pushCardioDurationRules(playlist, rules, zoneKeys);
	}

	if (playlist.playlist_type_id === PLAYLIST_TYPE.LIVE) {
		// handle Walk On rules: cannot be submitted if none or walk on song is less than 2 minutes
		const walkOnZoneKey = zoneKeys.find(zk => ZONE_KEYS_NAMES.WALK_ON === zk.name);
		const walkOnSongs = walkOnZoneKey
			? playlist.songs.filter(song => song.zone_key_id === walkOnZoneKey.zone_key_id)
			: [];

		if (walkOnSongs.length !== 1) {
			rules.push({
				valid: walkOnSongs.length === 1,
				text: `Must have 1 Walk On song.`,
			});
		} else {
			rules.push({
				valid: walkOnSongs.length === 1 && getSongAdjustedDurationInSeconds(walkOnSongs[0]) >= 90,
				text: `Walk On needs to be at least 90 seconds.`,
			});
		}

		// handle Walk Off rule: cannot be submitted if none or walk off song is less than 3 minutes
		const walkOffZoneKey = zoneKeys.find(zk => ZONE_KEYS_NAMES.WALK_OFF === zk.name);
		const walkOffSongs = walkOffZoneKey
			? playlist.songs.filter(song => song.zone_key_id === walkOffZoneKey.zone_key_id)
			: [];

		if (walkOffSongs.length !== 1) {
			rules.push({
				valid: walkOffSongs.length === 1,
				text: `Must have 1 Walk Off song.`,
			});
		} else {
			rules.push({
				valid: walkOffSongs.length === 1 && getSongAdjustedDurationInSeconds(walkOffSongs[0]) >= 120,
				text: `Walk Off needs to be at least 2 minutes.`,
			});
		}

		// handle Adjusted Duration rules: Adjusted Duration must be between certain ranges in order to submit
		pushAdjustedDurationRules(playlist, rules, zoneKeys);

		// handle Stretch rules
		pushStretchSongsRules(playlist, rules, zoneKeys);
		// handle Cardio Duration rules
		pushCardioDurationRules(playlist, rules, zoneKeys);
	}

	return rules;
};

export { getPlaylistSubmissionRules, isPlaylistDurationValid, canBypassSubmissionRules };
