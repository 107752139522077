import { apiUrl, getDefaultGetInit, getDefaultPostInit, parseStandardResponse } from '../utils/api';
import { setUser } from '../utils/auth';

const AUTH_PATH = 'auth';

export async function login(username, password) {
	const url = `${apiUrl}/${AUTH_PATH}/login`;
	const init = getDefaultPostInit({ username, password });
	const response = await fetch(url, init);

	if (!response.ok) {
		const responseData = await response.json();
		if ((responseData.error || '').indexOf('maintenance') > -1) {
			throw { error: responseData.error, maitance_mode: true };
		}

		throw new Error(response.statusText);
	}
	const user = await response.json();

	setUser(user);
}

export async function registerUser(user) {
	const url = `${apiUrl}/${AUTH_PATH}/register-user`;
	const init = getDefaultPostInit(user);

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function editUser(user) {
	const url = `${apiUrl}/${AUTH_PATH}/edit-user`;
	const init = getDefaultPostInit(user);

	return parseStandardResponse(fetch(url, init), true, true);
}

export async function changePassword(payload) {
	const url = `${apiUrl}/${AUTH_PATH}/change-password`;
	const init = getDefaultPostInit(payload);

	return parseStandardResponse(fetch(url, init));
}

export async function logout() {
	const url = `${apiUrl}/${AUTH_PATH}/logout`;
	const init = getDefaultGetInit();

	// Clear variables stored
	sessionStorage.clear();

	return parseStandardResponse(fetch(url, init));
}
