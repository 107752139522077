import { BRANDS_MAP, PLAYLIST_STATUS_MAP } from '@content-playlist-creation/common/constants';
import invert from 'lodash/invert';

import SCS_PLAYLIST_SUBMISSION_RULES from '../constants/playlist-scs-submission-rules';
import { ZONE_KEYS_NAMES } from '../constants/zone_keys';

import {
	hhmmssToSecondsFormatter,
	mmssToSecondsFormatter,
	secondsToHHMMSSFormatter,
	secondsToMMSSFormatter,
} from './date-time';

export const prependInitials = (name, firstName, lastName, username) => {
	let a = [];

	if (!firstName && !lastName) {
		a.push(username);
	} else {
		a.push(firstName);
		a.push(lastName);
	}
	a = a.map(e => (e && typeof e === 'string' ? e.toUpperCase().charAt(0) : ''));
	return `${a.join('')}-${name}`;
};

const filterOutBreakZoneSongs = (songs = [], zoneKeys = []) => {
	const zoneKeyIDS = zoneKeys
		.filter(z => [ZONE_KEYS_NAMES.WALK_ON, ZONE_KEYS_NAMES.WALK_OFF].includes(z.name))
		.map(z => z.zone_key_id);

	return songs.filter(s => !zoneKeyIDS.includes(s.zone_key_id));
};

export const getSongAdjustedDurationInSeconds = song => {
	const outVal = song.out_val || secondsToMMSSFormatter(song.duration);
	const inVal = song.in_val || '0:00';

	return mmssToSecondsFormatter(outVal) - mmssToSecondsFormatter(inVal);
};

export const getAdjustedDuration = (songs = [], zoneKeys = []) => {
	if (!songs || songs.length === 0) {
		return '0:00:00';
	}
	let sum = 0;

	filterOutBreakZoneSongs(songs, zoneKeys).forEach(song => {
		sum += getSongAdjustedDurationInSeconds(song);
	});

	return secondsToHHMMSSFormatter(sum);
};

export const getTotalDuration = (songs = [], zoneKeys = []) => {
	if (!songs || songs.length === 0) {
		return '0:00:00';
	}
	let sum = 0;

	filterOutBreakZoneSongs(songs, zoneKeys).forEach(song => {
		sum += song.duration;
	});

	return secondsToHHMMSSFormatter(sum);
};

export const getAdjustedDurationInSeconds = (playlist, zoneKeys = []) => {
	const playlistBrandId = playlist.brand_id;
	const playlistZoneKeys = zoneKeys.filter(zone => !playlistBrandId || zone.brand_id === playlistBrandId);

	return hhmmssToSecondsFormatter(getAdjustedDuration(playlist.songs, playlistZoneKeys));
};

export const getAdjustedTargetDurationDifference = (playlist, zoneKeys = []) => {
	const adjustedDurationSeconds = getAdjustedDurationInSeconds(playlist, zoneKeys);
	const targetDurationSeconds = (playlist.target_duration || 0) * 60;

	return adjustedDurationSeconds - targetDurationSeconds;
};

export const getStretchSong = (playlist, zoneKeys = []) => {
	const stretchZoneKeyIds = zoneKeys.filter(zk => zk.name === ZONE_KEYS_NAMES.STRETCH).map(zk => zk.zone_key_id);

	return (playlist.songs || []).filter(song => stretchZoneKeyIds.includes(song.zone_key_id));
};

export const getCardioDuration = (playlist, zoneKeys = []) => {
	const stretchSong = getStretchSong(playlist, zoneKeys);
	const hasStretchSong = stretchSong.length > 0;

	// for target durations of 45, 60, 90, and [20/30 with 1 stretch song], Cardio Duration = Adjusted Duration - Adjusted Stretch Song Duration
	if (
		playlist.target_duration === 45 ||
		playlist.target_duration === 60 ||
		playlist.target_duration === 90 ||
		(playlist.target_duration === 20 && hasStretchSong) ||
		(playlist.target_duration === 30 && hasStretchSong)
	) {
		return secondsToHHMMSSFormatter(
			getAdjustedDurationInSeconds(playlist, zoneKeys) - hhmmssToSecondsFormatter(getAdjustedDuration(stretchSong))
		);
	}

	// for target durations of 20 & 30 WITHOUT stretch songs, Cardio Duration = Adjusted Duration
	if ((playlist.target_duration === 20 && !hasStretchSong) || (playlist.target_duration === 30 && !hasStretchSong)) {
		return secondsToHHMMSSFormatter(getAdjustedDurationInSeconds(playlist, zoneKeys));
	}

	return 0;
};

// will only be called for VoD/Live playlists with a target_duration >= 20
export const getCardioTargetDifference = (playlist, zoneKeys = []) => {
	const cardioDurationSeconds = hhmmssToSecondsFormatter(getCardioDuration(playlist, zoneKeys));
	const hasStretchSong = getStretchSong(playlist, zoneKeys).length > 0;

	// target is the min time specified by rule
	let requiredMinimum = 0;

	if (playlist.target_duration === 45 || playlist.target_duration === 60 || playlist.target_duration === 90) {
		// minimum cardio duration
		requiredMinimum = SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].CARDIO_DURATION_MIN * 60;
	}

	if (playlist.target_duration === 20 || playlist.target_duration === 30) {
		// minimum depends if there is a Stretch song or not
		if (hasStretchSong) {
			requiredMinimum = SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].CARDIO_DURATION_STRETCH_MIN * 60;
		} else {
			requiredMinimum = SCS_PLAYLIST_SUBMISSION_RULES[playlist.target_duration].CARDIO_DURATION_NO_STRETCH_MIN * 60;
		}
	}

	return cardioDurationSeconds - requiredMinimum;
};

export const formatPlaylistFilters = (playlistFilters, playlistSourceFilter, externalPlaylistStatuses) => {
	const playlistBrandsFilters = playlistFilters
		.filter(f => Object.values(BRANDS_MAP).includes(f))
		.map(b => invert(BRANDS_MAP)[b]);
	const playlistStatusesFilters = playlistFilters
		.filter(f => Object.values(PLAYLIST_STATUS_MAP).includes(f) || externalPlaylistStatuses.includes(f))
		.map(f => `'${f}'`.toLowerCase().trim());

	return {
		playlistSourceFilter: playlistSourceFilter === 'ALL' ? null : playlistSourceFilter,
		playlistBrandsFilter:
			Array.isArray(playlistBrandsFilters) && playlistBrandsFilters.length ? playlistBrandsFilters : null,
		playlistStatusesFilter:
			Array.isArray(playlistStatusesFilters) && playlistStatusesFilters.length ? playlistStatusesFilters : null,
	};
};
