// constants
const {
	AIRTABLE_TASK_ACTION_KEY,
	MUSIC_DROP_PLAYLIST_STATUS,
	AIRTABLE_ERROR_MESSAGES,
	AIRTABLE_ERROR_TYPE_KEY,
} = require('./airtable');
const {
	PLAYLIST_STATUS,
	PLAYLIST_STATUS_MAP,
	PLAYLIST_EVENTS,
	PLAYLIST_TYPE,
	PLAYLIST_TYPE_MAP,
} = require('./playlist');
const { BRANDS, BRANDS_MAP } = require('./brands');
const { ERRORS } = require('./messages');
const { MUSIC_FEED_ID, MUSIC_FEED_ABBREVIATION } = require('./music_metadata_feeds');
const { SHARED_FOLDER_USER_TYPES } = require('./shared_folder');

module.exports = {
	MUSIC_FEED_ID,
	MUSIC_FEED_ABBREVIATION,
	BRANDS,
	BRANDS_MAP,
	PLAYLIST_STATUS,
	PLAYLIST_STATUS_MAP,
	PLAYLIST_EVENTS,
	PLAYLIST_TYPE,
	PLAYLIST_TYPE_MAP,
	ERRORS,
	SHARED_FOLDER_USER_TYPES,
	AIRTABLE_TASK_ACTION_KEY,
	MUSIC_DROP_PLAYLIST_STATUS,
	AIRTABLE_ERROR_MESSAGES,
	AIRTABLE_ERROR_TYPE_KEY,
};
