import React, { useState, Fragment, useContext, useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import * as songsApi from '../../api/songs';
import { BRANDS } from '../../constants/brands';
import * as PERMISSIONS from '../../constants/permissions';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import chevronRightImg from '../../images/chevron-right.svg';
import moreDotsImg from '../../images/dots.svg';
import {
	isContentCreator,
	isInstructor,
	isAdministrator,
	hasPermission,
	isViewOnly,
	getBrands,
} from '../../utils/auth';

const renderMetadata = (isrc, variisTrackId, releaseYear, album, plineYear) => {
	return (
		<>
			<div className='action-menu__panel__metadata'>
				<div>
					<span className='action-menu__panel__metadata__header'>Album:</span> {album}
				</div>
				<div>
					<span className='action-menu__panel__metadata__header'>ISRC:</span> {isrc}
				</div>
				<div>
					<span className='action-menu__panel__metadata__header'>Variis ID:</span> {variisTrackId}
				</div>
				<div>
					<span className='action-menu__panel__metadata__header'>Released:</span> {releaseYear}
				</div>
				<div>
					<span className='action-menu__panel__metadata__header'>℗</span> {plineYear}
				</div>
			</div>
			<div className='action-menu__panel__divider' />
		</>
	);
};

const ActionMenuOption = ({ label, visible, optionCallback, submenu = false, isMenuOpen }) => {
	const actionItemRef = useRef(null);

	return visible ? (
		<div
			ref={actionItemRef}
			className={classNames('action-menu__panel__item', {
				'action-menu__panel__collections': submenu,
				'action-menu__panel__item--active': isMenuOpen,
			})}
			onClick={() => optionCallback(actionItemRef)}
		>
			<div className='action-menu__panel__item__label'>{label}</div>
			{submenu && (
				<img src={chevronRightImg} className='action-menu__panel__collections__chevron' alt='open sub menu' />
			)}
		</div>
	) : null;
};

const ActionSubMenu = ({ isOpen, coordinateX, children }) =>
	isOpen && (
		<div
			className='action-menu__panel__collections__panel shadow action-menu__panel__collections__panel--visible'
			style={{ top: coordinateX }}
		>
			{children}
		</div>
	);

const ActionMenu = ({
	song,
	playlists,
	folders,
	handleAddToPlaylist,
	handleAddToFolder = null,
	handleAddISRCSongToBlacklist = null,
	handleAddVariisIDSongToBlacklist = null,
	handleAddToFavourites,
	showSongInfo,
}) => {
	const [openActionsPanel, setOpenActionsPanel] = useState(false);
	const [openPlaylistsPanel, setOpenPlaylistsPanel] = useState(false);
	const [openFoldersPanel, setOpenFoldersPanel] = useState(false);
	const [openBlacklistPanel, setOpenBlacklistPanel] = useState(false);
	const [subMenuPosition, setSubMenuPosition] = useState(0);
	const canEditFolder = hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR]);
	const {
		createToast,
		toggleScrollLock,
		openISRCBlacklistModal,
		openVariisIDBlacklistModal,
		openISRCPlayCountModal,
		setShowNewPlaylistModal,
	} = useContext(GlobalContext);
	const mainPanelRef = useRef(null);

	const closePanelsAfterAction = (optionCallback = null) => {
		if (optionCallback) {
			optionCallback();
		}
		toggleScrollLock(false);
		setOpenActionsPanel(false);
		setOpenPlaylistsPanel(false);
		setOpenFoldersPanel(false);
		setOpenBlacklistPanel(false);
	};

	const handleDownloadSong = async () => {
		await songsApi
			.verifyMusicAsset(song.song_id, song.track_release_id)
			.then(() => {
				createToast(`Downloading ${song.name}.... `, TOAST_LEVEL.INFO, true);
				const tempLink = document.createElement('a');

				tempLink.href = songsApi.generateSongURL(song.song_id, song.track_release_id);
				tempLink.click();
			})
			.catch(() => {
				createToast(
					'There was a problem downloading the song, please contact your administrator',
					TOAST_LEVEL.ERROR,
					true
				);
			});
	};

	const handleAddSongISRCToBlacklistClick = async () => {
		openISRCBlacklistModal(song.isrc, handleAddISRCSongToBlacklist);
	};

	const handleAddSongVariisIDToBlacklistClick = async () => {
		openVariisIDBlacklistModal(song.variis_track_id, handleAddVariisIDSongToBlacklist);
	};

	const handlePlayCountClick = async () => {
		openISRCPlayCountModal(song.isrc);
	};

	const onScroll = () => {
		setOpenPlaylistsPanel(false);
		setOpenFoldersPanel(false);
	};

	return (
		<div className='action-menu'>
			<button
				name='ActionMenu - Action Panel'
				className='song-result__actions__item'
				onClick={() => {
					setOpenActionsPanel(!openActionsPanel);
				}}
				type='button'
			>
				<img src={moreDotsImg} alt='more song result actions' />
			</button>
			{openActionsPanel ? (
				<>
					<div
						ref={mainPanelRef}
						className={`action-menu__panel shadow ${openActionsPanel ? 'action-menu__panel--visible' : ''}`}
						onScroll={onScroll}
					>
						{showSongInfo &&
							renderMetadata(
								song.isrc,
								song.variis_track_id,
								parseInt(song.release_date, 10) || song.pline_year,
								song.album,
								song.pline_year
							)}
						<ActionMenuOption
							visible={isInstructor()}
							label='Play Count'
							optionCallback={() => closePanelsAfterAction(handlePlayCountClick)}
						/>
						<ActionMenuOption
							visible={isInstructor() && !!handleAddToFavourites}
							label={!song.is_favorite ? 'Add to My Songs' : 'Remove from My Songs'}
							optionCallback={() => closePanelsAfterAction(() => handleAddToFavourites(song.song_id))}
						/>
						<ActionMenuOption
							visible={isContentCreator() && !isViewOnly() && song.valid && !song.is_blacklisted}
							label='Download'
							optionCallback={() => closePanelsAfterAction(handleDownloadSong)}
						/>

						{handleAddISRCSongToBlacklist && handleAddVariisIDSongToBlacklist && (
							<>
								<ActionMenuOption
									visible={(isContentCreator() && !isViewOnly()) || isAdministrator()}
									label='Add ISRC to Blacklist'
									optionCallback={() => closePanelsAfterAction(handleAddSongISRCToBlacklistClick)}
								/>
								<ActionMenuOption
									visible={(isContentCreator() && !isViewOnly()) || isAdministrator()}
									label='Add Variis ID to Blacklist'
									optionCallback={() => closePanelsAfterAction(handleAddSongVariisIDToBlacklistClick)}
								/>
							</>
						)}
						<ActionMenuOption
							visible={isInstructor() || (isContentCreator() && !isViewOnly() && playlists.length)}
							label={isInstructor() ? 'Add to My Playlist' : 'Add to Playlist'}
							submenu
							isMenuOpen={openPlaylistsPanel}
							optionCallback={itemRef => {
								setSubMenuPosition(
									itemRef.current.offsetTop - mainPanelRef.current.scrollTop + mainPanelRef.current.offsetTop
								);
								setOpenBlacklistPanel(false);
								setOpenPlaylistsPanel(true);
								setOpenFoldersPanel(false);
							}}
						/>
						<ActionMenuOption
							visible={canEditFolder}
							label='Add to Folder'
							submenu
							isMenuOpen={openFoldersPanel}
							optionCallback={itemRef => {
								setSubMenuPosition(
									itemRef.current.offsetTop - mainPanelRef.current.scrollTop + mainPanelRef.current.offsetTop
								);
								setOpenBlacklistPanel(false);
								setOpenPlaylistsPanel(false);
								setOpenFoldersPanel(true);
							}}
						/>
					</div>

					{/* Playlist submenu */}
					<ActionSubMenu isOpen={openPlaylistsPanel} coordinateX={subMenuPosition}>
						{isInstructor() && (
							<>
								<div
									className='action-menu__panel__item action-menu__panel__collections__panel__new-collection'
									onClick={() => {
										// show modal instead if SoulCycle or Equinox
										const userBrands = getBrands();
										if (userBrands.includes(BRANDS.SOULCYCLE) || userBrands.includes(BRANDS.EQUINOX)) {
											setShowNewPlaylistModal(true, song.song_id);
											return;
										}

										handleAddToPlaylist(song.song_id, -1);
										closePanelsAfterAction();
									}}
								>
									<div className='action-menu__panel__item__label'>Create New Playlist</div>
								</div>
								<div className='action-menu__panel__divider' />
							</>
						)}

						{playlists.map(playlist => (
							<div
								className='action-menu__panel__item'
								onClick={() => {
									handleAddToPlaylist(song.song_id, playlist.playlist_id);
									closePanelsAfterAction();
								}}
								key={playlist.playlist_id}
							>
								<div className='action-menu__panel__item__label'>{playlist.name}</div>
							</div>
						))}
					</ActionSubMenu>

					{/* Folders submenu */}
					{canEditFolder && (
						<ActionSubMenu isOpen={openFoldersPanel} coordinateX={subMenuPosition}>
							<div
								className='action-menu__panel__item action-menu__panel__collections__panel__new-collection'
								onClick={() => {
									handleAddToFolder(song.song_id, -1);
									closePanelsAfterAction();
								}}
							>
								<div className='action-menu__panel__item__label'>Create New Folder</div>
							</div>
							<div className='action-menu__panel__divider' />
							{folders.map(folder => (
								<div
									className='action-menu__panel__item'
									onClick={() => {
										handleAddToFolder(song.song_id, folder.shared_folder_id);
										closePanelsAfterAction();
									}}
									key={folder.shared_folder_id}
								>
									<div className='action-menu__panel__item__label'>{folder.name}</div>
								</div>
							))}
						</ActionSubMenu>
					)}

					{openActionsPanel && <div className='action-menu__overlay' onClick={() => closePanelsAfterAction()} />}
				</>
			) : null}
		</div>
	);
};

ActionMenu.propTypes = {
	folders: PropTypes.arrayOf(PropTypes.object),
	handleAddISRCSongToBlacklist: PropTypes.func,
	handleAddVariisIDSongToBlacklist: PropTypes.func,
	handleAddToFavourites: PropTypes.func,
	handleAddToFolder: PropTypes.func.isRequired,
	handleAddToPlaylist: PropTypes.func.isRequired,
	playlists: PropTypes.arrayOf(PropTypes.object),
	showSongInfo: PropTypes.bool,
	song: PropTypes.oneOfType([PropTypes.object]),
};

ActionMenu.defaultProps = {
	playlists: [],
	folders: [],
	handleAddISRCSongToBlacklist: null,
	handleAddVariisIDSongToBlacklist: null,
	handleAddToFavourites: null,
	showSongInfo: true,
	song: {},
};

export default React.memo(ActionMenu);
