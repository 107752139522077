import { apiUrl, getDefaultGetInit, getDefaultPostInit, parseStandardResponse } from '../utils/api';

const METADATA_FEEDS_PATH = 'metadata-feeds';

export async function getLabelMusicFeeds() {
	const url = `${apiUrl}/${METADATA_FEEDS_PATH}?labelMusicOnly=true`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getMusicFeeds() {
	const url = `${apiUrl}/${METADATA_FEEDS_PATH}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function setLabelMusicFeedSearchable(musicMetadataFeedId, searchable) {
	const url = `${apiUrl}/${METADATA_FEEDS_PATH}/${musicMetadataFeedId}/set-searchable/${searchable}`;
	const init = getDefaultPostInit();

	return parseStandardResponse(fetch(url, init));
}
