import React, { useState } from 'react';

import PropTypes from 'prop-types';

import DataTable from '../../components/layout/DataTable';
import { PaginationButtons } from '../../components/PaginationButtons';
import { formatNumberWithCommas } from '../../utils/numbers';

const PAGE_SIZE = 10;
const REPORTS_SONGS_NUMBERS = {
	GENRE: {
		key: 'genre',
		columns: ['Genre', 'Count'],
	},
	LABEL: {
		key: 'label',
		columns: ['Label', 'Count'],
	},
	PUBLISHER: {
		key: 'publisher',
		columns: ['Track Source', 'Count'],
	},
};

const GeneralNumbers = ({
	genreBreakdown,
	labelBreakdown,
	publisherBreakdown,
	numGenres,
	numLabels,
	numPublishers,
}) => {
	const [selectedReport, setSelectedReport] = useState(REPORTS_SONGS_NUMBERS.GENRE.key);
	const [currentPageNum, setCurrentPageNum] = useState(1);

	const getDataTableData = report => {
		// CM-110 Dashboard number will be done by original genres not cleared
		if (report === REPORTS_SONGS_NUMBERS.GENRE.key) {
			return {
				columns: REPORTS_SONGS_NUMBERS.GENRE.columns,
				data: genreBreakdown.map(g => [g.genre, formatNumberWithCommas(g.count)]),
			};
		}

		if (report === REPORTS_SONGS_NUMBERS.LABEL.key) {
			return {
				columns: REPORTS_SONGS_NUMBERS.LABEL.columns,
				data: labelBreakdown.map(l => [l.label, formatNumberWithCommas(l.count)]),
			};
		}

		if (report === REPORTS_SONGS_NUMBERS.PUBLISHER.key) {
			return {
				columns: REPORTS_SONGS_NUMBERS.PUBLISHER.columns,
				data: publisherBreakdown.map(p => [p.publisher, formatNumberWithCommas(p.count)]),
			};
		}

		return null;
	};

	const table = getDataTableData(selectedReport);
	const data = table ? table.data || [] : [];
	const numPages = Math.ceil(data.length / PAGE_SIZE);
	const currentPageData = data.slice((currentPageNum - 1) * PAGE_SIZE, (currentPageNum - 1) * PAGE_SIZE + PAGE_SIZE);

	return (
		<>
			<h5>General Numbers</h5>
			<p>* Click on a stat number to display more detailed information</p>

			<div className='catalog__container'>
				<div className='catalog__stats__row'>
					<div
						className='catalog__stat catalog__stat--focusable'
						onClick={() => {
							setSelectedReport(REPORTS_SONGS_NUMBERS.GENRE.key);
							setCurrentPageNum(1);
						}}
					>
						<div className='catalog__stat__text'>Total Genres</div>
						<div className='catalog__stat__number'>{numGenres}</div>
					</div>
					<div
						className='catalog__stat catalog__stat--focusable'
						onClick={() => {
							setSelectedReport(REPORTS_SONGS_NUMBERS.LABEL.key);
							setCurrentPageNum(1);
						}}
					>
						<div className='catalog__stat__text'>Total Labels</div>
						<div className='catalog__stat__number'>{formatNumberWithCommas(numLabels)}</div>
					</div>
					<div
						className='catalog__stat catalog__stat--focusable'
						onClick={() => {
							setSelectedReport(REPORTS_SONGS_NUMBERS.PUBLISHER.key);
							setCurrentPageNum(1);
						}}
					>
						<div className='catalog__stat__text'>Total Track Sources</div>
						<div className='catalog__stat__number'>{formatNumberWithCommas(numPublishers)}</div>
					</div>
				</div>
				{!!table && (
					<div className='catalog__content'>
						<DataTable headerRow={table.columns} data={currentPageData} fixedLayout widths={['70%', '30%']} />
						{numPages > 1 && (
							<div className='d-flex align-items-center mt-2'>
								<PaginationButtons
									handlePageChange={nextPage => setCurrentPageNum(nextPage)}
									numPages={numPages}
									pageNum={currentPageNum}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

GeneralNumbers.propTypes = {
	genreBreakdown: PropTypes.arrayOf(PropTypes.object).isRequired,
	labelBreakdown: PropTypes.arrayOf(PropTypes.object).isRequired,
	numGenres: PropTypes.number,
	numLabels: PropTypes.number,
	numPublishers: PropTypes.number,
	publisherBreakdown: PropTypes.arrayOf(PropTypes.object).isRequired,
};

GeneralNumbers.defaultProps = {
	numGenres: 0,
	numLabels: 0,
	numPublishers: 0,
};

export default GeneralNumbers;
