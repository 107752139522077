import { apiUrl, getDefaultPostInit, parseStandardResponse } from '../utils/api';

const USER_SONGS_PATH = 'user-songs';

export async function updateUserSongs(userSongId, zoneKeyId, notes) {
	const url = `${apiUrl}/${USER_SONGS_PATH}/${userSongId}`;
	const init = getDefaultPostInit({ zoneKeyId, notes });

	return parseStandardResponse(fetch(url, init));
}
