import React from 'react';

import findIndex from 'lodash/findIndex';

import * as songsApi from '../api/songs';
import PLAYER_MODES from '../constants/player-modes';
import PLAYER_STATUS from '../constants/player-status';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import backImg from '../images/back.svg';
import forwardImg from '../images/forward.svg';
import pauseWhiteImg from '../images/pause-white.svg';
import playWhiteImg from '../images/play-white.svg';

class Player extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTime: 0,
			duration: 0,
			audioReady: false,
			progress: 0,
			song_id: null,
			status: PLAYER_STATUS.STOPPED,
		};
	}

	async componentDidMount() {
		this.registerAudioEvents();
		await this.loadAudio();
	}

	async componentDidUpdate(prevProps, prevState) {
		const { song_id, status } = this.state;
		const { playerStatus, currentSong } = this.context;

		if (!!prevState.song_id && prevState.song_id !== song_id) {
			// load new song
			await this.loadAudio();
		} else if (song_id !== currentSong.song_id) {
			// pause and reset for new song
			this.pauseSong();
			this.reset(currentSong.song_id);
		} else if (song_id === currentSong.song_id && playerStatus !== status) {
			// Sync player status and global status to manage control play/pause from SongRow component
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ status: playerStatus });
			if (playerStatus === PLAYER_STATUS.PAUSE) {
				this.pauseSong();
			} else if (playerStatus === PLAYER_STATUS.PLAYING) {
				this.playSong();
			}
		}
	}

	componentWillUnmount() {
		this.audio = null;
	}

	navigatePlaylist = direction => {
		const { setCurrentSong, setPlaylistSongId, playlistSongId, playerSongsQueue } = this.context;
		const currentPositionIndex = findIndex(playerSongsQueue, {
			playlist_song_id: playlistSongId,
		});

		if (direction === 1) {
			setCurrentSong(playerSongsQueue[currentPositionIndex + 1]);
			setPlaylistSongId(playerSongsQueue[currentPositionIndex + 1].playlist_song_id);
			this.pauseSong();
		} else if (direction === -1) {
			setCurrentSong(playerSongsQueue[currentPositionIndex - 1]);
			setPlaylistSongId(playerSongsQueue[currentPositionIndex - 1].playlist_song_id);
			this.pauseSong();
		}
	};

	registerAudioEvents = () => {
		const { setPlayerStatus, playerMode, playlistSongId, playerSongsQueue } = this.context;

		this.audio.onloadedmetadata = () => {
			this.setState({ duration: this.audio.duration });
		};
		this.audio.ontimeupdate = () => {
			if (this.audio) {
				this.setState({
					progress: (this.audio.currentTime / this.audio.duration) * 100,
					currentTime: this.audio.currentTime,
				});
			}
		};
		this.audio.oncanplaythrough = () => {
			this.setState({ audioReady: true });
		};
		this.audio.onended = () => {
			setPlayerStatus(PLAYER_STATUS.STOPPED);

			if (
				playerMode === PLAYER_MODES.PLAYLIST &&
				findIndex(playerSongsQueue, {
					playlist_song_id: playlistSongId,
				}) !==
					playerSongsQueue.length - 1
			) {
				this.navigatePlaylist(1);
			} else {
				setPlayerStatus(PLAYER_STATUS.STOPPED);
				this.setState({ currentTime: 0, progress: 0 });
				this.audio.load();
			}
		};
		this.audio.onplay = () => {
			setPlayerStatus(PLAYER_STATUS.PLAYING);
		};

		this.audio.onpause = () => {
			setPlayerStatus(PLAYER_STATUS.PAUSE);
		};
	};

	reset = (song_id = null) => {
		this.setState({
			currentTime: 0,
			duration: 0,
			status: PLAYER_STATUS.STOPPED,
			audioReady: false,
			progress: 0,
			song_id,
		});
	};

	loadAudio = async () => {
		const { createToast, currentSong } = this.context;

		if (!!currentSong.song_id && !!currentSong.track_release_id) {
			await songsApi
				.getStreamableSignedURL(currentSong.song_id, currentSong.track_release_id)
				.then(data => {
					this.audio.currentTime = 0;
					this.audio.src = data.url;
					this.playSong();
					this.setState({ song_id: currentSong.song_id });
				})
				.catch(() => {
					createToast(
						'There was a problem playing the song, please contact your administrator',
						TOAST_LEVEL.ERROR,
						true
					);
				});
		}
	};

	playSong = () => {
		this.audio.play();
	};

	pauseSong = () => {
		this.audio.pause();
	};

	formatTime = durationSeconds => {
		const hours = Math.floor(durationSeconds / 60 / 60);
		const minutes = Math.floor(durationSeconds / 60);
		const seconds = Math.floor(durationSeconds % 60);
		const prettyprint = num => (num > 9 ? num.toString() : `0${num}`);

		return `${hours > 0 ? `${prettyprint(hours)}:` : ''}${prettyprint(minutes)}:${prettyprint(seconds)}`;
	};

	seek = e => {
		let { currentTime } = this.state;
		const { duration } = this.state;
		const { left, width } = this.scrub.getBoundingClientRect();
		const seekedTime = ((e.pageX - left) / width) * duration;

		const handleLeft = e.pageX - left;

		if (handleLeft >= 0 && handleLeft <= width) {
			currentTime = seekedTime;
			this.audio.currentTime = currentTime;
			const progress = (currentTime / this.audio.duration) * 100;

			this.setState({ currentTime, progress });
		}
	};

	mouseUp = () => {
		this.handle.classList.remove('player__scrub-dot--visible');
		window.removeEventListener('mousemove', this.seek);
		window.removeEventListener('mouseup', this.mouseUp);
		this.audio.volume = 1;
	};

	mouseDown = () => {
		this.audio.volume = 0;
		this.handle.classList.add('player__scrub-dot--visible');
		window.addEventListener('mousemove', this.seek);
		window.addEventListener('mouseup', this.mouseUp);
	};

	hasOverflowingChildren = element =>
		!!element && (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);

	render() {
		const { currentTime, duration, audioReady, progress } = this.state;
		const { playerStatus, playerMode, playerPlaylist, playlistSongId, playerSongsQueue, currentSong } = this.context;
		const displayNavigitionButtons =
			playerMode === PLAYER_MODES.PLAYLIST && !!playerSongsQueue && !!playerSongsQueue.length;

		return (
			<div className='player noselect'>
				<audio
					style={{ display: 'none' }}
					ref={audio => {
						this.audio = audio;
					}}
					preload='none'
				/>
				<div className='col-2 d-flex p-0'>
					<div
						className='player__cover mr-3'
						style={{
							backgroundColor: '#c9c9c9',
							backgroundImage: `url(${currentSong.thumbnail_image || currentSong.image})`,
							backgroundSize: 'cover',
						}}
					/>
					<div className='player__track-info'>
						<div
							className={`player__track-info__name ellipsis ${
								this.hasOverflowingChildren(this.infoName) ? 'player__track-info--overflowing' : ''
							}`}
							ref={infoName => {
								this.infoName = infoName;
							}}
						>
							{currentSong.name}
						</div>
						<div
							className={`player__track-info__artists ellipsis ${
								this.hasOverflowingChildren(this.infoArtists) ? 'player__track-info--overflowing' : ''
							}`}
							ref={infoArtists => {
								this.infoArtists = infoArtists;
							}}
						>
							{currentSong.display_artist}
						</div>
					</div>
				</div>
				<div className='col-8 d-flex flex-column align-items-center p-0'>
					<div className='player__controls'>
						{displayNavigitionButtons && (
							<button
								name='Player - Previous Song'
								onClick={() => this.navigatePlaylist(-1)}
								disabled={
									findIndex(playerSongsQueue, {
										playlist_song_id: playlistSongId,
									}) === 0
								}
								className='player__back-btn player__btn'
								type='button'
							>
								<img src={backImg} alt='Previous Song' />
							</button>
						)}
						<button
							onClick={playerStatus === PLAYER_STATUS.PLAYING ? this.pauseSong : this.playSong}
							disabled={!audioReady}
							className='player__play-btn player__btn'
							style={{ cursor: audioReady ? 'pointer' : 'wait' }}
							type='button'
							name={playerStatus === PLAYER_STATUS.PLAYING ? 'Pause' : 'Play'}
						>
							<img
								src={playerStatus === PLAYER_STATUS.PLAYING ? pauseWhiteImg : playWhiteImg}
								alt={playerStatus === PLAYER_STATUS.PLAYING ? 'Pause' : 'Play'}
							/>
						</button>
						{displayNavigitionButtons && (
							<button
								name='Player - Next Song'
								onClick={() => this.navigatePlaylist(1)}
								disabled={
									findIndex(playerSongsQueue, {
										playlist_song_id: playlistSongId,
									}) ===
									playerSongsQueue.length - 1
								}
								className='player__forward-btn player__btn'
								type='button'
							>
								<img src={forwardImg} alt='Next Song' />
							</button>
						)}
					</div>
					<div className='player__progress-bar'>
						<span>{this.formatTime(currentTime)}</span>
						<div
							className='player__scrub'
							ref={scrub => {
								this.scrub = scrub;
							}}
							onClick={this.seek}
						>
							{audioReady && !!this.audio.currentTime && !!currentTime ? (
								<>
									<span className='player__scrub-progress' style={{ width: `${progress}%` }} />
									<span
										className='player__scrub-dot'
										ref={handle => {
											this.handle = handle;
										}}
										onMouseDown={this.mouseDown}
										style={{ left: `${progress}%` }}
									/>
								</>
							) : null}
						</div>
						<span>{this.formatTime(duration)}</span>
					</div>
				</div>

				<div className='col-2 d-flex p-0 align-items-center justify-content-end'>
					<div className='player__playlist'>
						{playerMode === PLAYER_MODES.PLAYLIST ? (
							<>
								<div className='player__playlist__info'>
									Playlist: <span>{playerPlaylist.name}</span>
								</div>
								<div className='player__playlist__song-index'>
									Song:
									<span>
										{`${
											findIndex(playerSongsQueue, {
												playlist_song_id: playlistSongId,
											}) + 1
										} / ${playerSongsQueue.length}`}
									</span>
								</div>
							</>
						) : null}
						{currentSong.bpm && (
							<div className='player__song-details'>
								BPM:
								{Math.round(currentSong.bpm)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

Player.contextType = GlobalContext;

export default Player;
