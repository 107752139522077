import React from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';

import * as sharedFoldersAPI from '../../api/shared-folders';
import { BRANDS_MAP } from '../../constants/brands';
import GlobalContext from '../../contexts/GlobalContext';
import { getBrands } from '../../utils/auth';

class ShareModal extends React.Component {
	constructor(props) {
		super(props);

		const folder = props.folder || {};

		this.state = {
			sharedWithAllViewersBrandIds:
				(folder.brands || []).filter(b => b.shared_with_all_viewers).map(b => b.brand_id) || [],
			sharedWithAllCreatorsBrandIds:
				(folder.brands || []).filter(b => b.shared_with_all_creators).map(b => b.brand_id) || [],
			viewerUsers: [],
			creatorUsers: [],
			selectedViewers: [],
			selectedCreators: [],
		};
		this.fetchFolderBrandUsers = this.fetchFolderBrandUsers.bind(this);
	}

	componentDidMount() {
		this.fetchFolderBrandUsers();
	}

	formatUserOption = user => {
		const userName = `${user.first_name || ''} ${user.middle_name || ''} ${user.last_name || ''}`
			.replace(/\s\s+/g, ' ')
			.trim();

		return `${userName} ${user.email ? `<${user.email}>` : ''}`.trim();
	};

	handleShareAllViewersToggleForBrand = brandId => {
		const { sharedWithAllViewersBrandIds } = this.state;

		if (sharedWithAllViewersBrandIds.includes(brandId)) {
			this.setState({
				sharedWithAllViewersBrandIds: [...sharedWithAllViewersBrandIds.filter(b => b !== brandId)],
			});
		} else {
			this.setState({
				sharedWithAllViewersBrandIds: [...sharedWithAllViewersBrandIds, brandId],
			});
		}
	};

	handleShareAllCreatorsToggleForBrand = brandId => {
		const { sharedWithAllCreatorsBrandIds } = this.state;

		if (sharedWithAllCreatorsBrandIds.includes(brandId)) {
			this.setState({
				sharedWithAllCreatorsBrandIds: [...sharedWithAllCreatorsBrandIds.filter(b => b !== brandId)],
			});
		} else {
			this.setState({
				sharedWithAllCreatorsBrandIds: [...sharedWithAllCreatorsBrandIds, brandId],
			});
		}
	};

	async fetchFolderBrandUsers() {
		const { folder } = this.props;
		const users = await sharedFoldersAPI.getFolderBrandUsers(folder.shared_folder_id, true);
		const { viewerUsers, creatorUsers } = users;

		this.setState({
			viewerUsers: viewerUsers || [],
			creatorUsers: creatorUsers || [],
			selectedViewers: (viewerUsers || [])
				.filter(u => !!u.can_view_folder)
				.map(u => ({ value: u.user_id, label: this.formatUserOption(u) })),
			selectedCreators: (creatorUsers || [])
				.filter(u => !!u.can_modify_folder)
				.map(u => ({ value: u.user_id, label: this.formatUserOption(u) })),
		});
	}

	renderSharedFolderViewers = () => {
		const { sharedWithAllViewersBrandIds, viewerUsers, selectedViewers } = this.state;

		const options = viewerUsers.map(u => ({ value: u.user_id, label: this.formatUserOption(u) }));
		const userBrandIds = getBrands();
		const allBrandsSharedAll = sharedWithAllViewersBrandIds.length === userBrandIds.length;

		return (
			<div>
				<div className='d-flex flex-column folder-modal__content--highlighted'>
					{userBrandIds.map(userBrandId => (
						<div className='folder-modal__content__share_all' key={userBrandId}>
							<label className='m-0'>
								<input
									className='mr-2'
									type='checkbox'
									checked={sharedWithAllViewersBrandIds.includes(userBrandId)}
									onChange={() => this.handleShareAllViewersToggleForBrand(userBrandId)}
								/>
								<b>Share with all {BRANDS_MAP[userBrandId]} Shared Folder Viewer users</b>
							</label>
						</div>
					))}
				</div>
				<div className='mt-3'>
					<label className='folder-modal__form__label'>Shared with Viewers:</label>
					<Select
						className='folder-modal__form__input folder-modal__form__select select-container'
						classNamePrefix='select'
						value={!allBrandsSharedAll ? selectedViewers : []}
						onChange={selectedUsers => this.setState({ selectedViewers: selectedUsers })}
						options={options}
						isMulti
						isDisabled={allBrandsSharedAll}
					/>
				</div>
			</div>
		);
	};

	renderSharedFolderCreators = () => {
		const { sharedWithAllCreatorsBrandIds, creatorUsers, selectedCreators } = this.state;

		const options = creatorUsers.map(u => ({ value: u.user_id, label: this.formatUserOption(u) }));
		const userBrandIds = getBrands();
		const allBrandsSharedAll = sharedWithAllCreatorsBrandIds.length === userBrandIds.length;

		return (
			<div className='mb-5'>
				<div className='d-flex flex-column folder-modal__content--highlighted'>
					{userBrandIds.map(userBrandId => (
						<div className='folder-modal__content__share_all' key={userBrandId}>
							<label className='m-0'>
								<input
									className='mr-2'
									type='checkbox'
									checked={sharedWithAllCreatorsBrandIds.includes(userBrandId)}
									onChange={() => this.handleShareAllCreatorsToggleForBrand(userBrandId)}
								/>
								<b>Share with all {BRANDS_MAP[userBrandId]} Shared Folder Creator users</b>
							</label>
						</div>
					))}
				</div>
				<div className='mt-3'>
					<label className='folder-modal__form__label'>Shared with Creators:</label>
					<Select
						className='folder-modal__form__input folder-modal__form__select select-container'
						classNamePrefix='select'
						value={!allBrandsSharedAll ? selectedCreators : []}
						onChange={selectedUsers => this.setState({ selectedCreators: selectedUsers })}
						options={options}
						isMulti
						isDisabled={allBrandsSharedAll}
					/>
				</div>
			</div>
		);
	};

	render() {
		const { onClose, onSubmit } = this.props;
		const { folder = {} } = this.props;
		const { sharedWithAllViewersBrandIds, sharedWithAllCreatorsBrandIds, selectedViewers, selectedCreators } =
			this.state;
		const selectedViewerUserIDs = (selectedViewers || []).map(u => u.value);
		const selectedCreatorUserIDs = (selectedCreators || []).map(u => u.value);

		return (
			<div className='modal folder-modal d-block' role='dialog'>
				<div className='folder-modal__dialog'>
					<div className='modal-content folder-modal__content'>
						<h5 className='folder-modal__content__header mb-3'>Share {folder.name || ''} folder</h5>
						<h5>Share with Shared Folder Creators</h5>
						{this.renderSharedFolderCreators()}
						<h5>Share with Shared Folder Viewers</h5>
						{this.renderSharedFolderViewers()}
						<div className='folder-modal__form__buttons px-2'>
							<button name='ShareModal - Close' type='button' className='secondary-btn mr-3' onClick={onClose}>
								Close
							</button>
							<button
								name='ShareModal - Save'
								className='primary-btn'
								onClick={() => {
									onSubmit(
										sharedWithAllViewersBrandIds,
										selectedViewerUserIDs,
										sharedWithAllCreatorsBrandIds,
										selectedCreatorUserIDs
									);
								}}
								type='button'
							>
								Save
							</button>
						</div>
						<button
							name='ShareModal - Close'
							type='button'
							className='folder-modal__close'
							onClick={onClose}
							aria-hidden
						/>
					</div>
				</div>
			</div>
		);
	}
}

ShareModal.contextType = GlobalContext;

ShareModal.propTypes = {
	folder: PropTypes.oneOfType([PropTypes.object]),
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

ShareModal.defaultProps = {
	folder: {},
};

export default ShareModal;
