module.exports = {
	MUSIC_FEED_ID: {
		SONY_MUSIC_ENTERTAINMENT: 1,
		UNIVERSAL_MUSIC_GROUP: 2,
		EXTREME: 5,
		VARIIS_CUSTOM_WORKS: 3,
		ORCHARD: 7,
		WARNER: 8,
	},
	MUSIC_FEED_ABBREVIATION: {
		SONY_MUSIC_ENTERTAINMENT: 'SONY',
		UNIVERSAL_MUSIC_GROUP: 'UMG',
	},
};
