/* eslint-disable camelcase */
import React from 'react';

import PropTypes from 'prop-types';

import * as licensingApi from '../../api/licensing';
import DataTable from '../../components/layout/DataTable';
import Loader from '../../components/Loader';
import { PaginationButtons } from '../../components/PaginationButtons';
import { SORT_ORDER } from '../../constants/sort-options';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { ReactComponent as ArrowDownImg } from '../../images/arrow-down.svg';
import { ReactComponent as DownloadImg } from '../../images/download.svg';
import { getISODateTimeString } from '../../utils/date-time';

const DEFAULT_PAGE_SIZE = 100;

class ClassesImpacted extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			pageSize: DEFAULT_PAGE_SIZE,
			total: 0,
			data: [],
			sorters: [
				{
					field: 'variis_track_id',
					order: SORT_ORDER.ASC,
				},
			],
			loaded: false,
		};
	}

	componentDidMount() {
		const { sorters } = this.state;
		this.fetchClasses(DEFAULT_PAGE_SIZE, 1, sorters);
	}

	getSortIcon(key) {
		const { sorters } = this.state;
		const existingSorter = sorters.find(s => s.field === key);

		if (!existingSorter) {
			return null;
		}
		if (existingSorter.order === SORT_ORDER.DESC) {
			return (
				<span className='licesing-dashboard__report__table__header__icon'>
					<ArrowDownImg />
				</span>
			);
		}
		return (
			<span className='licesing-dashboard__report__table__header__icon licesing-dashboard__report__table__header__icon--inverse'>
				<ArrowDownImg />
			</span>
		);
	}

	fetchClasses = async (pageSize, page, sorters = null) => {
		const { createToast } = this.context;

		this.setState({ loaded: false });

		await licensingApi
			.getPublishedClassesImpactedReport({ pageSize, page, sorters })
			.then(response => {
				this.setState({
					page,
					pageSize,
					total: response.count,
					data: response.data || [],
					...(!!sorters && { sorters }),
				});
			})
			.catch(() => {
				createToast('There was an error getting the information, please refresh', TOAST_LEVEL.ERROR, true);
			})
			.finally(() => {
				this.setState({ loaded: true });
			});
	};

	downloadReport = async () => {
		const { createToast } = this.context;

		createToast('Generating Published Classes Impacted Report...', TOAST_LEVEL.INFO, true);

		const data = await licensingApi.exportPublishedClassesImpactedReportCSV();

		const csvURL = window.URL.createObjectURL(data);
		const tempLink = document.createElement('a');

		tempLink.href = csvURL;
		tempLink.setAttribute('download', `variis_licesing_published_class_impacted_${getISODateTimeString()}.csv`);
		tempLink.click();
		tempLink.remove();
		window.URL.revokeObjectURL(data);
	};

	downloadHistoryReport = async () => {
		const { createToast } = this.context;

		createToast('Generating Published Classes History Report...', TOAST_LEVEL.INFO, true);

		const data = await licensingApi.exportPublishedClassesHistoryReportCSV();

		const csvURL = window.URL.createObjectURL(data);
		const tempLink = document.createElement('a');

		tempLink.href = csvURL;
		tempLink.setAttribute('download', `variis_invalidated_published_classes_history_${getISODateTimeString()}.csv`);
		tempLink.click();
		tempLink.remove();
		window.URL.revokeObjectURL(data);
	};

	toggleSort(key) {
		const { sorters, pageSize } = this.state;
		const existingSorter = sorters.find(s => s.field === key);

		if (!existingSorter) {
			sorters.push({ field: key, order: SORT_ORDER.ASC });
			this.fetchClasses(pageSize, 1, sorters);
		} else if (existingSorter.order === SORT_ORDER.ASC) {
			this.fetchClasses(
				pageSize,
				1,
				sorters.map(s => ({ ...s, ...(s.field === key && { order: SORT_ORDER.DESC }) }))
			);
		} else {
			this.fetchClasses(
				pageSize,
				1,
				sorters.filter(s => s.field !== key)
			);
		}
	}

	renderMultiLineCell = (data, delimiter, dataTransformation = null) => (
		<div className='licesing-dashboard__report__multi-line-cell'>
			{(data || '').split(delimiter).map((line, idx) => (
				// eslint-disable-next-line react/no-array-index-key
				<div key={idx}>{dataTransformation ? dataTransformation(line) : line}</div>
			))}
		</div>
	);

	render() {
		const { countSingleOcurrences, parentLoaded } = this.props;
		const { data, page, pageSize, total, sorters, loaded } = this.state;
		const numPages = Math.ceil(total / pageSize);
		const headers = [
			{ label: 'Class ID', key: 'class_id', sortDisabled: true },
			{ label: 'Asset ID', key: 'asset_id' },
			{ label: 'Playlist Name', key: 'playlist_name' },
			{ label: 'VARIIS ID', key: 'variis_track_id' },
			{ label: 'Song Title', key: 'name' },
			{ label: 'Artist Name', key: 'artist' },
			{ label: 'Drop Reason', key: 'dropped_reason' },
			{ label: 'Date', key: 'dropped_timestamp' },
		];

		return (
			<>
				<div className='licesing-dashboard__header'>
					<div>
						<h4>
							{countSingleOcurrences || 0} Published Class
							{countSingleOcurrences && countSingleOcurrences > 1 ? 'es' : ''} Impacted
						</h4>
						<p>Published classes currently live on the app that contain songs impacted by LSR drops or label purges</p>
					</div>
					<div className='d-flex flex-column align-items-start'>
						<button
							name='ClassesImpacted - Download CSV'
							className='d-flex secondary-btn mb-2'
							disabled={!total}
							onClick={this.downloadReport}
							type='button'
						>
							<DownloadImg /> Download CSV
						</button>
						<button
							name='ClassesImpacted - Download History'
							className='d-flex primary-btn'
							disabled={!total}
							onClick={this.downloadHistoryReport}
							type='button'
						>
							<DownloadImg /> Download History
						</button>
					</div>
				</div>

				<Loader loaded={parentLoaded && loaded} className='licesing-dashboard__loader'>
					<DataTable
						className='licesing-dashboard__report__table'
						headerRow={headers.map(h =>
							h.sortDisabled ? (
								h.label
							) : (
								<div
									key={h.key}
									className='licesing-dashboard__report__table__header'
									onClick={() => this.toggleSort(h.key)}
								>
									{h.label}
									{this.getSortIcon(h.key)}
								</div>
							)
						)}
						widths={['15%', '12.5%', '12.5%', '12.5%', '12.5%', '10%', '17.5%', '7.5%']}
						fixedLayout={false}
						data={data.map(
							({
								class_id,
								asset_id,
								playlist_name,
								variis_track_id,
								name,
								artist,
								dropped_reason,
								dropped_timestamp,
							}) => [
								class_id,
								asset_id,
								playlist_name,
								variis_track_id,
								name,
								artist,
								this.renderMultiLineCell(dropped_reason, ', '),
								this.renderMultiLineCell(dropped_timestamp, ', ', droppedDate =>
									droppedDate ? new Date(droppedDate).toLocaleDateString() : 'N/A'
								),
							]
						)}
					/>
					{numPages > 1 && (
						<div className='d-flex align-items-center mt-5'>
							<PaginationButtons
								handlePageChange={nextPage => {
									this.fetchClasses(pageSize, nextPage, sorters);
								}}
								numPages={numPages}
								pageNum={page || 1}
							/>
						</div>
					)}
				</Loader>
			</>
		);
	}
}

ClassesImpacted.propTypes = {
	countSingleOcurrences: PropTypes.number.isRequired,
	parentLoaded: PropTypes.bool.isRequired,
};

ClassesImpacted.contextType = GlobalContext;

export default ClassesImpacted;
