import React from 'react';

import * as authApi from '../api/auth';
import { ERRORS } from '../constants/messages';

const MODES = {
	LOGIN: 'LOGIN',
	CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			loggingIn: false,
			mode: MODES.LOGIN,
			oldPassword: '',
			newPassword: '',
			repeatNewPassword: '',
			errorMsg: '',
			changingPassword: false,
			successMsg: '',
		};

		this.changePasswordFormRef = React.createRef();
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
	}

	handleSubmit = e => {
		const { email, password } = this.state;

		if (email === '' || password === '') {
			return false;
		}
		e.preventDefault();
		this.setState({ loggingIn: true, successMsg: '' }, async () => {
			try {
				await authApi.login(email, password);
				window.location = '/';
			} catch (err) {
				this.setState({
					loggingIn: false,
					errorMsg:
						err && err.maitance_mode
							? 'Site is down for maintenance, we will be back soon!'
							: `Incorrect username or password. Please try again. `,
				});
			}
		});
		return true;
	};

	handleChangePassword(e) {
		e.preventDefault();

		const { email, oldPassword, newPassword, repeatNewPassword } = this.state;

		this.changePasswordFormRef.current.checkValidity();
		if (!this.changePasswordFormRef.current.reportValidity()) {
			return;
		}

		if (newPassword !== repeatNewPassword) {
			this.setState({ errorMsg: ERRORS.PASSWORDS_DO_NOT_MATCH });
			return;
		}

		this.setState({ changingPassword: true }, async () => {
			try {
				await authApi.changePassword({
					username: email,
					oldPassword,
					newPassword,
				});
				this.setState({
					errorMsg: '',
					mode: MODES.LOGIN,
					changingPassword: false,
					successMsg: 'Successfully changed password.',
					oldPassword: '',
					newPassword: '',
					repeatNewPassword: '',
				});
			} catch (err) {
				this.setState({
					changingPassword: false,
					errorMsg:
						err && err.maitance_mode
							? 'Site is down for maintenance, we will be back soon!'
							: `Incorrect username or password. Please try again. `,
				});
			}
		});
	}

	render() {
		const {
			mode,
			email,
			password,
			loggingIn,
			errorMsg,
			successMsg,
			oldPassword,
			newPassword,
			repeatNewPassword,
			changingPassword,
		} = this.state;

		return (
			<div className='login__container'>
				<div className='container'>
					<h5 className='h5 h5--white mb-0'>Welcome to </h5>
					<h1 className='h1 h1--white mb-4'>Platform X</h1>

					{mode === MODES.LOGIN ? (
						<form>
							<div className='form-group login__form'>
								<div className='input-group mb-3'>
									<div className='input-group-prepend'>
										<div className='login__form__input__icon login__form__input__icon--email' />
									</div>
									<input
										type='text'
										autoComplete='username'
										className='login__form__input'
										placeholder='Username'
										aria-label='Username'
										value={email}
										onChange={e => this.setState({ email: e.target.value })}
										required
									/>
								</div>

								<div className='input-group mb-3'>
									<div className='input-group-prepend'>
										<div className='login__form__input__icon login__form__input__icon--password' />
									</div>
									<input
										type='password'
										autoComplete='password'
										className='login__form__input login__form__input__password'
										placeholder='Password'
										aria-label='Password'
										value={password}
										onChange={e => this.setState({ password: e.target.value })}
										required
									/>
								</div>
							</div>
							<div className='flex'>
								<button
									name={`Login - ${loggingIn ? 'Logging In...' : 'Log In'}`}
									type='submit'
									className='primary-btn mr-4'
									onClick={this.handleSubmit}
								>
									{loggingIn ? 'Logging In...' : 'Log In'}
								</button>
								<span
									className='login__forgot-password'
									onClick={() =>
										this.setState({
											mode: MODES.CHANGE_PASSWORD,
											errorMsg: '',
											successMsg: '',
										})
									}
								>
									Change Your Password
								</span>
								<span className='login__error'> {errorMsg} </span>
								<span className='login__success'> {successMsg} </span>
							</div>
						</form>
					) : (
						<form ref={this.changePasswordFormRef}>
							<div className='form-group login__form'>
								<div className='input-group mb-3'>
									<div className='input-group-prepend'>
										<div className='login__form__input__icon login__form__input__icon--email' />
									</div>
									<input
										type='text'
										className='login__form__input'
										placeholder='Username'
										aria-label='Username'
										value={email}
										onChange={e => this.setState({ email: e.target.value })}
										required
									/>
								</div>

								<div className='input-group mb-3'>
									<div className='input-group-prepend'>
										<div className='login__form__input__icon login__form__input__icon--password' />
									</div>
									<input
										type='password'
										className='login__form__input login__form__input__password'
										placeholder='Old Password'
										aria-label='Old Password'
										value={oldPassword}
										onChange={e => this.setState({ oldPassword: e.target.value })}
										required
									/>
								</div>

								<div className='input-group mb-3'>
									<div className='input-group-prepend'>
										<div className='login__form__input__icon login__form__input__icon--password' />
									</div>
									<input
										type='password'
										autoComplete='password'
										className='login__form__input login__form__input__password'
										placeholder='New Password'
										aria-label='New Password'
										value={newPassword}
										onChange={e => this.setState({ newPassword: e.target.value })}
										required
									/>
								</div>

								<div className='input-group mb-3'>
									<div className='input-group-prepend'>
										<div className='login__form__input__icon login__form__input__icon--password' />
									</div>
									<input
										type='password'
										autoComplete='password'
										className='login__form__input login__form__input__password'
										placeholder='Repeat New Password'
										aria-label='Repeat New Password'
										value={repeatNewPassword}
										onChange={e => this.setState({ repeatNewPassword: e.target.value })}
										required
									/>
								</div>
							</div>
							<div className='flex'>
								<button
									name={`Login - ${changingPassword ? 'Changing Password...' : 'Change Password'}`}
									type='submit'
									className='primary-btn mr-4'
									onClick={this.handleChangePassword}
								>
									{changingPassword ? 'Changing Password...' : 'Change Password'}
								</button>
								<span
									className='login__forgot-password'
									onClick={() =>
										this.setState({
											mode: MODES.LOGIN,
											errorMsg: '',
											successMsg: '',
										})
									}
								>
									Back to Login
								</span>
								<span className='login__error'> {errorMsg} </span>
							</div>
						</form>
					)}
				</div>
			</div>
		);
	}
}

export default Login;
