import React from 'react';

import { PLAYLIST_STATUS, PLAYLIST_STATUS_MAP } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import BACKGROUND_POSITION_BY_INDEX from '../../constants/playlist-thumbnail-positions';
import STATUS_BADGE_COLOURS from '../../constants/status-badge-colours';
import defaultCardBg from '../../images/card-bg-1.svg';
import StatusBadge from '../StatusBadge';

class PlaylistCard extends React.Component {
	constructor(props) {
		super(props);
		this.handleCardClick = this.handleCardClick.bind(this);
	}

	handleCardClick() {
		const { history, playlistId } = this.props;

		history.push(`/playlist/${playlistId}`);
	}

	getCardBackgroundURL = (validImages, playlistStatusId, isMosaic) => {
		return {
			backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, ${
				playlistStatusId === PLAYLIST_STATUS.ARCHIVED ? '0.5' : '0'
			}) 0%, #000000 99.99%, rgba(0, 0, 0, 0) 100%), ${
				isMosaic
					? validImages.map(img => `url(${img})`).join(',')
					: `url(${validImages.length ? validImages[0] || defaultCardBg : defaultCardBg})`
			}`,
			backgroundPosition: isMosaic ? validImages.map((_, i) => `${BACKGROUND_POSITION_BY_INDEX[i]}`).join(',') : '',
			backgroundRepeat: 'no-repeat',
			backgroundSize: isMosaic ? 'cover, 50% 50%, 50% 50%, 50% 50%, 50% 50%' : 'cover',
		};
	};

	render() {
		const {
			className,
			statusId,
			flagged,
			inAirtable,
			numSongs,
			notes,
			totalDuration,
			adjustedDuration,
			coverImages,
			title,
		} = this.props;
		const validImages = (coverImages || '').split(',').filter(i => !!i);
		const isMosaic = validImages.length === 4;

		return (
			<div
				className={`${className} playlist__card `}
				style={{
					...this.getCardBackgroundURL(validImages, statusId, isMosaic),
				}}
				onClick={this.handleCardClick}
			>
				{statusId && (
					<StatusBadge
						status={PLAYLIST_STATUS_MAP[statusId]}
						flagged={flagged}
						className={`status-badge--collection-card ${STATUS_BADGE_COLOURS[statusId]}`}
						inAirtable={inAirtable}
					/>
				)}
				<div className='playlist__card__details'>
					<div className='playlist__card__details__title'>{title}</div>
					<div className='playlist__card__details__notes'>{notes}</div>
					<div className='playlist__card__details__difficulty'>
						<div className='float-left'>
							{numSongs} Song
							{numSongs > 1 || numSongs === 0 ? 's' : ''}
						</div>
						{totalDuration && (
							<div className='playlist__card__details__time'>
								{adjustedDuration} /{totalDuration}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

PlaylistCard.propTypes = {
	adjustedDuration: PropTypes.string,
	className: PropTypes.string,
	coverImages: PropTypes.string,
	flagged: PropTypes.bool,
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	inAirtable: PropTypes.bool,
	notes: PropTypes.string,
	numSongs: PropTypes.number,
	playlistId: PropTypes.number.isRequired,
	statusId: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	totalDuration: PropTypes.string,
};

PlaylistCard.defaultProps = {
	className: '',
	adjustedDuration: '',
	coverImages: '',
	flagged: false,
	inAirtable: false,
	notes: '',
	numSongs: 0,
	totalDuration: '',
};

export default withRouter(PlaylistCard);
