import React from 'react';

import PropTypes from 'prop-types';

import GlobalContext from '../../contexts/GlobalContext';

class FolderModal extends React.Component {
	constructor(props) {
		super(props);

		const folder = props.folder || {};

		this.state = {
			visible: true,
			folder,
			editMode: folder && folder.shared_folder_id,
			name: folder.name || '',
			description: folder.description || '',
		};

		this.formRef = React.createRef();
	}

	handleSubmit = async e => {
		const { onSubmit } = this.props;
		const { folder, name, description = '' } = this.state;

		if (this.formRef.current.reportValidity() && name && onSubmit) {
			e.preventDefault();
			onSubmit({ folderID: (folder || {}).shared_folder_id, name, description });
			this.reset();
		}
	};

	reset = () => {
		this.setState({
			visible: false,
			folder: {},
			editMode: false,
			name: '',
			description: '',
		});
	};

	onClose = e => {
		e.preventDefault();

		const { onClose } = this.props;

		this.reset();
		if (onClose) {
			onClose();
		}
	};

	render() {
		const { name, description, visible, editMode } = this.state;

		if (!visible) {
			return null;
		}

		return (
			<div className={`modal folder-modal ${visible ? 'd-block' : ''}`} role='dialog'>
				<div className='folder-modal__dialog'>
					<div className='modal-content folder-modal__content'>
						<h5 className='folder-modal__content__header'>{editMode ? 'Edit Folder' : 'Create a New Folder'}</h5>
						<form ref={this.formRef}>
							<div>
								<label className='folder-modal__form__label'>Name</label>
								<input
									type='text'
									required
									className='folder-modal__form__input px-2'
									value={name}
									onChange={e => this.setState({ name: e.target.value })}
									maxLength='50'
								/>
							</div>
							<div className='mt-3'>
								<label className='folder-modal__form__label'>Description</label>
								<textarea
									className='folder-modal__form__input px-2'
									onChange={e => this.setState({ description: e.target.value })}
									value={description}
									maxLength='500'
								/>
							</div>
							<div className='folder-modal__form__buttons px-2'>
								<button name='FolderModal - Close' type='button' className='secondary-btn mr-3' onClick={this.onClose}>
									Close
								</button>
								<button
									name={`FolderModal - ${editMode ? 'Save' : 'Create'}`}
									type='submit'
									className='primary-btn'
									onClick={this.handleSubmit}
								>
									{editMode ? 'Save' : 'Create'}
								</button>
							</div>
						</form>
						<button
							name='FolderModal - Close'
							type='button'
							className='folder-modal__close'
							onClick={this.onClose}
							aria-hidden
						/>
					</div>
				</div>
			</div>
		);
	}
}

FolderModal.contextType = GlobalContext;

FolderModal.propTypes = {
	folder: PropTypes.oneOfType([PropTypes.object]),
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

FolderModal.defaultProps = {
	folder: {},
};

export default FolderModal;
