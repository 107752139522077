import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';

const DropdownButton = ({
	options,
	placeholder,
	placeholderClassName,
	controlClassName,
	menuClassName,
	arrowClassName,
	showArrowIcon,
	showFilterIcon,
	onChange,
	value,
	disabled,
	flipArrow,
	alignCenter,
}) => (
	<Dropdown
		options={options}
		placeholder={placeholder}
		controlClassName={classNames('dropdown__root', {
			dropdown__filter: showFilterIcon,
			'dropdown__root--center': alignCenter,
			[controlClassName]: !!controlClassName,
		})}
		placeholderClassName={classNames('dropdown__placeholder', { [placeholderClassName]: !!placeholderClassName })}
		arrowClassName={classNames({
			[arrowClassName]: !!arrowClassName,
			dropdown__arrow: showArrowIcon,
			'dropdown__arrow--inverse': flipArrow,
		})}
		menuClassName={classNames('dropdown__menu shadow', { [menuClassName]: !!menuClassName })}
		onChange={onChange}
		value={value !== 'all' ? value : ''}
		disabled={disabled}
	/>
);

DropdownButton.propTypes = {
	alignCenter: PropTypes.bool,
	arrowClassName: PropTypes.string,
	controlClassName: PropTypes.string,
	disabled: PropTypes.bool,
	flipArrow: PropTypes.bool,
	menuClassName: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	placeholder: PropTypes.string,
	placeholderClassName: PropTypes.string,
	showArrowIcon: PropTypes.bool,
	showFilterIcon: PropTypes.bool,
	value: PropTypes.string,
};

DropdownButton.defaultProps = {
	alignCenter: false,
	arrowClassName: '',
	controlClassName: '',
	disabled: false,
	flipArrow: false,
	menuClassName: '',
	placeholder: '',
	placeholderClassName: '',
	showArrowIcon: false,
	showFilterIcon: false,
	value: '',
};

export default React.memo(DropdownButton);
