import { apiUrl, getDefaultGetInit, parseStandardResponse } from '../utils/api';

const BRANDS_PATH = 'brands';

export async function getBrands() {
	const url = `${apiUrl}/${BRANDS_PATH}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}
