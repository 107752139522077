import React from 'react';

import PropTypes from 'prop-types';

import alertBlackImg from '../images/alert-black.svg';

const Unavailable = ({ songs }) => {
	const unavailableSongs = songs.filter(s => !s.valid || s.is_blacklisted);

	return unavailableSongs.length > 0 ? (
		<div className='playlist__unavailable'>
			<img src={alertBlackImg} alt='alert' className='mr-3' />
			<div>
				Songs in your library have become unavailable.
				<ul>
					{unavailableSongs.map((song, i) => (
						<li key={i}>
							{song.name},{song.artist}
						</li>
					))}
				</ul>
			</div>
		</div>
	) : null;
};

Unavailable.propTypes = {
	songs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { Unavailable };
