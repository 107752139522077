import React from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import BACKGROUND_POSITION_BY_INDEX from '../../constants/playlist-thumbnail-positions';
import STATUS_BADGE_COLOURS from '../../constants/status-badge-colours';
import defaultCardBg from '../../images/card-bg-1.svg';
import viewersImg from '../../images/people-white-small.svg';
import addViewerImgWhite from '../../images/person-add-white-small.svg';
import StatusBadge from '../StatusBadge';

class FolderCard extends React.Component {
	constructor(props) {
		super(props);
		this.onCardClick = this.onCardClick.bind(this);
	}

	onCardClick() {
		const { history, folderID } = this.props;

		history.push(`/folder/${folderID}`);
	}

	getCardBackgroundURL = (validImages, isDeleted, isMosaic) => {
		return {
			backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, ${
				isDeleted ? '0.5' : '0'
			}) 0%, #000000 99.99%, rgba(0, 0, 0, 0) 100%), ${
				isMosaic
					? validImages.map(img => `url(${img})`).join(',')
					: `url(${validImages.length ? validImages[0] || defaultCardBg : defaultCardBg})`
			}`,
			backgroundPosition: isMosaic ? validImages.map((_, i) => `${BACKGROUND_POSITION_BY_INDEX[i]}`).join(',') : '',
			backgroundRepeat: 'no-repeat',
			backgroundSize: isMosaic ? 'cover, 50% 50%, 50% 50%, 50% 50%, 50% 50%' : 'cover',
		};
	};

	render() {
		const { className, coverImages, title, isDeleted, numSongs, totalViewers, totalCreators } = this.props;
		const validImages = (coverImages || '').split(',').filter(i => !!i);
		const isMosaic = validImages.length === 4;

		return (
			<div
				className={`${className} folder__card `}
				style={{
					...this.getCardBackgroundURL(validImages, isDeleted, isMosaic),
				}}
				onClick={this.onCardClick}
			>
				{isDeleted && (
					<StatusBadge status='ARCHIVED' className={`status-badge--collection-card ${STATUS_BADGE_COLOURS[6]}`} />
				)}
				<div className='folder__card__title'>
					<span className='folder__card__title--overflowing'>{title}</span>
				</div>
				<div className='folder__card__details'>
					<div className='float-left'>
						{numSongs} Song
						{numSongs > 1 || numSongs === 0 ? 's' : ''}
					</div>
					{!!totalCreators || totalCreators === 0 ? (
						<div className='float-right d-flex align-items-center' stye={{ lineHeight: '18px' }}>
							<img src={addViewerImgWhite} alt='Creators' className='ml-2 mr-1' />
							{totalCreators}
						</div>
					) : null}
					{!!totalViewers || totalViewers === 0 ? (
						<div className='float-right d-flex align-items-center' stye={{ lineHeight: '18px' }}>
							<img src={viewersImg} alt='Viewers' className='ml-2 mr-1' />
							{totalViewers}
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

FolderCard.propTypes = {
	className: PropTypes.string,
	coverImages: PropTypes.string,
	folderID: PropTypes.number.isRequired,
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	isDeleted: PropTypes.bool,
	numSongs: PropTypes.number,
	title: PropTypes.string.isRequired,
	totalViewers: PropTypes.number,
	totalCreators: PropTypes.number,
};

FolderCard.defaultProps = {
	className: '',
	coverImages: '',
	numSongs: 0,
	isDeleted: false,
	totalViewers: null,
	totalCreators: null,
};

export default withRouter(FolderCard);
