import * as PERMISSIONS from '../constants/permissions';
import * as ROLES from '../constants/roles';

const USER_KEY = 'music-profile';
const USERNAME = 'username';
const EMAIL = 'email';
const BRANDS = 'brand_ids';
const SHARED_FOLDER_ONLY_BRAND = 'shared_folders_only';

export const getUser = () => {
	const userJson = sessionStorage.getItem(USER_KEY);

	if (userJson) {
		return JSON.parse(userJson);
	}
	return null;
};

export const setUser = user => {
	sessionStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const clearUser = () => {
	sessionStorage.removeItem(USER_KEY);
};

export const hasRole = (roles, role) => (roles ? roles.filter(r => r === role || r.name === role).length !== 0 : false);

export const getRoles = () => {
	const user = getUser();

	if (user) {
		return user[ROLES.ROLES_KEY];
	}
	return [];
};

export const getUsername = () => {
	const user = getUser();

	if (user) {
		return user[USERNAME];
	}
	return '';
};

export const getEmail = () => {
	const user = getUser();

	if (user) {
		return user[EMAIL];
	}
	return '';
};

export const getInitials = () => {
	const user = getUser();

	let a = [];

	if (user) {
		if (!user.first_name && !user.last_name && !user.middle_name) {
			a.push(user.username);
		} else {
			a.push(user.first_name);
			a.push(user.middle_name);
			a.push(user.last_name);
		}
		a = a.map(e => (e && typeof e === 'string' ? e.toUpperCase().charAt(0) : ''));
		return `${a.join('')}`;
	}
	return '';
};

export const isContentCreator = () => hasRole(getRoles(), ROLES.CONTENT_CREATOR);

export const isAdministrator = () => hasRole(getRoles(), ROLES.ADMINISTRATOR);

export const isInstructor = () => hasRole(getRoles(), ROLES.INSTRUCTOR);

export const isAny = () =>
	hasRole(getRoles(), ROLES.INSTRUCTOR) ||
	hasRole(getRoles(), ROLES.CONTENT_CREATOR) ||
	hasRole(getRoles(), ROLES.ADMINISTRATOR);

export const getBrands = () => {
	const user = getUser();

	if (user) {
		return user[BRANDS];
	}
	return [];
};

export const getPermissions = () => {
	const user = getUser();

	if (user) {
		return user[PERMISSIONS.PERMISSIONS_KEY];
	}
	return [];
};

export const hasPermission = (requiredPermissions, atLeastOne = false) => {
	const permissions = getPermissions() || [];

	return atLeastOne
		? requiredPermissions.some(r => permissions.includes(r))
		: requiredPermissions.every(r => permissions.includes(r));
};

export const isSharedFolderUser = () =>
	hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR, PERMISSIONS.SHARED_FOLDER_VIEWER], true);

export const canSearchOnFullCatalog = () => {
	const user = getUser();

	const isSharedFolderViewer = hasPermission([PERMISSIONS.SHARED_FOLDER_VIEWER]);
	const isSharedFolderCreator = hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR]);
	const isBrandRestrictedToFolders = user[SHARED_FOLDER_ONLY_BRAND];

	return (
		(isInstructor() || isContentCreator()) &&
		(!isSharedFolderUser() || // not a shared folder user
			(isSharedFolderViewer && !isBrandRestrictedToFolders) || // a shared folder viewer where the brand allows instructors use full search catalog
			isSharedFolderCreator) // shared folder creators to search and create folders
	);
};

export const isViewOnly = () => {
	return hasPermission([PERMISSIONS.CONTENT_CREATOR_VIEW_ONLY]);
};

export const canViewLicensing = () => isAdministrator() || (isContentCreator() && !isViewOnly());
