import React, { useState, useEffect, useContext } from 'react';

import DualListBox from 'react-dual-listbox';

import * as blacklistApi from '../../api/blacklist';
import Loader from '../../components/Loader';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';

const GenreBlacklist = () => {
	const [allGenres, setAllGenres] = useState([]);
	const [blacklistedGenres, setBlacklistedGenres] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const { createToast } = useContext(GlobalContext);

	const fetchAllGenres = async () => {
		const genres = await blacklistApi.getAllGenres();

		setLoaded(true);
		setAllGenres(genres.map(g => ({ value: g.genre, label: g.genre })));
	};

	const fetchBlacklistedGenres = async () => {
		const apiBlacklistedGenres = await blacklistApi.getBlacklistedGenres();

		setBlacklistedGenres(apiBlacklistedGenres.map(g => g.value));
	};

	// componentDidMount
	useEffect(() => {
		fetchAllGenres();
		fetchBlacklistedGenres();
	}, []);

	const handleGenreOnChange = newBlacklistedGenres => {
		setBlacklistedGenres(newBlacklistedGenres);
	};

	const handleGenreFilterSubmit = async () => {
		const res = await blacklistApi.setBlacklistedGenres(blacklistedGenres);

		// show toast
		if (res.length === blacklistedGenres.length) {
			createToast('Successfully updated genre filter!', TOAST_LEVEL.SUCCESS, true);
		} else {
			createToast(
				'There was an error while updating the genre filter. Please try again later',
				TOAST_LEVEL.ERROR,
				true
			);
		}
	};

	return (
		<Loader loaded={loaded} className='licesing-dashboard__loader'>
			<div className='mb-3 d-flex flex-column'>
				<h4 className='h4 mb-1'>Genres</h4>
				<DualListBox
					options={allGenres}
					selected={blacklistedGenres}
					onChange={e => handleGenreOnChange(e)}
					showHeaderLabels
					icons={{
						moveLeft: <span key={0}>{'<'}</span>,
						moveRight: <span key={0}>{'>'}</span>,
						moveAllLeft: [<span key={0}>{'<<'}</span>],
						moveAllRight: [<span key={0}>{'>>'}</span>],
					}}
				/>
				<button
					name='GenreBlackList - Submit Changes'
					className='btn primary-btn align-self-end'
					onClick={handleGenreFilterSubmit}
					style={{ maxWidth: '131px' }}
					type='submit'
				>
					Submit Changes
				</button>
			</div>
		</Loader>
	);
};

export default GenreBlacklist;
