import React from 'react';

import { PLAYLIST_STATUS, BRANDS_MAP } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { getPlaylists } from '../api/playlists';
import DropdownButton from '../components/DropdownButton';
import Loader from '../components/Loader';
import { PaginationButtons } from '../components/PaginationButtons';
import PlaylistGrid from '../components/playlist/PlaylistGrid';
import { PLAYLISTS_SORT_OPTIONS, SORT_ORDER } from '../constants/sort-options';
import GlobalContext from '../contexts/GlobalContext';
import { getBrands } from '../utils/auth';

const SUBMITTED_URL_PATH = '/playlists/pending';
const APPROVED_URL_PATH = '/playlists/approved';
const INVALID_URL_PATH = '/playlists/invalid';
const REJECTED_URL_PATH = '/playlists/rejected';

const DEFAULT_LIMIT = 100; // use large pages until filtering has been implemented
const DEFAULT_PAGE = 1;

const DEFAULT_SORT_ORDER = SORT_ORDER.DESC;
const DEFAULT_SORT_OPTION = PLAYLISTS_SORT_OPTIONS.SUBMITTED_DATE.value;

// playlist type and brand filters
const PLAYLIST_TYPE_FILTER_KEY = 'playlist-type-filter';
const BRAND_FILTER_KEY = 'brand-filter';
const PLAYLIST_TYPE_DEFAULT_FILTER = { value: -1, label: 'All' };
const BRAND_DEFAULT_FILTER = { value: -1, label: 'All' };

class Playlists extends React.Component {
	constructor(props) {
		super(props);

		const { location } = this.props;

		this.state = {
			playlistsPath: location.pathname,
			playlists: {
				count: 0,
				data: [],
			},
			sortBy: DEFAULT_SORT_OPTION,
			sortOrder: DEFAULT_SORT_ORDER,
			playlistTypeFilter: JSON.parse(sessionStorage.getItem(PLAYLIST_TYPE_FILTER_KEY)) || PLAYLIST_TYPE_DEFAULT_FILTER,
			brandFilter: JSON.parse(sessionStorage.getItem(BRAND_FILTER_KEY)) || BRAND_DEFAULT_FILTER,
			loaded: false,
			pageSize: DEFAULT_LIMIT,
			pageNum: DEFAULT_PAGE,
			total: 0,
		};

		this.fetchPlaylists = this.fetchPlaylists.bind(this);
		this.toggleSortOrder = this.toggleSortOrder.bind(this);
	}

	componentDidMount() {
		const { playlistTypeFilter, brandFilter, pageSize, pageNum } = this.state;

		this.fetchPlaylists(
			pageSize,
			pageNum,
			DEFAULT_SORT_ORDER,
			DEFAULT_SORT_OPTION,
			playlistTypeFilter.value,
			brandFilter.value
		);
	}

	handleSortChange(option) {
		const { playlistTypeFilter, brandFilter, sortOrder, sortBy, pageSize, pageNum } = this.state;

		let newState = {
			sortBy: option.value,
		};

		// if same, simply toggle sort Order
		if (option.value === sortBy) {
			newState = {
				...newState,
				sortOrder: sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC,
			};
		}

		this.setState(newState, () => {
			this.fetchPlaylists(pageSize, pageNum, sortOrder, sortBy, playlistTypeFilter?.value, brandFilter?.value);
		});
	}

	handlePlaylistTypeFilterChange(option) {
		const { brandFilter, sortOrder, sortBy, pageSize } = this.state;

		sessionStorage.setItem(PLAYLIST_TYPE_FILTER_KEY, JSON.stringify(option));
		this.setState({
			playlistTypeFilter: option,
		});

		this.fetchPlaylists(pageSize, 1, sortOrder, sortBy, option.value, brandFilter?.value);
	}

	handleBrandFilterChange(option) {
		const { playlistTypeFilter, sortOrder, sortBy, pageSize } = this.state;

		sessionStorage.setItem(BRAND_FILTER_KEY, JSON.stringify(option));
		this.setState({
			brandFilter: option,
		});

		this.fetchPlaylists(pageSize, 1, sortOrder, sortBy, playlistTypeFilter?.value, option.value);
	}

	handlePageChange = nextPage => {
		const { playlistTypeFilter, brandFilter, sortOrder, sortBy, pageSize } = this.state;

		this.fetchPlaylists(pageSize, nextPage, sortOrder, sortBy, playlistTypeFilter?.value, brandFilter?.value);
	};

	getPlaylistTitleByURL() {
		const { playlistsPath } = this.state;

		switch (playlistsPath) {
			case SUBMITTED_URL_PATH:
				return 'Pending Approval';
			case APPROVED_URL_PATH:
				return 'Approved Playlists';
			case REJECTED_URL_PATH:
				return 'Rejected Playlists';
			default:
				return 'Invalid Playlists';
		}
	}

	getPlaylistTypeDropdownOptions = () => {
		const { playlistTypes } = this.context;
		return [
			{ label: 'All', value: -1 },
			...playlistTypes.map(type => ({
				value: type.playlist_type_id,
				label: type.name,
			})),
			{ label: 'No Category', value: null },
		];
	};

	getBrandDropdownOptions = () => {
		// only show the brands user has access to
		const userBrands = getBrands();

		return [
			{ label: 'All', value: -1 },
			...Object.entries(BRANDS_MAP)
				.filter(([id]) => userBrands.includes(Number(id)))
				.map(([value, label]) => ({
					label,
					value,
				})),
		];
	};

	toggleSortOrder() {
		const { sortOrder, sortBy, pageSize } = this.state;

		this.setState(
			{
				sortOrder: sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC,
			},
			() => this.fetchPlaylists(pageSize, 1, sortOrder, sortBy)
		);
	}

	async fetchPlaylists(limit, page, sortOrder, sortBy, playlistTypeFilter, brandFilter) {
		const { playlistsPath } = this.state;

		this.setState({ loaded: false });
		let playlistStatusId;

		switch (playlistsPath) {
			case SUBMITTED_URL_PATH:
				playlistStatusId = PLAYLIST_STATUS.SUBMITTED;
				break;
			case APPROVED_URL_PATH:
				playlistStatusId = PLAYLIST_STATUS.APPROVED;
				break;
			case REJECTED_URL_PATH:
				playlistStatusId = PLAYLIST_STATUS.REJECTED;
				break;
			default:
				playlistStatusId = PLAYLIST_STATUS.INVALID;
				break;
		}

		const playlists = await getPlaylists({
			status: [playlistStatusId],
			limit,
			page,
			sortOrder,
			sortBy,
			playlistTypeFilter,
			brandFilter,
		}).finally({ loaded: true });

		this.setState({ playlists, loaded: true, pageNum: page, total: playlists?.count || 0 });
	}

	render() {
		const { history } = this.props;
		const {
			playlistTypeFilter,
			brandFilter,
			sortOrder,
			sortBy,
			playlists,
			loaded,
			playlistsPath,
			pageNum,
			pageSize,
			total,
		} = this.state;
		const numPages = Math.ceil(total / pageSize);

		return (
			<div className='playlists'>
				<h3 className='h3'> {this.getPlaylistTitleByURL()}</h3>
				<div className='playlists__filters'>
					<div className='d-flex w-50 justify-content-between'>
						<span className='d-flex'>
							<span>Playlist Type: </span>
							<div className='playlists__filters__playlist_type'>
								<DropdownButton
									options={this.getPlaylistTypeDropdownOptions()}
									onChange={e => this.handlePlaylistTypeFilterChange(e)}
									value={playlistTypeFilter.label}
									showArrowIcon
									menuClassName='dropdown__menu--left'
									placeholderClassName='playlists__filter__text--gray'
									flipArrow={sortOrder !== DEFAULT_SORT_ORDER}
								/>
							</div>
						</span>
						<span className='d-flex'>
							<span>Brand: </span>
							<div className='playlists__filters__playlist_type'>
								<DropdownButton
									options={this.getBrandDropdownOptions()}
									onChange={e => this.handleBrandFilterChange(e)}
									value={brandFilter.label}
									showArrowIcon
									menuClassName='dropdown__menu--left'
									placeholderClassName='playlists__filter__text--gray'
									flipArrow={sortOrder !== DEFAULT_SORT_ORDER}
								/>
							</div>
						</span>
						<div className='mt-1'>
							<DropdownButton
								options={Object.values(PLAYLISTS_SORT_OPTIONS)}
								onChange={e => this.handleSortChange(e)}
								value={sortBy}
								showArrowIcon
								showFilterIcon
								menuClassName='dropdown__menu--left'
								placeholderClassName='playlists__filter__text--gray'
								flipArrow={sortOrder !== DEFAULT_SORT_ORDER}
							/>
						</div>
					</div>
				</div>
				<div className='playlists__grid'>
					<Loader loaded={loaded}>
						<PlaylistGrid
							playlists={playlists.data || []}
							handleRowClick={playlistId => history.push(`/playlist/${playlistId}`)}
							showPreviousStatus={playlistsPath === INVALID_URL_PATH}
							showNotes={playlistsPath === SUBMITTED_URL_PATH}
							total={total}
						/>
						{numPages > 1 ? (
							<div className='d-flex align-items-center mt-5'>
								<PaginationButtons
									handlePageChange={this.handlePageChange}
									numPages={numPages}
									pageNum={pageNum || 0}
								/>
							</div>
						) : null}
					</Loader>
				</div>
			</div>
		);
	}
}

Playlists.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
};

Playlists.contextType = GlobalContext;

export default withRouter(Playlists);
