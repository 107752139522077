import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { withRouter } from 'react-router';
import { Switch } from 'react-router-dom';

import * as blacklistApi from '../api/blacklist';
import * as playlistsApi from '../api/playlists';
import * as sharedFoldersAPI from '../api/shared-folders';
import ArtistBlacklistModal from '../components/blacklist/ArtistBlacklistModal';
import SongsBlacklistModal from '../components/blacklist/SongsBlacklistModal';
import SideNavigation from '../components/layout/SideNavigation';
import UserActions from '../components/layout/UserActions';
import Notifications from '../components/Notifications';
import Player from '../components/Player';
import InstructorPlaylistModal from '../components/playlist/InstructorPlaylistModal';
import NewPlaylistModal from '../components/playlist/NewPlaylistModal';
import ProtectedRoute from '../components/ProtectedRoute';
import PlaylistRail from '../components/search/PlaylistRail';
import SearchBar from '../components/search/SearchBar';
import SearchContext from '../components/search/SearchContext';
import FolderModal from '../components/sharedFolder/FolderModal';
import Toasts from '../components/Toast';
import { BLACKLIST_TYPE } from '../constants/blacklist';
import * as PERMISSIONS from '../constants/permissions';
import { ADMINISTRATOR, CONTENT_CREATOR, INSTRUCTOR } from '../constants/roles';
import { TOAST_LEVEL } from '../constants/toast-levels';
import GlobalContext from '../contexts/GlobalContext';
import { canSearchOnFullCatalog, isAdministrator, isContentCreator, isInstructor, isViewOnly } from '../utils/auth';
import { onDragEnd } from '../utils/drop-zone-events';

import Blacklist from './blacklist/Blacklist';
import Browse from './browse/Browse';
import BrowseResults from './browse/BrowseResults';
import Catalog from './catalog/Catalog';
import Dashboard from './dashboard';
import Folder from './Folder';
import Folders from './Folders';
import GenresManager from './GenresManager';
import HealthDashboard from './HealthDashboard';
import LicensingDashboard from './licensing/LicensingDashboard';
import MyPlaylists from './MyPlaylists';
import MySongs from './MySongs';
import Playlist from './Playlist';
import Playlists from './Playlists';
import SearchResults from './search/SearchResults';
import SearchResultsCollection from './search/SearchResultsCollection';
import SearchResultsSongs from './search/SearchResultsSongs';
import Settings from './Settings';
import ShowPlaylist from './ShowPlaylist';
import ShowPlaylists from './ShowPlaylists';
import SongsValidation from './SongsValidation';
import Team from './Team';

class Layout extends React.Component {
	initDrag = () => {
		const { setIsDragging } = this.context;

		setIsDragging(true);
	};

	handleDrop = async result => {
		const { setIsDragging } = this.context;

		await onDragEnd(this.context, result);
		setIsDragging(false);
	};

	handleNewFolderSubmit = async ({ name, description }) => {
		const { getSearchFolders, setShowNewFolderModal, songToInitializeFolder, createToast } = this.context;
		const { history } = this.props;

		sharedFoldersAPI
			.createSharedFolder(name, description, songToInitializeFolder)
			.then(folder => {
				if (folder) {
					history.push(`/folder/${folder.shared_folder_id}`);
				}
			})
			.catch(() => {
				createToast('There was a problem creating a folder, please try later', TOAST_LEVEL.ERROR, true);
			})
			.finally(async () => {
				setShowNewFolderModal(false);
				await getSearchFolders(true);
			});
	};

	handleNewPlaylistSubmit = async (playlistTypeId, redirect = true) => {
		const {
			getSearchPlaylists,
			newPlaylistModalInitSongId,
			clearNewPlaylistModalInitSongId,
			setShowNewPlaylistModal,
			createToast,
		} = this.context;
		const { history } = this.props;

		if (playlistTypeId) {
			// create playlist
			try {
				const playlists = await playlistsApi.createPlaylist(playlistTypeId);

				getSearchPlaylists(true);

				// added successfully
				if (playlists && playlists.length === 1) {
					// if there is an initSongId (when Create New Playlist is selected from the AddToPlaylistButton), add it to the playlist as well
					if (newPlaylistModalInitSongId) {
						await playlistsApi.addSongToPlaylist(playlists[0].playlist_id, newPlaylistModalInitSongId);

						// clear initSongId from GlobalContext
						clearNewPlaylistModalInitSongId();
					}

					// close modal
					setShowNewPlaylistModal(false);

					// redirect
					if (redirect) {
						history.push(`/playlist/${playlists[0].playlist_id}`);
					}
				}
			} catch (err) {
				createToast(
					'There was an error creating new playlist, please refresh the page and try again later',
					TOAST_LEVEL.ERROR,
					true
				);
			}
		}
	};

	handleAddSongISRCToBlacklist = async () => {
		const { isrcToBlacklist, isrcBlacklistCallback, createToast, closeISRCBlacklistModal } = this.context;
		const isrc = isrcToBlacklist;

		await blacklistApi
			.addSongToBlacklistByISRC(isrc)
			.then(async () => {
				if (isrcBlacklistCallback) {
					await isrcBlacklistCallback(isrc);
				}
				closeISRCBlacklistModal();

				createToast(`Successfully added ISRC ${isrc} to blacklist!`, TOAST_LEVEL.SUCCESS, true);
			})
			.catch(() => {
				closeISRCBlacklistModal();
				createToast(
					'There was an error adding song to a playlist, please refresh and try later',
					TOAST_LEVEL.ERROR,
					true
				);
			});
	};

	handleAddSongVariisIDToBlacklist = async () => {
		const { variisIDToBlacklist, variisBlacklistCallback, createToast, closeVariisIDBlacklistModal } = this.context;
		const variisID = variisIDToBlacklist;

		await blacklistApi
			.addSongToBlacklistByVariisID(variisID)
			.then(async () => {
				if (variisBlacklistCallback) {
					await variisBlacklistCallback(variisID);
				}
				closeVariisIDBlacklistModal();

				createToast(`Successfully added Variis ID ${variisID} to blacklist!`, TOAST_LEVEL.SUCCESS, true);
			})
			.catch(() => {
				closeVariisIDBlacklistModal();
				createToast(
					'There was an error adding song to a playlist, please refresh and try later',
					TOAST_LEVEL.ERROR,
					true
				);
			});
	};

	handleAddArtistToBlacklist = async selectedFeedIds => {
		const { artistToBlacklist, artistBlacklistCallback, closeArtistBlacklistModal, createToast } = this.context;

		const artist = artistToBlacklist;

		await blacklistApi
			.addArtistToBlacklist(artist, selectedFeedIds)
			.then(async res => {
				if (artistBlacklistCallback) {
					await artistBlacklistCallback(res);
				}
				closeArtistBlacklistModal();

				createToast(`Successfully added ${artist} to blacklist!`, TOAST_LEVEL.SUCCESS, true);
			})
			.catch(() => {
				closeArtistBlacklistModal();
				createToast(
					'There was an error while adding the Artist filter. Please try again later',
					TOAST_LEVEL.ERROR,
					true
				);
			});
	};

	render() {
		const { location, history } = this.props;
		const {
			toasts,
			scrollLock,
			playlistInSearchContext,
			setPlaylistToSearchContext,
			showPlayer,
			showNewFolderModal,
			setShowNewFolderModal,
			showNewPlaylistModal,
			setShowNewPlaylistModal,
			showISRCBlacklistModal,
			closeISRCBlacklistModal,
			showArtistBlacklistModal,
			closeArtistBlacklistModal,
			showVariisIDBlacklistModal,
			closeVariisIDBlacklistModal,
			showISRCPlayCountModal,
			closeISRCPlayCountModal,
		} = this.context;
		const onSearch = location && location.pathname.startsWith('/search');

		let defaultComponent = null;

		defaultComponent = isAdministrator() ? HealthDashboard : defaultComponent;
		// CC dashboard higher precedence over admin dashboard if user shares CC role with admin role
		defaultComponent = isContentCreator() ? Dashboard : defaultComponent;
		// Instructor dashboard higher precedence over CC dashboard if user shares Instructor role with Admin or CC role
		defaultComponent = isInstructor() ? Dashboard : defaultComponent;

		return (
			<div>
				<Toasts toasts={toasts} />
				<DragDropContext onDragEnd={this.handleDrop} onDragStart={this.initDrag}>
					<div className={classNames('container-fluid layout', { 'layout--no-scroll': scrollLock })}>
						<div className='row'>
							<div className='col-lg-2 side-nav shadow-sm'>
								<SideNavigation />
							</div>
							<div
								className={classNames('offset-lg-2 col-lg-10 main', {
									'main--playlist-rail-open': onSearch,
								})}
							>
								<div
									className={classNames('main__header', {
										'main__header--collapsed': !canSearchOnFullCatalog(),
									})}
								>
									<div className='main__header__left'>
										<SearchBar />
										<SearchContext
											playlistInSearchContext={playlistInSearchContext}
											setPlaylistToSearchContext={setPlaylistToSearchContext}
										/>
									</div>
									<div className='main__header__right'>
										<div className='mr-4'>
											<Notifications history={history} />
										</div>
										<UserActions history={history} />
									</div>
								</div>
								<div className={classNames('main__content', { 'main__content--playing': showPlayer })}>
									<Switch>
										<ProtectedRoute
											key='1'
											exact
											path='/'
											roles={[INSTRUCTOR, CONTENT_CREATOR, ADMINISTRATOR]}
											component={defaultComponent}
										/>
										<ProtectedRoute
											key='2'
											path='/dashboard'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											component={defaultComponent}
										/>
										<ProtectedRoute
											key='3_collection_songs'
											path='/search/:collection/:term/:identifier?/songs'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											restricted={!canSearchOnFullCatalog()}
											component={SearchResultsSongs}
										/>
										<ProtectedRoute
											key='3_songs'
											path='/search/songs'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											restricted={!canSearchOnFullCatalog()}
											component={SearchResultsSongs}
										/>
										<ProtectedRoute
											key='3_suggested'
											path='/search/suggested'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											restricted={!canSearchOnFullCatalog()}
											component={SearchResultsSongs}
										/>
										<ProtectedRoute
											key='3_collection_search'
											path='/search/:collection'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											restricted={!canSearchOnFullCatalog()}
											component={SearchResultsCollection}
										/>
										<ProtectedRoute
											key='4'
											path='/search'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											restricted={!canSearchOnFullCatalog()}
											component={SearchResults}
										/>
										<ProtectedRoute key='5' path='/my-playlists' roles={[INSTRUCTOR]} component={MyPlaylists} />
										<ProtectedRoute key='6' path='/my-songs' roles={[INSTRUCTOR]} component={MySongs} />
										<ProtectedRoute
											key='7'
											path='/playlist/:pid'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											component={Playlist}
										/>
										<ProtectedRoute key='8' path='/playlists/pending' roles={[CONTENT_CREATOR]} component={Playlists} />
										<ProtectedRoute
											key='9'
											path='/playlists/approved'
											roles={[CONTENT_CREATOR]}
											component={Playlists}
										/>
										<ProtectedRoute
											key='10'
											path='/team'
											roles={[ADMINISTRATOR]}
											component={Team}
											restricted={isViewOnly()}
										/>
										<ProtectedRoute
											key='11'
											path='/playlists/invalid'
											roles={[CONTENT_CREATOR]}
											component={Playlists}
										/>
										<ProtectedRoute
											key='12'
											path='/catalog'
											roles={[ADMINISTRATOR, CONTENT_CREATOR]}
											component={Catalog}
											restricted={isViewOnly()}
										/>
										<ProtectedRoute
											key='13'
											path='/validation'
											roles={[ADMINISTRATOR, CONTENT_CREATOR]}
											component={SongsValidation}
										/>
										<ProtectedRoute
											key='14'
											path='/blacklist/:collection?'
											roles={[ADMINISTRATOR, CONTENT_CREATOR]}
											component={Blacklist}
											restricted={isViewOnly()}
										/>
										<ProtectedRoute
											key='15'
											path='/show-playlists'
											roles={[CONTENT_CREATOR]}
											component={ShowPlaylists}
											restricted={isViewOnly()}
										/>
										<ProtectedRoute
											key='16'
											path='/show-playlist/:globalTrackerShowId'
											roles={[CONTENT_CREATOR]}
											component={ShowPlaylist}
											restricted={isViewOnly()}
										/>
										<ProtectedRoute
											key='18'
											path='/genres-manager'
											roles={[ADMINISTRATOR]}
											component={GenresManager}
											restricted={isViewOnly()}
										/>
										<ProtectedRoute
											key='19'
											path='/playlists/rejected'
											roles={[CONTENT_CREATOR]}
											component={Playlists}
										/>
										<ProtectedRoute
											key='20'
											path='/folders'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											permissions={[PERMISSIONS.SHARED_FOLDER_VIEWER, PERMISSIONS.SHARED_FOLDER_CREATOR]}
											component={Folders}
										/>
										<ProtectedRoute
											key='21'
											path='/folder/:fid'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											permissions={[PERMISSIONS.SHARED_FOLDER_VIEWER, PERMISSIONS.SHARED_FOLDER_CREATOR]}
											component={Folder}
										/>
										<ProtectedRoute
											key='22'
											path='/licensing-dashboard/:collection?'
											roles={[ADMINISTRATOR]}
											component={LicensingDashboard}
											restricted={isViewOnly()}
										/>
										<ProtectedRoute
											key='23'
											path='/browse/:collection/:term'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											component={BrowseResults}
										/>
										<ProtectedRoute
											key='24'
											path='/browse/:collection'
											roles={[INSTRUCTOR, CONTENT_CREATOR]}
											component={Browse}
										/>
										<ProtectedRoute key='25' path='/health' roles={[ADMINISTRATOR]} component={HealthDashboard} />
										<ProtectedRoute key='26' path='/settings' roles={[ADMINISTRATOR]} component={Settings} />
									</Switch>
								</div>
							</div>
							{onSearch ? <PlaylistRail /> : null}
							{showPlayer ? <Player /> : null}
							{showNewFolderModal ? (
								<FolderModal
									onClose={() => {
										setShowNewFolderModal(false);
									}}
									onSubmit={folder => this.handleNewFolderSubmit(folder)}
								/>
							) : null}
							{showNewPlaylistModal ? (
								<NewPlaylistModal
									onClose={() => {
										setShowNewPlaylistModal(false);
									}}
									onSubmit={(playlistTypeId, redirect) => this.handleNewPlaylistSubmit(playlistTypeId, redirect)}
								/>
							) : null}
							{(isContentCreator() || isAdministrator()) && (
								<>
									{showISRCBlacklistModal && (
										<SongsBlacklistModal
											onConfirmClick={this.handleAddSongISRCToBlacklist}
											onCancelClick={closeISRCBlacklistModal}
											blacklistType={BLACKLIST_TYPE.ISRC}
										/>
									)}

									{showVariisIDBlacklistModal && (
										<SongsBlacklistModal
											onConfirmClick={this.handleAddSongVariisIDToBlacklist}
											onCancelClick={closeVariisIDBlacklistModal}
											blacklistType={BLACKLIST_TYPE.VARIIS_ID}
										/>
									)}

									{showArtistBlacklistModal && (
										<ArtistBlacklistModal
											onConfirmClick={this.handleAddArtistToBlacklist}
											onCancelClick={closeArtistBlacklistModal}
										/>
									)}
								</>
							)}
							{isInstructor() && showISRCPlayCountModal && (
								<InstructorPlaylistModal onConfirmClick={() => closeISRCPlayCountModal()} />
							)}
						</div>
					</div>
				</DragDropContext>
			</div>
		);
	}
}

Layout.contextType = GlobalContext;

Layout.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
	location: PropTypes.objectOf(PropTypes.any).isRequired,
};

Layout.contextType = GlobalContext;

export default withRouter(Layout);
