import * as qs from 'query-string';

import { apiUrl, getDefaultGetInit, getDefaultPostInit, parseStandardResponse } from '../utils/api';

const SONG_PATH = 'songs';

export async function getUserDashboardSongs(limit, page) {
	const url = `${apiUrl}/${SONG_PATH}/favorites?${qs.stringify({
		limit,
		page,
		dashboard: true,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getUserSongs({ limit, page, bpms, sorter, sortOrder, genres, artists, musicFeedIDs, query }) {
	const url = `${apiUrl}/${SONG_PATH}/favorites?${qs.stringify({
		limit,
		page,
		bpms: bpms && bpms.length ? bpms.join(',') : undefined,
		genres: genres && genres.length ? genres.join(',') : undefined,
		artists: artists && artists.length ? artists.join(',') : undefined,
		musicFeedIDs: musicFeedIDs && musicFeedIDs.length ? musicFeedIDs.join(',') : undefined,
		sorter,
		sortOrder,
		query,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function addSongToFavorites(songId) {
	const url = `${apiUrl}/${SONG_PATH}/favorites/add`;
	const init = getDefaultPostInit({ songId });

	return parseStandardResponse(fetch(url, init));
}

export async function removeSongFromFavorites(songId) {
	const url = `${apiUrl}/${SONG_PATH}/favorites/remove`;
	const init = getDefaultPostInit({ songId });

	return parseStandardResponse(fetch(url, init));
}

export async function getClearanceStatusByISRC(isrcs) {
	const url = `${apiUrl}/${SONG_PATH}/isrcs-validation`;
	const init = getDefaultPostInit({ isrcs });

	return parseStandardResponse(fetch(url, init));
}

export async function getSongValidationReport(isrcs) {
	const url = `${apiUrl}/${SONG_PATH}/isrcs-report`;
	const init = getDefaultPostInit({ isrcs });
	const res = await fetch(url, init);

	return res.blob();
}

export async function getPurgedSongsCSV() {
	const url = `${apiUrl}/${SONG_PATH}/purged`;
	const init = getDefaultGetInit();
	const res = await fetch(url, init);

	return res.blob();
}

export function generateSongURL(songID, trackReleaseID) {
	return `${apiUrl}/${SONG_PATH}/${songID}/${trackReleaseID}/download`;
}

export async function verifyMusicAsset(songID, trackReleaseID) {
	const url = `${apiUrl}/${SONG_PATH}/${songID}/${trackReleaseID}/verify-asset-download`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getStreamableSignedURL(songID, trackReleaseID) {
	const url = `${apiUrl}/${SONG_PATH}/${songID}/${trackReleaseID}/streamable-url`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export function generateFullCatalogURL() {
	return `${apiUrl}/${SONG_PATH}/download/full-catalog`;
}

export function generateClearedCatalogURL() {
	return `${apiUrl}/${SONG_PATH}/download/cleared-songs-catalog`;
}

export function generateExtremeMetadataURL() {
	return `${apiUrl}/${SONG_PATH}/download/extreme-music-metadata`;
}

export function getSongUsageCountByISRCAndUser(isrc) {
	const url = `${apiUrl}/${SONG_PATH}/${isrc}/play-count-usage`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}
