import React from 'react';

import { MUSIC_FEED_ABBREVIATION, MUSIC_FEED_ID } from '@content-playlist-creation/common/constants';
import invert from 'lodash/invert';
import Switch from 'react-switch';

// import { exportSongsAddedReportCSVURL, exportSongsDroppedReportCSVURL } from '../../api/licensing';
import { getLabelMusicFeeds, setLabelMusicFeedSearchable } from '../../api/metadata-feeds';
// import { getPlaylistReport } from '../../api/playlists';
// import {
// 	generateClearedCatalogURL,
// 	generateFullCatalogURL,
// 	getPurgedSongsCSV,
// 	generateExtremeMetadataURL,
// } from '../../api/songs';
import AlertModal from '../../components/AlertModal';
import Loader from '../../components/Loader';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import { isAdministrator } from '../../utils/auth';
// import { getFormattedISODateTimeString, getISODateTimeString } from '../../utils/date-time';
import { getFormattedISODateTimeString } from '../../utils/date-time';

import CatalogStatistics from './CatalogStatistics';

class Catalog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			labelMusicFeedsLoaded: false,
			labelMusicFeeds: [],
			showLabelMusicFeedToggleModal: false,
			selectedLabelMusicFeed: {},
		};
	}

	componentDidMount() {
		if (isAdministrator()) {
			this.fetchLabelMusicFeeds();
		}
	}

	componentWillUnmount() {
		if (this.fetcher) {
			clearTimeout(this.fetcher);
			this.fetcher = 0;
		}
	}

	// handleGetPurgedSongs = async () => {
	// 	const { createToast } = this.context;

	// 	createToast('Processing Purged Releases Report...', TOAST_LEVEL.INFO, true);
	// 	const data = await getPurgedSongsCSV();

	// 	// download CSV file
	// 	const csvURL = window.URL.createObjectURL(data);

	// 	const tempLink = document.createElement('a');

	// 	tempLink.href = csvURL;
	// 	tempLink.setAttribute('download', `variis_sony_purged_releases_${getISODateTimeString()}.csv`);
	// 	tempLink.click();
	// 	tempLink.remove();
	// 	window.URL.revokeObjectURL(data);
	// };

	// handleGetPlaylistReport = async () => {
	// 	const { createToast } = this.context;

	// 	createToast('Processing Playlist Report...', TOAST_LEVEL.INFO, true);

	// 	const data = await getPlaylistReport();
	// 	// download CSV file
	// 	const csvURL = window.URL.createObjectURL(data);

	// 	const tempLink = document.createElement('a');

	// 	tempLink.href = csvURL;
	// 	tempLink.setAttribute('download', `variis_playlist_report_${getFormattedISODateTimeString()}.csv`);
	// 	tempLink.click();
	// 	tempLink.remove();
	// 	window.URL.revokeObjectURL(data);
	// };

	// handleGetExtremeMetadata = async () => {
	// 	const { createToast } = this.context;
	// 	createToast('Processing Extreme Metadata Report...', TOAST_LEVEL.INFO, true);

	// 	const tempLink = document.createElement('a');

	// 	tempLink.href = generateExtremeMetadataURL();
	// 	tempLink.click();
	// };

	handleLabelMusicFeedToggle(musicMetadataFeedId, searchable) {
		const { createToast } = this.context;

		this.setState(
			{
				showLabelMusicFeedToggleModal: false,
				labelMusicFeedsLoaded: false,
			},
			async () => {
				await setLabelMusicFeedSearchable(musicMetadataFeedId, searchable);
				createToast(
					`Successfully toggled ${MUSIC_FEED_ABBREVIATION[invert(MUSIC_FEED_ID)[musicMetadataFeedId]]} feed!`,
					TOAST_LEVEL.SUCCESS,
					true
				);

				this.fetchLabelMusicFeeds();
			}
		);
	}

	// getReportsDownloadButtonsData = () => [
	// 	{
	// 		label: 'Songs Added',
	// 		onClick: () => this.downloadSongsAddedReportCSV(),
	// 	},
	// 	{
	// 		label: 'Songs Dropped',
	// 		onClick: () => this.downloadSongsDroppedReportCSV(),
	// 	},
	// 	{
	// 		label: 'Latest Cleared Catalog File',
	// 		onClick: () => this.downloadClearedCatalog(),
	// 	},
	// 	{
	// 		label: 'Full Catalog File',
	// 		onClick: () => this.downloadFullCatalog(),
	// 	},
	// 	{
	// 		label: 'Purged Releases',
	// 		onClick: () => this.handleGetPurgedSongs(),
	// 	},
	// 	{
	// 		label: 'Playlist Report',
	// 		onClick: () => this.handleGetPlaylistReport(),
	// 	},
	// 	{
	// 		label: 'Extreme Metadata Report',
	// 		onClick: () => this.handleGetExtremeMetadata(),
	// 	},
	// ];

	getLabelMusicFeedToggle(feed, i) {
		const { labelMusicFeedsLoaded } = this.state;

		// show Modal upon clicking the switch
		return (
			<div key={i} className='border-bottom py-3'>
				<label className=''>
					<span className='playlist__switch__label'>{feed.feed_name}</span>
				</label>
				<Switch
					onChange={() => {
						this.setState({
							showLabelMusicFeedToggleModal: true,
							selectedLabelMusicFeed: feed,
						});
					}}
					checked={feed.searchable}
					checkedIcon={false}
					uncheckedIcon={false}
					height={20}
					width={40}
					className='mt-1'
					onColor='#000'
					offColor='#d2d2d2'
					disabled={!labelMusicFeedsLoaded}
				/>
			</div>
		);
	}

	fetchLabelMusicFeeds = async () => {
		const res = await getLabelMusicFeeds();

		this.setState({
			labelMusicFeeds: res,
			labelMusicFeedsLoaded: true,
		});
	};

	// downloadClearedCatalog = () => {
	// 	const tempLink = document.createElement('a');

	// 	tempLink.href = generateClearedCatalogURL();
	// 	tempLink.click();
	// };

	// downloadFullCatalog = () => {
	// 	const tempLink = document.createElement('a');

	// 	tempLink.href = generateFullCatalogURL();
	// 	tempLink.click();
	// };

	getLabelMusicFeedToggleModalReasons = (feedName, newSearchableValue) => {
		// setting searchable to TRUE
		if (newSearchableValue) {
			return [
				`Songs from ${feedName} will be shown in the Search.`,
				`Songs from ${feedName} that are in Shared Folders will be visible.`,
				`All label music feed toggles on the Catalog Dashboard will be disabled until changes are applied.`,
			];
		}

		// setting searchable to FALSE
		return [
			`Songs from ${feedName} will be hidden from the Search.`,
			`Songs from ${feedName} that are in Shared Folders will be hidden.`,
			`All label music feed toggles on the Catalog Dashboard will be disabled until changes are applied.`,
		];
	};

	getLabelMusicFeedToggleModalDescription = (feedName, newSearchableValue) => {
		return `Please confirm that you would like to ${
			newSearchableValue ? 'enable' : 'disable'
		} the label music feed for ${feedName}? This will result in: `;
	};

	// downloadSongsAddedReportCSV = () => {
	// 	const tempLink = document.createElement('a');

	// 	tempLink.href = exportSongsAddedReportCSVURL();
	// 	tempLink.click();
	// };

	// downloadSongsDroppedReportCSV = () => {
	// 	const tempLink = document.createElement('a');

	// 	tempLink.href = exportSongsDroppedReportCSVURL();
	// 	tempLink.click();
	// };

	render() {
		const { labelMusicFeeds, labelMusicFeedsLoaded, showLabelMusicFeedToggleModal, selectedLabelMusicFeed } =
			this.state;

		return (
			<div className='catalog'>
				<AlertModal
					title='Confirm Label Music Feed Toggle'
					description={this.getLabelMusicFeedToggleModalDescription(
						selectedLabelMusicFeed.feed_name,
						!selectedLabelMusicFeed.searchable
					)}
					reasons={this.getLabelMusicFeedToggleModalReasons(
						selectedLabelMusicFeed.feed_name,
						!selectedLabelMusicFeed.searchable
					)}
					showModal={showLabelMusicFeedToggleModal}
					onConfirmClick={() =>
						this.handleLabelMusicFeedToggle(
							selectedLabelMusicFeed.music_metadata_feed_id,
							!selectedLabelMusicFeed.searchable
						)
					}
					onCancelClick={() => this.setState({ showLabelMusicFeedToggleModal: false })}
				/>
				<h3 className='h3 mb-3'>Catalog Dashboard</h3>
				<div className='row'>
					<div className={isAdministrator() ? 'col-10' : 'col-12'}>
						<CatalogStatistics />
					</div>
					{isAdministrator() && (
						<div className='col-2 catalog__container catalog__container--sidebar'>
							{/* <h4 className='h4 mb-2'>Reports Download</h4>
							<div className='px-3 pb-4 mb-3'>
								{this.getReportsDownloadButtonsData().map((b, i) => (
									<div className='d-flex flex-column' key={`btn-report-${i}`}>
										<button type='button' className='px-3 primary-btn' onClick={b.onClick}>
											{b.label}
										</button>
									</div>
								))}
							</div> */}
							<h4 className='h4 mb-2'>Label Music Feed Toggle</h4>
							<Loader loaded={labelMusicFeedsLoaded}>
								<div className='px-3 pb-4 '>
									{labelMusicFeeds.map((feed, i) => this.getLabelMusicFeedToggle(feed, i))}
								</div>
							</Loader>
						</div>
					)}
				</div>
			</div>
		);
	}
}
Catalog.contextType = GlobalContext;

export default Catalog;
