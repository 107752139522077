import * as qs from 'query-string';

import { apiUrl, getDefaultGetInit, getDefaultPostInit, parseStandardResponse } from '../utils/api';

const BLACKLIST_PATH = 'blacklist';

export async function getAllGenres() {
	const url = `${apiUrl}/${BLACKLIST_PATH}/genres`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function getBlacklistedGenres() {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-genres`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function setBlacklistedGenres(genres) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-genres`;
	const init = getDefaultPostInit({ genres });

	return parseStandardResponse(fetch(url, init));
}

export async function getBlacklistedSongsByISRC(limit = null, page = null) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-isrc-songs?${qs.stringify({
		limit,
		page,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function removeBlacklistedISRC(isrc) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-isrc-songs/remove`;
	const init = getDefaultPostInit({ isrc });

	return parseStandardResponse(fetch(url, init));
}

export async function addSongToBlacklistByISRC(isrc) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-isrc-songs/add`;
	const init = getDefaultPostInit({ isrc });

	return parseStandardResponse(fetch(url, init));
}

export async function updateBlacklistRecord(blacklistTypeID, value, note) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/update-notes`;
	const init = getDefaultPostInit({ blacklistTypeID, value, note });

	return parseStandardResponse(fetch(url, init));
}

export async function getBlacklistedArtists(limit = null, page = null) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-artists?${qs.stringify({
		limit,
		page,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function removeBlacklistedArtist(artist) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-artists/remove`;
	const init = getDefaultPostInit({ artist });

	return parseStandardResponse(fetch(url, init));
}

export async function getPlaylistUsage(blacklistTypeID, value) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/playlist-usage`;
	const init = getDefaultPostInit({ blacklistTypeID, value });

	return parseStandardResponse(fetch(url, init));
}

export async function addArtistToBlacklist(artist, musicFeedIDs) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-artists/add`;
	const init = getDefaultPostInit({ artist, musicFeedIDs });

	return parseStandardResponse(fetch(url, init));
}

export async function getBlacklistedSongsByVariisID(limit = null, page = null) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-variis-id-songs?${qs.stringify({
		limit,
		page,
	})}`;
	const init = getDefaultGetInit();

	return parseStandardResponse(fetch(url, init));
}

export async function removeBlacklistedVariisID(variisID) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-variis-id-songs/remove`;
	const init = getDefaultPostInit({ variisID });

	return parseStandardResponse(fetch(url, init));
}

export async function addSongToBlacklistByVariisID(variisID) {
	const url = `${apiUrl}/${BLACKLIST_PATH}/blacklisted-variis-id-songs/add`;
	const init = getDefaultPostInit({ variisID });

	return parseStandardResponse(fetch(url, init));
}
