import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import * as playlistsApi from '../../api/playlists';
import { BRANDS, BRANDS_MAP } from '../../constants/brands';
import { DROP_ZONES } from '../../constants/drop-zones';
import * as PERMISSIONS from '../../constants/permissions';
import { TOAST_LEVEL } from '../../constants/toast-levels';
import GlobalContext from '../../contexts/GlobalContext';
import addToFolderImg from '../../images/add-folder-white.svg';
import alertImg from '../../images/alert.svg';
import approvedImg from '../../images/approved.svg';
import blacklistImg from '../../images/blacklist.svg';
import rejectedImg from '../../images/block-white.svg';
import dashboardImg from '../../images/dashboard.svg';
import showPlaylistsImg from '../../images/external-playlists.svg';
import genresManagerImg from '../../images/genres-manager.svg';
import healthDashboardImg from '../../images/health-dashboard.svg';
import listChevronImg from '../../images/list-chevron.svg';
import musicCatalogFolderImg from '../../images/music-catalog-white.svg';
import licesingImg from '../../images/music_library.svg';
import pendingImg from '../../images/pending.svg';
import playlistsImg from '../../images/playlists.svg';
import plusImg from '../../images/plus.svg';
import settingsImg from '../../images/settings.svg';
import songsImg from '../../images/songs.svg';
import teamsImg from '../../images/teams.svg';
import {
	getBrands,
	getUser,
	hasPermission,
	isAdministrator,
	isContentCreator,
	isInstructor,
	isSharedFolderUser,
	isViewOnly,
} from '../../utils/auth';

import NavItem from './NavItem';

class SideNavigation extends React.Component {
	constructor(props) {
		super(props);
		this.handleNewPlaylist = this.handleNewPlaylist.bind(this);
	}

	getName = user => {
		if (user.first_name && user.middle_name && user.last_name) {
			return `${user.first_name} ${user.middle_name} ${user.last_name}`;
		}
		return user.username;
	};

	handleNewPlaylist = async () => {
		const { history } = this.props;
		const { setShowNewPlaylistModal, getSearchPlaylists, createToast } = this.context;

		// if it is a SoulCycle or Equinox playlist, show NewPlaylistModal where they will specify the Playlist Type
		const userBrands = getBrands();
		if (userBrands.includes(BRANDS.SOULCYCLE) || userBrands.includes(BRANDS.EQUINOX)) {
			setShowNewPlaylistModal(true);
			return;
		}

		// all other brands create a new playlist as usual
		try {
			const playlists = await playlistsApi.createPlaylist();

			getSearchPlaylists(true);

			if (playlists && playlists.length === 1) {
				history.push(`/playlist/${playlists[0].playlist_id}`);
			}
		} catch (err) {
			createToast(
				'There was an error creating new playlist, please refresh the page and try again later',
				TOAST_LEVEL.ERROR,
				true
			);
		}
	};

	handleNewFolder = async () => {
		const { setShowNewFolderModal } = this.context;

		setShowNewFolderModal(true);
	};

	renderClickableOption = (callback, labelText, imgSrc, altText) => {
		const { isDragging } = this.context;

		return (
			<div
				className={classNames('sidenav__item sidenav__item__new', {
					'sidenav__item--drop-disabled': isDragging,
				})}
				onClick={() => callback()}
			>
				<img src={imgSrc} alt={altText} className='mr-0' />
				<span className='align-self-center h6 mb-0'>{labelText}</span>
			</div>
		);
	};

	render() {
		const INSTRUCTOR = isInstructor();
		const CONTENT_CREATOR = isContentCreator();
		const ADMINISTRATOR = isAdministrator();
		const user = getUser();
		const brandNames = getBrands().map(brand => BRANDS_MAP[brand]);
		const { showPlayer } = this.context;
		const { match } = this.props;

		return (
			<div
				className={classNames('sidenav', {
					'sidenav--playing': showPlayer,
				})}
			>
				<div className='global-header'>
					{ADMINISTRATOR ? (
						<NavLink className='global-header__text' to='/' exact>
							Equinox +
						</NavLink>
					) : (
						<NavLink className='global-header__text' to='/' exact>
							{brandNames.map(brand => (
								<div key={brand}>{brand}</div>
							))}
						</NavLink>
					)}
				</div>
				<div className='sidenav__user'>
					<h6 className='h6 h6--white'>{this.getName(user)}</h6>
					{INSTRUCTOR ? <div className='sidenav__user__role'>Instructor</div> : null}
					{CONTENT_CREATOR ? <div className='sidenav__user__role'>Content Creator</div> : null}
					{ADMINISTRATOR ? <div className='sidenav__user__role'>Administrator</div> : null}
					{hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR]) && (
						<div className='sidenav__user__permission'>
							<span className='sidenav__user__badge'>Shared Folder Creator</span>
						</div>
					)}
					{hasPermission([PERMISSIONS.SHARED_FOLDER_VIEWER]) && (
						<div className='sidenav__user__permission'>
							<span className='sidenav__user__badge'>Shared Folder Viewer</span>
						</div>
					)}
					{hasPermission([PERMISSIONS.CONTENT_CREATOR_VIEW_ONLY]) && (
						<div className='sidenav__user__permission'>
							<span className='sidenav__user__badge'>Content Creator Viewer</span>
						</div>
					)}
				</div>
				<div className='sidenav__content'>
					<div className='sidenav__items'>
						{INSTRUCTOR || CONTENT_CREATOR ? (
							<>
								<NavItem
									navigateTo='/dashboard'
									imageSrc={dashboardImg}
									imageAlt='Dashboard'
									label='Dashboard'
									forceActive={['/', '/dashboard'].includes(match.path) && match.isExact}
								/>
								{isSharedFolderUser() && (
									<NavItem
										navigateTo='/folders'
										imageSrc={musicCatalogFolderImg}
										imageAlt='Catalog Folders'
										label='Catalog Folders'
									/>
								)}
							</>
						) : null}
						{ADMINISTRATOR ? (
							<>
								<NavItem
									navigateTo='/health'
									imageSrc={healthDashboardImg}
									imageAlt='Health Dashboard'
									label='Health Dashboard'
									forceActive={
										((match.path === '/' && !(INSTRUCTOR || CONTENT_CREATOR)) || match.path === '/health') &&
										match.isExact
									}
								/>
								<NavItem
									navigateTo='/genres-manager'
									imageSrc={genresManagerImg}
									imageAlt='Genres Manger'
									label='Genres Manager'
								/>
								<NavItem navigateTo='/settings' imageSrc={settingsImg} imageAlt='Settings' label='Settings' />
							</>
						) : null}
						{INSTRUCTOR ? (
							<>
								<NavItem
									navigateTo='/my-playlists'
									imageSrc={playlistsImg}
									imageAlt='My Playlists'
									label='My Playlists'
								/>
								<NavItem
									navigateTo='/my-songs'
									imageSrc={songsImg}
									imageAlt='My Songs'
									label='My Songs'
									droppableID={DROP_ZONES.MY_SONG_NAV}
									droppablePlaceholder='Add to My Songs'
								/>
							</>
						) : null}
						{CONTENT_CREATOR ? (
							<>
								<NavItem
									navigateTo='/playlists/pending'
									imageSrc={pendingImg}
									imageAlt='Pending Playlists'
									label='Pending'
								/>
								<NavItem
									navigateTo='/playlists/approved'
									imageSrc={approvedImg}
									imageAlt='Approved Playlists'
									label='Approved'
								/>
								<NavItem
									navigateTo='/playlists/invalid'
									imageSrc={alertImg}
									imageAlt='Invalid Playlists'
									label='Invalid'
								/>
								<NavItem
									navigateTo='/playlists/rejected'
									imageSrc={rejectedImg}
									imageAlt='Rejected Playlists'
									label='Rejected'
								/>
								{!isViewOnly() && (
									<NavItem
										navigateTo='/show-playlists'
										imageSrc={showPlaylistsImg}
										imageAlt='Show Playlists'
										label='Show Playlists'
									/>
								)}
							</>
						) : null}
						{ADMINISTRATOR || (CONTENT_CREATOR && !isViewOnly()) ? (
							<>
								<NavItem navigateTo='/catalog' imageSrc={dashboardImg} imageAlt='Catalog' label='Catalog' />
								<NavItem
									navigateTo='/validation'
									imageSrc={listChevronImg}
									imageAlt='Songs Validation'
									label='Songs Validation'
								/>
								<NavItem navigateTo='/blacklist' imageSrc={blacklistImg} imageAlt='Blacklist' label='Blacklist' />
							</>
						) : null}
						{ADMINISTRATOR ? (
							<>
								<NavItem navigateTo='/team' imageSrc={teamsImg} imageAlt='My Team' label='Team' />
							</>
						) : null}
					</div>
					{INSTRUCTOR || CONTENT_CREATOR ? (
						<>
							<div className='sidenav__actions sidenav__items'>
								{hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR]) &&
									this.renderClickableOption(this.handleNewFolder, 'New Folder', addToFolderImg, 'Create Folder')}
								{INSTRUCTOR &&
									this.renderClickableOption(this.handleNewPlaylist, 'New Playlist', plusImg, 'Add Playlist')}
							</div>
						</>
					) : null}
				</div>
			</div>
		);
	}
}

SideNavigation.propTypes = {
	history: PropTypes.objectOf(PropTypes.any).isRequired,
};

SideNavigation.contextType = GlobalContext;

export default withRouter(SideNavigation);
