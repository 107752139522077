import { apiUrl, getDefaultPostInit, parseStandardResponse } from '../utils/api';

const UTILS_PATH = 'utils';

export async function triggerReindex({ index, destructiveReindex }) {
	const url = `${apiUrl}/${UTILS_PATH}/reindex`;
	const init = getDefaultPostInit({ index, destructiveReindex });

	return parseStandardResponse(fetch(url, init));
}

export const formatPlaylistFiltersPayload = ({
	playlistSourceFilter,
	playlistBrandsFilter,
	playlistStatusesFilter,
} = {}) => ({
	...(playlistSourceFilter && { playlistSourceFilter }),
	...(playlistBrandsFilter && { playlistBrandsFilter: JSON.stringify(playlistBrandsFilter) }),
	...(playlistStatusesFilter && { playlistStatusesFilter: JSON.stringify(playlistStatusesFilter) }),
});
