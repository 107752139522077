import React, { useState, useEffect } from 'react';

import Switch from 'react-switch';

import {
	getDashboardNumbers,
	getDashboardGenresBreakdown,
	getDashboardLabelsBreakdown,
	getDashboardPublishersBreakdown,
} from '../../api/dashboard';
import Loader from '../../components/Loader';

import GeneralNumbers from './GeneralNumbers';
import SongNumbers from './SongNumbers';

const CatalogStatistics = () => {
	const [includePurgedSongs, setIncludePurgedSongs] = useState(true);
	const [onlyValidSongs, setOnlyValidSongs] = useState(false);
	const [catalogNumbers, setCatalogNumbers] = useState({
		cleared_songs: 0,
		invalid_songs: 0,
		purged_songs: 0,
		total_songs: 0,
		genres: 0,
		labels: 0,
		publishers: 0,
	});
	const [genreBreakdown, setGenreBreakdown] = useState([]);
	const [labelBreakdown, setLabelBreakdown] = useState([]);
	const [publisherBreakdown, setPublisherBreakdown] = useState([]);
	const [statisticsLoaded, setStatisticsLoaded] = useState(false);

	const fetchDashboardStatistics = (includePurgedSongsArg, onlyValidSongsArg) => {
		setStatisticsLoaded(false);

		Promise.all([
			getDashboardNumbers(includePurgedSongsArg, onlyValidSongsArg),
			getDashboardGenresBreakdown(includePurgedSongsArg, onlyValidSongsArg),
			getDashboardLabelsBreakdown(includePurgedSongsArg, onlyValidSongsArg),
			getDashboardPublishersBreakdown(includePurgedSongsArg, onlyValidSongsArg),
		]).then(
			([dashboardNumbersResponse, genresBreakdownResponse, labelsBreakdownResponse, publishersBreakdownResponse]) => {
				setCatalogNumbers(dashboardNumbersResponse);
				setGenreBreakdown(genresBreakdownResponse);
				setLabelBreakdown(labelsBreakdownResponse);
				setPublisherBreakdown(publishersBreakdownResponse);
				setStatisticsLoaded(true);
			}
		);
	};

	useEffect(() => {
		fetchDashboardStatistics(includePurgedSongs, onlyValidSongs);
	}, [includePurgedSongs, onlyValidSongs]);

	return (
		<Loader loaded={statisticsLoaded} className='licesing-dashboard__loader'>
			<div className='d-flex justify-content-between'>
				<h4 className='h4 mb-4'>Catalog Statistics</h4>
				<div>
					<label className='mr-4'>
						<span className='playlist__switch__label'>Included Purged Songs</span>
						<Switch
							onChange={() => setIncludePurgedSongs(!includePurgedSongs)}
							checked={includePurgedSongs}
							checkedIcon={false}
							uncheckedIcon={false}
							height={20}
							width={40}
							className='mt-1'
							onColor='#000'
							offColor='#d2d2d2'
							disabled={!statisticsLoaded}
						/>
					</label>
					<label className='mr-4'>
						<span className='playlist__switch__label'>Show Valid Songs Only</span>
						<Switch
							onChange={() => setOnlyValidSongs(!onlyValidSongs)}
							checked={onlyValidSongs}
							checkedIcon={false}
							uncheckedIcon={false}
							height={20}
							width={40}
							className='mt-1'
							onColor='#000'
							offColor='#d2d2d2'
							disabled={!statisticsLoaded}
						/>
					</label>
				</div>
			</div>
			<SongNumbers
				clearedSongs={catalogNumbers.cleared_songs}
				invalidSongs={catalogNumbers.invalid_songs}
				purgedSongs={catalogNumbers.purged_songs}
				totalSongs={catalogNumbers.total_songs}
			/>
			<GeneralNumbers
				genreBreakdown={genreBreakdown}
				labelBreakdown={labelBreakdown}
				publisherBreakdown={publisherBreakdown}
				numGenres={catalogNumbers.genres}
				numLabels={catalogNumbers.labels}
				numPublishers={catalogNumbers.publishers}
			/>
		</Loader>
	);
};

export default CatalogStatistics;
