export const PLAYLISTS_SORT_OPTIONS = {
	SUBMITTED_DATE: {
		value: 'submitted-date',
		label: 'Submitted Date',
	},
	PLAYLIST_TITLE: {
		value: 'playlist-title',
		label: 'Playlist Title',
	},
	CREATED_BY: {
		value: 'created-by',
		label: 'Created By',
	},
	CREATED_DATE: {
		value: 'created-date',
		label: 'Created Date',
	},
};

export const SORT_ORDER = {
	ASC: 'asc',
	DESC: 'desc',
};

export const MY_SONGS_SORT_OPTIONS = [
	{ value: 'user_song.added_timestamp', label: 'Recently Added', default: true, order: SORT_ORDER.DESC },
	{ value: 'name', label: 'Song', order: SORT_ORDER.ASC },
	{ value: 'artist', label: 'Artist', order: SORT_ORDER.ASC },
	{ value: 'genre', label: 'Genre', order: SORT_ORDER.ASC },
	{ value: 'duration', label: 'Length', order: SORT_ORDER.ASC },
	{ value: 'track_source', label: 'Track Source', order: SORT_ORDER.ASC },
];
